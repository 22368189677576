import React from 'react';
import { get } from '../../../lib/strings';
import MediaTile from '../../../components/core/tiles/media-tile/MediaTile';
import SocialMediaIcons from '../../../components/core/social-media-icons/SocialMediaIcon';
import DashboardTile from '../../../components/core/tiles/dashboard-tile/DashboardTile';
import ExternalLinkButton from '../../../components/core/link/ExternalLinkButton';
import numberOneImg from '../../../assets/number-one.svg';
import './NoOrganisation.scss';

const NoOrganisation = () => {
  return (
    <div className="img-facility page">
      <div className="page-header">
        <h3 className="--dark review-page-section-title">
          <div>{get('startedPage-noOrgGreetingTitle')}</div>
        </h3>

        <div className="dashboard-no-org-tiles-row">
          {/* Disable user tour tile */}
          {/* <div className="dashboard-no-org-tile"> */}
          {/* <DashboardTile
              isActivePage
              title={get('dashboard-GettingStarted')}
              subTitle={get('dashboard-take-a-tour')}
              content={get('dashboard-take-a-tour-description')}
              className="dashboard-number"
              imgSourse={numberOneImg}
            /> */}
          {/* </div> */}
          <div className="dashboard-no-org-tile">
            <DashboardTile
              isActivePage
              href="/create-facility"
              title={get('dashboard-GettingStarted')}
              subTitle={get('create-facility')}
              content={get('create-facility-description')}
              className="dashboard-number"
              imgSourse={numberOneImg}
            />
          </div>
          {/* add transparent tile to fill in flex row */}
          <div className="dashboard-no-org-tile dashboard-transparent-tile" />
        </div>
        <div className="dashboard-container-tiles-row">
          <div className="dashboard-social-media-tile">
            <MediaTile
              title={get('component-SocialMediaWidgetTitle')}
              description={get('component-SocialMediaWidgetDescription')}
              subLinkText={`#${get('component-SocialMediaTwitterTag')}`}// '#ForSustainableGolf',
              subLink={get('component-SocialMediaTwitterTag')}
              InsertButtonNode={<SocialMediaIcons />}
            />
          </div>

          <div className="dashboard-social-media-tile">
            <MediaTile
              title={get('component-HighLightTileTitle')}
              description={get('component-HighLightTileDescription')}
              InsertButtonNode={(
                <ExternalLinkButton
                  className="dashboard-to-highlight-button medium"
                  href="https://sustainable.golf/highlights"
                >
                  {get('component-HighLightTileButton')}
                </ExternalLinkButton>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoOrganisation;
