import React from 'react';
import PropTypes from 'prop-types';

const QuestionMarkCompleteImg = ({ fill, className }) => {
  return (
    <svg id="icon-section-complete" className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <circle id="Ellipse_1383" data-name="Ellipse 1383" cx="12" cy="12" r="12" fill={fill} />
      <path id="Path_19158" data-name="Path 19158" d="M11.192-.125a1.048,1.048,0,0,0,.923-.536l4.9-7.647q.091-.171.177-.353a.853.853,0,0,0,.085-.365.745.745,0,0,0-.29-.61,1,1,0,0,0-.644-.234.952.952,0,0,0-.809.524l-4.374,7L9.108-4.991a1.2,1.2,0,0,0-.393-.336.964.964,0,0,0-.427-.1.853.853,0,0,0-.644.268.9.9,0,0,0-.256.644,1.153,1.153,0,0,0,.273.707L10.235-.661a1.469,1.469,0,0,0,.444.41A1.045,1.045,0,0,0,11.192-.125Z" transform="translate(-0.331 16.996)" fill="#fff" />
    </svg>
  );
};

export default QuestionMarkCompleteImg;

QuestionMarkCompleteImg.defaultProps = {
  fill: '#877972',
  className: '',
};

QuestionMarkCompleteImg.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};
