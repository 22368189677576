import React from 'react';
import RegistrationResponseWidget from '../../../components/registration/registration-response-widget/RegistrationResponseWidget';
import { get } from '../../../lib/strings';

const InvalidRegistrationTokenPage = () => (
  <RegistrationResponseWidget
    heading={get('invalidRegistrationToken-ErrorTtile')}
    bodyText={get('invalidRegistrationToken-ErrorDescription')}
    href="/login"
  />
);

export default InvalidRegistrationTokenPage;
