import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../lib/strings';

const FacilityTeamControls = ({ orgUser, onClickUserAction }) => {
  const actionLeave = get('facilityProfileTeam-action-leave');
  const actionRemove = get('facilityProfileTeam-action-remove');
  const actionReInvite = get('facilityProfileTeam-action-reInvite');
  const actionSwitchOwner = get('facilityProfileTeam-action-switchOwner');

  // actions are set in the facilityTeam component and attahed to the orgUser object
  const {
    switchOwner, leave, remove, reInvite,
  } = orgUser.cta;

  return (
    <div className="badge-container-buttons">
      {switchOwner && (
        <button
          type="button"
          className={
            `profile-facility-team-roles-delete-button --red
            `
    }
          data-cy="profile-switch-role-button"
          onClick={() => onClickUserAction(orgUser, 'switch')}
        >
          {actionSwitchOwner}
        </button>
      ) }

      {leave && (
      <button
        type="button"
        className={
            `profile-facility-team-roles-delete-button --red
            `
    }
        data-cy="profile-delete-role-button"
        onClick={() => onClickUserAction(orgUser, 'leave')}
      >

        {actionLeave}
      </button>
      )}

      {reInvite
      && (
      <button
        type="button"
        className={
            `profile-facility-team-roles-delete-button --green
            `
    }
        data-cy="profile-re-invite-role-button"
        onClick={() => onClickUserAction(orgUser, 'reInvite')}
      >
        {actionReInvite}
      </button>
      )}

      {remove && (
      <button
        type="button"
        className={
                `profile-facility-team-roles-delete-button --red
                `
            }
        data-cy="profile-delete-role-button"
        onClick={() => onClickUserAction(orgUser, 'delete')}
      >
        {actionRemove}
      </button>
      )}
    </div>
  );
};

FacilityTeamControls.propTypes = {
  orgUser: PropTypes.shape({
    id: PropTypes.string,
    userType: PropTypes.string,
    cta: PropTypes.string,
  }).isRequired,
  onClickUserAction: PropTypes.func.isRequired,

};
export default FacilityTeamControls;
