import React, {
  useState, useContext, useRef, useEffect, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { useForm, useFieldArray } from 'react-hook-form';
import { Accordion } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';
import AccordionCard from '../core/accordionCard/AccordionCard';
import Button from '../core/button/Button';
import NestedQuestions from '../core/NestedQuestions/NestedQuestions';
import RowQuestions from '../core/row-questions/RowQuestions';
import DataGridQuestions from '../core/datagrid-questions/DataGridQuestions';
import MultipleChoiceQuestions from '../core/MultipleChoiceQuestions/MultipleChoiceQuestions';
import SidebarModal from '../core/sidebarModal/SidebarModal';
import Modal from '../core/modal/Modal';
import { defaultUserUnits } from '../../lib/utils';
import Notes from '../core/addNotes/Notes';
import questionTypes from '../../lib/consts/questionTypes';
import reviewsService from '../../services/reviews/reviews-service';
import answerSetUtils from '../../lib/answerSetUtils';
import answerSetUnsavetils from '../../lib/answerSetUnsaveUtils';
import { QuestionMarkCompleteImg, QuestionMarkReviewImg } from '../Icons';
import RouteLeavingGuard from '../warningUnSaveGuardModal/RouteLeavingGuard';
import './QuestionsWrapper.scss';

import { get, stringSanitizer } from '../../lib/strings';
import CalculatingQuestion from '../core/row-questions/CalculatingQuestion';

const QuestionsWrapper = ({
  question,
  onComplete,
  secondary,
  answerData,
  notesHandlers,
  answerPreviousYearData,
  colour,
}) => {
  // get color based on type of question-set i.e. type:color = nature:green, resources:orange etc.
  const { user = {}, org } = useContext(GlobalContext);
  const { type } = useParams();

  const [guidanceModal, updateGuidanceModal] = useState(false);
  const [submitting, updateSubmitting] = useState(false);
  const [noteLoading, updateNoteLoading] = useState(false);
  const [clickedSaveButton, updateClickedSaveButton] = useState(false);
  // pass to data grid for checking isDirty or not upon saved or not

  const childRef = useRef();
  // set question status as boolean :isComplete
  const [isCompleteStatus, setIsCompleteStatus] = useState(false);
  const [isSaveInProgressStatus, setIsSaveInProgressStatus] = useState(false);
  const [customerAnswerFieldsWithIndex, updateCustomerAnswerFieldsWithIndex] = useState({});

  const DEFAULT_USER_UNITS = defaultUserUnits(org.measurement_unit);

  const isDropdown = question.uiType === questionTypes.DROPDOWNLIST;

  // Guidance Section
  const countryGuidance = question.guidance ? question.guidance[org.country_lookup_id] : null;
  const tournamentGroupsGuidance = question.guidanceTourGroups
    ? question.guidanceTourGroups[org.trounamentGroup]
    : null;
  const organistionGroupsGuidance = question.guidanceOrgGroups
    ? question.guidanceOrgGroups[org.organisationGroup]
    : null;

  const renderGuidanceSection = (data) => {
    if (!data) return null;

    return (
      <div key={data.title}>
        {data.title && (
          <div
            className="mb-2 guidance_title"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: stringSanitizer(data.title),
            }}
          />
        )}
        {data.summary && (
          <div
            className="mb-2"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: stringSanitizer(data.summary),
            }}
          />
        )}

        {data.text && (
          <div
            className="mb-2"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: stringSanitizer(data.text),
            }}
          />
        )}
      </div>
    );
  };

  const defaultValues = {
    ...question,
    answers:
      question.answers && question.answers.length !== 0
        ? question.answers.filter((obj) => obj.active).sort((a, b) => a.sortOrder - b.sortOrder)
        : question.rowQuestions,
    // IF answers == null , will assign rowQuestions for ansers,
    // so rowQuestions is able to through hook range
    notes: question.notes,
  };

  const {
    register,
    handleSubmit,
    errors,
    watch,
    control,
    getValues,
    setValue,
    clearErrors,
    formState,
  } = useForm({
    defaultValues,
  });
  const history = useHistory();

  useEffect(() => {
    const answerFromPayload = answerSetUtils.pullOutAnswerById(question.id, answerData);
    if (answerFromPayload && answerFromPayload.complete) {
      setIsCompleteStatus(answerFromPayload.complete);
    }
    if (answerFromPayload && answerFromPayload.savelater) {
      setIsSaveInProgressStatus(answerFromPayload.savelater);
    }
  }, []);

  const watchAllFields = watch();

  // Array of answers
  const {
    fields: answersFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'answers',
  });

  // Array of notes
  const {
    fields: notesFields,
    prepend,
    append: notesAppend,
    remove: notesRemove,
  } = useFieldArray({
    control,
    name: 'notes',
  });

  const submitForm = async (answers, isSaveInProgress = false) => {
    updateSubmitting(true);
    // dropdown fields return undefined when not set, if so, we should just bomb-out here
    if (isDropdown && answers && answers.dropdownAnwser === undefined) return;

    let customerAnswers;
    if (question.uiType === questionTypes.DATAGRID) {
      const answersDataGrid = childRef.current.getDatagridAnswers();
      // eslint-disable-next-line no-param-reassign
      answers = answersDataGrid.dataGridAnswers;
      customerAnswers = answersDataGrid.customerAnswerFields;
    }

    const payload = reviewsService.shapeAnswersToPayload(
      answers,
      question.uiType,
      question.questionType,
    );

    if (question.uiType === questionTypes.DATAGRID) {
      payload.facilityAddedAnswers = customerAnswers;
      // reviewsService
      //   .shapeFacilityAddedAnswersForDataGrid(customerAnswers);
    } else {
      payload.facilityAddedAnswers = reviewsService.shapeFacilityAddedAnswers(answersFields);
    }

    if (isSaveInProgress === true) {
      setIsCompleteStatus(false);
      setIsSaveInProgressStatus(true);
      payload.complete = false;
      payload.savelater = isSaveInProgress;
      clearErrors();
    } else {
      // is from complete button
      setIsCompleteStatus(true);
      setIsSaveInProgressStatus(false);
      payload.complete = true;
      payload.savelater = false;
    }

    await onComplete({ [question.id]: payload });
    updateSubmitting(false);
    updateClickedSaveButton(true); // pass to data grid component
  };

  const submittingNotes = async (note) => {
    updateNoteLoading(true);
    const updatedNote = await notesHandlers.updateNotes(note);
    updateNoteLoading(false);
    return updatedNote;
  };

  const editingNotes = async (noteId, note) => {
    updateNoteLoading(true);
    const updatedNote = await notesHandlers.editNotes(noteId, question.id, note);
    updateNoteLoading(false);
    return updatedNote;
  };

  const deleteNote = async (noteId, index) => {
    updateNoteLoading(true);
    await notesHandlers.deleteNote(noteId, question.id);
    notesRemove(index);
    updateNoteLoading(false);
  };

  const getStatusIconByComplete = () => {
    if (isCompleteStatus) return QuestionMarkCompleteImg;
    if (isSaveInProgressStatus) return QuestionMarkReviewImg;
  };
  const saveAsComplete = async () => {
    // auto-validation
    const answers = getValues();
    handleSubmit(submitForm(answers));
  };

  const saveForLater = async () => {
    const answers = getValues();
    submitForm(answers, true);
  };

  return (
    <div className="components__questions-wrapper question-wrapper">
      {question.active && (
        <Accordion>
          <Form
            id={question.id}
            className="question-form-wrapper"
            onSubmit={handleSubmit(submitForm)}
          >
            <AccordionCard
              colour={colour}
              headerTitle={question.title}
              eventKey={1}
              secondary={secondary}
              StatusIcon={getStatusIconByComplete()}
              isRequiredCert={question.requiredForCertification}
            >
              <div className="question-outer-container">
                <div className="question-wrapper ">
                  <>
                    {(question.uiType === questionTypes.MULTIPLECHOICE_ADDMORE
                      || question.uiType === questionTypes.MULTIPLECHOICE
                      || question.uiType === questionTypes.SINGLECHOICE
                      || question.uiType === questionTypes.DROPDOWNLIST) && (
                      <MultipleChoiceQuestions
                        instructions={question.instructions}
                        answers={answersFields}
                        isDropdown={isDropdown}
                        answerData={answerSetUtils.pullOutAnswerById(question.id, answerData)}
                        answerPreviousYearData={answerSetUtils.pullOutAnswerById(
                          question.id,
                          answerPreviousYearData,
                        )}
                        isMultiple={question.isMultiple}
                        allowAddMore={question.allowAddMore}
                        questionType={question.uiType}
                        // colorClass={colorClass}
                        color={colour}
                        checkIcon={type}
                        {...{
                          control,
                          register,
                          watchAllFields,
                          setValue,
                          append,
                          remove,
                          getValues,
                          handleSubmit,
                        }}
                      />
                    )}

                    {question.uiType === questionTypes.MULTIPLECHOICE_NESTED && (
                      <>
                        <NestedQuestions
                          instructions={question.instructions}
                          answers={answersFields}
                          userUnits={DEFAULT_USER_UNITS}
                          userMeasurementUnit={org.measurement_unit}
                          answerData={answerSetUtils.pullOutAnswerById(question.id, answerData)}
                          {...{
                            control,
                            register,
                            getValues,
                            setValue,
                          }}
                          answerPreviousYearData={answerSetUtils.pullOutAnswerById(
                            question.id,
                            answerPreviousYearData,
                          )}
                          colour={colour}
                        />
                      </>
                    )}

                    {question.uiType === questionTypes.TEXTINPUTS
                      && question.questionType !== questionTypes.CALCULATING && (
                        <RowQuestions
                          instructions={question.instructions}
                          questions={answersFields}
                          userUnits={DEFAULT_USER_UNITS}
                          userMeasurementUnit={org.measurement_unit}
                          answerData={answerSetUtils.pullOutAnswerById(question.id, answerData)}
                          answerPreviousYearData={answerSetUtils.pullOutAnswerById(
                            question.id,
                            answerPreviousYearData,
                          )}
                          submitForm={submitForm}
                          {...{
                            control,
                            register,
                            getValues,
                            setValue,
                            errors,
                          }}
                        />
                    )}
                    {question.uiType === questionTypes.TEXTINPUTS
                      && question.questionType === questionTypes.CALCULATING && (
                        <CalculatingQuestion
                          colour={colour}
                          title={question.title}
                          instructions={question.instructions}
                          questions={answersFields}
                          userUnits={DEFAULT_USER_UNITS}
                          userMeasurementUnit={org.measurement_unit}
                          unitType={question.unitType}
                          answerData={answerSetUtils.pullOutAnswerById(question.id, answerData)}
                          answerPreviousYearData={answerSetUtils.pullOutAnswerById(
                            question.id,
                            answerPreviousYearData,
                          )}
                          submitForm={submitForm}
                          {...{
                            control,
                            register,
                            getValues,
                            setValue,
                            errors,
                          }}
                        />
                    )}
                    {question.uiType === questionTypes.DATAGRID && (
                      <DataGridQuestions
                        instructions={question.instructions}
                        user={user}
                        colour={colour}
                        // colour={colorClass}
                        userMeasurementUnit={org.measurement_unit}
                        answers={answersFields}
                        answerData={answerSetUtils.pullOutAnswerById(question.id, answerData)}
                        answerPreviousYearData={answerSetUtils.pullOutAnswerById(
                          question.id,
                          answerPreviousYearData,
                        )}
                        ref={childRef}
                        verticalTableLayout={question.verticalTableLayout}
                        userUnits={DEFAULT_USER_UNITS}
                        customerAnswerFieldsWithIndex={customerAnswerFieldsWithIndex}
                        updateCustomerAnswerFieldsWithIndex={updateCustomerAnswerFieldsWithIndex}
                        clickedSaveButton={clickedSaveButton}
                        updateClickedSaveButton={updateClickedSaveButton}
                        color={colour}
                        {...{
                          control,
                          register,
                          getValues,
                          watch,
                          errors,
                          setValue,
                          append,
                          handleSubmit,
                          formState,
                        }}
                      />
                    )}
                  </>
                </div>

                <div className="question-guidance-wrapper">
                  <p className="summaryText">
                    <i>{question.summary}</i>
                  </p>
                  <div className="question-guidance-button__container">
                    {(question.guidance && (question.guidance.all || countryGuidance))
                    || (question.guidanceTourGroups
                      && (question.guidanceTourGroups.all || tournamentGroupsGuidance))
                    || (question.guidanceOrgGroups
                      && (question.guidanceOrgGroups.all || organistionGroupsGuidance)) ? (
                        <Button
                          className="question-guidance-button"
                          onClick={() => updateGuidanceModal(true)}
                          colour={colour}
                          inverse
                        >
                          {get('question-set-guidanceButton')}
                        </Button>
                      ) : null}
                  </div>
                  <Button
                    loading={submitting}
                    className="question-guidance-button"
                    colour={colour}
                    onClick={saveForLater}
                    type="submit"
                  >
                    {get('bestPractices-saveLaterButton')}
                  </Button>
                  <Button
                    loading={submitting}
                    className="question-guidance-button --marginTop"
                    colour={colour}
                    onClick={saveAsComplete}
                    type="submit"
                  >
                    {get('bestPractices-saveCompleteButton')}
                  </Button>
                </div>
              </div>
              <div className="question-separator" />
              <div className="question-add-notes">
                <Notes
                  deleteNote={deleteNote}
                  loading={noteLoading}
                  submitNotes={submittingNotes}
                  editNotes={editingNotes}
                  notesFields={notesFields}
                  answerData={answerSetUtils.pullOutAnswerById(question.id, answerData)}
                  question={question}
                  name="notes"
                  {...{
                    notesAppend,
                    setValue,
                    prepend,
                    notesRemove,
                  }}
                  colour={colour}
                  // colourClass={colorClass}
                />
              </div>
            </AccordionCard>
          </Form>
        </Accordion>
      )}

      {window.innerWidth > 768 ? (
        <SidebarModal
          show={guidanceModal}
          onClose={() => updateGuidanceModal(false)}
          heading={get('question-set-modalGuidanceTitle')}
          bodyText={(
            <div className="guidance-modal-body">
              {/* Render guidance for all countries */}
              {question.guidance
                && question.guidance.all
                && question.guidance.all.map((item) => renderGuidanceSection(item))}

              {/* Render coutnry specific guidance */}
              {countryGuidance && renderGuidanceSection(countryGuidance)}

              {/* Render guidance for all tournament Groups */}
              {question.guidanceTourGroups
                && question.guidanceTourGroups.all
                && question.guidanceTourGroups.all.map((item) => renderGuidanceSection(item))}

              {/* Render tournament Group specific guidance */}
              {tournamentGroupsGuidance && renderGuidanceSection(tournamentGroupsGuidance)}

              {/* Render guidance for all organisation Groups */}
              {question.guidanceOrgGroups
                && question.guidanceOrgGroups.all
                && question.guidanceOrgGroups.all.map((item) => renderGuidanceSection(item))}

              {/* Render organisation Group specific guidance */}
              {organistionGroupsGuidance && renderGuidanceSection(organistionGroupsGuidance)}
            </div>
          )}
        />
      ) : (
        <Modal
          show={guidanceModal}
          onClose={() => updateGuidanceModal(false)}
          heading={get('question-set-modalGuidanceTitle')}
          bodyText={(
            <div className="guidance-modal-body">
              {/* Render guidance for all countries */}
              {question.guidance
                && question.guidance.all
                && question.guidance.all.map((item) => renderGuidanceSection(item))}

              {/* Render coutnry specific guidance */}
              {countryGuidance && renderGuidanceSection(countryGuidance)}
            </div>
          )}
        />
      )}

      <RouteLeavingGuard
        when={answerSetUnsavetils.isUpdateTrueAnswerOrNot(
          question,
          answerData,
          user,
          getValues(),
          childRef,
        )}
        navigate={(path) => history.push(path)}
        // eslint-disable-next-line no-unused-vars
        shouldBlockNavigation={(location) => {
          if (
            answerSetUnsavetils.isUpdateTrueAnswerOrNot(
              question,
              answerData,
              user,
              getValues(),
              childRef,
            )
          ) return true;
          return false;
        }}
      />
    </div>
  );
};

QuestionsWrapper.defaultProps = {
  onComplete: () => {},
  secondary: true,
  question: PropTypes.shape({}),
  answerPreviousYearData: [],
  colour: '#4CA471',
};

QuestionsWrapper.propTypes = {
  answerPreviousYearData: PropTypes.arrayOf(PropTypes.shape({})),
  answerData: PropTypes.shape({
    complete: PropTypes.bool,
  }).isRequired,
  onComplete: PropTypes.func,
  secondary: PropTypes.bool,
  question: PropTypes.shape({
    id: PropTypes.string,
    unitType: PropTypes.string,
    questionType: PropTypes.string,
    active: PropTypes.bool.isRequired,
    title: PropTypes.string,
    instructions: PropTypes.string,
    summary: PropTypes.string,
    uiType: PropTypes.string,
    guidance: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    guidanceTourGroups: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    guidanceOrgGroups: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    isMultiple: PropTypes.bool,
    allowAddMore: PropTypes.bool,
    requiredForCertification: PropTypes.bool,
    answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    notes: PropTypes.arrayOf(PropTypes.shape({})),
    rowQuestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    nestedQuestions: PropTypes.arrayOf(PropTypes.shape({})),
    verticalTableLayout: PropTypes.bool,
  }),
  notesHandlers: PropTypes.shape({
    deleteNote: PropTypes.func,
    updateNotes: PropTypes.func,
    editNotes: PropTypes.func,
  }).isRequired,
  colour: PropTypes.string,
};
export default QuestionsWrapper;
