import React, { useState, useEffect, useContext } from 'react';

import './AnnualReview.scss';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import { GlobalContext } from '../../../context/GlobalContext';
import Panel from '../../../components/core/panel/Panel';
import Button from '../../../components/core/button/Button';
import { get, stringSanitizer } from '../../../lib/strings';
import Modal from '../../../components/core/modal/Modal';
import DropdownField from '../../../components/core/form/DropdownField';
import CircularProgressCard from '../../../components/core/progress/circular/CircularProgressCard';
import questionSetLib from '../../../lib/questionSets';
import reviewService from '../../../services/reviews/reviews-service';
import themeType from '../../../lib/consts/themeType';

const sortAnswersByYear = (reviews) => reviews.sort((a, b) => b.year - a.year);

const Review = () => {
  const [addYearModal, updateAddYearModal] = useState(false);
  const [options, updateOptions] = useState([]);
  const { control, handleSubmit } = useForm({});
  const [reviews, addReviews] = useState([]);
  const {
    org, reviewLinks, user, updateReviewLinksAndAvailableYearsForAnnualData, theme,
  } = useContext(GlobalContext);
  const questionSetId = reviewService.pullOutAnnualDataId(reviewLinks);
  const sideBarMenuAnnualDataTitle = get('sidebar-AnnualData');

  const onSubmit = async (formData) => {
    // send request to the server
    await reviewService.createNewAnswerSet(
      org.organisation_id,
      questionSetId,
      formData.companyReportYears,
    );

    // returns new review id
    const updatedReviews = [
      ...reviews,
      {
        percentage: 0,
        year: formData.companyReportYears,
        id: formData.companyReportYears,
      },
    ];
    const sortedReviews = sortAnswersByYear(updatedReviews);

    addReviews(sortedReviews);

    const updatedOptions = options.filter((option) => option.key !== formData.companyReportYears);
    updateOptions(updatedOptions);
    updateReviewLinksAndAvailableYearsForAnnualData();
    updateAddYearModal(false);
  };

  useEffect(() => {
    if (questionSetId) {
      const getReviewsByOrg = async () => {
        const answers = await reviewService.getAnswerSetsByOrg(org.organisation_id, questionSetId);
        const yearOptions = await questionSetLib.getYearsFromAnswers(
          answers, { missingYears: true, isTournament: theme === themeType.TOURNAMENT },
        );
        const questionSections = await reviewService.getOrganisationQuestionsById(
          questionSetId,
          org.organisation_id,
          user.language,
        );

        const annualProgress = answers.map((ans) => {
          const activeQuestionsList = reviewService.activeQuestions(questionSections.sections);
          const activeQuestions = activeQuestionsList.length;
          if (activeQuestions === 0) return 0;

          // TODO: Duplicate IDs question and answers are generated here - Needs to be analysed
          const activeQuestionsListUnique = _.uniqWith(
            activeQuestionsList,
            (arrayVal, otherVal) => arrayVal.id === otherVal.id,
          );

          const completeAnswerCount = reviewService.getMarkedCompleteCount(
            ans.answers,
            activeQuestionsListUnique,
          );
          const percentage = (completeAnswerCount / activeQuestionsListUnique.length) * 100;

          return { ...ans, percentage: parseInt(percentage.toFixed(), 10) };
        });

        const sortedReviews = sortAnswersByYear(annualProgress);
        updateOptions(yearOptions);
        addReviews(sortedReviews);
      };

      getReviewsByOrg();
    }
  }, [questionSetId]);

  return (
    <>
      <Panel>
        <div className="container no-gutters">
          <div className="row panel-header">
            <div className="col-6 col-md-8 ">
              <h5> {get('review-AnnualDataCompletionWidgetTitle')}</h5>
              <p className="best-practices__panel-description">{get('review-AnnualDataTileDescription')}</p>
            </div>
            <div className="col-6 col-md-4 panel-button">
              {
                options.length > 0
                && <Button colour={theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471'} onClick={() => updateAddYearModal(true)}>{get('review-AnnualDataCompletionWidgetButtonText')}</Button>
              }
            </div>
          </div>
        </div>
        <div className="panel-content">
          <div className="annual-review__cards">
            {
              reviews.map((review) => {
                return (
                  <div className="annual-review__card-container" key={review.id}>
                    <CircularProgressCard key={review.id} percentage={review.percentage} href={`/annual-review/${sideBarMenuAnnualDataTitle}/${review.year}`} text={review.year} />
                  </div>
                );
              })
            }
          </div>
        </div>
        <hr />
        <div className="annual-review__benefits-blurb">
          <h5 className="annual-review__panel-title"> {get('review-AnnualDataWidgetBlurbTitle')}</h5>
          <div
            className="annual-review__panel-description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: stringSanitizer(get('review-AnnualDataWidgetBlurb')),
            }}
          />
        </div>
      </Panel>
      <Modal
        show={addYearModal}
        onClose={() => updateAddYearModal(false)}
        heading={get('review-AddAYearModalTitle')}
        subtitle={get('review-AddAYearModalDescription')}
        bodyText={(
          <div>
            <Form>
              <DropdownField
                label={get('review-AddAYearModalLabel')}
                control={control}
                name="companyReportYears"
                options={options}
              />
            </Form>
          </div>
        )}
        footer={(
          <Button
            onClick={
              handleSubmit(onSubmit)
            }
          >
            {get('review-AnnualDataCompletionWidgetButtonText')}
          </Button>
        )}
      />
    </>
  );
};

export default Review;
