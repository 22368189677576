import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { GlobalContext } from '../../../context/GlobalContext';
import Permissions from '../../../lib/permissions';

const PermissionsGated = ({ activities, children }) => {
  const globalContext = useContext(GlobalContext);
  const { org } = globalContext;

  const hasPermission = Permissions.doesUserHaveActivities(
    org.activities,
    activities,
  );

  if (!hasPermission) {
    return <></>;
  }

  return <>{children}</>;
};

PermissionsGated.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

export default PermissionsGated;
