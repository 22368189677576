import React, {
  useState, useContext, useImperativeHandle, forwardRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import HttpStatus from 'http-status-codes';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';
import { GlobalContext } from '../../context/GlobalContext';
import ProfileDetailsForm from '../../components/profile/profileDetails-form/ProfileDetailsForm';
import NewPasswordForm from '../../components/authentication/new-password-form/NewPasswordForm';
import Modal from '../../components/core/modal/Modal';
import GenericDropdownField from '../../components/core/form/GenericDropdownField';
import Button from '../../components/core/button/Button';
import Alert from '../../components/core/alert/Alert';
import arrayUtils from '../../utils/array';
import userService from '../../services/user/user-service';
import ErrorCodes from '../../lib/error-codes';
import { get } from '../../lib/strings';
import themeType from '../../lib/consts/themeType';
import RouteLeavingGuard from '../../components/warningUnSaveGuardModal/RouteLeavingGuard';
import './ProfileGeneralInformation.scss';

const ProfileGeneralInformation = forwardRef(({
  userOrganisations,
  setUserOrganisations,
  detailFormDirtyRef,
}, ref) => {
  const roleOptions = arrayUtils.getRoleOptions();

  const { user, updateUser } = useContext(GlobalContext);
  const [profileError, setProfileError] = useState(undefined);
  const [addRoleModal, updateAddRole] = useState(false);
  const [passwordModal, updatePasswordModal] = useState(false);
  const [passwordChangedModal, updatePasswordChangedModal] = useState(false);
  const [facilities, setFacilities] = useState(
    arrayUtils.getFacilityOptions(userOrganisations.filter((_org) => {
      return _org.role === null;
    })),
  );
  const [originOrganisationDetails, setOriginOrganisationDetails] = useState([]);
  const [roleOptionsTheme, setRoleOptions] = useState(roleOptions);

  const [roles, setRoles] = useState(userOrganisations.filter((_org) => _org.role !== null));
  const [isClickedSave, setIsClickedSave] = useState(false);
  const { control, handleSubmit, errors } = useForm({});
  const history = useHistory();

  const isDirtyRoles = !_.isEqual(
    originOrganisationDetails.sort((a, b) => ((a.organisation_id > b.organisation_id) ? 1 : -1)),
    userOrganisations.sort((a, b) => ((a.organisation_id > b.organisation_id) ? 1 : -1)),
  );

  useImperativeHandle(ref, () => ({
    getDirtyState() {
      return isDirtyRoles;
    },
  }));

  const errorMessage = get('profile-errorOnSaveMessage');
  const fromRequiredFieldError = get('registration-fromErrorRequiredField');
  const passwordChangeErrorMessage = get('profile-changePasswordError');
  const passwordGenericErrorMessage = get('profile-changePasswordGenericError');

  useEffect(() => {
    const getOrganisation = async () => {
      const result = await userService.getUserOrgs(user.id);
      if (result.ok) {
        setOriginOrganisationDetails(result.data);
      }
    };
    getOrganisation();
  }, [isClickedSave]);

  const onClickSaveDetails = async (newUser) => {
    setProfileError(undefined);

    try {
      await updateUser(user.id, { ...newUser, roles });
    } catch (error) {
      setProfileError(errorMessage);
    }
    // change save status to true
    setIsClickedSave(true);
  };

  const onClickDeleteRole = (index) => {
    // change save status to false
    setIsClickedSave(false);
    const roleToDeleteDetails = roles[index];

    // Add the deleted role to the facility list
    const newFacilities = facilities.concat({
      value: roleToDeleteDetails.organisation_id,
      label: roleToDeleteDetails.organisation_name,
      type: roleToDeleteDetails.organisation_type
        && roleToDeleteDetails.organisation_type.name === themeType.FACILITY.toUpperCase()
        ? themeType.FACILITY : themeType.TOURNAMENT,
    });

    setFacilities(newFacilities);

    // Get organisation details to add to the roles object
    const orgDetails = userOrganisations.filter((_org) => {
      return _org.organisation_id === parseInt(roleToDeleteDetails.organisation_id, 10);
    });
    const restOrganisations = userOrganisations.filter((_org) => {
      return _org.organisation_id !== parseInt(roleToDeleteDetails.organisation_id, 10);
    });
    // set relative org role to null
    if (orgDetails && orgDetails.length === 1) {
      orgDetails[0].role = null;
      setUserOrganisations(restOrganisations.concat(orgDetails));
    }
    // Delete Role //update: not delete update role property value, in order to com
    const newRoles = roles.filter((role) => {
      return role.organisation_id !== roleToDeleteDetails.organisation_id;
    });

    setRoles(newRoles);
  };

  const onClickAddRole = () => {
    updateAddRole(true);
  };

  const onSubmitRole = (formData) => {
    updateAddRole(true);

    // change save status to false
    setIsClickedSave(false);
    // Get organisation details to add to the roles object
    const orgDetails = userOrganisations.filter((_org) => {
      return _org.organisation_id === parseInt(formData.facilityid, 10);
    });

    if (orgDetails && orgDetails.length === 1) {
      orgDetails[0].role = formData.userRole;

      const newRoles = roles.concat(orgDetails);
      setRoles(newRoles);

      // Remove the add organistion from the facilities object
      const newFacilities = facilities.filter((facility) => {
        return facility.value !== parseInt(formData.facilityid, 10);
      });

      setFacilities(newFacilities);
    }

    updateAddRole(false);
  };

  const openPasswordModal = () => {
    updatePasswordModal(true);
  };

  const displayValidationError = (validationError) => {
    if (
      validationError.data.field === 'password'
      && validationError.data.code === ErrorCodes.PASSWORD_DOESNT_MEET_REQUIREMENTS
    ) {
      // If the error is one we are expecting, display the message accordingly.
      return passwordChangeErrorMessage;
    }

    return passwordGenericErrorMessage;
  };

  const onPasswordChange = async (password) => {
    const result = await userService.changePassword(user.id, password);

    if (result.ok) {
      updatePasswordModal(false);
      updatePasswordChangedModal(true);
    } else {
      if (result.statusCode === HttpStatus.UNPROCESSABLE_ENTITY) {
        // If the error is a validation error, the display a message accordingly
        return displayValidationError(result);
      }

      return passwordGenericErrorMessage;
    }
  };

  return (
    <div className="component__profile-details">
      {profileError && (
      <Alert data-cy="profile-error" className="alert m-0 mt-5" variant="danger">
        {profileError}
      </Alert>
      )}

      <ProfileDetailsForm
        className="profile-page-profile-details-form"
        onComplete={onClickSaveDetails}
        openPasswordModal={openPasswordModal}
        ref={detailFormDirtyRef}
      />
      <div className="profile-details-roles-card">
        <div className="profile-details-roles-header">
          <p className="profile-details-roles-header-title">{get('profile-tabGeneralInfoCardRoleTitle')}</p>
          {facilities.length > 0 && (
            <Button className="profile-details-roles-add-button" data-cy="profile-add-role-button" onClick={onClickAddRole}>
              {get('profile-tabGeneralInfoCardRoleAddRoleButton')}
            </Button>
          )}
        </div>
        <p className="profile-details-roles-description mt-3">{get('profile-tabGeneralInfoCardRoleDescription')}</p>
        <table>
          <thead>
            <tr>
              <th scope="col">{get('profile-tabGeneralInfoCardRoleTableHeaderRightColumn')}</th>
              <th scope="col">{get('profile-tabGeneralInfoCardRoleTableHeaderLeftColumn')}</th>
              <th scope="col" aria-label="cta" />
            </tr>
          </thead>
          <tbody>
            {roles.map((item, index) => {
              return (
                <tr>
                  <td data-label={get('profile-tabGeneralInfoCardRoleTableHeaderLeftColumn')}>
                    {item.role === 'USER' ? get('registration-formRoleOptions-user') : arrayUtils.getOptionLabel(roleOptions, item.role)}
                  </td>
                  <td data-label={get('profile-tabGeneralInfoCardRoleTableHeaderRightColumn')}>{item.organisation_name}</td>
                  <td data-label="">
                    <button type="button" className="profile-details-roles-delete-button" data-cy="profile-delete-role-button" onClick={() => onClickDeleteRole(index)}>
                      {get('profile-tabGeneralInfoCardRoleTableDeleteButton')}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        show={addRoleModal}
        onClose={() => updateAddRole(false)}
        heading={get('profile-tabGeneralInfoCard-addRoleModalTitle')}
        bodyText={(
          <Form className="profile-add-role-modal-wrapper">
            <GenericDropdownField
              control={control}
              name="facilityid"
              label={get('profile-tabGeneralInfoCard-addRoleModalFacilityLabel')}
              className="profile-add-role-modal-input-field-rows"
              options={facilities}
              errors={errors}
              handleChange={(e) => {
                const selectedFacility = facilities.filter((item) => item.value === e);

                if (selectedFacility && selectedFacility.length > 0) {
                  setRoleOptions(roleOptions.filter(
                    (item) => item.type === selectedFacility[0].type,
                  ));
                }
              }}
              validationRules={(value) => {
                if (typeof value !== 'undefined' || facilities.some((el) => el.value === value)) {
                  return true;
                }
                return fromRequiredFieldError;
              }}
            />

            <GenericDropdownField
              control={control}
              name="userRole"
              label={get('profile-tabGeneralInfoCard-addRoleModalRoleLabel')}
              className="profile-add-role-modal-input-field-rows"
              options={roleOptionsTheme}
              errors={errors}
              validationRules={(value) => {
                if (typeof value !== 'undefined' || roleOptions.some((el) => el.key === value)) {
                  return true;
                }
                return fromRequiredFieldError;
              }}
            />
          </Form>
      )}
        footer={(
          <Button onClick={handleSubmit(onSubmitRole)} className="mt-5">
            {get('profile-tabGeneralInfoCard-addRoleModalButton')}
          </Button>
      )}
      />
      <Modal
        show={passwordModal}
        onClose={() => updatePasswordModal(false)}
        heading={get('profile-tabGeneralInfoCard-changePasswordModalTitle')}
        bodyText={(
          <div>
            <NewPasswordForm
              onComplete={onPasswordChange}
              submitButtonText={get('newPassword-formButton')}
              formDescriptionText="Enter your new password below:"
              passwordLabel={get('newPassword-formNewPassword')}
              passwordLabelPlaceholder={get('newPassword-formNewPasswordPlaceholder')}
              retypePasswordLabel={get('newPassword-formRetypePassword')}
              retyPasswordLabelPlaceholder={get('newPassword-formRetypePasswordPlaceholder')}
            />
          </div>
      )}
      />
      <Modal
        show={passwordChangedModal}
        onClose={() => updatePasswordChangedModal(false)}
        heading={get('profile-changePasswordModalTitle')}
        bodyText={get('Your password has been changed successfully.')}
      />

      <RouteLeavingGuard
            // When should shouldBlockNavigation be invoked,
            // simply passing a boolean
        when={isDirtyRoles}
            // Navigate function
        navigate={(path) => history.push(path)}
            // Use as "message" prop of Prompt of React-Router
        // eslint-disable-next-line no-unused-vars
        shouldBlockNavigation={(location) => {
          // is able to filter specific location
          if (isDirtyRoles) return true;
          return false;
        }}
      />
    </div>
  );
});

ProfileGeneralInformation.defaultProps = {
  detailFormDirtyRef: PropTypes.shape({ current: undefined }),
};

ProfileGeneralInformation.propTypes = {
  userOrganisations: PropTypes.arrayOf({
    organisation_name: PropTypes.string,
  }).isRequired,
  setUserOrganisations: PropTypes.func.isRequired,
  detailFormDirtyRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default ProfileGeneralInformation;
