import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Question.scss';

import InternalLink from '../../../core/link/InternalLink';
import TickSmall from '../../../../assets/svgs-as-components/TickSmall';

const Question = ({
  title, completed, colour, href, questionBlockToOpen, subquestionToOpen,
}) => {
  return (
    <InternalLink className="question-tile" style={{ color: colour, border: `2px solid ${colour}` }} to={{ pathname: href, hash: _.camelCase(questionBlockToOpen), subQuestionHash: _.camelCase(subquestionToOpen) }}>
      <div className="question-title">
        {title}
      </div>
      {
           completed
           && <div className="question-complete"> <TickSmall color={colour} /> </div>
       }
    </InternalLink>
  );
};

Question.defaultProps = {
  completed: false,
  colour: 'blue',
  questionBlockToOpen: null,
  subquestionToOpen: null,
};

Question.propTypes = {
  title: PropTypes.string.isRequired,
  completed: PropTypes.bool,
  colour: PropTypes.string,
  href: PropTypes.string.isRequired,
  questionBlockToOpen: PropTypes.string,
  subquestionToOpen: PropTypes.string,
};
export default Question;
