import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { Controller } from 'react-hook-form';
import { GlobalContext } from '../../../context/GlobalContext';
import themeType from '../../../lib/consts/themeType';
import FormError from './FormError';
import { ArrowDown } from '../../Icons';
import Label from '../label/Label';
import { get } from '../../../lib/strings';

const GenericDropdownField = ({
  name,
  label,
  variant,
  className,
  options,
  control,
  errors,
  validationRules,
  handleChange,
  isSearchable,
}) => {
  const { theme } = useContext(GlobalContext);
  const pleaseChoose = get('control-dropdown-default') || 'Please choose';
  const noOptionsMessage = get('general-dropdown-no-options');
  const primaryColor = theme === themeType.FACILITY ? '#4CA471' : '#1077C6';
  const color = variant === 'white' ? '#1d1d1d' : '#ffffff';
  const backgroundColor = variant === 'white' ? '#ffffff' : '#1d1d1d';

  // eslint-disable-next-line no-nested-ternary
  options.sort((a, b) => a.label.localeCompare(b.label));
  const otherIndex = options.findIndex((item) => item.label === get('facility-typesOptions-other'));

  if (otherIndex !== -1) {
    const newOther = options[otherIndex];
    // delete other from index
    options.splice(otherIndex, 1);
    options.push(newOther);
  }

  const customStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color,
        paddingLeft: '0.3em',
        fontSize: 15,
      };
    },
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid #969896',
      color: state.isSelected ? primaryColor : color,
      padding: 10,
      paddingLeft: '1em',
      cursor: 'pointer',
      backgroundColor,
      '&:hover': {
        color: primaryColor,
      },
    }),
    input: (styles) => ({
      ...styles,
      height: 48,
      margin: 0,
      padding: 0,
      color,
    }),
    valueContainer: (styles) => ({
      ...styles,
      color,
    }),
    container: (styles) => ({
      ...styles,
      color,
    }),
    menu: (styles) => ({
      ...styles,
      color,
      backgroundColor,
      border: '1px solid #969896',
      zIndex: 4,
    }),
    control: (styles, state) => {
      return ({
        ...styles,
        maxWidth: 360,
        height: 48,
        backgroundColor,
        color,
        cursor: 'pointer',
        border: state.menuIsOpen ? `1px solid ${primaryColor}` : '1px solid #969896',
        boxShadow: 'none',
        '&:hover': {
          border: `1px solid ${primaryColor}`,
        },
      });
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        color,
        opacity,
        transition,
      };
    },
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };

  let errorMessage;

  if (errors[name]) {
    if (errors[name].type === 'required') {
      errorMessage = get('login-formRequiredField');
    } else {
      errorMessage = errors[name].message;
    }
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDown />
      </components.DropdownIndicator>
    );
  };

  return (
    <div
      className={`components__form-dropdown-field ${className}`}
    >
      {label && <Label text={label} />}
      <Controller
        control={control}
        name={name}
        rules={{
          validate: (value) => validationRules(value),
        }}
        render={(fields) => {
          return (
            <Select
              options={options}
              styles={customStyles}
              isSelected
              isSearchable={isSearchable}
              onChange={(eventKey) => {
                fields.onChange(eventKey.value);
                handleChange(eventKey.value);
              }}
              placeholder={pleaseChoose}
              menuShouldScrollIntoView
              noOptionsMessage={() => noOptionsMessage}
              defaultValue={options.filter((c) => c.value === fields.value)}
              components={{ DropdownIndicator }}
            />
          );
        }}
      />
      {errorMessage && <FormError message={errorMessage} />}
    </div>
  );
};

GenericDropdownField.defaultProps = {
  variant: 'white',
  label: undefined,
  className: undefined,
  errors: {},
  validationRules: () => {},
  handleChange: () => {},
  isSearchable: false,
};

GenericDropdownField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ).isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
  isSearchable: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  validationRules: PropTypes.func,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({}),
  ]),
};

export default GenericDropdownField;
