import api from '../../lib/http';

export default {
  completeRegistration: async (token, password) => {
    const result = await api(
      'post',
      'registration/complete-registration',
      { token, password },
      true,
    );
    return result.data;
  },
  validateToken: async (token) => {
    const result = await api(
      'get',
      `registration/validate-token?token=${token}`,
      false,
      false,
      true,
    );
    return result;
  },
  newRegistration: async (
    dropdownHearAbout,
    dropdownLanguage,
    dropdownStatus,
    checkBoxCorrispondence,
    firstName,
    lastName,
    email,
  ) => {
    const response = await api(
      'post',
      'registration/new-registration',
      {
        first_name: firstName,
        last_name: lastName,
        email,
        language: dropdownLanguage,
        status: dropdownStatus,
        hear_about_us: dropdownHearAbout,
        allow_correspondence: checkBoxCorrispondence,
        email_notifications: false,
        introductory_tour: false,
        measurement_unit: 'metric',
      },
      false,
      false,
      true,
    );

    return response;
  },
  reSendEmail: async (email) => {
    const result = await api(
      'post',
      'registration/re-send-email',
      {
        email_address: email,
      },
      false,
      false,
      true,
    );

    return result.data;
  },
};
