import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../panel/Panel';
import InternalLink from '../../link/InternalLink';
import Pointer from '../../../../assets/svgs-as-components/RightPointer';
import './DashboardTile.scss';
import HtmlViewer from '../../html-viewer/HTMLViewer';

const DashboardTile = ({
  isActivePage, href, title, subTitle, content, imgSourse, className,
}) => {
  return (
    <div key={href} className={`page-nav-title ${isActivePage ? '--active' : ''}`}>
      <InternalLink to={href}>
        <Panel>
          <div className="page-nav-title__content">
            <div> {title}</div>
            <div> {subTitle}</div>
            <HtmlViewer html={content} />
          </div>
          <div className="page-nav-title-content__aside">
            <Pointer />
          </div>
          {imgSourse && <img src={imgSourse} alt="page-nav--card" className={`page-nav--card-img ${className}`} />}

        </Panel>

      </InternalLink>

    </div>
  );
};
DashboardTile.defaultProps = {
  isActivePage: false,
  imgSourse: undefined,
  className: undefined,
};
DashboardTile.propTypes = {
  isActivePage: PropTypes.bool,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  imgSourse: PropTypes.string,
  className: PropTypes.string,
};

export default DashboardTile;
