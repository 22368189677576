import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBoxField from '../form/CheckBoxField';
import DropdownField from '../form/DropdownField';
import plusImg from '../../../assets/plus-black.svg';
import removeAnswerImg from '../../../assets/remove_answer.svg';
import editAnswerImg from '../../../assets/edit-answer.svg';

import { get } from '../../../lib/strings';
import answerSetUtils from '../../../lib/answerSetUtils';
import './MultipleChoiceQuestionsWithOwnField.scss';
import WhatElseBadge from '../badge/whatElseBadge';
import DeleteAnswerBadge from '../badge/deleteAnswerBadge';

const CheckBoxFieldRender = ({
  type,
  answer,
  index,
  control,
  facilityAddedAnswerSelected,
  updateFacilityAddedAnswersSelected,
  removeCustomerAnswer,
  showWhatElseBadge,
  setValue,
  clickAnswerId,
  register,
  name: parentName,
  isSingleCheckBox,
  singleChoiceSelected,
  setSingleChoiceSelected,
  // from Modal datagrid multi-choice
  persistMultipleQuestionValues,
  color,
}) => {
  useEffect(() => {
    if (facilityAddedAnswerSelected.includes(answer.id)) {
      setValue(`${parentName}.[${index}].${answer.id}`, true);
    }
  }, [clickAnswerId]);

  useEffect(() => {
    if (!persistMultipleQuestionValues) return;
    setValue(`${parentName}.[${index}].${answer.id}`, persistMultipleQuestionValues.includes(answer.id));
  }, [persistMultipleQuestionValues]);

  useEffect(() => {
    if (!isSingleCheckBox) return;
    if (singleChoiceSelected === answer.id) {
      setValue(`${parentName}.[${index}].${singleChoiceSelected}`, true);
    } else {
      setValue(`${parentName}.[${index}].${answer.id}`, false);
    }
  }, [singleChoiceSelected]);

  return (
    <CheckBoxField
      key={type + answer.id}
      name={`${parentName}.[${index}].${answer.id}`}
      label={answer.title}
      control={control}
      rounded
      ref={register}
      isRequiredCert={answer.requiredForCertification}
      className="check-box-input"
      color={color}
      handleChange={(name, value) => {
        // is Single choice CheckBox
        if (isSingleCheckBox) {
          if (singleChoiceSelected === answer.id) {
            setSingleChoiceSelected(undefined);
          } else {
            setSingleChoiceSelected(answer.id);
          }
        }
        const removed = facilityAddedAnswerSelected.filter(
          (selectedAnswer) => selectedAnswer !== answer.id,
        );
        if (value) {
          updateFacilityAddedAnswersSelected([
            answer.id, ...removed]);
        } else {
          updateFacilityAddedAnswersSelected(removed);
        }
      }}
    >
      {answer.facilityAddedAnswer
      && (
        <>
          <button
            type="button"
            key={`edit_${answer.id}`}
            className="toggle-remove-button"
            style={{ color, borderColor: color }}
            onClick={() => showWhatElseBadge(answer)}
          > <img src={editAnswerImg} alt="edit" />
          </button>
          <button
            type="button"
            key={`close_${answer.id}`}
            className="toggle-remove-button"
            style={{ color, borderColor: color }}
            onClick={() => removeCustomerAnswer(answer)}
          > <img src={removeAnswerImg} alt="close" />
          </button>
        </>
      )}
    </CheckBoxField>
  );
};
CheckBoxFieldRender.defaultProps = {
  clickAnswerId: null,
  showWhatElseBadge: () => {},
  setSingleChoiceSelected: () => {},
  singleChoiceSelected: undefined,
  isSingleCheckBox: false,
  persistMultipleQuestionValues: undefined,
};
CheckBoxFieldRender.propTypes = {
  answer: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  facilityAddedAnswerSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateFacilityAddedAnswersSelected: PropTypes.func.isRequired,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  clickAnswerId: PropTypes.string,
  type: PropTypes.string.isRequired,
  removeCustomerAnswer: PropTypes.func.isRequired,
  showWhatElseBadge: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isSingleCheckBox: PropTypes.bool,
  singleChoiceSelected: PropTypes.string,
  setSingleChoiceSelected: PropTypes.func,
  persistMultipleQuestionValues: PropTypes.arrayOf(PropTypes.shape()),
};

const MultipleChoiceQuestionsWithOwnField = ({
  answers = [],
  isDropdown,
  allowAddMore,
  name,
  control,
  setValue,
  getValues,
  register,
  answerData,
  color,
  checkIcon,
  nestIndex: fieldIndex,
  customerAnswerFields,
  removeAnswerFromCustomerFields,
  appendAnswerToCustomerFields,
  indexOfModalForm,
  isShowAddToListButton,
  setIsShowAddToListButton,
  isOpenWhatElseBadge,
  setIsOpenWhatElseBadge,
  isOpenDeleteBadge,
  setIsOpenDeleteBadge,
  clickedAnswer,
  updateClickedAnswer,
  isSingleCheckBox,
  persistMultipleQuestionValues,
  clickedEditRowFromTable,
}) => {
  const [render, updateRender] = useState(false);
  const [dropdownOptions, updateDropdownOptions] = useState([]);
  const [errorInAddAnswerModal, updateErrorInAddAnswerModal] = useState();
  const [facilityAddedAnswerSelected, updateFacilityAddedAnswersSelected] = useState([]);
  // if single choice question, selected answer id will in state
  const [singleChoiceSelected, setSingleChoiceSelected] = useState(undefined);

  useEffect(() => {
    if (isDropdown) {
      const options = answers.map((answer) => {
        return {
          key: answer.id,
          value: answer.title,
        };
      });
      updateDropdownOptions(options);
    }
    updateRender(true);
  }, []);

  useEffect(() => {
    if (render === true) {
      (answers).map((answer, index) => {
        const fieldName = `answers.[${index}].${answer.id}.name`;
        const answerFromData = answerSetUtils.pullOutAnswerSet(answer.id, answerData);
        answerSetUtils.setAnswersHandler(fieldName, answerFromData, setValue);
        return answer;
      });

      if (isDropdown && answerData) {
        const answerId = answerSetUtils.pullOutDropDownAnswer(answerData);
        if (answerId) {
          setValue('dropdownAnwser', answerId);
        }
      }
      updateRender(false);
    }
  }, [render]);

  const removeCustomerAnswer = (answer) => {
    updateClickedAnswer(answer);
    const currentIsOpenDeleteBadge = [...isOpenDeleteBadge].map(() => false);
    currentIsOpenDeleteBadge[fieldIndex] = true;
    setIsOpenDeleteBadge(currentIsOpenDeleteBadge);

    const currentIsShowAddToListButton = [...isShowAddToListButton].map(() => true);
    currentIsShowAddToListButton[fieldIndex] = false;
    setIsShowAddToListButton(currentIsShowAddToListButton);

    const currentIsOpenWhatElseBadge = [...isOpenWhatElseBadge].map(() => false);
    setIsOpenWhatElseBadge(currentIsOpenWhatElseBadge);
  };

  const onClickConfirmRemoveAnswer = () => {
    const getIndexByAnswerId = customerAnswerFields
      .findIndex((answer) => answer.id === clickedAnswer.id);
    removeAnswerFromCustomerFields(getIndexByAnswerId);
    updateRender(true);
    updateClickedAnswer(undefined);

    const currentIsOpenDeleteBadge = [...isOpenDeleteBadge].map(() => false);
    setIsOpenDeleteBadge(currentIsOpenDeleteBadge);

    const currentIsShowAddToListButton = [...isShowAddToListButton].map(() => true);
    setIsShowAddToListButton(currentIsShowAddToListButton);
  };

  const onClickConfirmAddAnswer = () => {
    const currAddAnswer = getValues(`${name}.[${fieldIndex}]`);
    if (currAddAnswer.length > 250) { updateErrorInAddAnswerModal('question-AddAnswer-errorMessage-maxlength-250'); return; }
    const newAnswer = {
      name,
      active: true,
      title: currAddAnswer,
      facilityAddedAnswer: true,
      nestedQuestionsId: name.split('__')[1],
      indexOfModalForm,
    };

    if (clickedAnswer) { // edit title for existing answer
      const customerAnswerFieldsCopy = customerAnswerFields.map((_answer) => {
        if (_answer.id === clickedAnswer.id) {
          const answerCopy = _answer;
          answerCopy.title = currAddAnswer;
          return answerCopy;
        }
        return _answer;
      });
      removeAnswerFromCustomerFields();
      appendAnswerToCustomerFields(customerAnswerFieldsCopy);
    } else { // new answer
      // append to customerAnswerFields in DataGridQuestions
      appendAnswerToCustomerFields(newAnswer);
    }
    updateErrorInAddAnswerModal();
    // badge control
    const currentIsOpenWhatElseBadge = [...isOpenWhatElseBadge].map(() => false);
    const currentIsShowAddToListButton = [...isShowAddToListButton].map(() => true);
    setIsOpenWhatElseBadge(currentIsOpenWhatElseBadge);
    setIsShowAddToListButton(currentIsShowAddToListButton);

    updateClickedAnswer(undefined);// needs set to undefined
  };

  const onClickCancelWhatElseBadge = () => {
    // badge control
    const currentIsShowAddToListButton = [...isShowAddToListButton].map(() => true);
    const currentIsOpenWhatElseBadge = [...isOpenWhatElseBadge].map(() => false);
    setIsOpenWhatElseBadge(currentIsOpenWhatElseBadge);
    setIsShowAddToListButton(currentIsShowAddToListButton);
    updateClickedAnswer(undefined);// needs set to undefined
  };

  const showWhatElseBadge = (answer) => { // from clicking checkbox to what else badge
    updateClickedAnswer(answer);
    setValue(`${name}.[${fieldIndex}]`, answer.title);
    // badge control
    const currentIsShowAddToListButton = [...isShowAddToListButton].map(() => true);
    currentIsShowAddToListButton[fieldIndex] = false;
    setIsShowAddToListButton(currentIsShowAddToListButton);

    const currentIsOpenWhatElseBadge = [...isOpenWhatElseBadge].map(() => false);
    currentIsOpenWhatElseBadge[fieldIndex] = true;
    setIsOpenWhatElseBadge(currentIsOpenWhatElseBadge);

    const currentIsOpenDeleteBadge = [...isOpenDeleteBadge].map(() => false);
    setIsOpenDeleteBadge(currentIsOpenDeleteBadge);
  };

  const onClickAddMoreButton = (index) => {
    updateClickedAnswer(undefined);

    const currentIsShowAddToListButton = [...isShowAddToListButton].map(() => true);
    currentIsShowAddToListButton[index] = false;
    setIsShowAddToListButton(currentIsShowAddToListButton);

    const currentIsOpenWhatElseBadge = [...isOpenWhatElseBadge].map(() => false);
    currentIsOpenWhatElseBadge[index] = true;
    setIsOpenWhatElseBadge(currentIsOpenWhatElseBadge);

    const currentIsOpenDeleteBadge = [...isOpenDeleteBadge].map(() => false);
    setIsOpenDeleteBadge(currentIsOpenDeleteBadge);
  };
  const AddMoreButton = () => {
    return (
      <div role="button" tabIndex={0} className="d-flex flex-row justify-content-center align-items-center" onClick={() => onClickAddMoreButton(fieldIndex)} onKeyDown={() => onClickAddMoreButton(fieldIndex)}>
        <img src={plusImg} alt="plus" />
        <p className="data-grid-add-button-text">{get('question-set-AddMoreButton')}</p>
      </div>
    );
  };
  const onClickCancelDeleteBadge = () => {
    // badge control
    const currentIsShowAddToListButton = [...isShowAddToListButton].map(() => true);
    const currentIsOpenDeleteBadge = [...isOpenDeleteBadge].map(() => false);
    setIsOpenDeleteBadge(currentIsOpenDeleteBadge);
    setIsShowAddToListButton(currentIsShowAddToListButton);
    updateClickedAnswer(undefined);
  };
  // 1ADD new row or 2 edit row
  const filterCustomerAnswerByNestQuestionIdAndIndexModal = customerAnswerFields.filter((customerAnswer) => (customerAnswer.nestedQuestionsId === name.split('__')[1] && customerAnswer.indexOfModalForm === indexOfModalForm)
  || (customerAnswer.nestedQuestionsId === name.split('__')[1] && clickedEditRowFromTable && customerAnswer.indexOfModalForm === clickedEditRowFromTable.arrayIndex));

  return (
    <div className="components__multiple-choice-question">
      {isDropdown ? (
        <DropdownField
          colour={color}
          control={control}
          name={`${name}`}
          className="input-field-row"
          options={dropdownOptions}
        />
      ) : (
        <>
          <div className="question-checkbox-wrapper">
            {!isSingleCheckBox ? (
              <>
                {(answers).map((answer, index) => (
                  <CheckBoxFieldRender
                    key={`original_answer_${index + answers.length}`}
                    type="original_answer"
                    answer={answer}
                    index={index}
                    control={control}
                    register={register()}
                    facilityAddedAnswerSelected={facilityAddedAnswerSelected}
                    updateFacilityAddedAnswersSelected={updateFacilityAddedAnswersSelected}
                    setValue={setValue}
                    name={name}
                    removeCustomerAnswer={removeCustomerAnswer}
                    clickAnswerId={clickedAnswer ? clickedAnswer.id : null}
                    persistMultipleQuestionValues={persistMultipleQuestionValues}
                    color={color}
                    checkIcon={checkIcon}
                  />
                ))}

                { filterCustomerAnswerByNestQuestionIdAndIndexModal // render all customer's answers
              && (filterCustomerAnswerByNestQuestionIdAndIndexModal)
                .map((customerAnswer, index) => {
                  // Hidden clicked answer
                  return !clickedAnswer || customerAnswer.id !== clickedAnswer.id ? (
                    <CheckBoxFieldRender
                      key={`extra_answer_${index + answers.length}`}
                      type="extra_answer"
                      answer={customerAnswer}
                      index={index + answers.length} // keep adding from original answers
                      control={control}
                      register={register()}
                      facilityAddedAnswerSelected={facilityAddedAnswerSelected}
                      updateFacilityAddedAnswersSelected={updateFacilityAddedAnswersSelected}
                      setValue={setValue}
                      name={name}
                      removeCustomerAnswer={removeCustomerAnswer}
                      showWhatElseBadge={showWhatElseBadge}
                      clickAnswerId={clickedAnswer ? clickedAnswer.id : null}
                      persistMultipleQuestionValues={persistMultipleQuestionValues}
                      color={color}
                    />
                  ) : null;
                })}

              </>
            ) : (// render (true/false) single choice checkbox
              <>
                {(answers).map((answer, index) => {
                  return (
                    <CheckBoxFieldRender
                      key={`original_answer_${index + answers.length}`}
                      type="original_answer"
                      answer={answer}
                      index={index}
                      control={control}
                      register={register()}
                      facilityAddedAnswerSelected={facilityAddedAnswerSelected}
                      updateFacilityAddedAnswersSelected={updateFacilityAddedAnswersSelected}
                      setValue={setValue}
                      name={name}
                      color={color}
                      removeCustomerAnswer={removeCustomerAnswer}
                      clickAnswerId={clickedAnswer ? clickedAnswer.id : null}
                      isSingleCheckBox={isSingleCheckBox}
                      singleChoiceSelected={singleChoiceSelected}
                      setSingleChoiceSelected={setSingleChoiceSelected}
                      persistMultipleQuestionValues={persistMultipleQuestionValues}
                    />
                  );
                })}

              </>
            )}
            {(allowAddMore && isShowAddToListButton[fieldIndex])
              && <AddMoreButton />}
          </div>
          {allowAddMore ? (
            <>
              {/* {isShowAddToListButton[fieldIndex]
              && <AddMoreButton />} */}
              {isOpenWhatElseBadge[fieldIndex]
              && (
              <WhatElseBadge
                errorInAddAnswerModal={errorInAddAnswerModal}
                onClickConfirmAddAnswer={onClickConfirmAddAnswer}
                onClickCancelWhatElseBadge={onClickCancelWhatElseBadge}
                fieldIndex={fieldIndex}
                name={name}
                register={register}
                setValue={setValue}
                clickedAnswer={clickedAnswer}
              />
              )}
              {isOpenDeleteBadge[fieldIndex]
              && (
              <DeleteAnswerBadge
                title={clickedAnswer ? clickedAnswer.title : null}
                onClickCancelDeleteBadge={onClickCancelDeleteBadge}
                fieldIndex={fieldIndex}
                onClickConfirmRemoveAnswer={onClickConfirmRemoveAnswer}
              />
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

MultipleChoiceQuestionsWithOwnField.defaultProps = {
  answerData: {
    facilityAddedAnswers: [],
  },
  allowAddMore: false,
  clickedAnswer: undefined,
  persistMultipleQuestionValues: undefined,
  clickedEditRowFromTable: undefined,
};

MultipleChoiceQuestionsWithOwnField.propTypes = {
  answerData: PropTypes.shape({
    facilityAddedAnswers: [],
  }),
  allowAddMore: PropTypes.bool,
  isDropdown: PropTypes.bool.isRequired,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,

  nestIndex: PropTypes.number.isRequired,
  customerAnswerFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeAnswerFromCustomerFields: PropTypes.func.isRequired,
  appendAnswerToCustomerFields: PropTypes.func.isRequired,
  indexOfModalForm: PropTypes.number.isRequired,
  isShowAddToListButton: PropTypes.arrayOf(PropTypes.bool).isRequired,
  setIsShowAddToListButton: PropTypes.func.isRequired,
  isOpenWhatElseBadge: PropTypes.arrayOf(PropTypes.bool).isRequired,
  setIsOpenWhatElseBadge: PropTypes.func.isRequired,
  isOpenDeleteBadge: PropTypes.arrayOf(PropTypes.bool).isRequired,
  setIsOpenDeleteBadge: PropTypes.func.isRequired,
  clickedAnswer: PropTypes.shape(PropTypes.object),
  updateClickedAnswer: PropTypes.func.isRequired,
  isSingleCheckBox: PropTypes.bool.isRequired,
  persistMultipleQuestionValues: PropTypes.arrayOf(PropTypes.string),
  clickedEditRowFromTable: PropTypes.shape(),
  color: PropTypes.string.isRequired,
  checkIcon: PropTypes.string.isRequired,
};
export default MultipleChoiceQuestionsWithOwnField;
