/* eslint-disable max-len */
import React, {
  useState, forwardRef, useEffect, useImperativeHandle, useContext,
} from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { useHistory } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { Table, Accordion, useAccordionToggle } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import AccordionContext from 'react-bootstrap/AccordionContext';
import FormModal from './FormModal/FormModal';
import Button from '../button/Button';
import plusImg from '../../../assets/plus-black.svg';
import { get } from '../../../lib/strings';
import answerSetUtils from '../../../lib/answerSetUtils';
import RowRenderer from './RowRenderer/RowRenderer';
import './DataGridQuestions.scss';
import RouteLeavingGuard from '../../warningUnSaveGuardModal/RouteLeavingGuard';
import { GlobalContext } from '../../../context/GlobalContext';
import { defaultUserUnits as checkUserUnits } from '../../../lib/utils';

import IconEditImg from '../../../assets/icon-edit.svg';
import IconTrashImg from '../../../assets/icon-trash.svg';
import DownArrowImg from '../../../assets/arrows-chevron-down.svg';
import UpArrowImg from '../../../assets/arrows-chevron-up.svg';
import Modal from '../modal/Modal';

const DataGridQuestions = forwardRef((props, ref) => {
  const {
    answers = [],
    userUnits,
    control,
    errors,
    register,
    watch,
    getValues,
    answerData,
    answerPreviousYearData,
    append,
    setValue,
    verticalTableLayout,
    handleSubmit,
    clickedSaveButton,
    updateClickedSaveButton,
    formState,
    instructions,
    colour,
  } = props;
  const previousDataString = get('question-previous-data-message');
  const { org } = useContext(GlobalContext);
  const [tableColumnTitles, updateTableColumnTitles] = useState([]);
  const [dataGridAnswers, updateDataGridAnswers] = useState([]);
  const [showPreviousDataMessage, setShowPreviousDataMessage] = useState(false);
  const [showDltConfirmModal, setShowDltConfirmModal] = useState('');
  const [tobedeletedData, setTobedeletedData] = useState(null);
  // when first render, populate data grid answers
  const [originalDataGridAnswers, updateOriginalDataGridAnswers] = useState([]);

  const [addModal, updateAddModal] = useState(false);
  // Index of sum table: 1 initial rows from answerData.rows,
  // 2  click 'add' button in Modal, should plus 1;
  // 3  click 'delete' button in sum table mins 1;
  // 4  this value will be attached into customerAnswer object
  const [indexOfModalForm, setIndexOfModalForm] = useState(answerData.rows
    ? answerData.rows.length : 0);

  const [clickedEditRowFromTable, setClickedEditRowFromTable] = useState(undefined);
  const [additionAdded, setAdditionAdded] = useState(false);
  // const isTranslatedTotalString = get('question-addDataGrid-total');

  // manage all customer's answers: append, remove, insert and naming relatively.
  // the pattern of each items in customerAnswerFields as follows:
  //   {
  //   name,
  //   active: true,
  //   title: // entry answer title
  //   facilityAddedAnswer: true,
  //   nestedQuestionsId: name.split('__')[1],
  //   indexOfModalForm,
  // };
  const {
    fields: customerAnswerFields, remove: removeAnswerFromCustomerFields,
    append: appendAnswerToCustomerFields, insert: insertAnswerToCustomerFields,
  } = useFieldArray({
    control,
    name: 'customerNestQuestions',
  });

  // invoke for wrapper into QuestionWrapper component as payload
  useImperativeHandle(ref, () => ({

    getDatagridAnswers() {
      return {
        dataGridAnswers,
        customerAnswerFields,
        isDataDirty: !_.isEqual(originalDataGridAnswers, dataGridAnswers),
      };
    },
  }));
  const history = useHistory();
  // remove row of sum table
  const onClickDeleteItem = (index, answer) => {
    updateClickedSaveButton(false);// suppress save button status

    // remove original answer by index
    const newArray = [...dataGridAnswers];
    newArray.splice(index, 1);
    updateDataGridAnswers(newArray);

    // remove customer answer:
    // 1 find out all customer answers' id by clicking
    const removedCustomerAnswerIds = Object.values(answer).map((t) => Object.keys(t))
      .flat().filter((t) => t !== 'undefined');

    const newCustomerAnswerFields = [...customerAnswerFields];
    // 2 find out relative index with the customer answers' id in customerAnswerFields
    const removeIndexes = removedCustomerAnswerIds.map((_removedId) => {
      return customerAnswerFields
        .findIndex((_answer) => _answer.id === _removedId);
    });
    // 3 update indexOfModalForm property to indexOfModalForm-1
    // for rest of customer answers in customerAnswerFields.
    const updateIndexOfModalForCustomerAnswers = newCustomerAnswerFields.map((_answer, _index) => {
      const lastIndex = Math.max(...removeIndexes);
      if (lastIndex > 0 && _index > lastIndex) {
        const updateIndexOfModal = _answer.indexOfModalForm;
        const newAnswer = _answer;
        newAnswer.indexOfModalForm = updateIndexOfModal - 1;
        return newAnswer;
      }
      return _answer;
    });
    // remove relative customer answers from copy array, and filter out falsy
    // eslint-disable-next-line array-callback-return
    const filterNewCustomerAnswerFields = updateIndexOfModalForCustomerAnswers.map((_answer) => {
      if (!removedCustomerAnswerIds.includes(_answer.id)) return _answer;
    }).filter((t) => t);

    // row of tables needs -1
    setIndexOfModalForm((prev) => prev - 1);
    removeAnswerFromCustomerFields();
    appendAnswerToCustomerFields(filterNewCustomerAnswerFields);
  };

  const onClickConfirmAddItem = (addTwoRows) => {
    updateClickedSaveButton(false);// suppress save button status

    setIndexOfModalForm((preIndex) => preIndex + 1);
    const formValues = getValues({ nest: true });
    let row = {};

    Object.keys(formValues).map((key) => {
      const nestedIdStringArray = key.split('__');

      if (nestedIdStringArray.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        const nestedQuestionId = nestedIdStringArray[1];
        const rowQuestionId = null || nestedIdStringArray[2];

        const sectionsKey = rowQuestionId ? `nestedQuestions__${nestedQuestionId}__${rowQuestionId}` : `nestedQuestions__${nestedQuestionId}`;
        const shapedAnswer = {
          [nestedQuestionId]: {

          },
        };

        const columnObject = answerSetUtils.getColumnDataByNestedQuestionId(
          tableColumnTitles, nestedQuestionId,
        );

        if (columnObject.uiType === 'DROPDOWNLIST') { // convert uitype of drop down within multi-choices component and in RowRender component
          shapedAnswer[nestedQuestionId] = {
            [formValues[sectionsKey]]: {
              unitMeasurementType: null,
              uiType: columnObject.uiType,
              value: true,
            },
          };
        } else if (columnObject.uiType === 'MULTIPLECHOICE_ADDMORE' || columnObject.uiType === 'SINGLECHOICE') {
          const selectedAnswers = formValues[sectionsKey].filter((item) => Object.values(item)[0]);
          const selectedKeys = selectedAnswers.map((item) => Object.keys(item)[0]);
          const target = selectedKeys.reduce((acc, curr) => {
            acc[curr] = {
              unitMeasurementType: null,
              value: true,
              uiType: columnObject.uiType,
              nestedQuestionId,
            };
            return acc;
          }, {});
          shapedAnswer[nestedQuestionId] = target;
        } else {
          const fieldDataFromForm = formValues[`nestedQuestionId__${nestedQuestionId}__${rowQuestionId}`];
          let value = formValues[rowQuestionId];
          const unitFieldName = `nestedQuestionUnit__${nestedQuestionId}__${rowQuestionId}`;
          // Fetch selected unit Label, otherwise set default
          const units = formValues[unitFieldName]
            && formValues[unitFieldName].unitLabel
            ? formValues[unitFieldName].unitLabel : org.measurement_unit;

          if (fieldDataFromForm !== undefined && fieldDataFromForm.fieldType === 'DateTime') {
            value = format(value, 'yyyy/MM/dd');
          }

          shapedAnswer[nestedQuestionId] = {
            ...row[nestedQuestionId],
            [rowQuestionId]: {
              value,
              unitMeasurementType: units.toLowerCase(),
              uiType: columnObject.uiType,
              isDateTime: fieldDataFromForm && fieldDataFromForm.fieldType === 'DateTime',
              isUnit: !!(formValues[unitFieldName] && formValues[unitFieldName].unitLabel),
            },
          };
        }

        row = {
          ...row,
          ...shapedAnswer,
        };
      }
      return key;
    });

    const updatedAnswers = [...dataGridAnswers, ...[row]];
    if (addTwoRows) updatedAnswers.push(row);
    updateDataGridAnswers(updatedAnswers);
    updateAddModal(false);
    setAdditionAdded(true);
  };

  useEffect(() => {
    const columns = answerSetUtils.pullColumnsOutOfQuestionSet(answers);

    updateTableColumnTitles(columns);
    if (answerData.rows && answerData.rows.length > 0) {
      updateDataGridAnswers(answerData.rows);
      // keep origin into state
      updateOriginalDataGridAnswers(answerData.rows);
    } else if (answerPreviousYearData.rows && answerPreviousYearData.rows.length > 0) {
      setShowPreviousDataMessage(true);
      updateDataGridAnswers(answerPreviousYearData.rows);
      // keep origin into state
      updateOriginalDataGridAnswers(answerPreviousYearData.rows);
    }

    // populate customer answers into react hook customerFields
    if (answerData.facilityAddedAnswers && answerData.facilityAddedAnswers.length > 0) {
      appendAnswerToCustomerFields(answerData.facilityAddedAnswers);
    } else if (answerPreviousYearData.facilityAddedAnswers
      && answerPreviousYearData.facilityAddedAnswers.length > 0
    ) {
      appendAnswerToCustomerFields(answerPreviousYearData.facilityAddedAnswers);
    }
  }, []);

  useEffect(() => {
    if (!answerData.rows && answerPreviousYearData.rows) {
      setShowPreviousDataMessage(true);
    } else if (showPreviousDataMessage) {
      setShowPreviousDataMessage(false);
    }
  }, [answerData]);

  const getMassyRowData = (answer) => {
    const catagoriesEntries = Object.entries(answer);

    const rowData = catagoriesEntries.map((item) => {
      const valueArray = Object.entries(item[1]).map((_valueArray) => {
        const isAnswersNotBlocked = answers[0].nestedQuestions.find(
          (nestedQuestion) => nestedQuestion.id === item[0],
        );
        const answersData = isAnswersNotBlocked
          ? isAnswersNotBlocked.rowQuestions
            .find((rowQuestion) => rowQuestion.id === _valueArray[0])
          : undefined;

        if (answersData) {
          const { unitMeasurement, unitType } = answersData;
          // check from latest update first and initial answer set
          const unitMeasurementType = Object.prototype.hasOwnProperty.call(_valueArray[1], 'unitMeasurementType') ? _valueArray[1].unitMeasurementType : unitMeasurement;
          const defaultUnits = checkUserUnits(unitMeasurementType) || {};
          return {
            questionId: `nestedQuestionId__${_valueArray[0]}__${item[0]}`,
            title: answersData.title,
            value: _valueArray[1].value,
            sortOrder: answersData.sortOrder,
            unitMeasurementType: unitMeasurementType ? unitMeasurementType.toLowerCase() : '',
            unitLabel: defaultUnits[unitType],
          };
        }
        return {};
      });
      const sectionData = answers[0].nestedQuestions.find((object) => object.id === item[0]);
      if (sectionData) {
        return {
          questionType: sectionData.questionType,
          category: sectionData.title,
          content: valueArray.sort((a, b) => a.sortOrder - b.sortOrder),
          sortOrder: sectionData.sortOrder,
        };
      }
      return {};
    });
    return rowData.sort((a, b) => a.sortOrder - b.sortOrder);
  };
  const onClickPopUpEditModal = (index, formSectionIndex = 0) => {
    setClickedEditRowFromTable({ formSectionIndex, arrayIndex: index, dataGridAnswer: dataGridAnswers[index] });
    updateAddModal(true);
  };
  const onClickConfirmEditItem = () => {
    const formValues = getValues({ nest: true });
    let row = {};

    Object.keys(formValues).map((key) => {
      const nestedIdStringArray = key.split('__');

      if (nestedIdStringArray.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        const nestedQuestionId = nestedIdStringArray[1];
        const rowQuestionId = null || nestedIdStringArray[2];

        const sectionsKey = rowQuestionId ? `nestedQuestions__${nestedQuestionId}__${rowQuestionId}` : `nestedQuestions__${nestedQuestionId}`;
        const shapedAnswer = {
          [nestedQuestionId]: {

          },
        };

        const columnObject = answerSetUtils.getColumnDataByNestedQuestionId(
          tableColumnTitles, nestedQuestionId,
        );

        if (columnObject.uiType === 'DROPDOWNLIST') { // convert uitype of drop down within multi-choices component and in RowRender component
          shapedAnswer[nestedQuestionId] = {
            [formValues[sectionsKey]]: {
              unitMeasurementType: null,
              uiType: columnObject.uiType,
              value: true,
            },
          };
        } else if (columnObject.uiType === 'MULTIPLECHOICE_ADDMORE' || columnObject.uiType === 'SINGLECHOICE') {
          const selectedAnswers = formValues[sectionsKey].filter((item) => Object.values(item)[0]);
          const selectedKeys = selectedAnswers.map((item) => Object.keys(item)[0]);
          const target = selectedKeys.reduce((acc, curr) => {
            acc[curr] = {
              unitMeasurementType: null,
              value: true,
              uiType: columnObject.uiType,
              nestedQuestionId,
            };
            return acc;
          }, {});
          shapedAnswer[nestedQuestionId] = target;
        } else {
          const fieldDataFromForm = formValues[`nestedQuestionId__${nestedQuestionId}__${rowQuestionId}`];
          let value = formValues[rowQuestionId];
          const unitFieldName = `nestedQuestionUnit__${nestedQuestionId}__${rowQuestionId}`;
          // Fetch selected unit Label, otherwise set default
          const units = formValues[unitFieldName]
            && formValues[unitFieldName].unitLabel
            ? formValues[unitFieldName].unitLabel : org.measurement_unit;

          if (fieldDataFromForm.fieldType === 'DateTime') {
            // time check
            if (value && value.toString().split('/').length !== 3) value = format(value, 'yyyy/MM/dd');
          }

          shapedAnswer[nestedQuestionId] = {
            ...row[nestedQuestionId],
            [rowQuestionId]: {
              value,
              unitMeasurementType: units,
              uiType: columnObject.uiType,
              isDateTime: fieldDataFromForm.fieldType === 'DateTime',
              isUnit: !!(formValues[unitFieldName] && formValues[unitFieldName].unitLabel),
            },
          };
        }

        row = {
          ...row,
          ...shapedAnswer,
        };
      }
      return key;
    });

    dataGridAnswers[clickedEditRowFromTable.arrayIndex] = row;
    updateDataGridAnswers(dataGridAnswers);

    updateAddModal(false);
    setClickedEditRowFromTable(undefined);
  };

  const CustomDivToggle = ({
    eventKey,
    children,
    className,
  }) => {
    const decoratedOnClick = useAccordionToggle(eventKey);

    return (
      <div
        id={eventKey}
        className={`${className}`}
        onClick={(e) => {
          if (e.target.id) { decoratedOnClick(); }
        }}
        aria-hidden="true"
      >
        {children}
      </div>
    );
  };
  CustomDivToggle.displayName = 'CustomDivToggle';
  CustomDivToggle.defaultProps = {
    className: '',
  };
  CustomDivToggle.propTypes = {
    eventKey: PropTypes.number.isRequired,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  const CustomToggle = ({
    eventKey, callback,
    className,
  }) => {
    const currentEventKey = React.useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      return callback && callback(eventKey);
    });
    const isCurrentEventKey = currentEventKey && eventKey && currentEventKey === eventKey
      ? true
      : currentEventKey && eventKey
      && currentEventKey.toString().toUpperCase() === eventKey.toString().toUpperCase();
    const label = isCurrentEventKey ? <img src={DownArrowImg} alt="down arrow" /> : <img src={UpArrowImg} alt="down arrow" />;

    return (
      <div
        className={`data-grid-button_colapse ${className}`}
        onClick={decoratedOnClick}
        aria-hidden="true"
      >
        {label}
      </div>
    );
  };
  CustomToggle.displayName = 'CustomToggle';
  CustomToggle.defaultProps = {
    callback: () => { },
    className: '',
  };
  CustomToggle.propTypes = {
    callback: PropTypes.func,
    eventKey: PropTypes.number.isRequired,
    className: PropTypes.string,
  };

  function onClickAddRowButton(datagridAnswers) {
    // find first unpopulated row
    let shouldEditRow = -1;

    for (let i = 0; i < datagridAnswers.length; i += 1) {
      const thisTablesAnswerData = getMassyRowData(datagridAnswers[i]);

      let anyAnswers = false;

      const keys = Object.keys(thisTablesAnswerData);
      for (let q = 0; q < thisTablesAnswerData.length; q += 1) {
        const entry = thisTablesAnswerData[keys[q]];
        switch (entry.questionType) {
          case 'QUESTIONLIST': {
            const answerKeys = Object.keys(entry.content);
            for (let a = 0; a < entry.content.length; a += 1) {
              const thisAnswer = entry.content[answerKeys[a]];
              if (thisAnswer.value !== undefined && thisAnswer.value.length > 0) {
                anyAnswers = true;
              }
            }
            break;
          }
          case 'CHOICE': {
            if (entry.content.length > 0) {
              const contentKeys = Object.keys(entry.content);
              for (let c = 0; c < entry.content.length; c += 1) {
                const content = entry.content[contentKeys[c]];
                if (content.length > 0 || Object.keys(content).length > 0) {
                  anyAnswers = true;
                  break;
                }
              }
            }
            break;
          }
          default:
            break;
        }
        if (anyAnswers === true) {
          break;
        }
      }

      if (anyAnswers === false) {
        shouldEditRow = i;
        break;
      }
    }

    if (shouldEditRow !== -1) {
      onClickPopUpEditModal(shouldEditRow);
    } else {
      onClickConfirmAddItem(datagridAnswers.length === 0);
      onClickPopUpEditModal(datagridAnswers.length);
    }
  }

  return (
    <div className="components__data-grid">
      <h7 className="italic">{instructions}</h7>
      {showPreviousDataMessage ? <p className="review-page-alert">{previousDataString}</p> : null}
      {
        <Table>
          {((!dataGridAnswers.length > 0 && !verticalTableLayout) || (!dataGridAnswers.length > 0 && (verticalTableLayout && (answers[0].nestedQuestions[0] && answers[0].nestedQuestions[0].questionType) !== 'QUESTIONLIST'))) && (
          <>
            <thead>
              <tr>
                {
                tableColumnTitles.map((column) => {
                  return (
                    <th key={column.title} scope="col">{column.title}</th>
                  );
                })
                }
                <th className="last-table-tr-th" scope="col" aria-label="cta" />
              </tr>
            </thead>
            <tbody>
              <tr onClick={() => {
                onClickConfirmAddItem();
                onClickPopUpEditModal(0, dataGridAnswers[0]);
              }}
              >
                {
                  tableColumnTitles.map((column, index) => {
                    return (
                      <td className="temp-example-td" key={column.id} data-label={`${tableColumnTitles[index].title} `}>
                        {get('question-set-ExampleAnswer')}
                      </td>
                    );
                  })
                }
                <td data-label="">
                  <button
                    type="button"
                    className="data-grid-delete-button"
                    data-cy="profile-delete-role-button"
                    onClick={() => {
                      onClickConfirmAddItem();
                      onClickPopUpEditModal(0, dataGridAnswers[0]);
                    }}
                  >
                    <img
                      src={IconEditImg}
                      alt="edit answer"
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </>
          )}
          {((dataGridAnswers.length > 0 && !verticalTableLayout) || (dataGridAnswers.length > 0 && (verticalTableLayout && (answers[0].nestedQuestions[0] && answers[0].nestedQuestions[0].questionType) !== 'QUESTIONLIST')))
              && (
              <>
                <thead>
                  <tr>
                    {
                tableColumnTitles.map((column) => {
                  return (
                    <th key={column.title} scope="col">{column.title}</th>
                  );
                })
                }
                    <th className="last-table-tr-th" scope="col" aria-label="cta" />
                  </tr>
                </thead>
                {dataGridAnswers.map((answer, index) => {
                  const key = index;
                  return (
                    <tr key={key}>
                      <RowRenderer
                        key={answer.id}
                        row={answer}
                        columns={tableColumnTitles}
                        answers={answers}
                        customerAnswerFields={customerAnswerFields}
                        onclick={() => onClickPopUpEditModal(index, answer)}
                        showPreviousDataMessage={
                        // as long as index  <= preious data row length , should be red text
                        showPreviousDataMessage && index <= answerPreviousYearData.rows.length - 1
                      }
                        indexOfModalForm={indexOfModalForm}
                      />
                      <td>
                        <button type="button" className="data-grid-delete-button" data-cy="profile-delete-role-button" onClick={() => onClickPopUpEditModal(index, answer)}>
                          <img src={IconEditImg} alt="edit answer" />
                        </button>
                        {(!_.isEmpty(answer) || index > 0) && (
                          <button
                            type="button"
                            className="data-grid-delete-button"
                            data-cy="profile-delete-role-button"
                            onClick={() => {
                              setShowDltConfirmModal('row');
                              setTobedeletedData({ index, answer });
                            }}
                          >
                            <img src={IconTrashImg} alt="trash answer" />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </>
              )}
        </Table>
      }
      {/* {
        (!dataGridAnswers.length > 0 && verticalTableLayout) && (
          <p>test</p>
        )
      } */}
      {/* {dataGridAnswers.length === 0 ? onClickConfirmAddItem() : null} */}

      {
        ((!dataGridAnswers.length > 0 && verticalTableLayout) && ((answers[0].nestedQuestions[0] && answers[0].nestedQuestions[0].questionType) === 'QUESTIONLIST'))
        && (
        <div className="dataGrid__mass_table --no-border">
          {answers[0] && (answers[0].nestedQuestions || []).map((nestedQuestion, index) => {
            return (
              <Accordion defaultActiveKey={additionAdded ? dataGridAnswers.length : 1}>
                <CustomDivToggle
                  className="dataGrid_table__buttons right_float mt-3"
                  attr="collapseOneNote"
                  eventKey={index + 1}
                >
                  <div className="margin-left">
                    <h6>{(nestedQuestion.questionType) === 'QUESTIONLIST' ? nestedQuestion.title : ''}</h6>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center ">
                    <button
                      type="button"
                      id="edit"
                      className="data-grid-edit-button p-1 hidden-on-smaller-Screens"
                      onClick={() => onClickPopUpEditModal(index, index)}
                    >
                      <img src={IconEditImg} alt="edit answer" />
                    </button>
                    <CustomToggle
                      attr="collapseOneNote"
                      eventKey={index + 1}
                    />
                  </div>
                </CustomDivToggle>
                <Accordion.Collapse eventKey={index + 1}>
                  <Table>
                    <tbody>
                      {(answers[0].nestedQuestions.map((column, innndex) => {
                        if (innndex !== index) return;
                        if (column.uiType === 'TEXTINPUTS') {
                          return (
                            column.rowQuestions.map((row) => {
                              return (
                                <tr onClick={() => onClickPopUpEditModal(index, index)}>
                                  <td className="veritcal-table-td bold_text">{row.title}</td>
                                  <td className="temp-example-td">
                                    <span className="placeholder-answer-text">{get('question-set-ExampleAnswer')}</span>
                                  </td>
                                </tr>
                              );
                            })
                          );
                        }
                        return (
                          <tr key={column.id}>
                            <td className="veritcal-table-td bold_text">{column.title}</td>
                            <td className="temp-example-td">
                              {`(Example) ${column.rowQuestions[0] ? column.rowQuestions[0].title : ''}`}
                            </td>

                          </tr>
                        );
                      })) }
                    </tbody>
                  </Table>
                </Accordion.Collapse>
              </Accordion>

            );
          })}
        </div>
        )
            }

      {
        ((dataGridAnswers.length > 0 && verticalTableLayout) && ((answers[0].nestedQuestions[0] && answers[0].nestedQuestions[0].questionType) === 'QUESTIONLIST'))
        && dataGridAnswers.map((answer, index) => {
          const key = index;
          return (
            <div key={key} className="dataGrid__mass_table --no-border">
              {answers[0] && (answers[0].nestedQuestions || []).map((nestedQuestion, idx) => {
                return (
                  <Accordion defaultActiveKey={additionAdded ? dataGridAnswers.length : 1}>
                    <CustomDivToggle
                      className="dataGrid_table__buttons right_float mt-3"
                      attr="collapseOneNote"
                      eventKey={idx + 1}
                    >
                      <div className="margin-left">
                        <h6>{(nestedQuestion.questionType) === 'QUESTIONLIST' ? nestedQuestion.title : ''}</h6>
                      </div>
                      <div className="d-flex flex-row justify-content-center align-items-center ">
                        <button
                          type="button"
                          id="edit"
                          className="data-grid-edit-button p-1"
                          onClick={() => onClickPopUpEditModal(index, idx)}
                        >
                          <img src={IconEditImg} alt="edit answer" />
                        </button>
                        {/* onClickDeleteItem(index, answer) */}
                        {((!_.isEmpty(answer) && index > 0) || (index > 0 && _.isEmpty(answer))) && (
                          <button
                            type="button"
                            className="data-grid-delete-button p-1 hidden-on-smaller-Screens"
                            data-cy="profile-delete-role-button"
                            onClick={() => {
                              setShowDltConfirmModal('table');
                              setTobedeletedData({ index, answer });
                            }}
                          >
                            <img src={IconTrashImg} alt="trash answer" />
                          </button>
                        )}
                        <CustomToggle
                          attr="collapseOneNote"
                          eventKey={index + 1}
                        />
                      </div>
                    </CustomDivToggle>
                    <Accordion.Collapse eventKey={index + 1}>
                      <Table>
                        <tbody>
                          {_.isEmpty(answer) ? (answers[0].nestedQuestions.map((column, innndex) => {
                            if (innndex !== idx) return;
                            if (column.uiType === 'TEXTINPUTS') {
                              return (
                                column.rowQuestions.map((row) => {
                                  return (
                                    <tr onClick={() => onClickPopUpEditModal(index, index)}>
                                      <td className="veritcal-table-td bold_text">{row.title}</td>
                                      <td className="temp-example-td">
                                        <span className="placeholder-answer-text">{get('question-set-ExampleAnswer')}</span>
                                      </td>
                                    </tr>
                                  );
                                })
                              );
                            }
                            return (
                              <tr key={column.id}>
                                <td className="veritcal-table-td bold_text">{column.title}</td>
                                <td className="temp-example-td">
                                  {`(Example) ${column.rowQuestions[0] ? column.rowQuestions[0].title : ''}`}
                                </td>

                              </tr>
                            );
                          })) : (
                            getMassyRowData(answer).map((item) => {
                              // eslint-disable-next-line no-unused-vars
                              const isExpand = additionAdded
                        && item.content && item.content.filter((row) => row
                          && row.value
                          && row.value.toString().length !== 0).length !== 0;
                              if (item.questionType === 'QUESTIONLIST') {
                                return nestedQuestion.rowQuestions.map((rowQuestion, inndx) => {
                                  // Make sure we only show the answers for the current nestedQuestion
                                  if (item && item.content && item.content.length > 0 && item.content[0].questionId.indexOf(nestedQuestion.id) === -1) return;
                                  return (
                                    <tr onClick={() => onClickPopUpEditModal(index, idx)}>
                                      <td className="veritcal-table-td bold_text">{rowQuestion.title}</td>
                                      <td className={showPreviousDataMessage && index <= answerPreviousYearData.rows.length - 1 ? 'redText' : ''}>
                                        {item.content[inndx].value ? (
                                          <>
                                            <span>{item.content[inndx].value} {item.content[inndx].unitLabel ? item.content[inndx].unitLabel : ''}</span>
                                            <br />
                                          </>
                                        ) : (
                                          <span className="placeholder-answer-text">
                                            {(item.content[inndx].value === '' || item.content[inndx].value === undefined) ? '' : `${get('question-set-ExampleAnswer')}`}
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                });
                              }

                              return (
                                item && item.content && (
                                  <>
                                    <tr className="right-align-table-tr">
                                      <td className="veritcal-table-td bold_text">{item.category}</td>

                                      <td className={showPreviousDataMessage && index <= answerPreviousYearData.rows.length - 1 ? 'redText' : ''}>

                                        {item.content
                                          .map((detail) => (
                                            detail.value && (
                                              <>
                                                <span>{detail.title} {detail.unitLabel ? detail.unitLabel : ''}</span>
                                                <br />
                                              </>
                                            )
                                          ))}
                                      </td>
                                    </tr>
                                  </>
                                )
                              );
                            })

                          )}
                        </tbody>
                      </Table>
                    </Accordion.Collapse>
                  </Accordion>

                );
              })}
            </div>
          );
        })
      }

      {/* Show the add button only if user has added a row, otherwise let the user edit the example row */}
      {(dataGridAnswers.length > 0 && !_.isEmpty(dataGridAnswers[0])) ? (
        <button
          type="button"
          className="data-grid-add-button"
          onClick={() => {
            onClickAddRowButton(dataGridAnswers);
          }}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <img src={plusImg} alt="plus" />
            <p className="data-grid-add-button-text">{answers[0].nestedQuestions[0] && answers[0].nestedQuestions[0].questionType === 'QUESTIONLIST' ? get('question-set-AddNewTableButton') : get('question-set-AddRowButton')}</p>
          </div>
        </button>
      ) : null}

      <Modal
        closeBtnColour={colour}
        show={showDltConfirmModal}
        onClose={() => setShowDltConfirmModal('')}
        heading={showDltConfirmModal === 'table' ? get('question-set-delete-table') : get('question-set-delete-row')}
        subtitle={showDltConfirmModal === 'table' ? get('question-set-delete-table-messagae') : get('question-set-delete-row-messagae')}
        footer={(
          <div className="delete-table-row-ctas">
            <Button
              colour={colour}
              className="flex-1"
              form="add-editor-form"
              type="button"
              inverse
              onClick={() => setShowDltConfirmModal('')}
            >
              {get('question-set-delete-table-row-no')}
            </Button>
            <Button
              colour={colour}
              className="flex-1"
              form="add-editor-form"
              type="button"
              onClick={
                () => {
                  if (tobedeletedData) {
                    onClickDeleteItem(tobedeletedData.index, tobedeletedData.answer);
                    setTobedeletedData(null);
                    setShowDltConfirmModal('');
                  }
                }
              }
            >
              {get('question-set-delete-table-row-yes')}
            </Button>
          </div>
        )}
      />
      <FormModal
        colour={colour}
        watch={watch}
        register={register}
        addModal={addModal}
        answers={answers}
        updateAddModal={updateAddModal}
        control={control}
        errors={errors}
        onClickAddItem={onClickConfirmAddItem}
        userUnits={userUnits}
        userMeasurementUnit={org.measurement_unit}
        setValue={setValue}
        getValues={getValues}
        append={append}
        handleSubmit={handleSubmit}
        answerData={answerData}
        customerAnswerFields={customerAnswerFields}
        removeAnswerFromCustomerFields={removeAnswerFromCustomerFields}
        appendAnswerToCustomerFields={appendAnswerToCustomerFields}
        insertAnswerToCustomerFields={insertAnswerToCustomerFields}
        indexOfModalForm={indexOfModalForm}
        clickedEditRowFromTable={clickedEditRowFromTable}
        setClickedEditRowFromTable={setClickedEditRowFromTable}
        onClickConfirmEditItem={onClickConfirmEditItem}
      />

      <RouteLeavingGuard
        navigate={(path) => history.push(path)}
        // eslint-disable-next-line no-unused-vars
        shouldBlockNavigation={(location) => {
          if (clickedSaveButton) return false;
          if (!_.isEmpty(formState.dirtyFields)) return true;
          if (!_.isEqual(originalDataGridAnswers, dataGridAnswers)) return true;

          return false;
        }}
      />
    </div>
  );
});

DataGridQuestions.defaultProps = {
  errors: {},
  answerData: {
    facilityAddedAnswers: [],
  },
  answerPreviousYearData: {
    facilityAddedAnswers: [],
  },
  userUnits: {},
  instructions: '',
  colour: '#4CA471',
};

DataGridQuestions.propTypes = {
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({}),
  ]),
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  formState: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  userUnits: PropTypes.shape({}),
  answerData: PropTypes.shape({
    facilityAddedAnswers: [],
    rows: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  answerPreviousYearData: PropTypes.shape({
    facilityAddedAnswers: [],
    rows: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  append: PropTypes.func.isRequired,
  verticalTableLayout: PropTypes.bool.isRequired,
  clickedSaveButton: PropTypes.bool.isRequired,
  updateClickedSaveButton: PropTypes.func.isRequired,
  instructions: PropTypes.string,
  colour: PropTypes.string,

};
export default DataGridQuestions;

// q: how to check if an object is empty
