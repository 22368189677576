/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import FormError from './FormError';
import { ArrowUp, ArrowDown } from '../../Icons';
import Label from '../label/Label';
import { get } from '../../../lib/strings';
import arrayUtils from '../../../utils/array';

import './DropdownField.scss';
import themeType from '../../../lib/consts/themeType';

const DropdownField = ({
  name,
  label,
  theme,
  className,
  options,
  control,
  errors,
  validationRules,
  handleChange,
  colour,
}) => {
  const root = document.documentElement;
  const isTournament = theme === themeType.TOURNAMENT;

  if (root) {
    root.style.setProperty('--primary-dropdown', colour || (isTournament ? '#1077C6' : '#4CA471'));
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => validationRules(value),
      }}
      render={(fields) => {
        return (
          <CustomDropdownField
            {...fields}
            label={label}
            className={className}
            theme={theme}
            options={options}
            handleChange={handleChange}
            errors={errors}
          />
        );
      }}
    />
  );
};

DropdownField.defaultProps = {
  theme: 'light',
  label: undefined,
  className: undefined,
  errors: {},
  validationRules: () => { },
  colour: '',
};

DropdownField.propTypes = {
  colour: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ).isRequired,
  label: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  validationRules: PropTypes.func,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({}),
  ]),
};

export default DropdownField;

const CustomDropdownField = React.forwardRef(({
  name,
  value,
  onChange,
  label,
  options,
  theme,
  className,
  handleChange,
  errors,
}, ref) => {
  const [id] = useState(_.uniqueId(''));
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(value);
  const pleaseChoose = get('control-dropdown-default') || 'Please choose';

  options.sort((a, b) => a.value.localeCompare(b.value));
  const otherIndex = options.findIndex((item) => item.value === get('facility-typesOptions-other'));

  if (otherIndex !== -1) {
    const newOther = options[otherIndex];

    // delete other from index
    options.splice(otherIndex, 1);
    options.push(newOther);
  }

  useEffect(() => {
    if (value !== 'Please choose') {
      setDropdownValue(arrayUtils.getOptionLabel(options, value));
    } else {
      setDropdownValue(pleaseChoose);
    }
  }, [value]);

  const themeClass = `components__form-dropdown-${theme}`;
  const optionsLength = options && options.length;
  let errorMessage;

  if (errors[name]) {
    if (errors[name].type === 'required') {
      errorMessage = get('login-formRequiredField');
    } else {
      errorMessage = errors[name].message;
    }
  }

  const CustomToggle = React.forwardRef(({ value, onClick }, ref) => {
    const dropdownIsOpen = isOpen ? 'dropdown-open' : {};
    const dropdownSelectedValue = options.length >= 0 && options[0] !== value
      ? 'dropdown-toggle-value'
      : {};
    const inputClasses = errors[name] ? 'error' : '';

    return (
      <div
        className={`dropdown-toggle ${dropdownIsOpen} ${inputClasses}`}
        href=""
        ref={ref}
        aria-hidden="true"
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <p className={`dropdown-toggle-value-default ${dropdownSelectedValue}`}>
          {value}
        </p>
        <div className="dropdown-toggle-icon-wrapper">
          {isOpen ? <ArrowUp /> : <ArrowDown />}
        </div>
      </div>
    );
  });

  CustomToggle.displayName = 'CustomToggle';

  const onSelect = (eventKey) => {
    onChange(eventKey);
    handleChange(eventKey);
  };

  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({
      children, style, className, 'aria-labelledby': labeledBy,
    }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          {children}
        </div>
      );
    },
  );

  return (
    <div
      ref={ref}
      className={`components__form-dropdown-field ${className} ${themeClass}`}
    >
      {label && <Label htmlFor={id} text={label} />}
      <Dropdown
        value={dropdownValue}
        onSelect={onSelect}
        onToggle={(e) => setIsOpen(e)}
      >
        <Dropdown.Toggle as={CustomToggle} value={dropdownValue} />
        <Dropdown.Menu
          name={name}
          as={CustomMenu}
        >
          {options.map((item, index) => {
            const divider = optionsLength - 1 !== index;
            return (
              <div key={item.value}>
                <Dropdown.Item
                  className="dropdown-item"
                  eventKey={item.key}
                >
                  {item.value}
                </Dropdown.Item>

                {divider && (
                  <Dropdown.Divider
                    name={name}
                    className="dropdown-divider"
                  />
                )}
              </div>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>

      {errorMessage && <FormError message={errorMessage} />}
    </div>
  );
});

CustomDropdownField.displayName = 'CustomDropdownField';

CustomDropdownField.defaultProps = {
  value: 'Please choose',
  theme: 'light',
  className: undefined,
  errors: {},
  label: undefined,
  handleChange: () => { },
};

CustomDropdownField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({}),
  ]),
};
