import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from '../../components/core/backgroundImage/BackgroundImage';
import OnCourseLogo from '../../assets/OnCourse_Logo.svg';
import TermsAndConditionsContent from './TermsAndConditionsContent';
import './TermsAndConditions.scss';

const TermsAndConditions = ({ hasLogo }) => {
  return (
    <div className={`${hasLogo ? '' : 'terms-and-conditions-no-margin'} terms-and-conditions__page`}>
      {hasLogo ? (
        <>
          <BackgroundImage />
          <img className="logo-title" src={OnCourseLogo} alt="OnCourse_logo" />
        </>
      ) : null}
      <TermsAndConditionsContent />
    </div>
  );
};

TermsAndConditions.defaultProps = {
  hasLogo: true,
};

TermsAndConditions.propTypes = {
  hasLogo: PropTypes.bool,
};

export default TermsAndConditions;
