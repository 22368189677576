import * as React from 'react';
import PropTypes from 'prop-types';

function ArrowUp({ fill, className }) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Icon/Arrow/Up</title>
      <path
        stroke={fill || '#6E7682'}
        d="M3.5 20.5l11-11 11 11"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
}

export default ArrowUp;

ArrowUp.defaultProps = {
  fill: '#6E7682',
  className: '',
};

ArrowUp.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};
