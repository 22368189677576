import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ProfileSettingsForm from '../../components/profile/profileSettings-form/ProfileSettingsForm';
import { GlobalContext } from '../../context/GlobalContext';
import { get } from '../../lib/strings';
import Loader from '../../components/Loaders/PageLoader';

import './ProfileSettings.scss';

const ProfileSettings = ({ settingFormRef }) => {
  const { getStrings, updateUser, user } = useContext(GlobalContext);
  const [profileError, setProfileError] = useState(undefined);
  const errorMessage = get('profile-errorOnSaveMessage');
  const [isPageLoading, updateIsPageLoading] = useState(false);

  const onClickSaveSettings = async (newUser) => {
    setProfileError(undefined);
    updateIsPageLoading(true);

    // Trigger language update if the new language is different from the user language
    if (user.language !== newUser.language) {
      await getStrings(newUser.language);
    }

    try {
      await updateUser(user.id, newUser);
    } catch (error) {
      setProfileError(errorMessage);
    }
    setTimeout(() => {
      updateIsPageLoading(false);
    }, 300);
  };

  return (
    <div className="component__profile-settings">
      <Loader isLoading={isPageLoading}>
        <ProfileSettingsForm
          className="profile-page-profile-settings-form"
          onComplete={onClickSaveSettings}
          profileError={profileError}
          ref={settingFormRef}
        />
      </Loader>
    </div>
  );
};

ProfileSettings.defaultProps = {
  settingFormRef: PropTypes.shape({ current: undefined }),
};

ProfileSettings.propTypes = {
  settingFormRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default ProfileSettings;
