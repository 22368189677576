import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../context/GlobalContext';
import themeType from '../../lib/consts/themeType';

const CheckedIcon = ({ fill, className }) => {
  const { theme } = useContext(GlobalContext);
  const colourFromTheme = theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471';

  return (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Input Field / Checkbox / Checked</title>
      <g id="Input-Field-/-Checkbox-/-Checked" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle id="Oval" fill={fill || colourFromTheme} cx="12" cy="12" r="12" />
        <g id="Group" transform="translate(12.500000, 11.000000) rotate(45.000000) translate(-12.500000, -11.000000) translate(10.000000, 5.000000)" fill="#FFFFFF">
          <rect id="Rectangle" x="3" y="0" width="2" height="12" rx="1" />
          <rect id="Rectangle" x="0" y="10" width="5" height="2" rx="1" />
        </g>
      </g>
    </svg>
  );
};

export default CheckedIcon;

CheckedIcon.defaultProps = {
  fill: '',
  className: '',
};

CheckedIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};
