import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import BootstrapModal from 'react-bootstrap/Modal';
import { getCroppedImg } from '../../../lib/canvasUtils';
import { CloseIcon } from '../../Icons';
import Alert from '../alert/Alert';
import Button from '../button/Button';
import { get } from '../../../lib/strings';

import './ImageUpload.scss';

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const ImageUpload = ({
  name,
  label,
  cropModalTitle,
  cropModalButtonText,
  className,
  control,
  error,
  validationRules,
  imageWidth,
  imageHeight,
  aspectRation,
  imagePlaceholder,
  onImageUpload,
  onCloseModal,
  src,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => validationRules(value),
      }}
      render={(fields) => {
        return (
          <ImgUpload
            {...fields}
            label={label}
            name={name}
            cropModalTitle={cropModalTitle}
            cropModalButtonText={cropModalButtonText}
            className={className}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            aspectRation={aspectRation}
            imagePlaceholder={imagePlaceholder}
            error={error}
            onImageUpload={onImageUpload}
            onCloseModal={onCloseModal}
            src={src}
          />
        );
      }}
    />
  );
};

ImageUpload.defaultProps = {
  error: undefined,
  validationRules: () => {},
  onImageUpload: () => {},
  onCloseModal: () => {},
  className: undefined,
  label: undefined,
  cropModalTitle: undefined,
  cropModalButtonText: undefined,
  aspectRation: 1,
  imageHeight: 160,
  imageWidth: 160,
  imagePlaceholder: true,
  src: '',
};

ImageUpload.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  cropModalTitle: PropTypes.string,
  cropModalButtonText: PropTypes.string,
  name: PropTypes.string.isRequired,
  aspectRation: PropTypes.number,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  imagePlaceholder: PropTypes.bool,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  validationRules: PropTypes.func,
  onImageUpload: PropTypes.func,
  onCloseModal: PropTypes.func,
  error: PropTypes.string,
  src: PropTypes.string,
};

export default ImageUpload;

const ImgUpload = React.forwardRef((props, ref) => {
  const {
    src,
    name,
    onChange,
    onImageUpload,
    onCloseModal,
    label,
    cropModalButtonText,
    cropModalTitle,
    className,
    imageWidth,
    imageHeight,
    imagePlaceholder,
    error,
  } = props;

  const refContainer = useRef(null);

  const [imageFile, setImageFile] = React.useState(null);
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(src);
  const [cropSize, setCropSize] = useState(null);
  const width = imageWidth ? `${imageWidth}px` : '160px';
  const height = imageHeight ? `${imageHeight}px` : '160px';

  const onCropComplete = useCallback((croppedArea, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel);
  }, []);

  const resetState = () => {
    setImageSrc(null);
    setZoom(1);

    onCloseModal();
  };

  useEffect(() => {
    if (!src) return;
    if (src instanceof FormData) return;
    setCroppedImage(src);
  }, [src]);

  const onImageLoad = (image) => {
    const cropHeight = image.height > 270 ? 270 : image.height; // 270px container's size
    const cropWidth = image.width > 270 ? 270 : image.width;
    setCropSize({ width: cropWidth, height: cropHeight });
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedWidth = name === 'logo' ? croppedAreaPixels.width : imageWidth;// fixed size for profile
      const croppedHeight = name === 'logo' ? croppedAreaPixels.height : imageHeight;
      const fileCropped = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        croppedWidth,
        croppedHeight,
      );

      const imageCropped = URL.createObjectURL(fileCropped);

      onImageUpload(fileCropped, imageFile.name, imageCropped, () => {
        setCroppedImage(imageCropped);
        resetState();

        onChange(imageCropped);
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  const onClose = useCallback(() => {
    resetState();
  }, []);

  const onClickUploadImage = () => {
    refContainer.current.click();
  };

  const onInputclick = () => {
    refContainer.current.value = null;
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);
      setImageFile(file);
      setImageSrc(imageDataUrl);
    }
  };

  return (
    <div ref={ref} className="components__form-image-upload">
      <div className={`photo-upload-wrapper ${className}`}>
        <label htmlFor="photo-upload" className="custom-file-upload fas" style={{ width, height }}>
          <div className="img-wrap img-upload" style={{ width, height }}>
            {croppedImage ? (
              <img htmlFor="photo-upload" src={croppedImage} alt="profile" />
            ) : (
              <div className="img-placeholder" style={{ width, height }}>
                {imagePlaceholder ? <img htmlFor="photo-upload" src={imagePlaceholder} alt="default" style={{ width, height }} /> : null}
              </div>
            )}
          </div>
          <input id="photo-upload" type="file" accept=".png, .jpg, .jpeg" onChange={onFileChange} onClick={onInputclick} ref={refContainer} hidden />
        </label>
        <div className="upload-button" onClick={onClickUploadImage} aria-hidden="true">
          <p className="upload-button-text">{label}</p>

        </div>
        <div className="recommend-text">
          <p>{`${get('image-min-size')}: ${width.slice(0, -2)}x${height.slice(0, -2)}`}</p>
          <p>{`${get('image-format')}: jpeg,png,jpg`}</p>
        </div>
      </div>
      {imageSrc ? (
        <BootstrapModal
          className="crop-overlay"
          centered
          show={!!imageSrc}
          onHide={onClose}
          backdrop
        >
          <div className="crop-overlay-content">
            <div className="crop-overlay-header">
              <p className="crop-overlay-header-title">{cropModalTitle}</p>
              <div className="crop-overlay-header-close" data-cy="close-button" aria-hidden="true" onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
            <div className="cropContainer">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                onZoomChange={setZoom}
                cropSize={cropSize}
                onMediaLoaded={onImageLoad}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                objectFit="contain"
              />
            </div>
            <div className="controls">
              <div className="sliderContainer">
                <p className="controls-text">-</p>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{ root: 'slider', rail: 'rail', thumb: 'thumb' }}
                  onChange={(e, zoomLevel) => setZoom(zoomLevel)}
                />
                <p className="controls-text">+</p>
              </div>
              <Button
                className="crop-overlay--button medium"
                data-cy="crop-upload-button"
                onClick={showCroppedImage}
              >
                {cropModalButtonText}
              </Button>
            </div>
            {error ? (
              <Alert data-cy="image-upload-error" className="mt-3" variant="danger">
                {error}
              </Alert>
            ) : null}
          </div>

        </BootstrapModal>

      ) : null}
    </div>
  );
});

ImgUpload.displayName = 'ImgUpload';

ImgUpload.defaultProps = {
  label: undefined,
  name: undefined,
  cropModalTitle: undefined,
  cropModalButtonText: undefined,
  className: undefined,
  src: '',
  imageHeight: 160,
  imageWidth: 160,
  imagePlaceholder: undefined,
  error: undefined,
  onImageUpload: () => {},
  onCloseModal: () => {},
};

ImgUpload.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  cropModalTitle: PropTypes.string,
  cropModalButtonText: PropTypes.string,
  className: PropTypes.string,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  imagePlaceholder: PropTypes.string,
  src: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func,
  onCloseModal: PropTypes.func,
  error: PropTypes.string,
};
