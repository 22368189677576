import React from 'react';
import PropTypes from 'prop-types';

const ArrowIconAutoColour = ({ fill, className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <polyline points="6 9 12 15 18 9" />
    </svg>

  );
};

export default ArrowIconAutoColour;

ArrowIconAutoColour.defaultProps = {
  fill: '#525352',
  className: '',
};

ArrowIconAutoColour.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};
