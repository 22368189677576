/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import _ from 'lodash';
import api from '../../lib/http';
import questionTypes from '../../lib/consts/questionTypes';

const getMarkedComplete = (answers) => {
  const keys = Object.keys(answers);
  const completeIds = [];
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const item = answers[key];
    if (item && item.complete) {
      completeIds.push(key);
    }
  }
  return completeIds;
};

export default {
  getOrganisationQuestionsById: async (
    questionSetId,
    orgId,
    languageCode,
  ) => {
    // api will default to english
    const code = (languageCode) ? `?code=${languageCode}` : '';
    const response = await api(
      'get',
      `/reviews/questions/${questionSetId}/organisation/${orgId}${code}`,
      {},
      false,
      false,
      true,
    );

    return response.data.questions;
  },
  getAnswerSetBySlugs: async (
    questionSetSlugs,
    orgId,
  ) => {
    const response = await api(
      'post',
      `/reviews/answers/organisation/${orgId}`,
      { slugs: questionSetSlugs },
      false,
      false,
      true,
    );

    return response.data.questions;
  },
  getQuestionSetReferenceData: async (languageId, orgId) => {
    const response = await api('get', `reviews/questions/reference-data?languageId=${languageId}&orgId=${orgId}`, {}, true, false, true);
    return response.data;
  },
  getAnswerSetsByOrg: async (
    orgId,
    questionSetId,
    year,
  ) => {
    let query = '';
    if (year) query = `?year=${year}`;
    const response = await api(
      'get',
      `reviews/answers/question/${questionSetId}/organisation/${orgId}${query}`,
      {},
      true,
      false,
      true,
    );

    return response.data;
  },
  createNewAnswerSet: async (organisationId, questionSetId, year = {}) => {
    const response = await api(
      'post',
      `reviews/answers/question/${questionSetId}/organisation/${organisationId}/new`,
      { year }, true, false, true,
    );
    return response.data;
  },
  patchAnswerSet: async (organisationId, answerId, answers) => {
    const response = await api(
      'patch',
      `reviews/answers/${answerId}/organisation/${organisationId}`,
      answers,
      true, false, true,
    );
    return response.data;
  },
  postNotes: async (organisationId, answerId, notes) => {
    const response = await api(
      'post',
      `reviews/answers/${answerId}/organisation/${organisationId}/notes`,
      notes,
      true, false, true,
    );
    return response.data;
  },
  editNotes: async (organisationId, answerId, questionId, noteId, notes) => {
    const response = await api(
      'patch',
      `reviews/answers/${answerId}/organisation/${organisationId}/question/${questionId}/note/${noteId}`,
      notes,
      true, false, true,
    );
    return response.data;
  },
  deleteNotes: async (organisationId, answerId, noteId, questionId) => {
    const response = await api(
      'delete',
      `reviews/answers/${answerId}/organisation/${organisationId}/question/${questionId}/note/${noteId}`,
      {},
      true, false, true,
    );
    return response.data;
  },
  shapeAnswersToPayload: (values, uiType, type) => {
    let shapedAnswers = {};
    switch (uiType) {
      case questionTypes.MULTIPLECHOICE_NESTED:
        (values.question || []).filter((answer) => {
          const answerId = (Object.keys(answer))[0];
          return (answer[answerId] === true);
        }).map((answer) => {
          const answerId = (Object.keys(answer))[0];

          const nestedValues = values.answersFields ? values.answersFields[answerId] : null;
          let nestedItems;
          if (nestedValues) {
            const nestedValueIds = nestedValues.map((nestedValueId) => Object.keys(nestedValueId));

            nestedItems = nestedValueIds
              .map((nestedValue, index) => {
                return {
                  id: nestedValue,
                  unitMeasurementType: nestedValues[index][nestedValue].units,
                  value: nestedValues[index][nestedValue].value,
                };
              }).reduce((accumulatedObj, currentObj) => {
                return {
                  ...accumulatedObj,
                  [currentObj.id]: {
                    unitMeasurementType: currentObj.unitMeasurementType,
                    value: currentObj.value,
                  },
                };
              }, {});
          }

          shapedAnswers[answerId] = {
            value: true,
            unitMeasurementType: null,
            nestedValues: nestedItems,
            rows: [],
          };
          return answer;
        });
        break;
      case questionTypes.DATAGRID:
        // the rows are shaped in the datagrid component
        shapedAnswers = {
          value: true,
          unitMeasurementType: null,
          nestedValues: {},
          rows: values,
        };

        break;
      case questionTypes.TEXTINPUTS:
        if (type === questionTypes.CALCULATING) {
          shapedAnswers = { nestedValues: {} };
          for (let i = 0; i < (values.question || []).length; i += 1) {
            const questionData = values.question[i];
            const units = values.question[0] && values.question[0].units ? values.question[0].units.value : '';
            const questionId = Object.keys(questionData.row);
            questionId.map((id) => {
              const valueKey = Object.keys(questionData.row[id]);
              const value = valueKey ? questionData.row[id][valueKey].value : '';
              const rowId = valueKey;
              if (_.isEmpty(shapedAnswers[id])) {
                const newAnswers = {
                  [rowId]: {
                    value,
                    unitMeasurementType: units,
                  },
                };
                shapedAnswers[id] = {
                  nestedValues: {
                    ...newAnswers,
                  },
                };
              } else {
                const newAnswers = {
                  [rowId]: {
                    value,
                    unitMeasurementType: units,
                  },
                };

                shapedAnswers[id] = {
                  ...shapedAnswers[id],
                  nestedValues: {
                    ...shapedAnswers[id].nestedValues,
                    ...newAnswers,
                  },
                };
              }
            });
          }
        } else {
          const collectChildKeyValues = [];
          const collectChildValues = [];
          const mappedChildObject = {};

          (values.question || []).map((answer) => {
            const answerId = (Object.keys(answer))[0];
            const answerIdKeys = Object.keys(answer || []).filter((item) => item.split('-').length === 5);

            if (answerIdKeys && answerIdKeys.length > 0) {
              shapedAnswers[answerId] = {
                value: answer[answerId].value,
                unitMeasurementType: answer[answerId].units,
              };
            } else {
              Object.values(answer).map((answers) => {
                const answerIdParentKeys = Object.keys(answers || []).filter((item) => item.split('-').length === 5); // Parent keys

                for (let i = 0; i < answerIdParentKeys.length; i += 1) { // Iterate over Parent keys
                  if (!_.isEmpty(answerIdParentKeys) && answerIdParentKeys[i]) {
                    const answerIdChildKeys = Object.keys(answers[answerIdParentKeys[i]]).filter((item) => item.split('-').length === 5); // Child Keys

                    collectChildKeyValues.push(...Object.values(answerIdChildKeys)); // Collecting Child Keys
                    collectChildValues.push(...Object.values(answers[answerIdParentKeys[i]])); // Collecting the Child Values

                    collectChildKeyValues.map((item, index) => { // Iterate over Child keys
                      mappedChildObject[item] = collectChildValues[index].value; // Map Child Keys: Child Values

                      if (Object.keys(answers[answerIdParentKeys[i]])[0] === item) {
                        shapedAnswers[item] = {
                          value: mappedChildObject[item],
                        };
                      }
                    });
                  }
                }
              });
            }
            return answer;
          });
        }
        break;
      default:
        if (values && values.dropdownAnwser) {
          const answerId = (Object.values(values))[0];
          shapedAnswers[answerId] = {
            value: true, nestedValues: {}, unitMeasurementType: null, rows: [],
          };
        } else if (values && values.answers) {
          values.answers.filter((answer) => {
            const answerId = (Object.keys(answer))[0];
            return (answer[answerId].name === true);
          }).map((answer) => {
            const answerId = (Object.keys(answer))[0];
            shapedAnswers[answerId] = {
              value: true, nestedValues: {}, unitMeasurementType: null, rows: [],
            };
            return answer;
          });
        }
        break;
    }
    return shapedAnswers;
  },
  shapeFacilityAddedAnswers: (answers) => {
    const facilityAddedAnswers = answers.filter((answer) => {
      return answer.facilityAddedAnswer === true;
    }).map((answer) => {
      return {
        id: answer.id,
        title: answer.title,
        active: true,
      };
    });

    return facilityAddedAnswers;
  },
  shapeFacilityAddedAnswersForDataGrid: (answers) => {
    const facilityAddedAnswers = answers.filter((answer) => {
      return answer.facilityAddedAnswer === true;
    }).map((answer) => {
      return {
        id: answer.id,
        title: answer.title,
        active: true,
        nestedQuestionsId: answer.nestedQuestionsId,
        facilityAddedAnswer: true,
      };
    });

    return facilityAddedAnswers;
  },
  getPanelsFromSlug: (slug, linksObject) => {
    const panels = [];
    const subPanels = [];

    linksObject.map((links) => {
      if (links.slug === slug) {
        panels.push(links);
      }

      if (links.children.length > 0) {
        links.children.map((child) => {
          if (child.slug === slug) {
            subPanels.push(child);
          }

          return {};
        });
      }

      return {};
    });

    if (subPanels.length > 0) {
      return subPanels[0];
    }
    return panels[0];
  },
  getQuestinSetIds: (questionSetData = []) => {
    if (questionSetData && questionSetData.bestPractices) {
      return questionSetData.bestPractices.map((questionSet) => {
        return questionSet.id;
      });
    }
  },
  getIdBySlug: (key, type, questionSetData) => {
    if (questionSetData && questionSetData[key]) {
      const questionData = questionSetData[key];
      const { id } = questionData.filter((question) => question.slug === type)
        .reduce((accumulator) => accumulator);
      return id;
    }
  },
  getSectionBySlug: (key, type, questionSetData) => {
    if (questionSetData && questionSetData[key]) {
      const questionData = questionSetData[key];
      const section = questionData.filter((question) => question.slug === type)
        .reduce((accumulator) => accumulator);
      return section;
    }
  },
  pullOutAnnualDataId: (questionSetData) => {
    if (questionSetData && questionSetData.annualData && questionSetData.annualData[0]) {
      return questionSetData.annualData[0].id;
    }
    return '';
  },
  getBestPracticePanels: (questionSetData) => {
    if (questionSetData && questionSetData.bestPractices) {
      return questionSetData.bestPractices.map((questionSet) => {
        return {
          title: questionSet.title,
          percentage: 0,
          colour: questionSet.colour,
          href: `/review/best-practice/summary#${questionSet.slug}`,
          icon: questionSet.icon,
          id: questionSet.id,
        };
      });
    }
    return [];
  },
  activeQuestions: (sections) => {
    return sections
      .map((item) => (item.active ? item.subsections : false))
      .filter(Boolean)
      .flat()
      .map((item) => (item.active ? item.fieldSets : false))
      .filter(Boolean)
      .flat()
      .map((item) => (item.active ? item.questions : false))
      .filter(Boolean)
      .flat();
  },
  availableYears: async (isTournament = false) => {
    if (isTournament) {
      const response = await api(
        'get',
        'reviews/annual-data/tournaments/year',
        {},
        false,
        false,
        true,
      );

      return response.data;
    }
    const response = await api(
      'get',
      'reviews/annual-data/facilities/year',
      {},
      false,
      false,
      true,
    );

    return response.data;
  },
  getMarkedCompleteCount: (answers, activeQuestions) => {
    const allComplete = getMarkedComplete(answers);
    const activeIds = activeQuestions.map((item) => item.id);
    const activeComplete = _.intersection(allComplete, activeIds);
    return activeComplete.length;
  },
  getMarkedComplete,
};
