import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Error from '../pages/error/Error';

const LoggedInRoutes = () => {
  return (
    <Route render={() => (
      <Switch>
        <Route exact path="/error" component={Error} />
      </Switch>
    )}
    />
  );
};

export default LoggedInRoutes;
