/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PageNavTiles from '../core/tiles/page-nav-tiles/PageNavTiles';
import themeType from '../../lib/consts/themeType';
import { GlobalContext } from '../../context/GlobalContext';

const PanelLinkLayout = ({
  tiles, title, children, hasShadow = true,
}) => {
  const { org, user, theme } = useContext(GlobalContext);
  return (
    <div className={`${theme === themeType.TOURNAMENT ? 'img-tour page' : 'img-facility page'}`}>
      <div className="page-header">
        <h2 className="review-page-section-title mb-4">{title}</h2>
        <div className="row">
          <div className="col-12 col-lg-5">
            <PageNavTiles
              tiles={tiles}
              hasShadow={hasShadow}

            />
          </div>
          <div className="col-12 col-lg-7 ml-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
PanelLinkLayout.defaultProps = {
  hasShadow: true,
};
PanelLinkLayout.propTypes = {
  title: PropTypes.string.isRequired,
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      href: PropTypes.string,
    }),
  ).isRequired,
  hasShadow: PropTypes.bool,
};
export default PanelLinkLayout;
