import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import Form from 'react-bootstrap/Form';
import HttpStatus from 'http-status-codes';
import { GlobalContext } from '../../context/GlobalContext';
import Button from '../../components/core/button/Button';
import Modal from '../../components/core/modal/Modal';
import Loader from '../../components/Loaders/PageLoader';
import TextInputField from '../../components/core/form/TextInputField';
import organisationService from '../../services/organisation/organisation-service';
import DropdownField from '../../components/core/form/DropdownField';
import Alert from '../../components/core/alert/Alert';
import arrayUtils from '../../utils/array';
import { get } from '../../lib/strings';
import FacilityTeamControls from './FacilityTeamControls';

import './FacilityTeam.scss';

const FacilityTeam = ({ organisationId }) => {
  const { user } = useContext(GlobalContext);
  const [addUserModal, updateAddUserModal] = useState(false);
  const [deleteUserModal, updateDeleteUserModal] = useState(false);
  const [switchOwnerModal, updateSwitchOwnerModal] = useState(false);
  const [selectedUser, updateSelectedUser] = useState({});
  const [orgUsers, updateOrgUsers] = useState([]);
  const [isPageLoading, updateIsPageLoading] = useState(false);
  const [modalError, setModalError] = useState(undefined);
  const [reInviteUserModal, updateReInviteUserModal] = useState(false);
  const [error, setError] = useState(undefined);

  const {
    register, handleSubmit, errors, control,
  } = useForm();

  const roleTypeOptions = arrayUtils.getRoleTypeOptions(orgUsers, user.id);
  const switchOwnerOptions = arrayUtils.createOwnerSwtichOptions(orgUsers, user.id);

  // Strings
  const formInvalidEmailError = get('registration-formInvalidEmailError');
  const fromRequiredFieldError = get('registration-fromErrorRequiredField');
  const removeYourselError = get('facilityProfileTeam-removeModalYourselfError');
  const sendInviteError = get('facilityProfileTeam-sendInviteError');
  const reSendInviteError = get('facilityProfileTeam-reSendInviteError');
  const switchOwnerError = get('facilityProfileTeam-switchOwnerError');
  const statusInvited = get('facilityProfileTeam-status-invited');
  const statusOwner = get('facilityProfileTeam-status-owner');
  const statusJoined = get('facilityProfileTeam-status-joined');
  const statusAdmin = get('facilityProfileTeam-status-admin');

  const getUserStatus = (orgUser) => {
    if (orgUser.inviteStatus === 'invited') {
      return statusInvited;
    }

    if (orgUser.userType === 'OWNER') {
      return statusOwner;
    }

    if (orgUser.userType === 'ADMIN_USER') {
      return statusAdmin;
    }

    return statusJoined;
  };

  const getAction = (orgUser, numberOfUsers) => {
    const actions = {
      reInvite: false,
      remove: true,
      // for now a user cannot leave an org on their own
      leave: false,
      switchOwner: false,
    };

    if (orgUser.inviteStatus === 'invited') {
      actions.reInvite = true;
    }

    const currentUser = orgUser.id === user.id;
    if (currentUser) {
      actions.remove = false;
    }

    if (orgUser.userType === 'OWNER') {
      actions.remove = false;
    }

    // if current user is the owner we can show a leave option
    if (currentUser && orgUser.userType === 'OWNER') {
      if (numberOfUsers > 1) {
        actions.switchOwner = true;
      }
    }

    return actions;
  };

  const getFormattedOrgUser = (users) => {
    const formattedOrgUser = [];
    const numberOfUsers = users.length;

    for (let i = 0; i < users.length; i += 1) {
      const orgUser = users[i];
      const orgUserStatus = getUserStatus(orgUser);

      formattedOrgUser.push({
        ...orgUser,
        status: orgUserStatus,
        cta: getAction(orgUser, numberOfUsers),
      });
    }

    return formattedOrgUser;
  };

  const getOrganisation = async () => {
    updateIsPageLoading(true);

    const result = await organisationService.getUsersInOrganisation(organisationId);

    if (result.ok) {
      const formattedUsers = getFormattedOrgUser(result.data);
      updateOrgUsers(formattedUsers);
    }

    updateIsPageLoading(false);
  };

  useEffect(() => {
    if (organisationId !== null) {
      getOrganisation();
    }
  }, []);

  const onClickAddUser = () => {
    setModalError(undefined);
    setError(undefined);
    updateAddUserModal(true);
  };

  const onClickSendInvites = async (data) => {
    const newUser = {
      email_address: data.email_address,
      type_id: data.type_id,
      message: data.inviteText,
      organisation_id: organisationId,
    };

    const result = await organisationService.inviteUsersToOrganisation(organisationId, newUser);

    if (result.ok) {
      const formattedUsers = getFormattedOrgUser(result.data);
      updateOrgUsers(formattedUsers);
      updateAddUserModal(false);
    } else if (result.status === HttpStatus.UNAUTHORIZED) {
      setModalError(sendInviteError);
    } else {
      throw modalError;
    }
  };

  const onClickUserAction = async (orgUserData, action) => {
    setError(undefined);

    if (action === 'reInvite') {
      updateSelectedUser(orgUserData);
      updateReInviteUserModal(true);
    } else if (action === 'delete') {
      updateSelectedUser(orgUserData);
      updateDeleteUserModal(true);
    } else if (action === 'switch') {
      updateSwitchOwnerModal(true);
      return action;
    } else if (action === 'leave') {
      return action;
    }
  };

  const onClickResendInvite = async (data) => {
    const result = await organisationService.reInviteUser(
      organisationId, selectedUser.id, data.inviteText,
    );

    if (result.ok) {
      updateReInviteUserModal(false);
      updateSelectedUser({});
    } else {
      setError(reSendInviteError);
    }
  };

  const onClickSwitchOwner = async (data) => {
    const result = await organisationService.switchOwner(organisationId, user.id, data.userId);

    if (result.ok) {
      getOrganisation();
      updateSwitchOwnerModal(false);
      updateSelectedUser({});
    } else {
      setError(switchOwnerError);
    }
  };

  const onClickRemoveUser = async () => {
    const result = await organisationService.removeUserFromOrganisation(
      organisationId, selectedUser.id,
    );

    if (result.ok) {
      getOrganisation();
      updateDeleteUserModal(false);
      updateSelectedUser({});
    } else if ((result.status === HttpStatus.FORBIDDEN)) {
      setError(removeYourselError);
    } else {
      throw error;
    }
  };

  const getOrgUserName = (orgUser) => {
    if (orgUser.first_name) {
      return `${orgUser.first_name} ${orgUser.last_name} ${user.email === orgUser.email_address ? '(You)' : ''}`;
    }

    return '---';
  };

  return (
    <Loader isLoading={isPageLoading}>
      <div className="component__profile-facility-team">
        <div className="profile-facility-team-roles-card">
          <div className="profile-facility-team-roles-header">
            <p className="profile-facility-team-roles-header-title">{get('facilityProfileTeam-title')}</p>
            <Button className="profile-facility-team-roles-add-button" data-cy="profile-team-invite-button" onClick={onClickAddUser}>
              {get('facilityProfileTeam-inviteButton')}
            </Button>
          </div>
          <p className="profile-facility-team-roles-description">{get('facilityProfileTeam-description')}</p>
          <table className="profile-facility-team-table">
            <thead>
              <tr>
                <th scope="col">{get('facilityProfileTeam-userTable-name')}</th>
                <th scope="col">{get('facilityProfileTeam-userTable-email')}</th>
                <th scope="col">{get('facilityProfileTeam-userTable-status')}</th>
                <th scope="col" aria-label="cta" />
              </tr>
            </thead>
            <tbody>
              {orgUsers.map((orgUser) => {
                return (
                  <tr>
                    <td data-label={get('facilityProfileTeam-userTable-name')}>{getOrgUserName(orgUser)}</td>
                    <td data-label={get('facilityProfileTeam-userTable-email')}>{orgUser.email_address}</td>
                    <td data-label={get('facilityProfileTeam-userTable-status')}>{orgUser.status}</td>
                    <td data-label="" aria-hidden>
                      <FacilityTeamControls
                        orgUser={orgUser}
                        onClickUserAction={onClickUserAction}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Modal
          show={addUserModal}
          onClose={() => updateAddUserModal(false)}
          heading={get('facilityProfileTeam-inviteModalTitle')}
          bodyText={(
            <div>
              <Form>
                <div className="profile-facility-team-invite-modal">
                  <TextInputField
                    type="emailAddress"
                    name="email_address"
                    label={get('facilityProfileTeam-inviteModalEmailLabel')}
                    placeholder={get('facilityProfileTeam-inviteModalEmailPlaceholder')}
                    className="input-field-email-row"
                    data-cy="invite-email-input"
                    controlled
                    ref={register({
                      required: true,
                      validate: {
                        isEmail: (value) => {
                          return validator.isEmail(value) || formInvalidEmailError;
                        },
                      },
                    })}
                    errors={errors}
                  />

                  <DropdownField
                    label={get('facilityProfileTeam-inviteModalInviteAsLabel')}
                    control={control}
                    name="type_id"
                    options={roleTypeOptions}
                    className="profile-add-user-modal-input-field-rows"
                    errors={errors}
                    validationRules={(value) => {
                      if (typeof value !== 'undefined' || roleTypeOptions.some((el) => el.key === value)) {
                        return true;
                      }
                      return fromRequiredFieldError;
                    }}
                  />
                </div>

                <TextInputField
                  as="textarea"
                  rows={3}
                  type="textarea"
                  name="inviteText"
                  label={get('facilityProfileTeam-inviteModalIviteTextLabel')}
                  placeholder={get('facilityProfileTeam-inviteModalInviteTextPlaceholder')}
                  className="input-field-row"
                  data-cy="invite-text-input"
                  controlled
                  ref={register({
                    required: false,
                  })}
                  errors={errors}
                />
              </Form>
            </div>
      )}
          footer={(
            <div className="profile-facility-team-modal-footer">
              {modalError && (
              <Alert data-cy="registration-error" className="profile-facility-team-modal-alert" variant="danger">
                {modalError}
              </Alert>
              )}
              <Button className="profile-facility-team-modal-button" onClick={handleSubmit(onClickSendInvites)}>
                {get('facilityProfileTeam-inviteModalButton')}
              </Button>
            </div>

      )}
        />
        <Modal
          show={deleteUserModal}
          onClose={() => {
            updateSelectedUser({});
            updateDeleteUserModal(false);
          }}
          heading={get('facilityProfileTeam-removeModalTitle')}
          bodyText={(
            <div>
              <p>{`${get('facilityProfileTeam-removeModalDescription-1')} ${selectedUser.first_name} ${selectedUser.last_name} ${get('facilityProfileTeam-removeModalDescription-2')}`}</p>
            </div>
      )}
          footer={(
            <div className="profile-facility-team-modal-footer">
              {error ? (
                <Alert data-cy="registration-error" className="profile-facility-team-modal-alert" variant="danger">
                  {error}
                </Alert>
              ) : null}
              <Button className="profile-facility-team-modal-button" onClick={onClickRemoveUser}>
                {get('facilityProfileTeam-removeModalButton')}
              </Button>
            </div>

      )}
        />

        <Modal
          show={reInviteUserModal}
          onClose={() => {
            updateSelectedUser({});
            updateReInviteUserModal(false);
          }}
          heading={get('facilityProfileTeam-reInviteModalTitle')}
          bodyText={(
            <Form>
              <p>{`${get('facilityProfileTeam-reInviteModalDescription-1')} ${selectedUser.email_address} ${get('facilityProfileTeam-reInviteModalModalDescription-2')}`}</p>
              <TextInputField
                as="textarea"
                rows={3}
                type="textarea"
                name="inviteText"
                label={get('facilityProfileTeam-inviteModalIviteTextLabel')}
                placeholder={get('facilityProfileTeam-inviteModalInviteTextPlaceholder')}
                className="input-field-row"
                data-cy="invite-text-input"
                controlled
                maxLength={200}
                ref={register({
                  required: false,
                })}
                errors={errors}
              />
            </Form>
      )}
          footer={(
            <div className="profile-facility-team-modal-footer">
              {error ? (
                <Alert data-cy="registration-error" className="profile-facility-team-modal-alert" variant="danger">
                  {error}
                </Alert>
              ) : null}
              <Button className="profile-facility-team-modal-button" onClick={handleSubmit(onClickResendInvite)}>
                {get('facilityProfileTeam-reInviteModalButton')}
              </Button>
            </div>

      )}
        />

        <Modal
          show={switchOwnerModal}
          onClose={() => {
            updateSelectedUser({});
            updateSwitchOwnerModal(false);
          }}
          heading={get('facilityProfileTeam-switchOwnerModalTitle')}
          bodyText={(
            <Form>
              <p>{`${get('facilityProfileTeam-switchOwnerModalDescription')}`}</p>
              <DropdownField
                label={get('facilityProfileTeam-switchOwnerDropdownLabel')}
                control={control}
                name="userId"
                options={switchOwnerOptions}
                className="profile-add-user-modal-input-field-rows"
                errors={errors}
                validationRules={(value) => {
                  if (typeof value !== 'undefined' || switchOwnerOptions.some((el) => el.key === value)) {
                    return true;
                  }
                  return fromRequiredFieldError;
                }}
              />
            </Form>
      )}
          footer={(
            <div className="profile-facility-team-modal-footer">
              {error ? (
                <Alert data-cy="registration-error" className="profile-facility-team-modal-alert" variant="danger">
                  {error}
                </Alert>
              ) : null}
              <Button className="profile-facility-team-modal-button" onClick={handleSubmit(onClickSwitchOwner)}>
                {get('facilityProfileTeam-switchOwnerModalButton')}
              </Button>
            </div>

      )}
        />
      </div>
    </Loader>
  );
};

FacilityTeam.defaultProps = {
};

FacilityTeam.propTypes = {
  organisationId: PropTypes.string.isRequired,
};

export default FacilityTeam;
