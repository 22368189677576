/* eslint-disable max-len */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import OrganisationDashboard from '../pages/dashboards/organisation/Organisation';
import LogoutPage from '../pages/authentication/logout-page/LogoutPage';

import ProfilePage from '../pages/profile/ProfilePage';
import GettingStartPage from '../pages/getting-start/GettingStartPage';

import { GlobalContext } from '../context/GlobalContext';
import Permissions from '../lib/permissions';
import ReviewRoutes from './ReviewRoutes';
import NoOrganisation from '../pages/dashboards/no-organisation/NoOrganisation';
import FacilityProfilePage from '../pages/facilityProfile/FacilityProfilePage';
import OnCourseReports from '../pages/improve/oncourse-reports/OnCourseReports';
import SupportPlanning from '../pages/improve/support-planning/SupportPlanning';
import DocumentLibrary from '../pages/document-library/DocumentLibrary';
import JoinFacility from '../pages/getting-start/JoinFacility';
import CreateFacility from '../pages/getting-start/CreateFacility';
import HelpdeskPage from '../pages/helpdesk/HelpdeskPage';
import TermsAndConditionsContent from '../pages/termsAndConditions/TermsAndConditionsContent';
import DocumentLibraryYourLocationDocuments from '../pages/document-library/tabs/DocumentLibraryYourLocationDocuments';

const LoggedInRoutes = ({ notificationBanner }) => {
  const { org } = useContext(GlobalContext);
  if (!org) { // user does not belong any organisations
    return (
      <Route render={(props) => (
        <Layout {...props} notificationBanner={notificationBanner}>
          <Switch>
            <Route exact path="/" component={NoOrganisation} />
            <Route exact path="/getting-started" component={GettingStartPage} />
            <Route exact path="/join-facility" component={JoinFacility} />
            <Route exact path="/create-facility" component={CreateFacility} />
            <Route exact path="/profile" component={ProfilePage} />
            <Route exact path="/logout" component={LogoutPage} />
            <Route exact path="/terms-and-conditions" component={TermsAndConditionsContent} />
          </Switch>
        </Layout>
      )}
      />
    );
  }
  if (org) {
    return (
      <Route render={(props) => (
        <Layout {...props} notificationBanner={notificationBanner}>
          <Switch>
            <Route exact path="/" component={OrganisationDashboard} />
            <Route exact path="/user-profile" component={ProfilePage} />
            <Route exact path="/helpdesk" component={HelpdeskPage} />
            <Route exact path="/logout" component={LogoutPage} />

            {/* {Permissions.doesUserHaveActivities(org.activities,
              [Permissions.activities.FACILITY_PROFILE])
              && <Route exact path="/facility-profile" component={FacilityProfilePage} />} */}

            {Permissions.doesUserHaveActivities(org.activities,
              [Permissions.activities.FACILITY_PROFILE])
              && <Route exact path="/profile/:type" component={FacilityProfilePage} />}

            {/* {Permissions.doesUserHaveActivities(org.activities,
              [Permissions.activities.IMPROVE]) && <Route exact path="/:section" component={OnCourseReports} />}

            {Permissions.doesUserHaveActivities(org.activities,
              [Permissions.activities.IMPROVE]) && <Route path="/:section" component={Recognition} />} */}

            {Permissions.doesUserHaveActivities(org.activities,
              [Permissions.activities.IMPROVE])
                        && <Route path="/section/:sectionId" component={OnCourseReports} />}

            {/* {Permissions.doesUserHaveActivities(org.activities,
              [Permissions.activities.IMPROVE]) && <Route exact path="/section/:sectionId/:contentId" component={Recognition} />} */}

            {Permissions.doesUserHaveActivities(org.activities,
              [Permissions.activities.IMPROVE]) && <Route exact path="/section/:section/support-planning" component={SupportPlanning} />}

            {/* {Permissions.doesUserHaveActivities(org.activities,
              [Permissions.activities.FACILITY_PROFILE]) && <Route exact path="/document-library" component={DocumentLibrary} />} */}

            {Permissions.doesUserHaveActivities(org.activities,
              [Permissions.activities.FACILITY_PROFILE]) && <Route exact path="/documents/:type" component={DocumentLibrary} />}

            {/* {Permissions.doesUserHaveActivities(org.activities,
              [Permissions.activities.FACILITY_PROFILE]) && <Route exact path="/document-library/your/:section" component={DocumentLibraryYourLocationDocuments} />}
             */}
            {Permissions.doesUserHaveActivities(org.activities,
              [Permissions.activities.FACILITY_PROFILE]) && <Route exact path="/documents/:type/your/:section" component={DocumentLibraryYourLocationDocuments} />}

            <Route exact path="/terms-and-conditions" component={TermsAndConditionsContent} />
            <ReviewRoutes />
          </Switch>
        </Layout>
      )}
      />
    );
  }
};

LoggedInRoutes.propTypes = {
  notificationBanner: PropTypes.object.isRequired,
};

export default LoggedInRoutes;
