import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  SubMenu,
} from 'react-pro-sidebar';
import { GlobalContext } from '../../../context/GlobalContext';
import Link from '../../core/link/InternalLink';
import { get } from '../../../lib/strings';

const OranisationGated = ({
  children, subMenuState, styleForSelectedItem, toggleSubMenu, styleForTopMenu,
  RouteSideBarMapping, handleToggleSidebar,
}) => {
  const globalContext = useContext(GlobalContext);
  const { org } = globalContext;

  if (!org) { // none-org 's nav items
    return (
      <>
        <MenuItem
          className={styleForSelectedItem('/', 'menu')}
          onClick={() => toggleSubMenu(RouteSideBarMapping.MAIN_DASHBOARD.INDEX)}
        ><Link to="/">{get('sidebar-MainDashboard')}</Link>
        </MenuItem>
        <SubMenu
          className={styleForTopMenu(RouteSideBarMapping.CREATE_FACILITY.INDEX)}
          onOpenChange={() => {
            toggleSubMenu(
              RouteSideBarMapping.CREATE_FACILITY.INDEX,
            );
          }}
          open={subMenuState[RouteSideBarMapping.CREATE_FACILITY.INDEX]}
          title={(
            <Link to="/create-facility">
              {get('sidebar-GettingStarted')}
            </Link>
      )}
        >

          <MenuItem className={styleForSelectedItem('/create-facility', 'submenu')} onClick={() => handleToggleSidebar()}><Link to="/create-facility">{get('sidebar-createFacility')}</Link></MenuItem>
          <MenuItem className={styleForSelectedItem('/join-facility', 'submenu')} onClick={() => handleToggleSidebar()}><Link to="/join-facility">{get('sidebar-joinFacility')}</Link></MenuItem>
        </SubMenu>
      </>

    );
  }
  return <>{children}</>;
};

OranisationGated.propTypes = {
  children: PropTypes.node.isRequired,
  styleForSelectedItem: PropTypes.func.isRequired,
  styleForTopMenu: PropTypes.func.isRequired,
  subMenuState: PropTypes.arrayOf(PropTypes.bool).isRequired,
  toggleSubMenu: PropTypes.func.isRequired,
  RouteSideBarMapping:
  PropTypes.shape({
    CREATE_FACILITY: PropTypes.shape({ LINK: PropTypes.string, INDEX: PropTypes.number }),
    MAIN_DASHBOARD: PropTypes.shape({ LINK: PropTypes.string, INDEX: PropTypes.number }),

  }).isRequired,
  handleToggleSidebar: PropTypes.func.isRequired,

};

export default OranisationGated;
