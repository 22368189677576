import HTTPError from '../../lib/errors/http-error';
import api from '../../lib/http';

export default {
  getCountries: async () => {
    const response = await api(
      'get',
      'country/countries',
      null,
      false,
      false,
      true,
    );

    if (!response.ok) {
      throw new HTTPError(response.status, response.data);
    }

    return response.data;
  },
  getIdByLookUpId: async (countryCode) => {
    const response = await api(
      'get',
      `country/countries/${countryCode}`,
      {},
      false,
      false,
      true,
    );

    if (!response.ok) {
      throw new HTTPError(response.status, response.data);
    }
    return response.data;
  },
};
