/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import organisationService from '../../../services/organisation/organisation-service';
import tilesService from '../../../services/tiles/tiles-service';
import mediaService from '../../../services/media/media-service';

import userService from '../../../services/user/user-service';
import { get } from '../../../lib/strings';
import DocumentIcon from '../../../assets/document-card-background.svg';
import DocumentTourIcon from '../../../assets/document-card-tour-background.svg';

import DashboardTile from '../../../components/core/tiles/dashboard-tile/DashboardTile';
import FacilityCardTile from '../../../components/core/tiles/dashboard-tile/FacilityCardTile';
import DocumentLibraryTile from '../../../components/core/tiles/dashboard-tile/DocumentLibraryTile';
import MediaTile from '../../../components/core/tiles/media-tile/MediaTile';
import { ProfileTabIndexes } from '../../../lib/consts/profileTabIndexes';
import SocialMediaIcons from '../../../components/core/social-media-icons/SocialMediaIcon';
import RouteSideBarMapping from '../../../lib/consts/routeSideBarMapping';
import './Organisation.scss';
import themeType from '../../../lib/consts/themeType';
import { GlobalContext } from '../../../context/GlobalContext';
import Button from '../../../components/core/button/Button';

const HomePage = () => {
  const { org, user, theme } = useContext(GlobalContext);
  const [orgDetails, setOrgDetails] = useState({});
  const [userOrganisations, setUserOrganisations] = useState([]);
  const [tiles, setTiles] = useState(undefined);

  const documentsPath = get('sidebar-DocumentLibrary');
  useEffect(() => {
    const getOrganisation = async () => {
      const result = await organisationService.getOrganisation(org.organisation_id);

      if (result) {
        setOrgDetails(result);
      }

      const userOrgs = await userService.getUserOrgs(user.id);

      if (userOrgs.ok) {
        setUserOrganisations(userOrgs.data);
      }
    };
    const getTiles = async () => {
      const tilesAll = await tilesService.getTilesByOrgId(org.organisation_id, user.language);
      if (!tilesAll) return;

      const tilesAllWithImage = await Promise.all(tilesAll.map(async (item) => {
        const itemClone = { ...item };
        itemClone.imgSource = itemClone.imageRecord
          ? await mediaService.getPartnerLogo(itemClone.imageRecord.id)
          : null;
        return itemClone;
      }));
      // update local state
      setTiles(tilesAllWithImage);
    };

    if (org !== null) {
      getOrganisation();
      getTiles();
    }
  }, []);

  return (
    <div className={`${theme === themeType.TOURNAMENT ? 'img-tour page' : 'img-facility page'}`}>
      <div className="page-header">
        <h2 className="review-page-section-title mb4">{get('startedPage-GreetingTitle')}</h2>
        <div className="dashboard-page-container">
          <div className="dashboard-container-row">
            <FacilityCardTile
              isOrganisationCertified={orgDetails.certified}
              orgTitle={orgDetails.title}
              userOrganisationsLength={userOrganisations.length}
              profileLink={RouteSideBarMapping.PROFILE.LINK}
              facilityProfileLink={RouteSideBarMapping.FACILITY_PROFILE.LINK}
              ProfileTabIndexe={ProfileTabIndexes.FACILITIES}
              logoId={orgDetails.logo_id}
              profileId={orgDetails.image_id}
            />
            <DocumentLibraryTile
              iconSource={theme === themeType.TOURNAMENT ? DocumentTourIcon : DocumentIcon}
              href={`${RouteSideBarMapping.DOCUMENT_LIBRARY.LINK}/${documentsPath}`}
            />
          </div>

          <div className="dashboard-container-tiles-row">

            {tiles && tiles.map((tile) => {
              const {
                description,
                link,
                name,
                imgSource,
                title,
              } = tile;
              return (
                <div className="dashboard-container-tile">
                  <DashboardTile
                    isActivePage
                    href={link}
                    title={name}
                    subTitle={title}
                    content={description}
                    imgSourse={imgSource}
                  />
                </div>
              );
            })}

          </div>
          <div className="dashboard-container-tiles-row">
            <div className="dashboard-social-media-tile">
              <MediaTile
                title={get('component-SocialMediaWidgetTitle')}
                description={get('component-SocialMediaWidgetDescription')}
                subLinkText={`#${get('component-SocialMediaTwitterTag')}`}// '#ForSustainableGolf',
                subLink={get('component-SocialMediaTwitterTag')}
                InsertButtonNode={<SocialMediaIcons />}
              />
            </div>

            <div className="dashboard-social-media-tile">
              <MediaTile
                title={get('component-HighLightTileTitle')}
                description={get('component-HighLightTileDescription')}
                InsertButtonNode={(
                  <Button
                    inverse
                    className="components__external-link-button dashboard-to-highlight-button medium"
                    colour={theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471'}
                  >
                    <a style={{ color: 'unset' }} target="_blank" rel="noopener noreferrer" href="https://sustainable.golf/highlights">
                      <span>
                        {get('component-HighLightTileButton')}
                      </span>
                    </a>
                  </Button>
              )}
              />
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default HomePage;
