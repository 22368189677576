const questionTypes = {
  MULTIPLECHOICE_ADDMORE: 'MULTIPLECHOICE_ADDMORE',
  MULTIPLECHOICE: 'MULTIPLECHOICE',
  SINGLECHOICE: 'SINGLECHOICE',
  DROPDOWNLIST: 'DROPDOWNLIST',
  MULTIPLECHOICE_NESTED: 'MULTIPLECHOICE_NESTED',
  TEXTINPUTS: 'TEXTINPUTS',
  DATAGRID: 'DATAGRID',
  CALCULATING: 'CALCULATING',
};

export default questionTypes;
