/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';
import PageLoader from '../../../../components/Loaders/PageLoader';
import reviewsService from '../../../../services/reviews/reviews-service';
import { GlobalContext } from '../../../../context/GlobalContext';
import SectionRenderer from '../../../../components/review/SectionRenderer/SectionRenderer';
import questionSetUtils from '../../../../lib/questionSets';
import InternalLink from '../../../../components/core/link/InternalLink';
import { get } from '../../../../lib/strings';
import DropdownField from '../../../../components/core/form/DropdownField';
import './AnnualDataWrapper.scss';
import themeType from '../../../../lib/consts/themeType';

const AnnualDataWrapper = () => {
  const [loading, updateLoading] = useState(true);
  const [questions, updateQuestions] = useState({});
  const [setData, updateSetData] = useState({});
  const [answerData, updateAnswers] = useState({});
  const [answerPreviousYearData, updateAnswersPreviousYear] = useState({});
  const [options, updateYearOptions] = useState([]);
  const sideBarMenuAnnualDataTitle = get('sidebar-AnnualData');

  const { year, sectionId, type } = useParams();
  const { hash: pathHash } = useLocation();

  const {
    org, reviewLinks, user, theme,
  } = useContext(GlobalContext);
  const id = reviewsService.pullOutAnnualDataId(reviewLinks);

  const {
    control,
  } = useForm({
    defaultValues: {
      companyReviewYears: year,
    },
  });

  useEffect(() => {
    if (id) {
      const getData = async () => {
        const questionData = await reviewsService.getOrganisationQuestionsById(
          id, org.organisation_id, user.language,
        );

        const { section } = questionSetUtils.getPageDataFromQuestionObjectById(
          questionData, sectionId,
        );

        const answers = await reviewsService.getAnswerSetsByOrg(org.organisation_id, id);
        const answerYearData = await questionSetUtils.pullOutAnswersByYear(answers, year);
        updateAnswers(answerYearData);

        const yearOptions = await questionSetUtils.getYearsFromAnswers(answers, {
          isTournament: theme === themeType.TOURNAMENT,
        });
        updateYearOptions(yearOptions);
        updateSetData(section);
        updateQuestions(questionData);

        const yearIndex = yearOptions.findIndex((item) => item.value === year);
        if (yearIndex !== -1 && yearIndex > 0) {
          const answersPreviousYearData = await questionSetUtils.pullOutAnswersByYear(
            answers, yearOptions[yearIndex - 1].key,
          );

          updateAnswersPreviousYear(answersPreviousYearData);
        }
        updateLoading(false);
      };

      getData();
    }
  }, [id, pathHash]);
  useEffect(() => {
    if (_.isEmpty(questions)) return;
    const { section } = questionSetUtils.getPageDataFromQuestionObjectById(
      questions, sectionId,
    );
    updateSetData(section);
  }, [sectionId]);

  const onComplete = async (payload) => {
    await reviewsService.patchAnswerSet(org.organisation_id, answerData.id, payload);
    const answers = await reviewsService.getAnswerSetsByOrg(org.organisation_id, id);
    const answerYearData = await questionSetUtils.pullOutAnswersByYear(answers, year);
    updateAnswers(answerYearData);
  };

  const notesHandlers = {
    deleteNote: async (noteId, questionId) => reviewsService.deleteNotes(
      org.organisation_id, answerData.id, noteId, questionId,
    ),
    updateNotes: (payload) => reviewsService.postNotes(
      org.organisation_id, answerData.id, payload,
    ),
    editNotes: (questionId, noteId, payload) => reviewsService.editNotes(
      org.organisation_id, answerData.id, noteId, questionId, payload,
    ),
  };

  const handleChange = (val) => {
    if (val !== year) {
      window.location = `/annual-review/${sideBarMenuAnnualDataTitle}/${val}/${type}/${sectionId}/${pathHash}`;
    }
  };

  return (
    <PageLoader isLoading={loading}>
      <div className={`${theme === themeType.TOURNAMENT ? 'img-tour' : 'img-facility'} page annual-data`}>
        <div className="page-header">
          <div className="annual-summary__header-container">
            <div className="annual-summary__titles">
              <h5 className="review-page-title --capitalize"><InternalLink to="/annual-review/annual-data" className="breadcrumb-link">{get('annualData-SummaryTitle')}</InternalLink> - {year}</h5>
              <div className="d-flex flex-row justify-content-between align-items-center pr-3">
                <h4 className="review-page-section-title">{setData.title}</h4>
              </div>
            </div>
            <div className="annual-summary__dropdown-container">
              <Form>
                <DropdownField
                  label={get('annualData-DateDropdownLabel')}
                  control={control}
                  name="companyReviewYears"
                  options={options}
                  className="annual-summary__dropdown "
                  handleChange={(e) => handleChange(e)}
                />
              </Form>
            </div>
          </div>
          <SectionRenderer
            isAnnual
            data={setData}
            onComplete={onComplete}
            answerData={answerData.answers}
            answerPreviousYearData={answerPreviousYearData.answers}
            notesHandlers={notesHandlers}
            colour={theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471'}
          />
        </div>
      </div>
    </PageLoader>
  );
};

AnnualDataWrapper.propTypes = {

};
export default AnnualDataWrapper;
