import React from 'react';
import IconLink from '../link/IconLink';
import twitter from '../../../assets/twitter.svg';
import youtube from '../../../assets/youtube.svg';
import linkedin from '../../../assets/linkedin.svg';
import facebook from '../../../assets/facebook.svg';
import instagram from '../../../assets/instagram.svg';
import './SocialMediaIcon.scss';

const iconLinkMapping = {
  TWITTER: {
    link: 'https://twitter.com/sustainablegolf',
    iconSource: twitter,
  },
  INSTAGRAM: {
    link: 'https://www.instagram.com/sustainablegolf/',
    iconSource: instagram,
  },
  YOUTUBE: {
    link: 'https://www.youtube.com/channel/UCC67uiQr-P-EEkLp3qcvXtg',
    iconSource: youtube,
  },
  FACEBOOK: {
    link: 'https://www.facebook.com/sustainablegolf/',
    iconSource: facebook,
  },
  LINKEDIN: {
    link: 'https://linkedin.com/company/11344034',
    iconSource: linkedin,
  },
};

const SocialMediaIcons = () => (// pass into MediaTile props
  <div className="social-media-icons">
    {
                  Object.keys(iconLinkMapping).map((item) => (
                    <IconLink type={iconLinkMapping[item]} key={item} />
                  ))
                }
  </div>
);

export default SocialMediaIcons;
