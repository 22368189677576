import React from 'react';
import PropTypes from 'prop-types';
import BootstrapAlert from 'react-bootstrap/Alert';

const Alert = ({
  children,
  className,
  variant,
  'data-cy': cypressId,
}) => {
  return (
    <BootstrapAlert className={className} variant={variant} data-cy={cypressId}>
      {children}
    </BootstrapAlert>
  );
};

Alert.defaultProps = {
  children: undefined,
  className: undefined,
  variant: 'primary',
  'data-cy': '',
};

Alert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  'data-cy': PropTypes.string,
};

export default Alert;
