const pullOutAnswerById = (id, answerSet) => {
  return answerSet && answerSet[id];
};

const pullOutAnswerSet = (id, answerSet, nestedValue = false) => {
  if (id && nestedValue && answerSet && answerSet.nestedValues) {
    return pullOutAnswerById(id, answerSet.nestedValues);
  }

  if (id && answerSet) {
    return pullOutAnswerById(id, answerSet);
  }
};

const setAnswersHandler = (fieldName, answerObject, setValue) => {
  if (answerObject && answerObject.value) {
    setValue(fieldName, answerObject.value);
  }
};

const pullOutDropDownAnswer = (answerData) => {
  const vals = Object.keys(answerData);
  const answerId = vals.filter((answerKey) => answerKey !== 'facilityAddedAnswers')[0];
  return answerId;
};

const pullColumnsOutOfQuestionSet = (questionSet) => {
  const columns = [];
  questionSet.map((answer) => {
    if (answer && answer.nestedQuestions) {
      answer.nestedQuestions.map((nestedQuestion) => {
        let column = {
          answerId: answer.id,
          nestedQuestionId: nestedQuestion.id,
          uiType: nestedQuestion.uiType,
          title: nestedQuestion.title,
          sortOrder: nestedQuestion.sortOrder,
        };

        if (nestedQuestion.questionType === 'QUESTIONLIST') {
          nestedQuestion.rowQuestions.map((rowQuestion) => {
            column = {
              ...column,
              title: rowQuestion.title,
              rowQuestionId: rowQuestion.id,
              unitType: rowQuestion.unitType,
              sortOrder: nestedQuestion.sortOrder,
            };

            columns.push(column);
            return rowQuestion;
          });
        } else {
          columns.push(column);
        }

        return nestedQuestion;
      });
    }
    return answer;
  });

  return columns.sort((a, b) => {
    return a.sortOrder - b.sortOrder;
  });
};

const pullDatagridAnswerFromQuestion = (questionSet, answerId) => {
  let value;
  questionSet.map((answer) => {
    if (answer && answer.nestedQuestions) {
      answer.nestedQuestions.map((nestedQuestion) => {
        if (nestedQuestion.id === answerId) { // check in nestQuestion
          value = nestedQuestion.title;
        } else { // check through rowQuestions
          nestedQuestion.rowQuestions.map((rowQuestion) => {
            if (rowQuestion.id === answerId) {
              value = rowQuestion.title;
            }
            return rowQuestion;
          });
        }
        return nestedQuestion;
      });
    }
    return answer;
  });

  if (value) {
    return value;
  }
};

const getColumnDataByNestedQuestionId = (columns, nestedQuestionId) => {
  return columns.filter((column) => column.nestedQuestionId === nestedQuestionId)
    .reduce((prevVal, currVal) => {
      return currVal;
    }, {});
};

export default {
  pullOutAnswerById,
  pullOutAnswerSet,
  setAnswersHandler,
  pullOutDropDownAnswer,
  pullColumnsOutOfQuestionSet,
  pullDatagridAnswerFromQuestion,
  getColumnDataByNestedQuestionId,
};
