/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import './Modal.scss';
import BootstrapModal from 'react-bootstrap/Modal';
import { CloseIconAutoColour } from '../../Icons';
import { GlobalContext } from '../../../context/GlobalContext';
import themeType from '../../../lib/consts/themeType';

const Modal = ({
  show,
  heading,
  bodyText,
  subtitle,
  footer,
  onClose,
  backdrop,
  enforceFocus,
  classForUnSaveGuard,
  closeBtnColour,
}) => {
  const { theme } = useContext(GlobalContext);

  const closeBtnImageColor = useMemo(() => {
    if (closeBtnColour.length > 0) {
      return closeBtnColour;
    }
    return theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471';
  }, [theme, closeBtnColour]);

  return (
    <BootstrapModal
      className={
        classForUnSaveGuard ? 'components__unsave_modal' : 'components__modal'
      }
      show={show}
      onHide={onClose}
      backdrop={backdrop}
      enforceFocus={enforceFocus}
    >
      <div className="modal-container">
        <div className="modal-header">
          <h5>{heading}</h5>
          <button
            type="button"
            aria-label="close"
            className="modal-close-button"
            onClick={onClose}
          >
            <CloseIconAutoColour fill={closeBtnImageColor} />
          </button>
        </div>
        {!classForUnSaveGuard && <hr />}
        {subtitle && <div className="modal-subtitle">{subtitle}</div>}
        <div className="modal-body-area">{bodyText}</div>
        {footer && <div className="modal-footer-area">{footer}</div>}
      </div>
    </BootstrapModal>
  );
};

Modal.defaultProps = {
  backdrop: undefined,
  subtitle: undefined,
  footer: undefined,
  enforceFocus: true,
  classForUnSaveGuard: false,
  closeBtnColour: '',
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  enforceFocus: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  bodyText: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  backdrop: PropTypes.string,
  subtitle: PropTypes.node,
  footer: PropTypes.node,
  classForUnSaveGuard: PropTypes.bool,
  closeBtnColour: PropTypes.string,
};

export default Modal;
