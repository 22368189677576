import { get } from '../strings';

import TROPHY from '../../assets/trophy.png';
import PEOPLE from '../../assets/people.png';
import SPEECH_BUBBLE from '../../assets/speech-bubble.png';
import OTHER from '../../assets/Other.svg';

const otherLocations = [
  {
    key: 'OTHER',
    value: 'documentLibrary-uploadLocationOptionOther',
  },
];

const DocumentLibraryUploadLocations = {
  otherLocations,
};

const DocumentLibrarySubsectionMapping = {
  OTHER: {
    labelString: () => get('documentLibrary-yourSubsectionOther'),
    slug: 'your/other',
    color: '#009150',
    titleColor: '#1d1d1d',
    filterKeys: ['OTHER'],
    key: 'OTHER',
    icon: OTHER,
  },
  TOURNAMENTS: [{
    labelString: () => get('documentLibrary-yourSubsectionStaging'),
    slug: 'your/staging',
    color: '#1D86CE',
    filterKeys: ['STAGING'],
    key: 'STAGING',
    icon: TROPHY,
  }, {
    labelString: () => get('documentLibrary-yourSubsectionLegacies'),
    slug: 'your/Legacies',
    color: '#2B462E',
    filterKeys: ['LEGACIES'],
    key: 'LEGACIES',
    icon: PEOPLE,
  }, {
    labelString: () => get('documentLibrary-yourSubsectionCommunications'),
    slug: 'your/Communications',
    color: '#1A9959',
    filterKeys: ['COMMUNICATIONS'],
    key: 'COMMUNICATIONS',
    icon: SPEECH_BUBBLE,
  }],
};

export {
  DocumentLibraryUploadLocations,
  DocumentLibrarySubsectionMapping,
};
