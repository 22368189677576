import React from 'react';
import PropTypes from 'prop-types';

import defaultImage from '../../../assets/background.png';

import './BackgroundImage.scss';

const BackgroundImage = ({ image }) => {
  return (
    <div
      className="components__background-image"
      style={{ backgroundImage: `url(${image})` }}
    />
  );
};

BackgroundImage.defaultProps = {
  image: defaultImage,
};

BackgroundImage.propTypes = {
  image: PropTypes.string,
};

export default BackgroundImage;
