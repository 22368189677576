import React, {
  useState, useEffect,
} from 'react';

import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import WarningUnSaveGuardModal from './WarningUnSaveGuardModal';
import { get } from '../../lib/strings';

const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {/* Your own alert/dialog/modal component */}
      <WarningUnSaveGuardModal
        open={modalVisible}
        titleText={get('warning-unsave-modal-title')}
        contentText={get('warning-unsave-modal-content')}
        cancelButtonText={get('warning-unsave-modal-cancel-button')}
        confirmButtonText={get('warning-unsave-modal-confirm-button')}
        onCancel={closeModal}
        onClose={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  );
};

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  shouldBlockNavigation: PropTypes.func.isRequired,
};

export default RouteLeavingGuard;
