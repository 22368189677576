import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PropTypes from 'prop-types';

const CircularProgressBar = ({ percentage, text }) => {
  return (
    <CircularProgressbar value={percentage} text={text} />
  );
};

CircularProgressBar.defaultProps = {
  percentage: '0',
  text: null,
};

CircularProgressBar.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default CircularProgressBar;
