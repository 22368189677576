import React from 'react';
import PropTypes from 'prop-types';
import './IconLink.scss';

const IconLink = ({ type }) => { // single icon link
  const { iconSource, link } = type;
  return <a href={link} target="_blank" rel="noreferrer"><img className="icon-link__img" src={iconSource} alt={iconSource} role="presentation" /></a>;
};
IconLink.propTypes = {
  type: PropTypes.shape({
    iconSource: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default IconLink;
