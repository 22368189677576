/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Nav from '../core/nav/Nav';
import Sidebar from '../core/sidebar/Sidebar';
import { GlobalContext } from '../../context/GlobalContext';
import './Layout.scss';
import PartnerFooter from '../core/footer-partner/PartnerFooter';
import contentService from '../../services/content/content-service';

function Layout(props) {
  const { org, user } = useContext(GlobalContext);
  const { history, children, notificationBanner } = props;
  const [toggled, setToggled] = useState(false);
  const [contentSections, setContentSections] = useState(undefined);
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  useEffect(
    () => {
      const getContentSections = async () => {
        const result = await contentService.getContentSections(org.organisation_id, user.language);

        if (result) {
          setContentSections(result);
        }
      };
      if (org) getContentSections();
    }, [org, user.language],
  );

  return (
    <>
      <div className="layout-wrapper">
        <Sidebar
          history={history}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          contentSections={contentSections}
        />
        <div className="layout-content">
          <Nav
            handleToggleSidebar={handleToggleSidebar}
            toggled={toggled}
            notificationBanner={notificationBanner}
          />
          {children}
          <PartnerFooter />
        </div>
      </div>

    </>
  );
}

Layout.propTypes = {
  history: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  notificationBanner: PropTypes.object.isRequired,
};

export default Layout;
