import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalContext';
import RegistrationResponseWidget from '../../../components/registration/registration-response-widget/RegistrationResponseWidget';
import { get } from '../../../lib/strings';
import THEME_TYPE from '../../../lib/consts/themeType';

const InvalidRegistrationTokenPage = () => {
  const history = useHistory();
  const { themeSetup, theme } = useContext(GlobalContext);
  const isTournamentLogin = history.location.pathname.indexOf('tournaments') !== -1;

  useEffect(() => {
    if (isTournamentLogin && theme !== THEME_TYPE.TOURNAMENT) {
      themeSetup(THEME_TYPE.TOURNAMENT);
    }
  }, []);

  return (
    <RegistrationResponseWidget
      heading={get('invalidPasswordResetToken-ErrorTtile')}
      bodyText={get('invalidPasswordResetToken-ErrorDescription')}
      href={`${isTournamentLogin ? '/tournaments' : ''}/login`}
      theme="white"
    />
  );
};

export default InvalidRegistrationTokenPage;
