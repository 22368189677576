import React, { useContext, useRef, useState } from 'react';
import { get } from '../../lib/strings';
import TabSwitch from '../../components/core/tabSwitch/TabSwitch';
import { DocumentLibraryTabIndexes } from '../../lib/consts/documentLibraryTabIndexes';
import './DocumentLibrary.scss';
import DocumentLibraryYourDocuments from './tabs/DocumentLibraryYourDocuments';
import { GlobalContext } from '../../context/GlobalContext';
import DocumentLibraryOnCourseDocuments from './tabs/DocumentLibraryOnCourseDocuments';
import themeType from '../../lib/consts/themeType';

const DocumentLibrary = () => {
  const { org, theme } = useContext(GlobalContext);
  const [tabIndex, setTabIndex] = useState(DocumentLibraryTabIndexes.YOUR);
  const tabRef = useRef();

  const onClickTab = (index) => {
    setTabIndex(index);
  };

  const renderTabContent = (index) => {
    if (index === DocumentLibraryTabIndexes.YOUR) {
      // set the tabRef
      return (
        <DocumentLibraryYourDocuments ref={tabRef} organisationId={org.organisation_id} />
      );
    }

    if (index === DocumentLibraryTabIndexes.ONCOURSE) {
      return (
        <DocumentLibraryOnCourseDocuments organisationId={org.organisation_id} />
      );
    }

    return null;
  };

  return (
    <div className={`${theme === themeType.TOURNAMENT ? 'img-tour page' : 'img-facility page'}`}>
      <div className="page-header">
        <div className="document-library-page-header">
          <h3 className="--dark review-page-section-title">
            {get('documentLibrary-sectionTitle')}
          </h3>
        </div>

        <TabSwitch className="document-library-tabswitch" labels={[get('documentLibrary-yourDocumentsTitle'), get('documentLibrary-oncourseDocumentsTitle')]} onClick={onClickTab} />
        {renderTabContent(tabIndex)}
      </div>
    </div>
  );
};

export default DocumentLibrary;
