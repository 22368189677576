// import HTTPError from '../../lib/errors/http-error';
import api from '../../lib/http';

export default {
  getMaintenance: async () => {
    const response = await api(
      'get',
      'maintenance',
      false,
      false,
      true,
    );

    return response;
  },
};
