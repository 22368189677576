import React, { useEffect, useContext, useState } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import { GlobalContext } from '../../../context/GlobalContext';
import organisationService from '../../../services/organisation/organisation-service';
import countryService from '../../../services/country/country-service';
import mediaService from '../../../services/media/media-service';
import geoLogo from '../../../assets/GEO.svg';
import close from '../../../assets/close.svg';
import closeBlue from '../../../assets/close-blue.svg';
import TermsAndConditions from '../../../pages/termsAndConditions/TermsAndConditions';
import themeType from '../../../lib/consts/themeType';
import './PartnerFooter.scss';

const currentYear = () => {
  const d = new Date();
  const n = d.getFullYear();
  return n;
};

const PartnerFooter = () => {
  const [partnerLogos, updatePartnerLogos] = useState([]);
  const [footerLogos, updateFooterLogos] = useState([]);
  const [openTC, setTC] = useState(false);
  const { org, theme } = useContext(GlobalContext);
  useEffect(() => {
    const getLogoIds = async () => {
      if (org) {
        const countryData = await countryService.getIdByLookUpId(org.country_lookup_id);
        const logoIds = await organisationService.getPartnerLogoIds(
          org.organisation_id, countryData.id,
        );
        const imagesAsBase = await Promise.all(logoIds.map(async (id) => {
          const logo = await mediaService.getPartnerLogo(id);
          return logo;
        }));
        updatePartnerLogos(imagesAsBase);
      }
    };

    const getFooterLogoIds = async () => {
      if (org) {
        const countryData = await countryService.getIdByLookUpId(org.country_lookup_id);
        const logoIds = await organisationService.getFooterLogoIds(
          org.organisation_id, countryData.id,
        );

        const imagesAsBase = await Promise.all(logoIds.map(async (id) => {
          const logo = await mediaService.getFooterLogo(id);
          return logo;
        }));
        updateFooterLogos(imagesAsBase);
      }
    };

    getLogoIds();
    getFooterLogoIds();
  }, [org]);

  const handleClick = () => setTC(true);

  return (
    <div className="partner-footer">
      <div className="partner-footer-inner-wrapper">
        <div className="partner-footer-copyright">
          © {currentYear()} GEO Foundation&nbsp;&nbsp;&nbsp;&nbsp;
          <button type="button" className="partner-footer-terms-link" onClick={handleClick}>
            Terms and Conditions
          </button>
        </div>
        <div className="partner-logo__sus-golf-brand sidebar-footer-logo">
          <img
            className="partner-logo"
            src={geoLogo}
            alt="sustianble golf geo"
          />
        </div>
        <div className="partner-logos">
          {footerLogos.length > 0 && (
            <div className="section-border dynamic-partner-logos">
              {footerLogos.map((src) => {
                return (
                  <img
                    className="download-partner-logo"
                    src={src}
                    alt="footer golf course"
                  />
                );
              })}
            </div>
          )}
          {partnerLogos.length > 0 && (
            <div className="section-border dynamic-partner-logos">
              {partnerLogos.map((src) => {
                return (
                  <img
                    className="download-partner-logo"
                    src={src}
                    alt="partner golf course"
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <BootstrapModal
        fullscreen
        show={openTC}
        onHide={() => setTC(false)}
        enforceFocus
        dialogClassName="tc-dialog"
        contentClassName="tc-content"
      >
        <div>
          <button
            type="button"
            className="tc-modal-close-button"
            onClick={() => setTC(false)}
          >
            {' '}
            <img
              src={theme === themeType.TOURNAMENT ? closeBlue : close}
              alt="close"
            />{' '}
          </button>
          <TermsAndConditions hasLogo={false} />
        </div>
      </BootstrapModal>
    </div>
  );
};

export default PartnerFooter;
