/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { Accordion, useAccordionToggle } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import './Notes.scss';
import Form from 'react-bootstrap/Form';
import { get } from '../../../lib/strings';
import RichTextEditor from '../form/RichTextEditor';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import HTMLViewer from '../html-viewer/HTMLViewer';
import NotesIcon from '../../../assets/icon-new-note.svg';
import HideIcon from '../../../assets/icon-hide.svg';
import ShowIcon from '../../../assets/icon-show.svg';
import EditIcon from '../../../assets/icon-edit.svg';
import TrashIcon from '../../../assets/icon-trash.svg';

const Notes = ({
  notesFields,
  loading,
  question,
  name,
  notesAppend,
  submitNotes,
  editNotes,
  answerData,
  deleteNote,
  prepend,
  notesRemove,
  colour,
}) => {
  const [noteModal, setNoteModal] = React.useState(false);
  const [deleteNoteModal, setDeleteNoteModal] = React.useState(false);
  const [noteIdToDelete, updateNoteIdToDelete] = React.useState('');
  const [noteToEdit, updateNoteToEdit] = React.useState({});
  const [accordionHideExpanded, setAccordionHideExpanded] = React.useState(false);

  const accordionHideAllRef = React.useRef(null);

  const formNoNoteError = get('question-addnotes-empty-note');

  const {
    handleSubmit, errors, control, getValues,
  } = useForm();

  const toggleAccordion = (ref) => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) { month = `0${month}`; }
    if (day.length < 2) { day = `0${day}`; }

    return [day, month, year].join('/');
  };
  const onClickAddNotes = async () => {
    const note = {
      questionId: question.id,
      type: 'notes',
      createDate: Date.now(),
      value: getValues('addNote'),
    };

    let updatedNote = {};

    if (noteToEdit.id) {
      note.id = noteToEdit.noteId;
      updatedNote = await editNotes(note.id, note);
      // Remove the existing note we will be adding the edited version later.
      notesRemove(noteToEdit.index);
    } else {
      updatedNote = await submitNotes(note);
    }

    if (!accordionHideExpanded) {
      toggleAccordion(accordionHideAllRef);
    }

    // need to swap the id across to noteId because useForm hooks (vom)
    // doesn't like fieldAarray's with keys of id inside them, in the effect.
    const newNote = {
      ...updatedNote,
      noteId: updatedNote.id,
    };

    delete newNote.id;
    prepend(newNote);
    setNoteModal(false);
    updateNoteToEdit({});
  };

  useEffect(() => {
    if (answerData.notes && answerData.notes.length > 0) {
      answerData
        .notes
        .sort((a, b) => b.createDate - a.createDate)
        .forEach((note) => {
          note.noteId = note.id;
          delete note.id;
          notesAppend(note);
        });
    }
  }, []);

  const handleDeleteNote = async (confirm) => {
    if (confirm) {
      // index is needed by form hook to remove the item from the array
      let getIndex = null;
      notesFields.map((item, index) => {
        if (item.noteId === noteIdToDelete) {
          getIndex = index;
        }
        return {};
      });

      await deleteNote(noteIdToDelete, getIndex);
    }
    updateNoteIdToDelete('');
    setDeleteNoteModal(false);
  };

  const CustomToggle = React.forwardRef(({
    eventKey, callback, showLabel, collapseLable,
    attr, className,
  }, ref) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      return callback && callback(eventKey);
    });
    const currentEventKey = React.useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey && eventKey && currentEventKey === eventKey
      ? true
      : currentEventKey && eventKey
      && currentEventKey.toString().toUpperCase() === eventKey.toString().toUpperCase();
    const label = isCurrentEventKey ? collapseLable : showLabel;

    if (attr === 'hideAllNotes') {
      if (isCurrentEventKey) {
        setAccordionHideExpanded(true);
      } else {
        setAccordionHideExpanded(false);
      }
    }

    return (
      <div
        ref={ref}
        className={`question-addnotes_colapse ${className}`}
        onClick={decoratedOnClick}
        aria-hidden="true"
      >
        {label}
      </div>
    );
  });

  CustomToggle.displayName = 'CustomToggle';

  CustomToggle.defaultProps = {
    callback: () => { },
    className: '',
  };

  CustomToggle.propTypes = {
    callback: PropTypes.func,
    showLabel: PropTypes.string.isRequired,
    collapseLable: PropTypes.string.isRequired,
    eventKey: PropTypes.number.isRequired,
    className: PropTypes.string,
    attr: PropTypes.string.isRequired,
  };

  const NotesButton = ({ children, ...rest }) => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
      setHover(true);
    };

    const handleMouseLeave = () => {
      setHover(false);
    };
    return (
      <button
        type="button"
        {...rest}
        style={{
          color: hover ? colour : 'black',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </button>
    );
  };

  return (
    <>
      <div className="question-addnotes-row">
        <h6>{get('question-notes-title')} {`${(!accordionHideExpanded && notesFields.length > 0) ? `(${notesFields.length})` : ''}`}</h6>
        <Accordion>
          <div className="question-addnotes__controls-container">
            <Accordion.Collapse eventKey={1}>
              <div className="question-addnotes-wrapper">
                {
                  notesFields.map((note, index) => {
                    return (
                      <div
                        key={note.noteId}
                        name={`${name}[${index}].value`}
                        style={{ backgroundColor: 'white' }}
                      >
                        <>
                          <div className="question-addnotes__content-container">
                            <div className="question-addnotes__delete">
                              <p className="p-0 m-0">{formatDate(note.createDate)}</p>
                            </div>
                            <div className="answer-container">
                              <div className="question-addnotes__content">
                                {/* <p>{formatDate(note.createDate)}</p> */}
                                <HTMLViewer
                                  html={note.value}
                                  expandableView
                                  colour={colour}
                                />
                              </div>
                              <div>
                                <button
                                  id="edit"
                                  type="button"
                                  onClick={
                                  () => {
                                    setNoteModal(true);
                                    updateNoteToEdit({ ...note, index });
                                  }
                                }
                                >
                                  <img src={EditIcon} alt="edit answer" />
                                </button>
                                <button
                                  id="delete"
                                  type="button"
                                  onClick={
                                  () => {
                                    setDeleteNoteModal(true);
                                    updateNoteIdToDelete(note.noteId);
                                  }
                                }
                                >
                                  <img src={TrashIcon} alt="edit answer" />
                                </button>
                              </div>
                            </div>

                          </div>
                          {index !== notesFields.length - 1 && (<div className="question-field-divider question-addnotes-divider--margin" />)}
                        </>

                      </div>
                    );
                  })
                }
              </div>
            </Accordion.Collapse>
            <div className="question-addnotes-button-contianer mt-4">
              <div className="question-addnotes-button-contianer-button-holder">
                <NotesButton type="button" className="question-addnotes-button pt-2 pb-2" onClick={() => setNoteModal(!noteModal)}>
                  <img className="mr-2" src={NotesIcon} alt="edit answer" />
                  {get('question-addnotes-button')}
                </NotesButton>
                {notesFields.length > 0 && (
                <NotesButton type="button" className="question-addnotes-button ml-4 pt-2 pb-2" onClick={() => toggleAccordion(accordionHideAllRef)}>
                  <img className="mr-2" src={accordionHideExpanded ? HideIcon : ShowIcon} alt="edit answer" />
                  {`${accordionHideExpanded ? get('question-addnotes-hideAll') : get('question-addnotes-showAll')}`}
                </NotesButton>
                )}
              </div>
              <CustomToggle
                attr="hideAllNotes"
                ref={accordionHideAllRef}
                eventKey={1}
                showLabel={`${get('question-addnotes-showAll')} (${notesFields.length})`}
                collapseLable={get('question-addnotes-hideAll')}
                className="--hide"
              />
            </div>
          </div>
        </Accordion>
      </div>
      <Modal
        show={noteModal}
        enforceFocus={false}
        closeBtnColour={colour}
        onClose={() => {
          setNoteModal(false);
          updateNoteToEdit({});
        }}
        heading={noteToEdit.value ? get('question-editnotes-heading') : get('question-addnotes-heading')}
        subtitle={get('question-addnotes-subtitle')}
        bodyText={(
          <div>
            <Form
              className="facility-details-form-wrapper"
            >
              <Controller
                name="addNote"
                control={control}
                defaultValue={noteToEdit.value}
                rules={{
                  validate: (value) => {
                    if (typeof value === 'undefined' || value.length === 0) {
                      return formNoNoteError;
                    }

                    return true;
                  },
                }}
                render={({ onChange, value }) => (
                  <RichTextEditor
                    maxLength={2000}
                    onChange={onChange}
                    value={value}
                    error={errors && errors.addNote && errors.addNote.message ? errors.addNote.message : ''}
                  />
                )}
              />
            </Form>
          </div>
        )}
        footer={(
          <>
            <Button
              loading={loading}
              type="button"
              onClick={handleSubmit(onClickAddNotes)}
              colour={colour}
            >
              {get('question-addnotes-save-button')}
            </Button>
          </>
        )}
      />
      <Modal
        show={deleteNoteModal}
        enforceFocus={false}
        onClose={() => setDeleteNoteModal(false)}
        heading={get('question-deleteNote-heading')}
        subtitle={get('question-deleteNote-subtitle')}
        closeBtnColour={colour}
        footer={(
          <div className="delete-notes-ctas">
            <Button
              inverse
              colour={colour}
              loading={loading}
              className="flex-3"
              form="add-editor-form"
              type="button"
              onClick={() => handleDeleteNote(false)}
            >
              {get('question-deleteNotesCancelButton')}
            </Button>
            <Button
              colour={colour}
              className="flex-1"
              form="add-editor-form"
              type="button"
              onClick={() => handleDeleteNote(true)}
            >
              {get('question-deleteNotesConfirmButton')}
            </Button>
          </div>
        )}
      />
    </>
  );
};

Notes.defaultProps = {
  notesFields: [],
  answerData: {},
  loading: false,
  colour: '#4ca471',
};

Notes.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    uiType: PropTypes.string,
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        createDate: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string,
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
  notesFields: PropTypes.arrayOf(
    PropTypes.shape({
      createDate: PropTypes.number,
      value: PropTypes.string,
      type: PropTypes.string,
      questionId: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  name: PropTypes.string.isRequired,
  notesAppend: PropTypes.func.isRequired,
  submitNotes: PropTypes.func.isRequired,
  editNotes: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  answerData: PropTypes.shape({ notes: PropTypes.arrayOf(PropTypes.shape({})) }),
  loading: PropTypes.bool,
  prepend: PropTypes.func.isRequired,
  notesRemove: PropTypes.func.isRequired,
  colour: PropTypes.string,
};

export default Notes;
