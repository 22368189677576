import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import LoggedOutRoutes from './LoggedOutRoutes';
import LoggedInRoutes from './LoggedInRoutes';
import ErrorRoutes from './ErrorRoutes';
import maintenanceService from '../services/maintenance/maintenance-service';

import { GlobalContext } from '../context/GlobalContext';

const Routes = ({ error }) => {
  const globalContext = useContext(GlobalContext);
  const [notificationBanner, setNotificationBanner] = useState(null);

  const location = useLocation();

  if (error) {
    return <ErrorRoutes />;
  }

  const getMaintenanceMessage = async () => {
    const result = await maintenanceService.getMaintenance();
    if (result && result.data && result.data.notification) {
      setNotificationBanner({ ...result.data.notification });
    } else {
      setNotificationBanner(null);
    }
  };

  useEffect(() => {
    getMaintenanceMessage();
  }, [location]);

  if (globalContext.user) {
    return <LoggedInRoutes notificationBanner={notificationBanner} />;
  }

  return <LoggedOutRoutes notificationBanner={notificationBanner} />;
};

Routes.defaultProps = {
  error: false,
};

Routes.propTypes = {
  error: PropTypes.bool,
};

export default Routes;
