import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import themeType from '../../lib/consts/themeType';

function CloseIcon(props) {
  const { theme } = useContext(GlobalContext);
  const colourFromTheme = theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471';
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.778.808a1 1 0 011.414 1.414l-16.97 16.97a1 1 0 01-1.414-1.414L17.778.808zm1.414 16.97a1 1 0 01-1.414 1.414L.808 2.222A1 1 0 012.222.808l16.97 16.97z"
        fill={colourFromTheme}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default CloseIcon;
