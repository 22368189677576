import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../panel/Panel';
import './MediaTile.scss';

const MediaTile = ({
  title, description, subLinkText, subLink, InsertButtonNode,
}) => {
  return (
    <Panel>
      <div className="page-nav-title__content">
        <div className="page-nav-title__text"> {title}</div>
        <div className="page-nav-description__text"> {description}</div>
        <a target="_blank" rel="noopener noreferrer" className={subLink ? 'page-nav-sublink' : 'page-nav-sublink__hidden'} href={`https://twitter.com/hashtag/${subLink}`}>{subLink ? subLinkText : 'hidden_space'}</a>
      </div>
      <div className={subLink ? 'dashboard-title-button__wrapper' : 'dashboard-title-button__wrapper page-nav-right-align'}>
        {InsertButtonNode}
      </div>
    </Panel>
  );
};
MediaTile.defaultProps = {
  subLink: undefined,
  subLinkText: undefined,

};
MediaTile.propTypes = {

  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  subLinkText: PropTypes.string,
  subLink: PropTypes.string,
  InsertButtonNode: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

export default MediaTile;
