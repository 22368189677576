import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import RegistrationResponseWidget from '../../../components/registration/registration-response-widget/RegistrationResponseWidget';
import { get } from '../../../lib/strings';
import getCodeFromQueryString from '../../../lib/queryStringGetter';

const RegistrationCompletePage = () => {
  const [signInLink, updateSignInLink] = useState('/login');
  const location = useLocation();

  useEffect(() => {
    const qsToken = qs.parse(location.search, { ignoreQueryPrefix: true });
    const langCode = getCodeFromQueryString();
    let langQueryString = '';
    if (langCode) {
      langQueryString = `?lang=${langCode}`;
    }

    if (qsToken.signInLink) {
      updateSignInLink(`${qsToken.signInLink}${langQueryString}`);
    }
  }, [updateSignInLink, location.search]);

  return (
    <RegistrationResponseWidget
      heading={get('registrationComplete-title')}
      bodyText={get('registrationComplete-description')}
      href={signInLink}
    />
  );
};

export default RegistrationCompletePage;
