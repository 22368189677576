import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import themeType from '../../lib/consts/themeType';

function SvgComponent(props) {
  const { theme } = useContext(GlobalContext);
  const colour = theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471';
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Profile_noimg</title>
      <defs>
        <circle id="prefix__a" cx={24} cy={24} r={24} />
        <circle id="prefix__c" cx={24} cy={24} r={24} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path fill="#FFF" mask="url(#prefix__b)" d="M0 0h48v48H0z" />
        <ellipse
          fill={colour}
          mask="url(#prefix__b)"
          cx={24}
          cy={41.5}
          rx={15}
          ry={9.5}
        />
        <circle fill={colour} mask="url(#prefix__b)" cx={24} cy={23} r={10} />
        <circle stroke="#FFF" strokeWidth={2} cx={24} cy={24} r={23} />
      </g>
    </svg>
  );
}

export default SvgComponent;
