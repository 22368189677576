import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalContext';

import './InternalLinkButton.scss';

const InternalLinkButton = ({
  children,
  to,
  'data-cy': cypressId,
  className,
  size,
  colour,
  style,
  inverse,
}) => {
  const globalContext = useContext(GlobalContext);
  const { updateReviewYearForAnnualData } = globalContext;
  const clickSelectYearForAnnualData = () => {
    const pathArray = to.toString().split('/');
    const slug = pathArray[1];
    if (slug.toString() === 'annual-review') { // clicking from annual review, update selected year into context
      const selectedYear = pathArray[2];
      updateReviewYearForAnnualData(selectedYear);
    }
  };

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const boxStyle = inverse ? {
    backgroundColor: !isHover ? 'white' : colour,
    border: `2px solid ${colour}`,
    color: !isHover ? colour : 'white',
  } : {
    backgroundColor: isHover ? 'white' : colour,
    border: isHover ? `2px solid ${colour}` : '2px solid white',
    color: isHover ? colour : 'white',
  };

  return (
    <Link
      data-cy={cypressId}
      className={`components__internal-link-button btn__basic ${size} ${className}`}
      style={{ ...style, ...boxStyle }}
      to={to}
      onClick={clickSelectYearForAnnualData}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >{children}
    </Link>

  );
};

InternalLinkButton.defaultProps = {
  'data-cy': '',
  size: '',
  className: '',
  colour: 'primary',
  style: {},
  inverse: false,
};

InternalLinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  'data-cy': PropTypes.string,
  size: PropTypes.string,
  colour: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  inverse: PropTypes.bool,
};

export default InternalLinkButton;
