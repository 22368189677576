import validator from 'validator';

export default {
  locationStringForId: (themeOrSectionId, questionData, librarySections) => {
    if (themeOrSectionId && !validator.isUUID(themeOrSectionId)) {
      const section = librarySections
        && librarySections.filter((item) => item.key === themeOrSectionId);

      if (section && section.length > 0) {
        return section[0].labelString();
      }
    } else {
      if (!questionData) {
        return undefined;
      }
      const theme = questionData[themeOrSectionId];
      if (theme) {
        return theme.title;
      }

      let locationString;
      Object.keys(questionData).forEach((setKey) => {
        const questionSet = questionData[setKey];
        const section = questionSet.sections.find((entry) => entry.id === themeOrSectionId);
        if (section) {
          locationString = `${questionSet.title} / ${section.title}`;
        }
      });
      return locationString;
    }
  },
};
