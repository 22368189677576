const stringsFallback = {
  'general-back': 'Back',
  'general-hide': 'Hide',
  'general-show': 'Show',
  'image-format': 'Format',
  'login-footer': 'OnCourse has been made possible with generous support from partners and donors.',
  'profile-title': 'Your Profile',
  'helpdesk-title': 'Helpdesk',
  'image-max-size': 'Max Size',
  'image-min-size': 'Min Size',
  'login-formHelp': 'Need help? Contact',
  'sidebar-LogOut': 'Log Out',
  'sidebar-Review': 'Review',
  'create-facility': 'Create Facility Account',
  'login-formTitle': 'Log in',
  'sidebar-Improve': 'Improve',
  'communicate-title': 'Communicate',
  'improve-pageTitle': 'Improve',
  'campaignKits-title': 'Campaign Kits',
  'form-modal-add-btn': 'Add',
  'login-formPassword': 'Password',
  'profile-saveButton': 'Save',
  'registration-Title': 'Join OnCourse',
  'sidebar-AnnualData': 'Annual Data',
  'dashboard-certified': 'CERTIFIED',
  'date-picker-default': 'Please choose',
  'helpdesk-card-email': 'Get in touch at',
  'helpdesk-card-title': 'Get in touch',
  'login-registerTitle': 'New to OnCourse?',
  'sidebar-Communicate': 'Communicate',
  'sidebar-Recognition': 'Recognition',
  'createFacility-title': 'Getting Started',
  'dashboard-switchClub': 'Switch Accounts',
  'documentLibrary-Note': 'Note:',
  'form-modal-sub-title': '   ',
  'login-registerButton': 'Join',
  'question-notes-title': 'Notes',
  'review-BestPractices': 'Review',
  'sidebar-campaignKits': 'Campaign Kits',
  'sidebar-joinFacility': 'Join Facility',
  'dashboard-take-a-tour': 'Take a tour',
  'facilityProfile-title': 'Facility Profile',
  'form-modal-update-btn': 'Update',
  'login-formButtonLogin': 'Login',
  'newPassword-formTitle': 'Password Reset',
  'sidebar-BestPractices': 'Best Practices',
  'sidebar-MainDashboard': 'Dashboard',
  'termsconditions-title': 'Terms and Conditions',
  'documentLibrary-upload': 'Upload Document',
  'form-modal-add-heading': 'Add',
  'helpdesk-card-subText1': 'Please also feel free to contact us with your questions or to request a consultation call. You can also look at https://sustainable.golf/oncourse/support to help answer any questions you may have.',
  'helpdesk-card-subText2': 'Well do our best to reply right away within our office hours of 9am to 5pm GMT, Monday to Friday. Email us in your preferred language - our online translation tool usually works well enough to communicate.',
  'joinFacility-cardTitle': 'Join a Facility Account',
  'joinFacility-mail-text': 'I would like to join the facility',
  'login-formEmailAddress': 'Email Address',
  'login-formPasswordHide': 'Hide',
  'login-formPasswordShow': 'Show',
  'newPassword-formButton': 'Confirm Password',
  'sidebar-GettingStarted': 'Getting Started',
  'sidebar-createFacility': 'Create Facility',
  'summary-table-edit-btn': 'Edit',
  'teamIntroduction-title': 'Materials & Resources',
  'annualData-SummaryTitle': 'Annual Data',
  'document-cardButtonText': 'Your Documents',
  'joinFacility-panelTitle': 'Request to join a facility account',
  'login-footer-tournament': 'OnCourse has been made possible with generous support from DOW.',
  'login-formRequiredField': 'This field is required',
  'question-set-delete-row': 'Delete Entry',
  'sidebar-DocumentLibrary': 'Documents Library',
  'sidebar-FacilityProfile': 'Facility Profile',
  'sidebar-OncourseReports': 'Oncourse Reports',
  'bestPractices-saveButton': 'Save',
  'campaignKits-description': 'OnCourse Campaigns enable your golf course to run successful, sustainability initiatives without the hassle of creating your own materials. These campaigns focus on important sustainability topics as well as messages to help golfers better understand some of your maintenance practices.',
  'control-dropdown-default': 'Please choose',
  'createFacility-cardTitle': 'Create a Facility Account',
  'dashboard-GettingStarted': 'Getting Started',
  'login-formInvalidDetails': 'Incorrect email or please update your password',
  'login-noTournamentsError': 'There is not a tournament associated with this account.',
  'profile-tabSettingsTitle': 'Settings',
  'question-addnotes-button': 'Add Notes',
  'remove-answer-modal-text': 'Do you want to delete this answer?',
  'sidebar-Support&planning': 'Support',
  'sidebar-TeamIntroduction': 'Materials & Resources',
  'sidebar-teamIntroduction': 'Materials & Resources',
  'summary-table-delete-btn': 'Delete',
  'CalculatingQuestion-total': 'Total',
  'bestpractices-button-edit': 'Edit',
  'createFacility-panelTitle': 'About your facility',
  'facilityProfileTeam-title': 'Team Management',
  'form-employee-modal-title': 'Enter the number of {modal-name} you have within each area',
  'form-modal-date-of-survey': 'Date of survey',
  'guidanceRecognition-title': 'Guidance',
  'joinFacility-mail-subject': 'Join a Facility Account',
  'login-registerDescription': 'Its easy to set up an OnCourse account to begin or accelerate your sustainability journey. <br> <br>  There is no cost to use OnCourse and you only pay if you would like extra support services, annual OnCourse Reports with carbon footprint, or to become GEO Certified. In some countries, the cost of annual reports are covered by the national golf federation and we will email you within a week of your registration if this applies to your golf facility. <br> <br>  Just click below and well get you started!',
  'question-addnotes-heading': 'Add Notes',
  'question-addnotes-hideAll': 'Hide All',
  'question-addnotes-showAll': 'Show All',
  'question-set-AddRowButton': 'Add Entry',
  'question-set-delete-table': 'Delete table?',
  'registration-formLastName': 'Last Name',
  'review-AddAYearModalLabel': 'Year',
  'review-AddAYearModalTitle': 'Add a Year',
  'startedPage-GreetingTitle': ' ',
  'bestPractices-SummaryTitle': 'Best Practices',
  'campaignKits-card-download': 'Click to download',
  'completeRegistration-title': 'Create Password',
  'datagrid-placeholder-entry': ' ',
  'documentLibrary-selectFile': 'Choose File',
  'joinFacility-mail-greeting': 'Hello',
  'joinFacility-requestButton': 'Contact Us',
  'newPassword-formRuleLetter': 'At least 1 letter',
  'newPassword-formRuleNumber': 'At least 1 number',
  'newPassword-formRulesTitle': 'Enter your new password below. Password must include:',
  'passwordResetSuccess-title': 'Password Reset',
  'profile-errorOnSaveMessage': 'Something went wrong. Please try again later.',
  'profile-tabFacilitiesTitle': 'Your Accounts',
  'question-addnotes-no-notes': ' No notes to display',
  'question-addnotes-subtitle': 'Consider adding your initials so your team knows who made the note.',
  'question-editnotes-heading': 'Edit Notes',
  'question-set-AddMoreButton': 'Add',
  'question-set-ExampleAnswer': ' ',
  'question-set-dropdownLabel': 'Answers',
  'registration-formFirstName': 'First Name',
  'registration-formRoleTitle': 'Your role',
  'registration-fromFirstName': 'First Name',
  'registrationComplete-title': 'Complete Registration',
  'registrationLinkSent-title': 'Welcome!',
  'remove-answer-modal-header': 'Delete Answer',
  'review-AnnualDataPageTitle': 'Review',
  'review-AnnualDataTileTitle': 'Annual Data',
  'review-questionPanelNature': 'Nature',
  'warning-unsave-modal-title': 'Unsaved Changes',
  'CalculatingQuestion-overall': 'Overall',
  'create-facility-description': 'To use OnCourse you need to create an account for your golf facility - or join an account if your golf club is already OnCourse.',
  'createFacility-createButton': 'Create Facility Account',
  'facility-greenFeeOptions-na': 'Non-applicable',
  'facility-typesOptions-other': 'Other',
  'forgottenPassword-formTitle': 'Update Login Details',
  'helpdesk-formReasonRequired': 'Reason required',
  'login-formForgottenPassword': 'Update login details / reset password',
  'newPassword-formNewPassword': 'New Password',
  'passwordResetSuccess-button': ' Return to Login',
  'profile-changePasswordError': 'The password does not meet the required complexity requirements.',
  'question-deleteNote-heading': 'Delete Note',
  'question-set-guidanceButton': 'Read more',
  'registration-formTermsTitle': 'I agree to the OnCourse <a href="/terms-and-conditions">Terms & Conditions</a>',
  'sidebar-BestPracticesNature': 'Nature',
  'sidebar-guidanceRecognition': 'Guidance',
  'annualData-DateDropdownLabel': 'Change Year',
  'bestpractices-status-pending': 'Percent complete',
  'campaignKits-card-comingSoon': 'Coming soon',
  'component-HighLightTileTitle': 'Highlights',
  'dashboard-reviewTile-content': 'Begin with a self-assessment to record your actions and get new ideas using best-practice checklists, and then gather key annual data.',
  'documentLibrary-sectionTitle': 'Document Library',
  'documentLibrary-unselectFile': 'Remove',
  'facility-typesOptions-resort': 'Resort',
  'improve-RecognitionTileTitle': 'Recognition',
  'joinFacility-cardDescription': 'If you know your facility is already on OnCourse and youd like to join the account ,email hello@sustainable.golf and we can arrange this.',
  'question-addnotes-expandNote': 'Expand',
  'question-deleteNote-subtitle': 'Are you sure you want to delete this note?',
  'question-set-guidance-nested': 'Please check any item that applies and answer the nested question.',
  'review-AnnualDataWidgetBlurb': 'This section of OnCourse is for you to use each year to track data around important areas including turf management, water, energy, and waste and key social value data. Its useful to pull this together to see trends and you can also have an annual Sustainability Scorecard and Carbon Footprint prepared for you. If you are planning to be GEO Certified for the first time, please input data for the last complete calendar year. If you will be applying for a second or third certification, please email to find out how many years of data you will need at hello@sustainable.golf.',
  'teamIntroduction-description': 'We want to make it as easy as possible for you to communicate your OnCourse commitments and ambitions with everyone at your course. Your Team introduction includes email copy to highlight your sustainable golf commitment to employees and an introduction to the OnCourse programme and its benefits.',
  'termsconditions-pre-section1': 'By accessing to and browsing from ',
  'termsconditions-pre-section2': 'OnCourse you accept, without limitation or qualification, the Terms and Conditions herein, and you acknowledge that to the extent they are inconsistent herewith, any other agreements between you and the GEO Foundation (hereinafter GEO) are superseded and of no force or effect. Your access to and use of this Site is subject to the following Terms and Conditions, and all applicable laws, rules and regulations.',
  'warning-unsave-modal-content': 'There are unsaved changes on the page. Do you really want to leave this page?',
  'bestPractices-saveLaterButton': 'Save and Come Back',
  'bestpractices-button-continue': 'Continue',
  'bestpractices-status-complete': 'Section complete',
  'component-HighLightTileButton': 'View or Share',
  'component-improveSupportLinks': 'Documents',
  'dashboard-improveTile-content': 'Select from a range of additional support, analysis, reports and recognition available to help guide and celebrate your sustainability journey.',
  'dashboard-reviewTile-subTitle': 'Are we sustainable?',
  'icon-hover-text-cert-required': 'Required for Certification ',
  'improve-ReportsPanelSubTitle1': 'OnCourse Reports',
  'improve-ReportsPanelSubTitle2': 'What is included?',
  'joinFacility-panelDescription': 'If youd like to join an existing OnCourse account please get in touch with us at hello@sustainable.golf.',
  'login-formPasswordPlaceholder': 'Enter password',
  'question-addnotes-save-button': 'Save',
  'registration-formAboutUsTitle': 'How did your hear about OnCourse?',
  'registration-formCancelButton': 'Return to Login',
  'registration-formEmailAddress': 'Email Address',
  'remove-answer-modal-button-no': 'No',
  'review-BestPracticesTileTitle': 'Best Practices',
  'review-questionPanelCommunity': 'Community',
  'review-questionPanelResources': 'Resources',
  'termsconditions-linking-para1': 'This Site may contain links to Websites owned or operated by parties other than GEO. GEO has not reviewed the content of all sites linked to this Website. GEO does not control outside sites and is not responsible for the content of any offsite pages or any other sites linked to this Website. Linking to any other offsite pages or other sites is at your own risk. Outside sites may contain information that some people may find offensive or inappropriate. GEO assumes no responsibility for the screening of any site that is included as a link, and assumes no responsibility for the content, completeness, availability, accuracy, legality or decency of any linked site. GEO does not endorse any products or services available from other sites, and it is not responsible for the failure of any linked site to provide any promised goods or services. This Website may link you to pages that may have been removed or modified since the information was last collected and indexed.',
  'termsconditions-linking-para2': 'You may link your site to www.getoncourse.golf under the following conditions: You shall not place any content from this Site in a frame on your site. You shall not imply that GEO endorses your site, or any products on your site. You shall not misrepresent the relationship of your site with GEO. You shall not present false information about GEO, or its programmes and services. You shall not use the GEO trademarks without express written permission. Your site shall not contain material that could be construed as offensive. Your site shall be appropriate for all age groups. You shall indemnify and hold harmless GEO and employees from any claim brought by any third party arising out of your linking to this Website. You agree to immediately remove your link to this upon request by GEO, or if you breach any of these linking conditions.',
  'termsconditions-linking-title': '3. Linking',
  'termsconditions-privacy-list1': 'Personal details are for the sole purposes of identifying users and the golf facilities they represent, for the purposes of recording, administration and notifications directly and solely linked to the objectives of the OnCourse programme.',
  'termsconditions-privacy-list2': 'Good practices  information provided by users that record their sustainability practices via the programme checklists shall be shared and utilized as follows:',
  'termsconditions-privacy-list3': 'Data - specific quantitative data provided either through OnCourse tools and/or the Annual Data section of the website shall be shared and utilized as follows:',
  'termsconditions-privacy-list4': "Highlights by uploading highlights (photographs and text), you agree to them being potentially edited, approved and then published by GEO. You should also make sure that any persons included in photos have provided consent to be published within the highlight for the sole purpose of representing one if your facility's sustainability highlights. The in-programme agreement that the user is asked to confirm is:",
  'termsconditions-privacy-list5': 'Supporting information- any additional files or documents uploaded shall only be viewed by the facility account members, GEO and Accredited Verifiers to aid the evaluation of information as part of making certification decisions. It shall not be shared with any other parties.',
  'termsconditions-privacy-list6': 'Pesticides Log this data shall only be retained by GEO and shared with an accredited verifier for the purposes of evaluating facility performance against published certification criteria, unless in-programme guidance declares otherwise. In certain countries, pesticide information is legally required to be published and shared, and regionalised versions of OnCourse will clearly highlight if that is the case, and with which partners data may be shared and for what purposes.',
  'termsconditions-privacy-para1': 'GEO Foundation has always been committed to safeguarding the data collected from clients; ensuring the privacy, responsible and legal stewardship of your personal information; minimising personal data that is collected; and providing the tools and access to your own data, to easily modify, delete or store it. We assure all OnCourse users that the information provided is only that which is required for the purposes and outputs of this programme, as outlined below.',
  'termsconditions-privacy-para2': 'We do not share or pass on, in any form, personal or golf facility information with any other third party, unless expressly and clearly represented to you and separately agreed by you.',
  'termsconditions-privacy-para3': 'Please contact us at ',
  'termsconditions-privacy-para4': 'should you require any further specific information on your data.',
  'termsconditions-privacy-para5': 'Information you share and transmit via this website may be used by GEO for the following purposes:',
  'termsconditions-privacy-para6': 'Within the OnCourse programme are the tools necessary to make changes to the small amount of personal information and larger amount of facility information requested. There are clearly signposted means to edit and erase your personal information and your personal profile / account user details. For a facility to be deleted we need a written request from the main Facility Account holder. We will delete the Facility Account and erase all information related to that account immediately on receipt of an authenticated request from the main Facility Account holder.',
  'termsconditions-privacy-para7': 'We do keep track of the countries and domains from which people visit us, the browser and / or Internet Service Provider used and other related data. We analyse this data for trends and statistics.',
  'termsconditions-privacy-para8': 'We may also collect information automatically provided to us by your browser about the type of computer and operating system you are using. We do not use cookies to help identify users. (Cookies are pieces of information that a Web site transfers to an individual?s hard drive for record-keeping purposes.) If you have any questions, please contact the GEO webmaster via our contact form, and see our Privacy & Cookie Policy ',
  'termsconditions-privacy-para9': 'here.',
  'termsconditions-privacy-title': '2. Privacy Commitment',
  'bestPractices-SummaryPageTitle': 'Review',
  'campaignKits-card-carbon-title': 'Carbon Emissions',
  'campaignKits-card-health-title': 'Health and Wellbeing',
  'createFacility-cardDescription': 'Create a new OnCourse account for your golf facility.',
  'dashboard-improveTile-subTitle': 'How can we improve?',
  'facility-typesOptions-dailyFee': 'Daily Fee',
  'improve-ReportsPanelButtonText': 'Request Reports',
  'newPassword-formRetypePassword': 'Retype Password',
  'question-addnotes-collapseNote': 'Collapse',
  'question-previous-data-message': 'The previous years data has been added, in red, for your reference. Please update as needed; then press either Save button.',
  'question-set-AddNewTableButton': 'Add Entry',
  'question-set-guidance-datagrid': 'Please click add and enter the requested information for each entry.',
  'question-set-guidance-dropdown': 'Please select the answer most applicable from this dropdown list.',
  'registration-formConfirmButton': 'Confirm Registration',
  'registration-formLanguageTitle': 'Preferred Language',
  'remove-answer-modal-button-yes': 'Yes',
  'sidebar-BestPracticesCommunity': 'Community',
  'sidebar-BestPracticesResources': 'Resources',
  'startedPage-noOrgGreetingTitle': 'Welcome to OnCourse',
  'teamIntroduction-card-download': 'Download',
  'termsconditions-privacy-list2a': 'With the accredited verifier for the purposes of evaluation of facility performance against the published certification criteria (this only applies if the club takes the optional step of becoming GEO Certified©)',
  'termsconditions-privacy-list2b': 'Anonymously for the purposes of understanding and representing key trends in industry performance, regionally, nationally and internationally',
  'termsconditions-privacy-list2c': 'Provided back to the facility account members in a usable and archived form for the optional purpose of generating a golf facility sustainability review',
  'termsconditions-privacy-list3a': 'With the accredited verifier for the purposes of evaluation of tournament performance against the published certification criteria (this only applies if the club takes the optional step of becoming GEO Certified©)',
  'termsconditions-privacy-list3b': 'Anonymously for the purposes of understanding and representing key trends in industry performance, regionally, nationally and internationally.',
  'termsconditions-privacy-list3c': 'Provided back to the facility account members in a usable and archived form for the optional purpose of generating a golf facility sustainability review',
  'termsconditions-privacy-list4a': "By participating and sharing this highlight, I am giving permission for the OnCourse team to edit, if required, and share the highlight more widely, and I confirm that the people in the photo have provided their approval to be included in the image, for the sole purpose of promoting one of our facility's sustainability highlights.",
  'termsconditions-privacy-para10': 'This Privacy Policy does not extend to anything that is inherent in the operation of the Internet, and beyond the control of GEO and is not to be applied in any manner contrary to applicable laws, rules or regulations.',
  'calculatingQuestion-select-unit': 'Select a unit of measurement.',
  'component-SocialMediaTwitterTag': 'ForSustainableGolf',
  'createFacility-cardDescription ': 'If you cant find your Facility in out existing list please create it here to get it added to OnCourse.',
  'createFacility-modalPreview-vat': 'VAT Number:',
  'createFacility-panelDescription': 'Please fill out the details below to create your facility account. All fields are mandatory unless otherwise stated.',
  'data-grid-what-else-badge-label': 'What else is there',
  'facility-typesOptions-municipal': 'Municipal',
  'facilityProfileTeam-description': 'View and manage the people that have been invited to collaborate on OnCourse.',
  'forgottenPasswordLinkSent-title': 'Update Login Details',
  'form-validation-please-complete': 'Please complete ',
  'helpdesk-title-button-contactUs': 'Contact Us',
  'improve-ReportsPanelBenefitText': 'We can prepare three annual reports for you:',
  'question-addnotes-delete-button': 'Delete',
  'question-set-modalGuidanceTitle': 'Read more',
  'review-AddAYearModalDescription': 'To add a new event year please select from the list.',
  'review-BestPracticesWidgetBlurb': 'You can work through each of the nine action areas for sustainable golf in any order, recording the actions you currently undertake. Some actions may not be possible in your situation, but its good to keep a list of any ideas for improvements and you can return to these lists to update anytime. Actions that are required for Certification are marked with a small green flag.',
  'review-BestPracticesWidgetTitle': 'Best Practices',
  'termsconditions-copyright-para1': 'Unless otherwise stated, materials contained in this Site are the copyrighted property of GEO. All rights reserved. GEO neither warrants nor represents that your use of materials displayed in this Website will not infringe rights of third parties not owned by or affiliated with GEO. You may not distribute, modify, transmit, reuse, reproduce, re-post or use the copyrighted materials on this Website for public or commercial purposes, including the text, images, audio and video, without the express written consent of GEO or under any agreements / conditions provided by the owners of third party materials. You are hereby authorised to view, download, print and distribute individual pages from this Website subject to the following conditions:',
  'termsconditions-copyright-para2': 'The copyrighted materials on this Site may be used for informational purposes only.',
  'termsconditions-copyright-para3': 'Except as specifically authorised by GEO, the copyrighted materials on this Site may only be used for non-commercial, personal purposes.',
  'termsconditions-copyright-para4': 'Any copy or distribution of the materials on this Site, or portion thereof, must include this copyright notice, unless specifically covered by any other copyright and proprietary notices contained on the materials. Text: Unless otherwise stated, all text and resources featured on this website is copyright of GEO. Any reproduction, in full or in part, must credit GEO Photographs: All photographs featured on this site are subject to copyright and must not be reproduced without permission. If you are interested in using any of the images, please contact us.',
  'termsconditions-copyright-para5': 'Trademark: OnCourse©, the OnCourse logo, GEO Certified©, the GEO Certified logo, the GEO Foundation logo, and GEO Foundation are registered trademarks of GEO and are subject to copyright. They must not be used or reproduced without permission. If you are interested in using our trademarks, please contact us via our contact form quoting subject: trademark.',
  'termsconditions-copyright-title': '1. Copyright',
  'CalculatingQuestion-total-of-all': 'Total',
  'CalculatingQuestion-your-overall': 'Overall',
  'bestPractices-saveCompleteButton': 'Save as Complete',
  'campaignKits-card-footer-inTouch': 'Get in touch at',
  'completeRegistration-description': 'Thank you for verifying your email.<br/><br/>The final step is to create a password for your account. Enter your password below. Password must include:',
  'component-SocialMediaWidgetTitle': 'Follow Sustainable Golf',
  'createFacility-modalPreview-city': 'City:',
  'createFacility-modalPreview-name': 'Facility Name:',
  'displayMaterials-card-comingSoon': 'Coming soon',
  'documentLibrary-filesCountPlural': 'files',
  'facilityProfileTeam-action-leave': 'Leave',
  'facilityProfileTeam-inviteButton': 'Invite New People',
  'facilityProfileTeam-status-admin': 'User',
  'facilityProfileTeam-status-owner': 'Owner',
  'facilityProfileTeam-userName-you': '(you)',
  'forgottenPasswordLinkSent-button': ' Return to Login',
  'improve-ReportsPanelEmailSubject': 'Request Reports',
  'improve-onCourseReportsTileTitle': 'Reports',
  'improve-supportPlanningTileTitle': 'Support',
  'newPassword-formErrorNewPassword': 'Please enter a new password',
  'passwordResetSuccess-description': 'Thank you.<br/><br/>Your password has been reset. You can now use it to log in to your account.',
  'profile-changePasswordModalTitle': 'Change Password',
  'profile-tabSettingsCardTourTitle': 'Introductory Tour',
  'question-deleteNotesCancelButton': 'Cancel',
  'question-set-delete-row-messagae': 'Would you like to delete this entry?',
  'question-set-delete-table-row-no': 'No',
  'question-set-modalAddAnwserLabel': 'Any related practices to add (optional)',
  'question-set-modalAddAnwserTitle': 'Add an Answer',
  'registrationComplete-description': 'You have successfully registered.<br/><br/>You can now login into Oncourse using your email and password.',
  'registrationLinkSent-description': 'Thank you for joining OnCourse. <br/><br/> Please check your email and click the link we sent you to complete your registration process.   No email?  Check your junk folder and if still not there, we can send it to you again.<br/><br/>',
  'review-AnnualDataTileDescription': 'Record your key data each year to see trends and areas to improve or promote, and you can also get a carbon footprint.',
  'termsconditions-cautionary-para1': 'Statements in the documents in this Website with respect to the plans, strategies, and beliefs, of GEO as well as other statements that are not historical facts are forward-looking statements based on expectations and are naturally subject to risks and uncertainties. Actual results may vary materially from such statements due to changes in circumstances including, but not limited to, general economic conditions. Therefore, you are advised not to put undue reliance on those statements and GEO is under no obligation (and expressly disclaims any such obligations to) update or alter any forward-looking statements.',
  'termsconditions-cautionary-title': '4. Cautionary Statement',
  'termsconditions-disclaimer-para1': 'All users and participants accept and confirm that the GEO Foundation, and all other partners associated with this website and the content herein, shall not be held responsible, in whole or in part, to any outcomes arising from the guidance provided or the information reported as outlined above.',
  'termsconditions-disclaimer-para2': 'Actions and activities carried out by OnCourse users are undertaken entirely at their own discretion and risk and agree to their being no legal recourse towards GEO and partner bodies for any outcomes arising from those actions.',
  'termsconditions-disclaimer-title': '5. Disclaimer',
  'campaignKits-card-pollution-title': 'Pollution Prevention',
  'createFacility-modalPreview-title': 'Create Facility',
  'dashboard-facility-profile-button': 'Facility Profile',
  'dashboard-take-a-tour-description': 'A  tour of OnCourse will be available here soon.',
  'data-grid-delete-badge-keep-label': 'Are you sure you want to delete this answer',
  'facility-greenFeeOptions-below-30': 'Below 30',
  'facilityProfileTeam-action-remove': 'Remove',
  'facilityProfileTeam-status-joined': 'Joined',
  'facilityProfileTeam-usertype-user': 'User (view-only)',
  'forgottenPassword-formDescription': 'Enter your email address below. If there is an OnCourse account for that address, you will receive an email with instructions on how to reset your password.',
  'forgottenPassword-formResetButton': 'Reset Password',
  'helpdesk-card-message-modal-title': 'Contact Us',
  'login-formEmailAddressPlaceholder': 'Enter email address',
  'profile-tabSettingsCardUnitsTitle': 'Preferred Units of Measurement',
  'question-deleteNotesConfirmButton': 'Confirm',
  'question-set-delete-table-row-yes': 'Yes',
  'question-set-guidance-multiChoice': 'Please select the answer that applies the most.',
  'question-set-modalAddAnwserButton': 'Confirm',
  'registration-formErrorAcceptTerms': 'Please agree to the terms & conditions',
  'registration-formRoleOptions-user': 'User',
  'registrationComplete-CancelButton': 'Return to login',
  'registrationComplete-ReSendButton': 'Re-send Email',
  'registrationLinkSent-CancelButton': 'Return to login',
  'registrationLinkSent-ReSendButton': 'Re-send Email',
  'review-AnnualDataWidgetBlurbTitle': ' ',
  'termsconditions-privacy-sub-title': 'Amending and deleting your information',
  'calculatingQuestion-not-calculated': 'Not Calculated',
  'campaignKits-card-waterDrive-title': 'Water Drive',
  'component-HighLightTileDescription': 'Visit the gallery of Sustainable Golf Highlights from around the world to get ideas and inspire others by sharing some of your examples.',
  'createFacility-modalPreview-button': 'Confirm',
  'dashboard-communicate-Tile-content': 'Download materials to inspire your team, golfers and wider community, plus communications guidance to get the most from your achievements.',
  'data-grid-delete-badge-keep-button': 'Keep',
  'documentLibrary-documentTable-date': 'Date Uploaded',
  'documentLibrary-filesCountSingular': 'file',
  'documentLibrary-yourDocumentsTitle': 'Your Documents',
  'facility-greenFeeOptions-above-100': 'Above 100',
  'facility-surroundingsOptions-urban': 'Urban',
  'facilityProfileTeam-status-invited': 'Invited',
  'facilityProfileTeam-userTable-name': 'Name',
  'facilityProfileTeam-usertype-admin': 'User',
  'forgottenPassword-formCancelButton': 'Cancel',
  'forgottenPassword-formEmailAddress': 'Email Address',
  'helpdesk-card-message-modal-button': 'Send',
  'improve-RecognitionPanelButtonText': 'Get in touch',
  'improve-RecognitionTileDescription': 'Make the most of your sustainability, consider getting GEO Certified or being recognised for being Climate Neutral or other themed awards coming soon.',
  'profile-changePasswordGenericError': 'Something went wrong. please try again later.',
  'profile-tabGeneralInformationTitle': 'General Information',
  'question-addDataGrid-expandAnswers': 'Expand',
  'question-set-delete-table-messagae': 'Are you sure you want to delete this table?',
  'question-set-guidance-rowQuestions': 'Please answer any of the following questions that apply.',
  'registration-formEmailAddressError': 'This e-mail address is already registered, please return to login',
  'registration-formInvalidEmailError': 'Email not valid',
  'registration-formRoleOptions-owner': 'Other',
  'warning-unsave-modal-cancel-button': 'No',
  'communicate-card-campaignKits-title': 'Campaign Kits',
  'createFacility-modalPreview-address': 'Facility Address:',
  'createFacility-modalPreview-country': 'Country:',
  'dashboard-communicate-Tile-subTitle': 'What can we say?',
  'documentLibrary-yourSubsectionOther': 'Other',
  'facilityProfileDetails-genericError': 'Something went wrong please try again later.',
  'facilityProfileTeam-action-reInvite': 'Re-invite',
  'facilityProfileTeam-sendInviteError': 'User already part of organisation',
  'facilityProfileTeam-userTable-email': 'Email',
  'guidanceRecognition--guidance-title': 'Guidance',
  'invalidRegistrationToken-ErrorTtile': 'Registration Error',
  'newPassword-formErrorMinRequirement': 'Your password does not meet the minimum requirements',
  'profile-facility-change-modal-title': 'Select facility',
  'profile-tabGeneralInfoCardRoleTitle': 'My Roles',
  'profile-tabSettingsCardUnitsOptions': 'Metric',
  'registration-fromErrorRequiredField': 'This field is required',
  'review-BestPracticesTileDescription': 'Review and record actions from checklists of important practices for sustainable golf.',
  'warning-unsave-modal-confirm-button': 'Yes',
  'component-LinearPorgressCardComplete': 'Complete',
  'component-richEditor-max-characteres': 'Max characters',
  'createFacility-modalPreview-postcode': 'Zip/Postcode:',
  'data-grid-delete-badge-delete-button': 'Delete',
  'data-grid-what-else-badge-add-button': 'Add',
  'displayMaterials-card-footer-inTouch': 'Get in touch at',
  'documentLibrary-documentTable-delete': 'Delete',
  'documentLibrary-yourSubsectionNature': 'Nature',
  'facility-annualFeeOptions-above-5000': 'Above 5000',
  'facility-annualFeeOptions-below-1000': 'Below 1000',
  'facility-surroundingsOptions-coastal': 'Coastal',
  'facility-typesOptions-privateMembers': 'Private Members',
  'facilityProfile-tabFacilityTeamTitle': 'Facility Team',
  'facilityProfileDetails-defaultOption': 'Please choose',
  'facilityProfileTeam-inviteModalTitle': 'Invite others to join this account',
  'facilityProfileTeam-removeModalTitle': 'Remove from Facility',
  'facilityProfileTeam-userTable-status': 'Status',
  'improve-RecognitionPanelEmailSubject': 'Find out More',
  'improve-ReportsPanelImageDescription': 'The annual data you enter into OnCourse can be used to help you easily track and communicate your current strengths and see opportunities to progress  and save. Once all of our data for any given year is complete, click the request report button and we will get in touch to answer answer any questions or get started. The different cost-packages are listed in the document below.',
  'invalidPasswordResetToken-ErrorTtile': 'Password Reset Error',
  'profile-tabSettingsCardLanguageTitle': 'Preferred Language',
  'question-addDataGrid-collapseAnswers': 'Collapse',
  'registration-formAboutUsOptions-blog': 'Blog',
  'registration-formCorrespondenceTitle': "I'd like to get monthly tips & news about OnCourse/Sustainable Golf",
  'registration-formLastNamePlaceholder': 'Enter last name',
  'registrationLinkSent-ReSendingButton': 'Resending email',
  'review-BestPracticesWidgetBlurbTitle': ' Review',
  'teamIntroduction-card-footer-inTouch': 'Get in touch at',
  'calculatingQuestion-input-placeholder': 'Type number here...',
  'completeRegistration-formCofirmButton': 'Confirm Password',
  'documentLibrary-latestDocuments-title': 'Recent Uploads',
  'documentLibrary-yourSubsectionStaging': 'Staging',
  'facility-historicLandUseOptions-urban': 'Urban (post industrial)',
  'facility-surroundingsOptions-suburban': 'Suburban',
  'facilityProfileTeam-inviteModalButton': 'Send Invite',
  'facilityProfileTeam-removeModalButton': 'Confirm',
  'forgottenPasswordLinkSent-description': 'Thank you.<br/><br/>If an account exists you should receive, by email, instructions on resetting your password shortly.<br/><br/>If you have any trouble, check your junk mail or please contact us so we can help.',
  'guidanceRecognition-recognition-title': 'Recognition',
  'improve-ReportsPanelBenefitsListText1': 'Sustainability Scorecard.   Key metrics for every year you have entered data plus recommendations on things to promote or improve. This is primarily intended for you to use internally.',
  'improve-ReportsPanelBenefitsListText2': 'Carbon Report.   Your carbon footprint is worked out with a carbon calculator developed specifically for golf facilities. Includes recommendations on how to improve or become Climate Neutral.  ',
  'improve-ReportsPanelBenefitsListText3': 'Sustainability Review.   An overview of your best practices and graphs of your key data trends. Designed to help represent your environmental and social value to staff, golfers and community.',
  'profile-measurementUnitOptions-metric': 'Metric',
  'profile-tabGeneralInfo-cropModalTitle': 'Crop Image and Upload',
  'registration-formFirstNamePlaceholder': 'Enter first name',
  'registration-formRoleOptions-coaching': 'Coaching/Professional',
  'registration-formRoleOptions-commitee': 'Commitee/Volunteer',
  'review-AnnualReviewCardButtonComplete': 'Complete',
  'review-AnnualReviewCardButtonContinue': 'Continue',
  'teamIntroduction-card-committee-title': 'Committee Handout',
  'campaignKits-card-plasticBottles-title': 'Plastic Bottles',
  'campaignKits-card-turfManagement-title': 'Turf Management',
  'completeRegistration-formPasswordLabel': 'Password',
  'component-CircularPorgressCardComplete': 'Complete',
  'component-LinearPorgressCardIncomplete': 'Edit',
  'component-SocialMediaWidgetDescription': 'Follow the growing community, join the conversation and speak up for sustainable golf.',
  'createFacility-formErrorEmailNotUnique': 'This email address already exists associated to a Facility.',
  'documentLibrary-documentTable-filename': 'File Name',
  'documentLibrary-documentTable-location': 'Location',
  'documentLibrary-formErrorRequiredField': 'Form field required',
  'documentLibrary-oncourseDocumentsTitle': 'OnCourse Documents',
  'documentLibrary-yourSubsectionApproach': 'Approach',
  'documentLibrary-yourSubsectionLegacies': 'Legacies',
  'facility-profile-page-formGenericError': 'Something went wrong. Please try again later.',
  'facility-surroundingsOptions-natlitdev': ' Natural: little development',
  'facility-surroundingsOptions-natlowdev': 'Natural: low density development',
  'facilityProfile-formErrorRequiredField': 'This field is required',
  'facilityProfile-formInvalidNumberError': 'Invalid Number',
  'facilityProfileDetails-formPlaceholder': 'User input',
  'facilityProfileTeam-action-switchOwner': 'Change Owner',
  'facilityProfileTeam-reInviteModalTitle': 'Re-invite',
  'facilityProfileTeam-reinviteModalTitle': 'Re-invite',
  'helpdesk-card-confirmation-modal-title': 'Your request has been sent',
  'helpdesk-card-message-modal-queryLabel': 'Add details (please include email address)',
  'improve-onCourseReportsTileDescription': 'Custom sustainability reports can be prepared for you every year, including recommendations and carbon footprint.',
  'newPassword-formNewPasswordPlaceholder': 'Enter new password',
  'profile-changePasswordModalDescription': 'Your password has been changed successfully.',
  'profile-tabGeneralInfo-cropModalButton': 'Crop and Upload',
  'profile-tabSettingsCardTourDescription': 'Turning on this feature will play the introductory tour when you next log in',
  'question-set-modalAddAnwserPlaceholder': 'Type answer here',
  'registration-formAboutUsOptions-invite': 'Invite from colleagues',
  'review-AnnualDataCompletionWidgetTitle': 'Our Annual data',
  'teamIntroduction-card-staffIntro-title': 'Staff Intro Email',
  'communicate-card-teamIntroduction-title': 'Materials & Resources',
  'createFacility-modalPreview-description': 'You are about to create a new OnCourse facility account. Please check the following is correct:',
  'createFacility-modalPreview-profileLogo': 'Profile Logo',
  'data-grid-what-else-badge-cancel-button': ' Cancel',
  'documentLibrary-oncourseDocuments-title': 'OnCourse Documents',
  'documentLibrary-yourSubsectionCommunity': 'Community',
  'documentLibrary-yourSubsectionResources': 'Resources',
  'facility-greenFeeOptions-between-30-100': 'Between 30 - 100',
  'facility-historicLandUseOptions-natural': 'Natural habitat',
  'facilityProfile-tabFacilityDetailsTitle': 'Facility Details',
  'facilityProfileTeam-reInviteModalButton': 'Re-invite',
  'forgottenPassword-formInvalidEmailError': 'Email not valid',
  'helpdesk-card-message-modal-reasonLabel': 'Please select a reason',
  'profile-tabSettingsCardUnitsDescription': 'This is the unit of measurement used in question sets.',
  'question-set-guidance-multiChoice-multi': 'Please select any and all answers that apply.',
  'registration-formRoleOptions-management': 'Course Management',
  'teamIntroduction-card-websiteText-title': 'Website Text',
  'component-CircularPorgressCardIncomplete': 'Continue',
  'createFacility-modalPreview-emailAddress': 'Facility Email Address:',
  'createFacility-modalPreview-profileImage': 'Profile Image',
  'data-grid-what-else-badge-confirm-button': 'Confirm',
  'documentLibrary-uploadDocumentModalTitle': 'Upload Document',
  'documentLibrary-uploadDocumentModelTitle': 'Upload Document',
  'facility-historicLandUseOptions-cropland': 'Cropland',
  'facility-historicLandUseOptions-degraded': 'Degraded/brownfield',
  'facility-profile-page-formEmailNotUnique': 'Email not unique',
  'guidanceRecognition-guidance-description': 'Learn how to make sustainability claims credibly and confidently and use your social media channels, newsletters and website to highlight sustainability and climate action more visibly.',
  'guidanceRecognition-guidance-tile1-title': 'Marketing Sustainable Golf ',
  'guidanceRecognition-guidance-tile2-title': 'Social Media Tips',
  'guidanceRecognition-guidance-tile3-title': 'Communications Tips',
  'profile-tabSettingsCardNotificationTitle': 'Notifications',
  'registration-formAboutUsOptions-magazine': 'Magazine (print or online)',
  'registration-formDropdownOptions-default': 'Please choose',
  'registrationComplete-returnToLoginButton': 'Return to Login',
  'communicate-card-campaignKits-description': 'OnCourse campaign kits provide resources and guidance to run initiatives at your course on a range of important sustainability topics.',
  'documentLibrary-documentTable-description': 'Description',
  'documentLibrary-moveDocumentModalFormHead': 'Would you like to move this document?',
  'documentLibrary-storageSectionDescription': 'Chosen file will be stored in',
  'documentLibrary-uploadLocationOptionOther': 'Other',
  'facility-annualFeeOptions-below-1000-5000': 'Between 1000 - 5000',
  'facilityProfileDetails-facilityLand-title': 'Facility Land',
  'facilityProfileTeam-inviteModalEmailLabel': 'Email Address',
  'facilityProfileTeam-switchOwnerModalTitle': 'Change Owner',
  'helpdesk-reason-option-help-with-OnCourse': 'Help with OnCourse',
  'invalidRegistrationToken-ErrorDescription': 'The registration token is either invalid, or has been used before.<br/><br/>If you did not expect this message, please contact us using the e-mail below.',
  'newPassword-formRetypePasswordPlaceholder': 'Re-enter new Password',
  'profile-facility-change-modal-description': 'You are about to select: ',
  'profile-tabGeneralInfoCardRoleDescription': 'Add or update the primary role you have at the golf facility. If you are a member of more than one OnCourse account, you can add different roles for each, if needed. You can only add one role per facility account.',
  'registration-formAboutUsOptions-webSearch': 'Web Search',
  'teamIntroduction-card-teamworkGuide-title': 'Teamwork Guide',
  'campaignKits-card-footer-title-suggestions': 'Have suggestions for other useful materials?',
  'communicate-card-guidanceRecognition-title': 'Communications Guidance',
  'data-grid-what-else-badge-label-validation': 'max 250 characters',
  'facility-historicLandUseOptions-recreation': 'Site for another from of recreation',
  'facilityProfile-tabGeneralInformationTitle': 'General Information',
  'facilityProfileDetails-aboutFacility-Title': 'About the Facility',
  'facilityProfileTeam-switchOwnerModalButton': 'Change',
  'improve-onCourseRecognitionTileDescription': 'Make the most of your sustainability, consider getting recognised with GEO Certified and other distinctions coming soon.',
  'invalidPasswordResetToken-ErrorDescription': 'The reset password token is either invalid, or has been used before.<br/><br/>If you did not expect this message, please contact us using the e-mail below.',
  'profile-details-form-changePassword-button': 'Change Password',
  'profile-tabSettingsCardLanguageDescription': 'This is the language that OnCourse text will be displayed in.',
  'registration-formAboutUsOptions-conference': 'Conference',
  'documentLibrary-latestDocuments-description': 'View recently uploaded documents from everyone on your team.',
  'facilityProfile-formGeneralInformationTitle': 'Name, address and contact details',
  'facilityProfileDetails-dropdownOthers-Label': 'If not listed, please add below',
  'guidanceRecognition-recognition-description': "To get the most from your commitments and achievements, consider getting GEO Certified or becoming a Climate Leader. Please also join the sustainable golf community by sharing your favourite projects and results by creating 'Highlights' on sustainable.golf.",
  'guidanceRecognition-recognition-tile1-title': 'Marketing Certification',
  'guidanceRecognition-recognition-tile2-title': 'Communications with Community Groups',
  'guidanceRecognition-recognition-tile3-title': 'Credible Claims',
  'profile-tabGeneralInfoCardRoleAddRoleButton': 'Add Role',
  'registration-formAboutUsOptions-association': 'Golf association',
  'registration-formAboutUsOptions-socialMedia': 'Social Media',
  'review-AnnualDataCompletionWidgetButtonText': 'Add Year',
  'completeRegistration-formPasswordPlaceholder': 'Enter Password',
  'completeRegistration-formRetypePasswordLabel': 'Retype Password',
  'documentLibrary-yourSubsectionCommunications': 'Communications',
  'facility-maintenanceBudgetOptions-above-1.5M': 'Above 1.5 Million',
  'facilityProfileDetails-facilityCourses-title': 'Facility Courses',
  'facilityProfileDetails-facilityRevenue-title': 'Revenue and Costs (Optional & Confidential)',
  'facilityProfileTeam-inviteModalInviteAsLabel': 'Invite as',
  'facilityProfileTeam-removeModalDescription-1': 'Do you really want to delete',
  'facilityProfileTeam-removeModalDescription-2': 'from Facility?',
  'facilityProfileTeam-removeModalYourselfError': 'You cannot remove yourself from Facility',
  'facilityProfileTeam-switchOwnerDropdownLabel': 'Select',
  'profile-tabGeneralInfoCard-addRoleModalTitle': 'Add Role',
  'profile-tabSettingsCardTourToggleDescription': 'Show Tour on login?',
  'communicate-card-teamIntroduction-description': 'Inform staff and committee members about your OnCourse efforts and what sustainable golf means.',
  'communicate-card-teamintroduction-description': 'Get your staff, committees and golfers involved in the sustainability journey.',
  'documentLibrary-moveDocumentModalFormSubTitle': 'Current location',
  'documentLibrary-oncourseDocuments-description': 'Documents uploaded by the OnCourse team',
  'documentLibrary-uploadDocumentModalFormButton': 'Upload',
  'facilityProfileDetails-aboutFacility-subTitle': 'Type of Facility',
  'facilityProfileDetails-formErrorRequiredField': 'This field is required',
  'facilityProfileTeam-inviteModalIviteTextLabel': 'Invite (note: invites expire after 5 days)',
  'forgottenPassword-formEmailAddressPlaceholder': 'Enter email address',
  'helpdesk-card-confirmation-modal-description1': 'Your request has been sent to the team at GEO Foundation.',
  'helpdesk-card-confirmation-modal-description2': 'We will reply soon.',
  'profile-tabGeneralInfoCard-addRoleModalButton': 'Add Role',
  'question-AddAnswer-errorMessage-maxlength-250': 'New answer cannot exceed 250 characters',
  'displayMaterials-card-footer-title-suggestions': 'Have suggestions for other useful materials?',
  'facility-maintenanceBudgetOptions-Below-500000': 'Below 500,000',
  'facilityProfileDetails-facilityBuildings-title': 'Facility Buildings',
  'facilityProfileDetails-formErroIncorrectFields': 'Please check the form and correct the missing fields',
  'facilityProfileTeam-reInviteModalDescription-1': 'Do you want to re-invite',
  'facilityProfileTeam-reInviteModalDescription-2': 'to your facility?',
  'improve-onCourseSupportPlanningTileDescription': 'Additional support is available either to get your team started, analyse your reports or plan for next steps.',
  'profile-tabGeneralInfoCardPersonalDetailsEmail': 'Email Address',
  'profile-tabGeneralInfoCardPersonalDetailsTitle': 'Personal Details',
  'profile-tabSettingsCardLanguageDropDownOptions': 'English',
  'profile-tabSettingsCardNotificationDescription': 'OnCourse Notifications',
  'teamIntroduction-card-footer-title-suggestions': 'Have suggestions for other useful materials?',
  'facilityProfile-formGeneralInformation-vatLabel': 'VAT Number (optional)',
  'facilityProfileDetails-facilityCourses-parLabel': 'Par',
  'facilityProfileTeam-inviteModalEmailPlaceholder': 'Email Address',
  'facilityProfileTeam-switchOwnerModalDescription': 'Do you want to change the Owner of your facility?',
  'profile-measurementUnitOptions-british-imperial': 'British Imperial',
  'profile-tabGeneralInfoCardRoleTableDeleteButton': 'Delete',
  'registration-formRoleOptions-commercial-manager': 'Tournaments Commercial Manager',
  'registration-formRoleOptions-tournament-manager': 'Tournament Manager',
  'communicate-card-guidanceRecognition-description': 'Tips for sustainability communications and getting the most from your achievements.',
  'facilityProfile-formGeneralInformation-cityLabel': 'City',
  'facilityProfile-formGeneralInformation-logoLable': 'Upload logo',
  'facilityProfileDetails-aboutFacility-description': 'Select all that apply',
  'facilityProfileDetails-facilityCourses-AddButton': 'Add Another Course',
  'facilityProfileDetails-facilityCourses-nameLabel': 'Name of course',
  'profile-tabGeneralInfoCard-addRoleModalRoleLabel': 'Your Role',
  'registration-formRoleOptions-facility-management': 'Facility/Club Management',
  'registration-formRoleOptions-tournament-director': 'Tournament Director',
  'documentLibrary-uploadDocumentModalFormTitleLabel': 'Description',
  'documentLibrary-uploadLocationOptionNatureHabitat': 'Nature / Habitats & Biodiversity',
  'facilityProfile-formGeneralInformation-emailLabel': 'Facility Email Address',
  'profile-tabGeneralInfoCardPersonalDetailsLastName': 'Last Name',
  'registration-formRoleOptions-tournament-assistant': 'Tournament Assistant',
  'completeRegistration-formRetypePasswordPlaceholder': 'Re-enter password',
  'documentLibrary-uploadLocationOptionNatureTurgrass': 'Nature / Turfgrass',
  'documentLibrary-uploadLocationOptionResourcesWater': 'Resources / Water',
  'facilityProfileDetails-facilityLand-totalAreaLabel': 'Total area of golf course, clubhouse, parking and maintenance facility',
  'helpdesk-reason-option-request-a-consultation-call': 'Request a consultation call',
  'profile-tabGeneralInfoCardPersonalDetailsFirstName': 'First Name',
  'registration-formRoleOptions-tournament-consultant': 'Tournament Consultant',
  'documentLibrary-uploadLocationOptionNaturePollution': 'Nature / Pollution Prevention',
  'documentLibrary-uploadLocationOptionResourcesEnergy': 'Resources / Energy',
  'facility-maintenanceBudgetOptions-between-500k-1.5M': 'Between 500,000 - 1.5 Million',
  'facilityProfile-formGeneralInformation-countryLabel': 'Country',
  'facilityProfileDetails-facilityCourses-removeButton': 'Remove',
  'facilityProfileTeam-reInviteModalModalDescription-2': 'to your facility?',
  'profile-tabGeneralInfoCard-changePasswordModalTitle': 'Change Password',
  'profile-tabGeneralInfoCardRoleTableHeaderLeftColumn': 'Facility',
  'profile-tabSettingsCardNotificationEmailDescription': 'Email Notification',
  'registration-formRoleOptions-communications-manager': 'Tournament Communications Manager',
  'registration-formRoleOptions-sustainability-manager': 'Tournament Sustainability Manager',
  'CalculatingQuestion-calculated-subtotals-description': 'The totals below are calculated from the details provided above.',
  'documentLibrary-uploadLocationOptionCommunityGolfers': 'Community / Golfers and employees',
  'facilityProfile-formGeneralInformation-address1Label': 'Address Line 1',
  'facilityProfile-formGeneralInformation-address2Label': 'Address Line 2',
  'facilityProfile-formGeneralInformation-postcodeLabel': 'Zip/Postcode',
  'facilityProfileDetails-facilityRevenue-greenFeeLabel': 'Peak season green fee, 18-hole rate',
  'facilityProfileTeam-inviteModalInviteTextPlaceholder': 'Invitation text',
  'profile-tabGeneralInfoCard-addRoleModalFacilityLabel': 'Facility Name',
  'profile-tabGeneralInfoCardRoleTableHeaderRightColumn': 'Role',
  'documentLibrary-uploadLocationOptionCommunityOutreach': 'Community / Outreach',
  'facilityProfile-formGeneralInformation-cropModalTitle': 'Crop Image and Upload',
  'facilityProfile-formGeneralInformation-vatPlaceholder': 'VAT Number (optional)',
  'facilityProfileDetails-facilityLand-surroundingsLabel': 'Golf course surroundings',
  'facilityProfileDetails-facilityRevenue-annualFeeLabel': 'Membership annual fees',
  'profile-tabGeneralInfoCardPersonalDetailsProfileImage': 'Upload Photo',
  'documentLibrary-uploadLocationOptionResourcesMaterials': 'Resources / Materials',
  'facilityProfile-formGeneralInformation-cityPlaceholder': 'City',
  'facilityProfile-formGeneralInformation-cropModalButton': 'Crop and Upload',
  'facilityProfile-formGeneralInformationImageUploadTitle': 'Logo and Profile Image',
  'facilityProfile-formGeneralInformationMeasureUnitTitle': 'Change Default Metric',
  'facilityProfileDetails-facilityCourses-yearOpenedLabel': 'Year opened',
  'profile-measurementUnitOptions-united-states-customary': 'United States Customary',
  'documentLibrary-uploadDocumentModalFormTitlePlaceholder': 'Enter a brief description',
  'facilityProfile-formGeneralInformation-emailPlaceholder': 'Facility Email Address',
  'facilityProfileDetails-facilityBuildings-otherAreaLabel': 'All other structures',
  'facilityProfileDetails-facilityRevenue-petrolCostsLabel': 'Petrol (money/liter or gallon)',
  'facilityProfile-formGeneralInformation-facilityNameLabel': 'Facility Name',
  'facilityProfile-formGeneralInformation-facilityTypeLabel': 'Type of Facility',
  'facilityProfile-formGeneralInformation-profileImageLabel': 'Upload Image',
  'facilityProfileDetails-facilityBuildings-indoorAreaLabel': 'Indoor Area',
  'facilityProfileDetails-facilityBuildings-otherAreaLable ': 'Other',
  'facilityProfileDetails-facilityCourses-courseLengthLabel': 'Course length',
  'facilityProfileDetails-facilityCourses-removeModal-title': 'Remove course',
  'facilityProfileDetails-facilityLand-historicLandUseLable': 'Historical land use',
  'facilityProfileDetails-facilityRevenue-labourHourlyLabel': 'Average labour costs (money/hour)',
  'documentLibrary-uploadLocationOptionApproachAnnualActions': 'Approach / Annual actions',
  'facilityProfile-formGeneralInformation-contactNumberLabel': 'Contact Number',
  'facilityProfileDetails-aboutFacility-managementGroupLabel': 'Business owner / management group (if applicable)',
  'facilityProfileDetails-facilityCourses-numberOfHolesLabel': 'Number of holes',
  'facilityProfileDetails-facilityCourses-removeModal-button': 'Remove',
  'facilityProfileDetails-facilityCourses-roundsPerYearLabel': 'Rounds per year',
  'profile-tabGeneralInfoCardPersonalDetailsEmailPlaceholder': 'Email Address',
  'documentLibrary-uploadLocationOptionApproachGettingStarted': 'Approach / Getting started',
  'facilityProfile-formGeneralInformation-address1Placeholder': 'Address Line 1',
  'facilityProfile-formGeneralInformation-address2Placeholder': 'Address Line 2',
  'facilityProfile-formGeneralInformation-postcodePlaceholder': 'Zip/Postcode',
  'facilityProfileDetails-facilityBuildings-practiceAreaLabel': 'Practice area building',
  'documentLibrary-uploadDocumentModalFormSectionDropdownLabel': 'Where would you like to store this file?',
  'documentLibrary-uploadLocationOptionCommunityCommunications': 'Community / Communications',
  'facilityProfileDetails-facilityBuildings-clubHouseAreaLabel': 'Clubhouse(s) inc. pro-shop',
  'facilityProfileDetails-facilityRevenue-pesticideBudgetLabel': 'Estimated annual pesticide budget',
  'facilityProfileDetails-facilityRevenue-fertiliserBudgetLabel': 'Estimated annual fertiliser budget',
  'profile-tabGeneralInfoCardPersonalDetailsLastNamePlaceholder': 'Last Name',
  'facilityProfileDetails-aboutFacility-tournamentOrganiserLabel': 'Hosts tournaments organised by (if applicable)',
  'facilityProfileDetails-facilityBuildings-maintenanceAreaLabel': 'Maintenance facility',
  'facilityProfileDetails-facilityRevenue-maintenanceBudgetLabel': 'Maintenance budget',
  'profile-tabGeneralInfoCardPersonalDetailsFirstNamePlaceholder': 'First Name',
  'documentLibrary-uploadLocationOptionApproachFurtherInformation': 'Approach / Further information',
  'facilityProfile-formGeneralInformation-facilityNamePlaceholder': 'Facility Name',
  'facilityProfileDetails-facilityCourses-removeModal-description': 'You are about to remove the course with the name',
  'facilityProfile-formGeneralInformation-contactNumberPlaceholder': 'Contact Number',
};

module.exports = stringsFallback;
