/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import RegistrationForm from '../../../components/registration/registration-form/RegistrationForm';
import Card from '../../../components/core/card/Card';
import InternalLink from '../../../components/core/link/InternalLink';
import Footer from '../../../components/core/footer/Footer';
import BackgroundImage from '../../../components/core/backgroundImage/BackgroundImage';
import registrationService from '../../../services/registration/registration-service';
import errorCodes from '../../../lib/error-codes';
import HTTPError from '../../../lib/errors/http-error';
import OnCourseLogo from '../../../assets/OnCourse_Logo.svg';

import { get } from '../../../lib/strings';

import './RegistrationPage.scss';

const RegistrationPage = () => {
  const [registrationError, setRegistrationError] = useState(undefined);
  const [newUserEmail, setNewUserEmail] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    async function validateAndSetToken() {
      // Redirect to invalid token page if no token in query params.
      const params = qs.parse(history.location.search, { ignoreQueryPrefix: true });
      const { token, emailAddress } = params;

      if (emailAddress && !token) {
        history.push('/invalid-registration-token');
        // we return a clean up function to stop further state changes
        return () => {};
      }

      if (emailAddress) {
        // Redirect to invalid token page if the token is not valid
        const result = await registrationService.validateToken(token);

        if (!result.data.valid) {
          history.push('/invalid-registration-token');
          return () => {};
        }

        // All looks good save the email
        setNewUserEmail(emailAddress);
      }
    }

    // If the user lands on this page from the invite facility link
    // Check if the token is valid
    validateAndSetToken();
  }, [newUserEmail]);

  const performRegistration = async ({
    dropdownHearAbout,
    dropdownLanguage,
    dropdownStatus,
    checkBoxCorrispondence,
    firstName,
    lastName,
    email,
  }) => {
    setRegistrationError(undefined);

    const result = await registrationService.newRegistration(dropdownHearAbout,
      dropdownLanguage,
      dropdownStatus,
      checkBoxCorrispondence,
      firstName,
      lastName,
      email);

    if (result.ok) {
      return history.push({
        pathname: '/registration-link-sent',
        search: `?lang=${dropdownLanguage}`,
        state: { email },
      });
    }

    if (result.data.field === 'email_address' && result.data.code === errorCodes.NOT_UNIQUE) {
      setRegistrationError(() => get('registration-formEmailAddressError'));
    } else {
      throw new HTTPError(result.status, result.data);
    }
  };

  return (
    <div className="pages__registration-page">
      <BackgroundImage />
      <img className="login-title" src={OnCourseLogo} alt="OnCourse_logo" />

      <Card
        className="registration-form-card"
        title={get('registration-Title')}
        titleClassName="register-title"
        hasOverlay
      >
        <RegistrationForm
          onComplete={performRegistration}
          registrationError={registrationError}
          newUserEmail={newUserEmail}
        />

        <div className="registration-return">
          <InternalLink to="/login" className="registration-return-text">
            {get('registration-formCancelButton')}
          </InternalLink>
        </div>
      </Card>
      <Footer />
    </div>
  );
};

export default RegistrationPage;
