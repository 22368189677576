import React, {
  useState, useContext, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';
import { Accordion } from 'react-bootstrap';
import { useForm, useFieldArray } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalContext';
import Alert from '../../core/alert/Alert';
import Modal from '../../core/modal/Modal';
import DropdownField from '../../core/form/DropdownField';
import GenericDropdownField from '../../core/form/GenericDropdownField';
import CheckBoxField from '../../core/form/CheckBoxField';
import AccordionCard from '../../core/accordionCard/AccordionCard';
import TextInputField from '../../core/form/TextInputField';
import Button from '../../core/button/Button';
import Label from '../../core/label/Label';
import arrayUtils from '../../../utils/array';
import crossImg from '../../../assets/cross.svg';
import { get } from '../../../lib/strings';
import HiddenField from '../../core/form/HiddenField';
import RouteLeavingGuard from '../../warningUnSaveGuardModal/RouteLeavingGuard';
import { defaultUnits } from '../../../lib/utils';

import './FacilityDetailsForm.scss';

const FacilityDetailsForm = React.forwardRef(({
  orgDetails = {}, tournaments, groups, onComplete, error,
}, ref) => {
  const [removeCourseModal, updateRemoveCourseModal] = useState(false);
  const [courseIndex, setCourseIndex] = useState(null);
  const [showManagementGroupFreeText, setShowManagementGroupFreeText] = useState(orgDetails.management_group && orgDetails.management_group.indexOf('Other') > 0);
  const [showTournamentOrganiserFreeText, setShowTournamentOrganiserFreeText] = useState(orgDetails.tournament_organiser && orgDetails.tournament_organiser.indexOf('Other') > 0);
  const { org } = useContext(GlobalContext);

  // Dropdown options
  const GREEN_FEE = arrayUtils.getGreenFeeOptions();
  const ANNUAL_FEE = arrayUtils.getAnnualFeeOptions();
  const MAINTENANCE_BUDGET = arrayUtils.getMaintenanceBudgetOptions();
  const HISTORIC_LAND_USE = arrayUtils.getHistoricLandUseOptions();
  const SURROUNDINGS = arrayUtils.getSurroundingsOptions();
  const FACILITY_TYPES = arrayUtils.getFacilityTypeOptions();
  const TOURNAMENTS_HOSTS = arrayUtils.getTournamentHostseOptions(tournaments);
  const OWNERS = arrayUtils.getOrganisationGroupsOptions(groups);
  // change to org
  const DEFAULT_USER_UNITS = defaultUnits(org.measurement_unit);

  // Strings
  const formOptionsDefaultValue = get('facilityProfileDetails-defaultOption');
  const formPlaceholder = get('facilityProfileDetails-formPlaceholder');
  const formIndoorLabel = get('facilityProfileDetails-facilityBuildings-indoorAreaLabel');

  // Labels
  const currencyLabel = DEFAULT_USER_UNITS.CURRENCY;
  const areaUnitsLabel = DEFAULT_USER_UNITS.AREA;
  const areaOutdoorUnitsLabel = DEFAULT_USER_UNITS.AREA_OUTDOOR;
  const distanceUnitLabel = DEFAULT_USER_UNITS.DISTANCE;

  // Form default values
  const defaultValues = {
    ...orgDetails,
    green_fee: orgDetails.green_fee
      ? arrayUtils.getOptionLabel(GREEN_FEE, orgDetails.green_fee)
      : formOptionsDefaultValue,
    annual_fee: orgDetails.annual_fee
      ? arrayUtils.getOptionLabel(ANNUAL_FEE, orgDetails.annual_fee)
      : formOptionsDefaultValue,
    maintenance_budget: orgDetails.maintenance_budget
      ? arrayUtils.getOptionLabel(MAINTENANCE_BUDGET, orgDetails.maintenance_budget)
      : formOptionsDefaultValue,
    historic_land_use: orgDetails.historic_land_use
      ? arrayUtils.getOptionLabel(HISTORIC_LAND_USE, orgDetails.historic_land_use)
      : formOptionsDefaultValue,
    surroundings: orgDetails.surroundings
      ? arrayUtils.getOptionLabel(SURROUNDINGS, orgDetails.surroundings)
      : formOptionsDefaultValue,
    club_house_area_unit: areaUnitsLabel,
    maintenance_area_unit: areaUnitsLabel,
    total_area_unit: areaOutdoorUnitsLabel,
    practice_area_unit: areaUnitsLabel,
    other_area_unit: areaUnitsLabel,
    petrol_costs_unit: currencyLabel,
    pesticide_budget_unit: currencyLabel,
    labour_hourly_unit: currencyLabel,
    fertiliser_budget_unit: currencyLabel,
    // If no courses yet just show the empty form
    courses: orgDetails.courses && orgDetails.courses.length === 0 ? [{ name: '' }] : orgDetails.courses,
  };

  const {
    register, handleSubmit, errors, control,
    getValues,
    formState: {
      dirtyFields,
    },
  } = useForm({
    defaultValues,
  });

  // Array of courses
  const { fields, append, remove } = useFieldArray({
    name: 'courses',
    control,
  });

  // Array of facility types
  const { fields: typesFields } = useFieldArray({
    control,
    name: 'types',
  });
  const history = useHistory();

  const fieldsEdited = dirtyFields;
  const isDirtyAlt = !!Object.keys(dirtyFields).length;

  useImperativeHandle(ref, () => ({ getDirtyState() { return isDirtyAlt; } }));

  const onSubmit = (data) => {
    const typesFormatted = [];
    if (data && data.types) {
      for (let i = 0; i < data.types.length; i += 1) {
        const value = Object.keys(data.types[i])[0];
        typesFormatted.push({
          value,
          selected: data.types[i][value],
        });
      }
    }

    const mappedDetails = {
      ...data,
      historic_land_use: typeof fieldsEdited.historic_land_use !== 'undefined' ? data.historic_land_use : arrayUtils.getOptionKey(HISTORIC_LAND_USE, data.historic_land_use),
      surroundings: typeof fieldsEdited.surroundings !== 'undefined' ? data.surroundings : arrayUtils.getOptionKey(SURROUNDINGS, data.surroundings),
      green_fee: typeof fieldsEdited.green_fee !== 'undefined' ? data.green_fee : arrayUtils.getOptionKey(GREEN_FEE, data.green_fee),
      annual_fee: typeof fieldsEdited.annual_fee !== 'undefined' ? data.annual_fee : arrayUtils.getOptionKey(ANNUAL_FEE, data.annual_fee),
      maintenance_budget: typeof fieldsEdited.maintenance_budget !== 'undefined' ? data.maintenance_budget : arrayUtils.getOptionKey(MAINTENANCE_BUDGET, data.maintenance_budget),
      types: typesFormatted,
    };

    onComplete(mappedDetails);
  };

  const onClickRemoveCourse = (index) => {
    setCourseIndex(index);
    updateRemoveCourseModal(true);
  };

  return (
    <div className="components_facility-details-form">
      {(!_.isEmpty(errors) || error) && (
        <Alert data-cy="registration-error" className="profile-facility-team-modal-alert" variant="danger">
          {!_.isEmpty(errors) ? get('facilityProfileDetails-formErroIncorrectFields') : error}
        </Alert>
      )}
      <Accordion>
        <Form
          id="my-form"
          className="facility-details-form-wrapper"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/*  About the Facility Card */}
          <AccordionCard
            headerTitle={get('facilityProfileDetails-aboutFacility-Title')}
            eventKey={1}
          >
            <Label text={get('facilityProfileDetails-aboutFacility-subTitle')} htmlFor="type" />
            <p className="facility-details-form-text"><i>{get('facilityProfileDetails-aboutFacility-description')}</i></p>
            <div className="facility-details-form-checkbox-wrapper">
              {typesFields.map((item, index) => {
                return (
                  <CheckBoxField
                    key={item.value}
                    name={`types[${index}].${item.value}`}
                    defaultValue={item.selected}
                    label={arrayUtils.getOptionLabel(FACILITY_TYPES, item.value)}
                    control={control}
                    rounded
                    className="check-box-input"
                  />
                );
              })}
            </div>

            <GenericDropdownField
              control={control}
              name="management_group"
              label={get('facilityProfileDetails-aboutFacility-managementGroupLabel')}
              className="input-field-half-row"
              options={OWNERS}
              isSearchable
              errors={errors}
              handleChange={(e) => {
                if (e && e.indexOf('Other') > 0) {
                  setShowManagementGroupFreeText(true);
                } else if (showManagementGroupFreeText) {
                  setShowManagementGroupFreeText(false);
                }
              }}
            />

            {showManagementGroupFreeText
              ? (
                <TextInputField
                  name="organisation_owner_other"
                  label={get('facilityProfileDetails-dropdownOthers-Label')}
                  placeholder={formPlaceholder}
                  className="input-field-row"
                  data-cy="area-input"
                  ref={register({
                    maxLength: 255,
                    required: getValues('management_group') && getValues('management_group').indexOf('Other') > 0,
                  })}
                  errors={errors}
                />
              )
              : null}

            <GenericDropdownField
              control={control}
              name="tournament_organiser"
              label={get('facilityProfileDetails-aboutFacility-tournamentOrganiserLabel')}
              className="input-field-half-row"
              options={TOURNAMENTS_HOSTS}
              isSearchable
              errors={errors}
              handleChange={(e) => {
                if (e && e.indexOf('Other') > 0) {
                  setShowTournamentOrganiserFreeText(true);
                } else if (showTournamentOrganiserFreeText) {
                  setShowTournamentOrganiserFreeText(false);
                }
              }}
            />

            {showTournamentOrganiserFreeText
              ? (
                <TextInputField
                  name="tournament_host_other"
                  label={get('facilityProfileDetails-dropdownOthers-Label')}
                  placeholder={formPlaceholder}
                  className="input-field-row"
                  data-cy="area-input"
                  ref={register({
                    required: getValues('tournament_organiser') && getValues('tournament_organiser').indexOf('Other') > 0,
                    maxLength: 255,
                  })}
                  errors={errors}
                />
              )
              : null}

          </AccordionCard>

          {/*  Facility Land Card  */}
          <AccordionCard
            headerTitle={get('facilityProfileDetails-facilityLand-title')}
            eventKey={2}
          >
            <div className="facility-details-form-dropdown-wrapper">
              <DropdownField
                control={control}
                name="historic_land_use"
                label={get('facilityProfileDetails-facilityLand-historicLandUseLable')}
                className="input-field-half-row"
                options={HISTORIC_LAND_USE}
                errors={errors}
              />

              <DropdownField
                control={control}
                name="surroundings"
                label={get('facilityProfileDetails-facilityLand-surroundingsLabel')}
                className="input-field-half-row"
                options={SURROUNDINGS}
                errors={errors}
              />
            </div>

            <TextInputField
              type="number"
              step={0.1}
              name="total_area"
              label={get('facilityProfileDetails-facilityLand-totalAreaLabel')}
              placeholder={formPlaceholder}
              units={defaultValues.total_area_unit}
              className="input-field-row"
              data-cy="area-input"
              ref={register({
                required: false,
              })}
              errors={errors}
            />
          </AccordionCard>

          {/*  Facility Buildings  */}

          <AccordionCard
            headerTitle={get('facilityProfileDetails-facilityBuildings-title')}
            eventKey={3}
          >
            <p className="facility-details-form-text --semibold p-0 m-0">{get('facilityProfileDetails-facilityBuildings-clubHouseAreaLabel')}</p>
            <TextInputField
              type="number"
              step={0.1}
              name="club_house_area"
              label={formIndoorLabel}
              placeholder={formPlaceholder}
              units={defaultValues.club_house_area_unit}
              className="input-field-row pt-0 mt-0"
              data-cy="indoor-area-input"
              ref={register({
                required: false,
              })}
              errors={errors}
            />

            <p className="facility-details-form-text --semibold p-0 m-0">{get('facilityProfileDetails-facilityBuildings-maintenanceAreaLabel')}</p>
            <TextInputField
              type="number"
              step={0.1}
              name="maintenance_area"
              label={formIndoorLabel}
              placeholder={formPlaceholder}
              units={defaultValues.maintenance_area_unit}
              className="input-field-row pt-0 mt-0"
              data-cy="indoor-area-input"
              ref={register({
                required: false,
              })}
              errors={errors}
            />

            <p className="facility-details-form-text --semibold p-0 m-0">{get('facilityProfileDetails-facilityBuildings-practiceAreaLabel')}</p>
            <TextInputField
              type="number"
              step={0.1}
              name="practice_area"
              label={formIndoorLabel}
              placeholder={formPlaceholder}
              units={defaultValues.practice_area_unit}
              className="input-field-row pt-0 mt-0"
              data-cy="indoor-area-input"
              ref={register({
                required: false,
              })}
              errors={errors}
            />

            <p className="facility-details-form-text p-0 m-0 --semibold">{get('facilityProfileDetails-facilityBuildings-otherAreaLabel')}</p>
            <TextInputField
              type="number"
              step={0.1}
              name="other_area"
              label={formIndoorLabel}
              placeholder={formPlaceholder}
              units={defaultValues.other_area_unit}
              className="input-field-row pt-0 mt-0"
              data-cy="indoor-area-input"
              ref={register({
                required: false,
              })}
              errors={errors}
            />

          </AccordionCard>

          {/*  Facility Courses Card  */}
          <AccordionCard
            headerTitle={get('facilityProfileDetails-facilityCourses-title')}
            eventKey={4}
            bodyClassName="facility-details-form-courses-body"
          >
            {fields.map((field, index) => {
              return (
                <div key={field.id} className={`facility-details-form-courses ${index !== 0 && '--marginTop'}`}>
                  {index !== 0 ? (
                    <div className="facility-details-form-remove-cross" onClick={() => onClickRemoveCourse(index)} aria-hidden>
                      <img src={crossImg} alt="remove" />
                    </div>
                  ) : null}
                  <div className="d-flex">
                    <div className="wrapper">
                      <div className="facility-details-form-courses-wrapper">
                        <HiddenField name={`courses.${index}.unit`} control={control} defaultValue={field.unit} />
                        <TextInputField
                          type="text"
                          name={`courses.${index}.name`}
                          defaultValue={`${field.name}`}
                          label={get('facilityProfileDetails-facilityCourses-nameLabel')}
                          placeholder={formPlaceholder}
                          className="input-text-field-half-row"
                          data-cy="indoor-area-input"
                          ref={register({
                            required: false,
                          })}
                          errors={errors.courses && errors.courses[index] ? { [`courses.${index}.name`]: errors.courses[index].name } : {}}
                        />

                        <TextInputField
                          type="number"
                          step={0.1}
                          name={`courses.${index}.length`}
                          defaultValue={`${field.length}`}
                          label={get('facilityProfileDetails-facilityCourses-courseLengthLabel')}
                          placeholder={formPlaceholder}
                          units={field.unit}
                          className="input-text-field-half-row"
                          data-cy="indoor-area-input"
                          ref={register({
                            required: false,
                          })}
                          errors={errors.courses && errors.courses[index] ? { [`courses.${index}.length`]: errors.courses[index].length } : {}}
                        />

                      </div>
                      <div className="facility-details-form-courses-wrapper">
                        <TextInputField
                          type="number"
                          step={0.1}
                          name={`courses.${index}.number_of_holes`}
                          defaultValue={`${field.number_of_holes}`}
                          label={get('facilityProfileDetails-facilityCourses-numberOfHolesLabel')}
                          placeholder={formPlaceholder}
                          className="input-field-small-row"
                          data-cy="indoor-area-input"
                          ref={register({
                            required: false,
                          })}
                          errors={errors.courses && errors.courses[index] ? { [`courses.${index}.number_of_holes`]: errors.courses[index].number_of_holes } : {}}
                        />

                        <TextInputField
                          type="number"
                          step={0.1}
                          name={`courses.${index}.year_opened`}
                          defaultValue={`${field.year_opened}`}
                          label={get('facilityProfileDetails-facilityCourses-yearOpenedLabel')}
                          placeholder={formPlaceholder}
                          className="input-field-small-row"
                          data-cy="indoor-area-input"
                          ref={register({
                            required: false,
                          })}
                          errors={errors.courses && errors.courses[index] ? { [`courses.${index}.year_opened`]: errors.courses[index].year_opened } : {}}

                        />

                        <TextInputField
                          type="number"
                          step={0.1}
                          name={`courses.${index}.par`}
                          defaultValue={`${field.par}`}
                          label={get('facilityProfileDetails-facilityCourses-parLabel')}
                          placeholder={formPlaceholder}
                          className="input-field-small-row"
                          data-cy="indoor-area-input"
                          ref={register({
                            required: false,
                          })}
                          errors={errors.courses && errors.courses[index] ? { [`courses.${index}.par`]: errors.courses[index].par } : {}}
                        />

                        <TextInputField
                          type="number"
                          step={0.1}
                          name={`courses.${index}.rounds_per_year`}
                          defaultValue={`${field.rounds_per_year}`}
                          label={get('facilityProfileDetails-facilityCourses-roundsPerYearLabel')}
                          placeholder={formPlaceholder}
                          className="input-field-small-row"
                          data-cy="indoor-area-input"
                          ref={register({
                            required: false,
                          })}
                          errors={errors.courses && errors.courses[index] ? { [`courses.${index}.rounds_per_year`]: errors.courses[index].rounds_per_year } : {}}
                        />
                      </div>
                    </div>

                    <div className="facility-details-form-remove-button-wrapper">
                      {index !== 0 ? (
                        <Button
                          className="facility-details-form-remove-button"
                          onClick={() => onClickRemoveCourse(index)}
                        >
                          <span className="facility-details-form-remove-button-text">{get('facilityProfileDetails-facilityCourses-removeButton')}</span>
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}

            <Button
              className="facility-details-form-add-another-button"
              onClick={() => append({
                name: '',
                length: '',
                number_of_holes: '',
                year_opened: '',
                par: '',
                rounds_per_year: '',
                unit: distanceUnitLabel,
              })}
            >
              {get('facilityProfileDetails-facilityCourses-AddButton')}
            </Button>
          </AccordionCard>

          {/*  Revenue and Costs (Confidential) */}
          <AccordionCard
            headerTitle={get('facilityProfileDetails-facilityRevenue-title')}
            eventKey={5}
            bodyClassName="facility-details-form-courses-body"
          >
            <div className="facility-details-form-courses">
              <div className="facility-details-form-dropdown-wrapper">
                <DropdownField
                  control={control}
                  name="green_fee"
                  label={get('facilityProfileDetails-facilityRevenue-greenFeeLabel')}
                  className="input-field-half-row"
                  options={GREEN_FEE}
                  errors={errors}
                />

                <DropdownField
                  control={control}
                  name="annual_fee"
                  label={get('facilityProfileDetails-facilityRevenue-annualFeeLabel')}
                  className="input-field-half-row"
                  options={ANNUAL_FEE}
                  errors={errors}
                />
              </div>

              <DropdownField
                control={control}
                name="maintenance_budget"
                label={get('facilityProfileDetails-facilityRevenue-maintenanceBudgetLabel')}
                className="input-field-half-row"
                options={MAINTENANCE_BUDGET}
                errors={errors}
              />
            </div>

            <div className="facility-details-form-courses mt-5">
              <TextInputField
                type="number"
                name="petrol_costs"
                label={get('facilityProfileDetails-facilityRevenue-petrolCostsLabel')}
                placeholder={formPlaceholder}
                units={defaultValues.petrol_costs_unit}
                className="input-field-row"
                data-cy="indoor-area-input"
                step={0.1}
                ref={register({
                  required: false,
                })}
                errors={errors}
              />

              <TextInputField
                type="number"
                step={0.1}
                name="labour_hourly"
                label={get('facilityProfileDetails-facilityRevenue-labourHourlyLabel')}
                placeholder={formPlaceholder}
                units={defaultValues.labour_hourly_unit}
                className="input-field-row"
                data-cy="indoor-area-input"
                ref={register({
                  required: false,
                })}
                errors={errors}
              />

              <TextInputField
                type="number"
                step={0.1}
                name="fertiliser_budget"
                label={get('facilityProfileDetails-facilityRevenue-fertiliserBudgetLabel')}
                placeholder={formPlaceholder}
                units={defaultValues.fertiliser_budget_unit}
                className="input-field-row"
                data-cy="indoor-area-input"
                ref={register({
                  required: false,
                })}
                errors={errors}
              />
              <TextInputField
                type="number"
                step={0.1}
                name="pesticide_budget"
                label={get('facilityProfileDetails-facilityRevenue-pesticideBudgetLabel')}
                placeholder={formPlaceholder}
                units={defaultValues.pesticide_budget_unit}
                className="input-field-row"
                data-cy="indoor-area-input"
                ref={register({
                  required: false,
                })}
                errors={errors}
              />
            </div>

          </AccordionCard>
        </Form>
      </Accordion>

      <Modal
        show={removeCourseModal}
        onClose={() => updateRemoveCourseModal(false)}
        heading={get('facilityProfileDetails-facilityCourses-removeModal-title')}
        bodyText={(
          <div>
            <p>{`${get('facilityProfileDetails-facilityCourses-removeModal-description')} ${fields[courseIndex] && fields[courseIndex].name ? fields[courseIndex].name : ''}`}</p>
          </div>
      )}
        footer={(
          <Button onClick={() => {
            remove(courseIndex);
            updateRemoveCourseModal(false);
          }}
          >
            {get('facilityProfileDetails-facilityCourses-removeModal-button')}
          </Button>
      )}
      />

      <RouteLeavingGuard
            // When should shouldBlockNavigation be invoked,
            // simply passing a boolean
            // (same as "when" prop of Prompt of React-Router)
        when={isDirtyAlt}
            // Navigate function
        navigate={(path) => history.push(path)}
            // Use as "message" prop of Prompt of React-Router
        // eslint-disable-next-line no-unused-vars
        shouldBlockNavigation={(location) => {
          // is able to filter specific location
          if (isDirtyAlt) return true;
          return false;
        }}
      />

    </div>
  );
});

FacilityDetailsForm.defaultProps = {
  onComplete: () => {},
  error: undefined,
  tournaments: [],
  groups: [],
};

FacilityDetailsForm.propTypes = {
  orgDetails: PropTypes.shape({
    id: PropTypes.number,
    organisation_id: PropTypes.number,
    historic_land_use: PropTypes.string,
    surroundings: PropTypes.string,
    management_group: PropTypes.string,
    tournament_organiser: PropTypes.string,
    total_area: PropTypes.number,
    total_area_unit: PropTypes.string,
    club_house_area: PropTypes.number,
    club_house_area_unit: PropTypes.string,
    maintenance_area: PropTypes.number,
    maintenance_area_unit: PropTypes.string,
    practice_area: PropTypes.number,
    practice_area_unit: PropTypes.string,
    other_area: PropTypes.number,
    other_area_unit: PropTypes.string,
    green_fee: PropTypes.string,
    annual_fee: PropTypes.string,
    maintenance_budget: PropTypes.string,
    petrol_costs: PropTypes.string,
    petrol_costs_unit: PropTypes.string,
    labour_hourly: PropTypes.string,
    labour_hourly_unit: PropTypes.string,
    fertiliser_budget: PropTypes.string,
    fertiliser_budget_unit: PropTypes.string,
    pesticide_budget: PropTypes.string,
    pesticide_budget_unit: PropTypes.string,
  }).isRequired,
  onComplete: PropTypes.func,
  tournaments: PropTypes.arrayOf(PropTypes.shape({})),
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.string,
};

export default FacilityDetailsForm;
