import React from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import Analytics from 'react-router-ga';
import Routes from './routes/Routes';
import GlobalContextProvider from './context/GlobalContext';

function App() {
  if (window.location.pathname.indexOf('error') !== -1 || window.location.pathname.indexOf('Error') !== -1) {
    return (
      <Router>
        <Analytics id="UA-108699088-1">
          <Routes error />
        </Analytics>
      </Router>
    );
  }
  return (
    <GlobalContextProvider>
      <Router>
        <Analytics id="UA-108699088-1">
          <Routes />
        </Analytics>
      </Router>
    </GlobalContextProvider>
  );
}

export default App;
