import api from '../../lib/http';

const convertImageFromSteam = (result) => {
  return `data:image/png;base64,${btoa(new Uint8Array(result.data).reduce((data, byte) => {
    return data + String.fromCharCode(byte);
  }, ''))}`;
};

export default {
  uploadProfilePhoto: async (
    userId,
    data,
    config,
  ) => {
    const result = await api(
      'post',
      `/app-user/${userId}/profile`,
      data,
      false,
      false,
      true,
      config,
    );

    return result;
  },

  getProfilePhoto: async (
    photoId,
  ) => {
    const result = await api(
      'get',
      `/app-user/profile/${photoId}`,
      {},
      false,
      false,
      true,
      { responseType: 'arraybuffer' },
    );

    let src = '';

    if (result.ok) {
      // Convert the array buffer into a base64
      src = convertImageFromSteam(result);
    }

    return src;
  },

  uploadOrganisationProfileLogo: async (
    organisationId,
    data,
    config,
  ) => {
    const result = await api(
      'post',
      `/organisation/${organisationId}/logo`,
      data,
      false,
      false,
      true,
      config,
    );

    return result;
  },

  uploadOrganisationProfileImage: async (
    organisationId,
    data,
    config,
  ) => {
    const result = await api(
      'post',
      `/organisation/${organisationId}/profile`,
      data,
      false,
      false,
      true,
      config,
    );

    return result;
  },

  getOrganisationPhoto: async (
    photoId,
  ) => {
    const result = await api(
      'get',
      `/organisation/profile/${photoId}`,
      {},
      false,
      false,
      true,
      { responseType: 'arraybuffer' },
    );

    let src = '';

    if (result.ok) {
      // Convert the array buffer into a base64
      src = convertImageFromSteam(result);
    }

    return src;
  },

  getPartnerLogo: async (
    id,
  ) => {
    const result = await api(
      'get',
      `/organisation/partner-logo/${id}`,
      {},
      false,
      false,
      true,
      { responseType: 'arraybuffer' },
    );

    let src = '';

    if (result.ok) {
      // Convert the array buffer into a base64
      src = convertImageFromSteam(result);
    }

    return src;
  },

  getFooterLogo: async (
    id,
  ) => {
    const result = await api(
      'get',
      `/organisation/footer-logo/${id}`,
      {},
      false,
      false,
      true,
      { responseType: 'arraybuffer' },
    );

    let src = '';

    if (result.ok) {
      // Convert the array buffer into a base64
      src = convertImageFromSteam(result);
    }

    return src;
  },

  getReviewIcon: async (
    id,
  ) => {
    const result = await api(
      'get',
      `/reviews/icon/${id}`,
      {},
      false,
      false,
      true,
      { responseType: 'arraybuffer' },
    );

    let src = '';

    if (result.ok) {
      // Convert the array buffer into a base64
      src = convertImageFromSteam(result);
    }

    return src;
  },
  getOncourseLogo: async (
    countryId,
  ) => {
    const result = await api(
      'get',
      `/organisation/oncourse-logo/${countryId}`,
      {},
      false,
      false,
      true,
      { responseType: 'arraybuffer' },
    );

    let src = '';

    if (result.ok) {
      // Convert the array buffer into a base64
      src = convertImageFromSteam(result);
    }

    return src;
  },
};
