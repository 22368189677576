import React from 'react';
import PropTypes from 'prop-types';

const RightPointer = ({ color }) => {
  return (
    <>
      <svg width="20px" height="34px" viewBox="0 0 20 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Right Facing Bracket</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polyline id="Path" stroke={color} strokeWidth="4" points="2.2077946 1.98181275 16.9056002 17.0210151 2.2077946 32.159676" />
        </g>
      </svg>
    </>
  );
};

RightPointer.defaultProps = {
  color: '#20AB75',
};
RightPointer.propTypes = {
  color: PropTypes.string,
};

export default RightPointer;
