import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import ForgottenPasswordLinkSentPage from '../pages/authentication/forgotten-password-page/ForgottenPasswordLinkSentPage';
import ForgottenPasswordPage from '../pages/authentication/forgotten-password-page/ForgottenPasswordPage';
import NewPasswordPage from '../pages/authentication/new-password-page/NewPasswordPage';
import CompleteRegistrationPage from '../pages/registration/complete-registration/CompleteRegistrationPage';
import InvalidRegistrationTokenPage from '../pages/registration/complete-registration/InvalidRegistrationTokenPage';
import RegistrationCompletePage from '../pages/registration/complete-registration/RegistrationCompletePage';
import LoginPage from '../pages/authentication/login-page/LoginPage';
import PasswordSuccessfullyResetPage from '../pages/authentication/password-successfully-reset-page/PasswordSuccessfullyResetPage';
import InvalidPasswordResetTokenPage from '../pages/authentication/new-password-page/InvalidPasswordResetTokenPage';
import RegistrationPage from '../pages/registration/registration-page/RegistrationPage';
import RegistrationLinkSentPage from '../pages/registration/registration-link-sent/RegistrationLinkSentPage';
import TermsAndConditions from '../pages/termsAndConditions/TermsAndConditions';
import MaintenanceBanner from '../components/core/maintenanceBanner/MaintenanceBanner';

const LoggedOutRoutes = ({ notificationBanner }) => {
  return (
    <Route render={() => (
      <>
        {notificationBanner && notificationBanner.createdAt ? (
          <MaintenanceBanner
            notificationBanner={notificationBanner}
          />
        ) : null}
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route exact path="/login" component={LoginPage} />
          <Route
            exact
            path="/forgotten-password"
            component={ForgottenPasswordPage}
          />

          {/* Tournaments login flow routes */}
          <Route
            exact
            path="/tournaments/login"
            component={LoginPage}
          />
          <Route
            exact
            path="/tournaments/forgotten-password"
            component={ForgottenPasswordPage}
          />
          <Route
            exact
            path="/tournaments/forgotten-password/link-sent"
            component={ForgottenPasswordLinkSentPage}
          />
          <Route
            exact
            path="/tournaments/password-reset-success"
            component={PasswordSuccessfullyResetPage}
          />
          <Route
            exact
            path="/tournaments/new-password"
            component={NewPasswordPage}
          />
          <Route
            exact
            path="/tournaments/invalid-password-reset-token"
            component={InvalidPasswordResetTokenPage}
          />
          {/* End tournaments login flow routes */}

          <Route
            exact
            path="/forgotten-password/link-sent"
            component={ForgottenPasswordLinkSentPage}
          />
          <Route exact path="/new-password" component={NewPasswordPage} />
          <Route
            exact
            path="/complete-registration"
            component={CompleteRegistrationPage}
          />
          <Route
            exact
            path="/invalid-registration-token"
            component={InvalidRegistrationTokenPage}
          />
          <Route
            exact
            path="/registration-completed"
            component={RegistrationCompletePage}
          />
          <Route
            exact
            path="/password-reset-success"
            component={PasswordSuccessfullyResetPage}
          />
          <Route
            exact
            path="/invalid-password-reset-token"
            component={InvalidPasswordResetTokenPage}
          />
          <Route exact path="/registration-page" component={RegistrationPage} />
          <Route
            exact
            path="/registration-link-sent"
            component={RegistrationLinkSentPage}
          />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
        </Switch>
      </>
    )}
    />
  );
};

LoggedOutRoutes.defaultProps = {
  notificationBanner: {},
};

LoggedOutRoutes.propTypes = {
  notificationBanner: PropTypes.object,
};

export default LoggedOutRoutes;
