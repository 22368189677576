/* eslint-disable prefer-destructuring */
// eslint-disable-next-line import/no-cycle
import reviewServices from '../services/reviews/reviews-service';

const getQuestionSetIdBySlug = (slug, questionSetsRefData) => {
  const questionSet = questionSetsRefData.filter((set) => set.slug === slug);

  if (questionSet.length > 0) {
    return questionSet[0].id;
  }

  throw new Error('No id for slug.');
};

const getYearsFromAnswers = async (answers, options = {}) => {
  const yearsInAnswers = answers.map((answer) => answer.year);
  const availableYears = await reviewServices.availableYears(options.isTournament);

  if (options.missingYears) {
    return availableYears.map((year) => {
      if (!yearsInAnswers.includes(year)) {
        return {
          key: year,
          value: year,
          selected: false, // for selected year
        };
      }
      return null;
    }).filter((item) => item !== null);
  }
  return availableYears.map((year) => {
    if (yearsInAnswers.includes(year)) {
      return {
        key: year,
        value: year,
        selected: false,
      };
    }
    return null;
  }).filter((item) => item !== null);
};

const getPageDataFromQuestionObjectById = (questionData, sectionId) => {
  let sectionData = questionData.sections.filter((section) => section.id === sectionId);

  const dataToReturn = {
    section: {},
    level: '',
  };

  if (sectionData.length) {
    dataToReturn.section = sectionData[0];
    dataToReturn.level = 'sections';
    return dataToReturn;
  }

  // if we didn't get a match up there, we'll go a level deeper
  if (!sectionData.length) {
    sectionData = questionData.sections.map(
      (sect) => {
        return sect.subsections.filter(
          (subsect) => subsect.id === sectionId,
        );
      },
    ).filter((array) => array.length !== 0)
      .flat[0];
  }

  if (sectionData.length) {
    dataToReturn.section = sectionData;
    dataToReturn.level = 'subsections';
    return dataToReturn;
  }

  return dataToReturn;
};

const pullOutAnswersByYear = (answerData, year) => {
  const answers = answerData.filter((answer) => answer.year === year);
  return answers[0] || [];
};

export default {
  getQuestionSetIdBySlug,
  getYearsFromAnswers,
  getPageDataFromQuestionObjectById,
  pullOutAnswersByYear,

};
