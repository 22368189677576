import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PanelLinkLayout from '../../../components/layout/PanelLinkLayout';
import './OnCourseReports.scss';
import ImproveReportsPanel from '../../../components/core/tiles/improve-reports-panel/ImproveReportsPanel';
import { GlobalContext } from '../../../context/GlobalContext';
import countryService from '../../../services/country/country-service';
import contentService from '../../../services/content/content-service';
import mediaService from '../../../services/media/media-service';

const OnCourseReports = () => {
  const [content, updateContent] = useState('');
  const { org, user } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const [contentSections, setContentSections] = useState(undefined);
  const sectionId = pathname.split('/section/').pop().split('/')[0];

  const getPageContent = async () => {
    const country = await countryService.getIdByLookUpId(org.country_lookup_id);
    if (!country) return;
    const slug = pathname.split('/').pop();
    const pageContent = await
    contentService.getContentByPageAndLanguageCodeAndSectionId(
      slug, user.language, country.id, sectionId, org.organisation_id,
    );

    const page = await Promise.all(pageContent.map(async (item) => {
      const itemClone = { ...item };
      if (item.documents) {
        const documentLinks = itemClone.documents.map((_document) => {
          const document = { ..._document };
          document.link = `${process.env.REACT_APP_API_URL}/api/documents/document/${_document.id}`;
          document.text = `${_document.name}.${_document.fileType}`;
          return document;
        });
        itemClone.documentLinks = documentLinks;
      }

      itemClone.imgSource = itemClone.imageRecord
        ? await mediaService.getPartnerLogo(itemClone.imageRecord.id)
        : null;

      return itemClone;
    }));
    page.sort((a, b) => a.sortcode - b.sortcode);
    updateContent(page);
  };

  useEffect(() => {
    getPageContent();
  }, [pathname]);

  useEffect(() => {
    const getData = async () => {
      const result = await contentService.getContentSections(org.organisation_id, user.language);

      if (result) {
        // update local state
        setContentSections(result);
      }
    };

    getData();
  }, [pathname, org]);

  const createTiles = () => {
    const sections = [];
    let title = '';

    const section = contentSections.filter(
      (sectionData) => sectionData.slug === sectionId,
    );

    for (let i = 0; i < section.length; i += 1) {
      const data = section[i].contentData;
      title = section[i].name;

      for (let x = 0; x < data.length; x += 1) {
        sections.push({
          title: data[x].title,
          description: data[x].teaser,
          href: `/section/${sectionId}/${data[x].slug}`,
        });
      }
    }

    return {
      sections,
      title,
    };
  };

  if (!contentSections) return null;

  const tilesData = createTiles();

  return (
    <>
      <PanelLinkLayout
        title={tilesData.title}
        tiles={tilesData.sections}
        hasShadow={false}
      >

        {content ? (
          <ImproveReportsPanel
            content={content}
          />
        ) : null}
      </PanelLinkLayout>
    </>
  );
};

export default OnCourseReports;
