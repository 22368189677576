import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import PropTypes from 'prop-types';
import { get } from '../../../../lib/strings';
import './LinearProgressBar.scss';

const LinearProgressBar = ({ percentage, colour }) => {
  return (
    <>
      <div className="linear-progress-bar" style={{ color: colour }}>
        <div className="linear-progress-bar-container">
          <div className="linear-progress-bar-background" style={{ background: colour }} />
          <div className="linear-progress-bar-meter" style={{ width: `${percentage}%`, background: colour }} />
          <span className="linear-progress-bar-subtitle">{percentage === 100 ? get('bestpractices-status-complete') : get('bestpractices-status-pending')}</span>
        </div>
        <h6 className="--bold linear-progress-bar-percentage" style={{ color: colour }}>
          {percentage}%
        </h6>
      </div>
    </>
  );
};

LinearProgressBar.defaultProps = {
  percentage: '0',
  colour: null,
};

LinearProgressBar.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default LinearProgressBar;
