import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import themeType from '../../lib/consts/themeType';

function SGbgCircle(props) {
  const { theme } = useContext(GlobalContext);
  const colourFromTheme = theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471';
  return (
    <svg
      width={220}
      height={273}
      viewBox="0 0 220 273"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Combined Shape</title>
      <path
        d="M185.5 0C287.949 0 371 83.051 371 185.5S287.949 371 185.5 371 0 287.949 0 185.5 83.051 0 185.5 0zm1 69C122.711 69 71 120.711 71 184.5S122.711 300 186.5 300 302 248.289 302 184.5 250.289 69 186.5 69z"
        fill={colourFromTheme}
        fillRule="evenodd"
        opacity={0.08}
      />
    </svg>
  );
}

export default SGbgCircle;
