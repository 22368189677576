/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookie from 'universal-cookie';
import _ from 'lodash';
import authenticationService from '../services/authentication/authentication-service';
import stringService from '../services/string/string-service';
import languageService from '../services/language/language-service';
import userService from '../services/user/user-service';
import reviewsService from '../services/reviews/reviews-service';
import questionSetLib from '../lib/questionSets';
import organisationService from '../services/organisation/organisation-service';
import getCodeFromQueryString from '../lib/queryStringGetter';
import themeType from '../lib/consts/themeType';

const AUTH_COOKIE_NAME = 'vidatec-template-token';
const REFRESH_COOKIE_NAME = 'vidatec-template-refresh-token';
const cookies = new Cookie();

export const GlobalContext = React.createContext({
  user: null,
  org: null,
  loginHandler: () => {},
  getUserFromLocalStorage: () => {},
  updateDefaultOrgId: () => {},
});

const storeUserInLocalStorage = (userData) => {
  if (!userData) {
    localStorage.removeItem('user');
    return;
  }

  // Only storing what we actually need just now.
  const redactedUserData = {
    id: userData.id,
    email: userData.email,
    firstName: userData.firstName,
    lastName: userData.lastName,
    language: userData.language,
    image_id: userData.image_id,
    email_notifications: userData.email_notifications,
    measurement_unit: userData.measurement_unit,
    introductory_tour: userData.introductory_tour,
    allow_correspondence: userData.allow_correspondence,
    active_organisation_id: userData.active_organisation_id,
  };

  localStorage.setItem('user', JSON.stringify(redactedUserData));
};

const storeOrgInLocalStorage = (organisation) => {
  if (typeof organisation === 'undefined') {
    localStorage.removeItem('org');
    return;
  }
  localStorage.setItem('org', JSON.stringify(organisation));
};

const getUserFromLocalStorage = () => {
  const user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(user);
  }

  return undefined;
};
// generic get data from local by name; check falsy, return null
const getDataFromLocalStorage = (type) => {
  const org = localStorage.getItem(type);
  if (!org) return null;
  return JSON.parse(org);
};

const storeStringsInLocalStorage = (strings) => {
  if (!strings) {
    localStorage.removeItem('strings');
    return;
  }

  localStorage.setItem('strings', JSON.stringify(strings));
};

const getStringsFromLocalStorage = () => {
  const strings = localStorage.getItem('strings');

  if (strings) {
    return JSON.parse(strings);
  }

  return undefined;
};

const storeSupportedLanguageInLocalStorage = (strings) => {
  if (!strings) {
    localStorage.removeItem('SupportedLanguage');
    return;
  }

  localStorage.setItem('SupportedLanguage', JSON.stringify(strings));
};

const getSupportedLanguageFromLocalStorage = () => {
  const strings = localStorage.getItem('SupportedLanguage');

  if (strings) {
    return JSON.parse(strings);
  }

  return undefined;
};

// title(s) need to be language aware

const GlobalContextProvider = ({ children }) => {
  const [user, setUserData] = useState(getUserFromLocalStorage());
  const [strings, setStrings] = useState(getStringsFromLocalStorage());
  const [org, setOrg] = useState(getDataFromLocalStorage('org'));
  const [reviewLinks, updateReviewLinks] = useState([]);
  const [availableYearsForAnnualData, setAvailableYearsForAnnualData] = useState([]);
  const [theme, setTheme] = useState(themeType.FACILITY);
  const langFromQuery = getCodeFromQueryString();
  // eslint-disable-next-line max-len
  const [supportedLanguages, setSupportedLanguages] = useState(getSupportedLanguageFromLocalStorage());

  // get application strings.
  const getStrings = async (languageCode = 'en') => {
    storeStringsInLocalStorage();
    const response = await stringService.getStringByCountryCode(languageCode);

    setStrings(response.data.data);
    storeStringsInLocalStorage(response.data.data);
  };

  const getSupportedLanguages = async () => {
    storeSupportedLanguageInLocalStorage();
    const response = await languageService.getSupportedLanguage();
    const responseFormatted = languageService.supportedLanguageFormatted(response.data.languages);
    setSupportedLanguages(responseFormatted);
    storeSupportedLanguageInLocalStorage(responseFormatted);
  };

  useEffect(() => {
    let language = '';
    if (user && user.language) {
      language = user.language;
    } else if (langFromQuery) {
      language = langFromQuery;
    } else {
      language = 'en';
    }
    getStrings(language);
    getSupportedLanguages();
  }, []);
  const updateQuestionSetLinks = async () => {
    const questionSetLinks = await reviewsService.getQuestionSetReferenceData(
      user.language,
      org.organisation_id,
    );

    updateReviewLinks(questionSetLinks);

    const id = reviewsService.pullOutAnnualDataId(questionSetLinks);
    if (!id) return;
    const answers = await reviewsService.getAnswerSetsByOrg(org.organisation_id, id);
    const yearOptions = await questionSetLib.getYearsFromAnswers(answers, {
      isTournament: theme === themeType.TOURNAMENT,
    });
    setAvailableYearsForAnnualData(yearOptions);
  };

  const themeSetup = (type) => {
    setTheme(type);
  };

  useEffect(() => {
    if (org && org.organisation_type) {
      updateQuestionSetLinks();
      themeSetup(org.organisation_type.name === 'TOURNAMENT'
        ? themeType.TOURNAMENT : themeType.FACILITY);
    }
  }, [org, user]);

  useEffect(() => {
    const root = document.documentElement;
    const isTournament = theme === themeType.TOURNAMENT;
    if (root) {
      root.style.setProperty('--primary', isTournament ? '#1077C6' : '#4CA471');
      root.style.setProperty('--washedOutPrimary', isTournament ? '#bdd5e4' : '#e3eeea');
    }
  }, [theme]);

  const logoutHandler = async () => {
    let organisationType = null;
    // eslint-disable-next-line camelcase
    if (!_.isNull(org)) organisationType = org.organisation_type;
    storeUserInLocalStorage(undefined);
    storeOrgInLocalStorage(undefined);
    await cookies.remove(AUTH_COOKIE_NAME);
    await cookies.remove(REFRESH_COOKIE_NAME);

    setUserData(null);
    setOrg(null);
    updateReviewLinks([]);
    setAvailableYearsForAnnualData([]);
    window.location.replace(`${organisationType && organisationType.name === 'TOURNAMENT' ? 'tournaments' : ''}/login`);
  };

  // login handler, utilised by the login screen.
  const loginHandler = async (email, password, isTournamentLogin) => {
    // Clear up any existing sessions before attempting to login
    storeUserInLocalStorage(undefined);
    await cookies.remove(AUTH_COOKIE_NAME);
    await cookies.remove(REFRESH_COOKIE_NAME);
    setUserData(null);

    const response = await authenticationService.login(email, password, isTournamentLogin);

    // Start the token exchange
    if (response.data.refresh_token) {
      const result = await authenticationService.tokenExchange(response.data.refresh_token);

      if (result.ok && result.data.accessToken) {
        await cookies.set(AUTH_COOKIE_NAME, result.data.accessToken, { path: '/' });
        await cookies.set(REFRESH_COOKIE_NAME, result.data.refreshToken, { path: '/' });
      }
    }

    const userData = {
      id: response.data.user.id,
      email: response.data.user.email_address,
      firstName: response.data.user.first_name,
      lastName: response.data.user.last_name,
      language: response.data.user.language,
      image_id: response.data.user.image_id,
      email_notifications: response.data.user.email_notifications,
      measurement_unit: response.data.user.measurement_unit,
      introductory_tour: response.data.user.introductory_tour,
      allow_correspondence: response.data.user.allow_correspondence,
      active_organisation_id: response.data.user.active_organisation_id,
    };

    const getOrgWithActivities = await userService.getUserOrgs(userData.id);

    let organisations = null;
    if (getOrgWithActivities && getOrgWithActivities.data && getOrgWithActivities.data.length) {
      if (userData && userData.active_organisation_id) {
        const pullOutOrgByActive = getOrgWithActivities.data.filter(
          (item) => item.organisation_id === userData.active_organisation_id,
        );

        [organisations] = pullOutOrgByActive.length > 0
          ? pullOutOrgByActive
          : getOrgWithActivities.data;
      } else {
        [organisations] = getOrgWithActivities.data;
      }
    }

    if (organisations && organisations.organisation_id) {
      await organisationService.checkInvitedStatus(organisations.organisation_id, userData.id);
    }

    getStrings(userData.language);
    setUserData(userData);
    setOrg(organisations);
    storeUserInLocalStorage(userData);
    storeOrgInLocalStorage(organisations);
    userData.org = organisations;
    return userData;
  };

  const updateUser = async (userId, updatedUser) => {
    const response = await userService.updateUser(userId, updatedUser);

    if (response.ok) {
      const userData = {
        id: response.data.id,
        email: response.data.email_address,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        language: response.data.language,
        image_id: response.data.image_id,
        email_notifications: response.data.email_notifications,
        measurement_unit: response.data.measurement_unit,
        introductory_tour: response.data.introductory_tour,
        allow_correspondence: response.data.allow_correspondence,
        active_organisation_id: response.data.active_organisation_id,
      };

      setUserData(userData);
      storeUserInLocalStorage(userData);
    }
  };

  const updateOrg = async (newOrgDetails) => {
    updateReviewLinks([]);
    setAvailableYearsForAnnualData([]);

    if (newOrgDetails) {
      storeOrgInLocalStorage(newOrgDetails);
      setOrg(newOrgDetails);

      const mappedUser = {
        email_address: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        active_organisation_id: newOrgDetails.organisation_id,
      };

      await updateUser(user.id, mappedUser);
    }
  };

  const updateUserProfileImage = (photoId) => {
    if (photoId) {
      const newUser = {
        ...user,
        image_id: photoId,
      };

      setUserData(newUser);
      storeUserInLocalStorage(newUser);
    }
  };
  const updateReviewLinksAndAvailableYearsForAnnualData = () => {
    updateQuestionSetLinks();
  };
  // set selected year for annual data
  const updateReviewYearForAnnualData = (year) => {
    if (year) {
      const newReviewAvailableYearsForAnnualData = availableYearsForAnnualData
        .map((_itemEachYear) => {
          const itemEachYear = { ..._itemEachYear }; // decouple instance
          if (itemEachYear.key === year) itemEachYear.selected = true;
          else itemEachYear.selected = false;
          return itemEachYear;
        });

      setAvailableYearsForAnnualData(newReviewAvailableYearsForAnnualData);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        login: loginHandler,
        logout: logoutHandler,
        strings,
        getStrings,
        supportedLanguages,
        user,
        updateUser,
        updateOrg,
        updateUserProfileImage,
        org,
        theme,
        themeSetup,
        reviewLinks,
        availableYearsForAnnualData,
        updateReviewLinksAndAvailableYearsForAnnualData,
        updateReviewYearForAnnualData,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalContextProvider;
