/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';
import Modal from '../../modal/Modal';
import TextInputField from '../../form/TextInputField';
import DatePickerField from '../../form/DatePicker';
import HiddenField from '../../form/HiddenField';
import { getUnits } from '../../../../lib/utils';
import Button from '../../button/Button';
import questionTypes from '../../../../lib/consts/questionTypes';
import MultipleChoiceQuestionsWithOwnField from '../../MultipleChoiceQuestions/MultipleChoiceQuestionsWithOwnField';
import './FormModal.scss';
import { get } from '../../../../lib/strings';
import DropdownField from '../../form/DropdownField';
import Label from '../../label/Label';
import EmployeeModal from './EmployeeModal';
import arrayUtils from '../../../../utils/array';

const FormModal = ({
  answers,
  updateAddModal,
  control,
  errors,
  addModal,
  register,
  watch,
  userUnits,
  onClickAddItem,
  userMeasurementUnit,
  setValue,
  getValues,
  append,
  handleSubmit,
  answerData,
  customerAnswerFields,
  removeAnswerFromCustomerFields,
  appendAnswerToCustomerFields,
  insertAnswerToCustomerFields,
  indexOfModalForm,
  clickedEditRowFromTable,
  setClickedEditRowFromTable,
  onClickConfirmEditItem,
  colour,
}) => {
  const [persistMultipleQuestionValues, setPersistMultipleQuestionValues] = useState([]);

  useEffect(() => { // edit: populate exist values
    if (!clickedEditRowFromTable || !clickedEditRowFromTable.dataGridAnswer) {
      setPersistMultipleQuestionValues([]);
      return;
    }
    const answerFields = Object.values(clickedEditRowFromTable.dataGridAnswer);
    const currentValues = getValues();
    const currentEntries = Object.entries(currentValues);
    const answerIdWithTrue = [];
    answerFields.forEach((fields) => {
      const answerEntries = Object.entries(fields);
      if (_.isEmpty(answerEntries)) return;
      answerEntries.forEach((answerPair) => {
        let foundType = false;
        if (answerPair[1] && answerPair[1].uiType && answerPair[1].uiType === 'DROPDOWNLIST') {
          answers.forEach((answer, answerIndex) => {
            answer.nestedQuestions.forEach((nestedQuestion) => {
              if (nestedQuestion.uiType === 'DROPDOWNLIST') {
                nestedQuestion.rowQuestions.forEach((rowQuestion) => {
                  if (rowQuestion.id === answerPair[0]) {
                    foundType = true;
                    setValue(`nestedQuestions__${nestedQuestion.id}__${answerIndex}`, rowQuestion.id);
                  }
                });
              }
            });
          });
        }
        if (answerPair[1] && answerPair[1].uiType && answerPair[1].uiType === 'TEXTINPUTS') {
          foundType = true;
          setValue(answerPair[0], answerPair[1].value);
        }
        if ((answerPair[1] && answerPair[1].uiType && answerPair[1].uiType === 'MULTIPLECHOICE_ADDMORE') || (answerPair[1] && answerPair[1].uiType && answerPair[1].uiType === 'SINGLECHOICE')) {
          currentEntries.forEach((entry) => {
            if (entry[0].includes(answerPair[1].nestedQuestionId)) {
              if (answerPair[1].value) {
                foundType = true;
                answerIdWithTrue.push(answerPair[0]);
              }
            }
          });
        }

        if (foundType === false) {
          // some existing answers does not have uiType property, by default setting
          setValue(answerPair[0], answerPair[1].value);
          answerIdWithTrue.push(answerPair[0]);
          answers.forEach((answer, answerIndex) => {
            answer.nestedQuestions.forEach((nestedQuestion) => {
              nestedQuestion.rowQuestions.forEach((rowQuestion) => {
                if (rowQuestion.id === answerPair[0]) {
                  setValue(`nestedQuestions__${nestedQuestion.id}__${answerIndex}`, rowQuestion.id);
                }
              });
            });
          });
        }
      });
    });
    setPersistMultipleQuestionValues(answerIdWithTrue);
  }, [clickedEditRowFromTable]);
  const unitFieldName = (nestedQuestion, rowQuestion) => `nestedQuestionUnit__${nestedQuestion.id}__${rowQuestion.id}.unitLabel`;

  const formatData = (answersData) => {
    const data = [...answersData];
    data.map((answer, index) => {
      return answer.nestedQuestions && answer.nestedQuestions.map((nestedQuestion, ix) => {
        return nestedQuestion.rowQuestions
          // eslint-disable-next-line array-callback-return
          && nestedQuestion.rowQuestions.sort((a, b) => a.sortOrder - b.sortOrder).map((rowQuestion, idx) => {
            let units = {};

            if (rowQuestion.unitType) {
              units = {
                unitLabel: userUnits[rowQuestion.unitType],
                unitTypes: rowQuestion.unitType,
                unitMeasurement: rowQuestion.unitMeasurement || userMeasurementUnit,
              };
            }

            const newRowQuestions = {
              ...rowQuestion,
              ...units,
            };

            data[index].nestedQuestions[ix].rowQuestions[idx] = newRowQuestions;
          });
      });
    });

    return data;
  };

  // eslint-disable-next-line no-unused-vars
  const [content, updateContent] = useState(formatData(answers));
  // badge control status in modal, will display relative field's dialogue badge.
  const [isShowAddToListButton, setIsShowAddToListButton] = useState([true, true]);
  const [isOpenWhatElseBadge, setIsOpenWhatElseBadge] = useState([false, false]);
  const [isOpenDeleteBadge, setIsOpenDeleteBadge] = useState([false, false]);
  // the click answer: edit or remove checkbox;
  const [clickedAnswer, updateClickedAnswer] = useState(undefined);

  return (
    <Modal
      closeBtnColour={colour}
      show={addModal}
      onClose={() => {
        const removeAnswersIndexByIndexOfModalForm = [];
        customerAnswerFields
          .forEach((answer, _i) => {
            if (answer.indexOfModalForm === indexOfModalForm) {
              removeAnswersIndexByIndexOfModalForm.push(_i);
            }
          });
        const currentIsOpenWhatElseBadge = [...isOpenWhatElseBadge].map(() => false);
        setIsOpenWhatElseBadge(currentIsOpenWhatElseBadge);
        const currentIsOpenDeleteBadge = [...isOpenDeleteBadge].map(() => false);
        setIsOpenDeleteBadge(currentIsOpenDeleteBadge);
        const currentIsShowAddToListButton = [...isShowAddToListButton].map(() => true);
        setIsShowAddToListButton(currentIsShowAddToListButton);

        updateAddModal(false);
        setClickedEditRowFromTable(undefined);
      }}
      heading={get('form-modal-add-heading')}
      bodyText={(
        <div className="data-grid-modal pb-3">
          <Form
            id="add-item-form"
            className="facility-details-form-wrapper"
          >
            {content.map((answer, index) => {
              useEffect(() => {
                if (!addModal) return;

                const setUnit = () => answer.nestedQuestions
                  && answer.nestedQuestions.forEach((nestedQuestion) => {
                    nestedQuestion.rowQuestions.forEach((rowQuestion) => {
                      if (rowQuestion.unitTypes) {
                        // check 1 clicked row has unit;
                        // 2 set unit measurement from row org.measurement_unit as default
                        const setUnitValue = clickedEditRowFromTable
                          && clickedEditRowFromTable.dataGridAnswer
                          && clickedEditRowFromTable.dataGridAnswer[nestedQuestion.id]
                          && clickedEditRowFromTable.dataGridAnswer[nestedQuestion.id][rowQuestion.id]
                          && clickedEditRowFromTable.dataGridAnswer[nestedQuestion.id][rowQuestion.id]
                            .unitMeasurementType
                          ? clickedEditRowFromTable.dataGridAnswer[nestedQuestion.id][rowQuestion.id]
                            .unitMeasurementType
                          : userMeasurementUnit;

                        setValue(unitFieldName(nestedQuestion, rowQuestion),
                          setUnitValue.toLowerCase());
                      }
                    });
                  });
                setUnit();
              }, [addModal]);
              const key = `${answer.id}-${index}`;
              return (
                <div key={key}>
                  {answer.nestedQuestions && answer.nestedQuestions.map((nestedQuestion,
                    nestedQuestionsIndex) => {
                    // todo, currently hard code Wash water discharge id as identical,
                    // might update to a new question type
                    // hidden divide line:
                    // 1 between Wash water discharge:8E9294FE-5772-4CFC-B643-A79400DB00A9
                    // with Formal discharge agreement
                    // 2 last item
                    const isNeedDivideLine = nestedQuestion.id !== '8E9294FE-5772-4CFC-B643-A79400DB00A9' && (nestedQuestionsIndex !== answer.nestedQuestions.length - 1);
                    // hard code divider for Community/employees
                    const isNeedDivideFieldForEmployee = answer.id === '474B2C04-24A9-48BE-9A03-A69F00923CB3';
                    if (isNeedDivideFieldForEmployee) {
                      const questionsIndexToShow = clickedEditRowFromTable
                        && clickedEditRowFromTable.formSectionIndex
                        ? clickedEditRowFromTable.formSectionIndex
                        : 0;
                      return (
                        <div hidden={questionsIndexToShow !== nestedQuestionsIndex}>
                          <div key={nestedQuestion.title}>
                            <div className="employee-form-title">
                              {get('form-employee-modal-title').replace('{modal-name}', nestedQuestion.title.toLowerCase())}
                            </div>
                            <EmployeeModal
                              answer={answer}
                              nestedQuestion={nestedQuestion}
                              rowQuestions={nestedQuestion.rowQuestions}
                              register={register}
                              setValue={setValue}
                              getValues={getValues}
                              control={control}
                              errors={errors}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (nestedQuestion.uiType === 'TEXTINPUTS') {
                      return (
                        <>
                          <div key={nestedQuestion.title}>
                            {nestedQuestion.title && <p className={`form-title ${nestedQuestionsIndex ? 'pt-3 pb-1' : ''}`}>{nestedQuestion.title}</p>}
                            {nestedQuestion.rowQuestions.map((rowQuestion, idx) => {
                              const dateReceived = watch(rowQuestion.id);
                              return (
                                <div key={rowQuestion.id} className="form-modal-question-container">
                                  {rowQuestion.fieldType === 'DateTime' ? (
                                    <>
                                      <DatePickerField
                                        className={`myClassname ${idx === 0 && 'pb-4'}`}
                                        name={rowQuestion.id}
                                        label={get('form-modal-date-of-survey')}
                                        defaultValue={new Date()}
                                        selectedValue={
                                          dateReceived && dateReceived ? new Date(dateReceived) : ''
                                        }
                                        control={control}
                                        register={register}
                                        errors={errors}
                                      />
                                    </>
                                  ) : (
                                    <div className="d-flex align-items-end">
                                      {rowQuestion.unitType && <Label text={rowQuestion.title} />}
                                      <div className="d-flex form-modal-question-container-child">
                                        <TextInputField
                                          key={answer.id}
                                          type={rowQuestion.fieldType}
                                          name={rowQuestion.id}
                                          label={`${!rowQuestion.unitType ? rowQuestion.title : '  '}`}
                                          className={`${!rowQuestion.unitType ? 'input-data-grid-field-row' : 'input-data-grid-field-row-unit'}`}
                                          errors={errors.textInput && errors.textInput[idx] ? { [`textInput[${idx}]`]: errors.textInput[idx] } : {}}
                                          ref={register({
                                            required: true,
                                          })}
                                        />

                                        {rowQuestion.unitTypes
                                          ? (
                                            <div className="data-grid__dropdown-container">
                                              <DropdownField
                                                colour={colour}
                                                control={control}
                                                name={unitFieldName(nestedQuestion, rowQuestion)}
                                                className="row-question__dropdown"
                                                options={getUnits(rowQuestion.unitType).map(
                                                  ({ unitLabel, unitType }) => ({ key: unitType, value: `${arrayUtils.getMeasurementUnitValue(unitType, unitType)} (${unitLabel})`, label: unitLabel }),
                                                )}
                                              />
                                            </div>
                                          ) : null}
                                      </div>
                                    </div>
                                  )}
                                  <HiddenField
                                    name={`nestedQuestionId__${nestedQuestion.id}__${rowQuestion.id}`}
                                    control={control}
                                    defaultValue={{
                                      type: 'TEXTINPUTS',
                                      fieldType: rowQuestion.fieldType,
                                      nestedQuestionId: nestedQuestion.id,
                                      columnName: `${nestedQuestion.title} ${rowQuestion.title}`,
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </>
                      );
                    }

                    if (nestedQuestion.uiType === 'MULTIPLECHOICE_ADDMORE' || nestedQuestion.uiType === 'DROPDOWNLIST' || nestedQuestion.uiType === questionTypes.SINGLECHOICE || nestedQuestion.uiType === 'CHOICE') {
                      return (
                        <div key={nestedQuestion.title}>
                          <p className="form-title">{nestedQuestion.title}</p>
                          {(nestedQuestion.uiType === 'MULTIPLECHOICE_ADDMORE' || nestedQuestion.uiType === 'DROPDOWNLIST' || nestedQuestion.uiType === 'CHOICE') && <p className="form-title-subtitle">{get('form-modal-sub-title')}</p>}
                          <MultipleChoiceQuestionsWithOwnField
                            name={`nestedQuestions__${nestedQuestion.id}__${index}`}
                            answers={nestedQuestion.rowQuestions}
                            isDropdown={nestedQuestion.uiType === questionTypes.DROPDOWNLIST}
                            isSingleCheckBox={nestedQuestion.uiType === questionTypes.SINGLECHOICE}
                            answerData={answerData || []}
                            nestIndex={nestedQuestionsIndex}
                            isMultiple={nestedQuestion.isMultiple}
                            allowAddMore={nestedQuestion.allowAddMore}
                            questionType={nestedQuestion.uiType}
                            customerAnswerFields={customerAnswerFields}
                            removeAnswerFromCustomerFields={removeAnswerFromCustomerFields}
                            appendAnswerToCustomerFields={appendAnswerToCustomerFields}
                            insertAnswerToCustomerFields={insertAnswerToCustomerFields}
                            indexOfModalForm={indexOfModalForm}
                            isShowAddToListButton={isShowAddToListButton}
                            setIsShowAddToListButton={setIsShowAddToListButton}
                            isOpenWhatElseBadge={isOpenWhatElseBadge}
                            setIsOpenWhatElseBadge={setIsOpenWhatElseBadge}
                            isOpenDeleteBadge={isOpenDeleteBadge}
                            setIsOpenDeleteBadge={setIsOpenDeleteBadge}
                            clickedAnswer={clickedAnswer}
                            updateClickedAnswer={updateClickedAnswer}
                            persistMultipleQuestionValues={persistMultipleQuestionValues}
                            clickedEditRowFromTable={clickedEditRowFromTable}
                            color={colour}
                            {...{
                              control,
                              register,
                              setValue,
                              append,
                              getValues,
                              handleSubmit,
                            }}
                          />
                          {isNeedDivideLine && <div className="form-modal-field-divider" />}
                        </div>
                      );
                    }

                    return <></>;
                  })}
                </div>
              );
            })}
            {answers.length && (
              <HiddenField name="answerId" control={control} defaultValue={answers[0].id} />
            )}
          </Form>
        </div>
      )}
      footer={(
        <Button colour={colour} form="add-item-form" onClick={clickedEditRowFromTable ? onClickConfirmEditItem : onClickAddItem}>
          {!clickedEditRowFromTable ? get('form-modal-update-btn') : get('form-modal-add-btn')}
        </Button>
      )}
    />
  );
};

FormModal.defaultProps = {
  errors: {},
  userUnits: {},
  answerData: {
    rows: [],
    facilityAddedAnswers: [],
  },
  clickedEditRowFromTable: undefined,
  colour: '#4CA471',
};

FormModal.propTypes = {
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({}),
  ]),
  watch: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateAddModal: PropTypes.func.isRequired,
  addModal: PropTypes.bool.isRequired,
  onClickAddItem: PropTypes.func.isRequired,
  userUnits: PropTypes.shape({}),
  userMeasurementUnit: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  answerData: PropTypes.shape({
    rows: [],
    facilityAddedAnswers: [],
  }),
  append: PropTypes.func.isRequired,
  customerAnswerFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeAnswerFromCustomerFields: PropTypes.func.isRequired,
  appendAnswerToCustomerFields: PropTypes.func.isRequired,
  insertAnswerToCustomerFields: PropTypes.func.isRequired,
  indexOfModalForm: PropTypes.number.isRequired,
  clickedEditRowFromTable: PropTypes.shape(),
  setClickedEditRowFromTable: PropTypes.func.isRequired,
  onClickConfirmEditItem: PropTypes.func.isRequired,
  colour: PropTypes.func,
};
export default FormModal;
