import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import './FlagIcon.scss';
import Flag from './Cert.svg';

const FlagIcon = ({ hoverText, hoverPosition }) => {
  return (
    <Tooltip title={hoverText} placement={hoverPosition}>
      <img className="flag-icon" src={Flag} alt="FlagIcon" />
    </Tooltip>
  );
};

FlagIcon.defaultProps = {
  hoverPosition: 'top',

};
FlagIcon.propTypes = {
  hoverText: PropTypes.string.isRequired,
  hoverPosition: PropTypes.string,

};

export default FlagIcon;
