import { create } from 'apisauce';

const api = create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

export default {
  // Should default to down unless we get an ok, not vice-versa
  isServerDown: async () => {
    const response = await api
      .get('/healthcheck');

    // eslint-disable-next-line no-console
    console.info('Checking if the server is down.', response);
    if (response.ok) {
      return false;
    }

    return true;
  },
};
