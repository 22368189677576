import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';
import Panel from '../../panel/Panel';
import InternalLink from '../../link/InternalLink';
import './PageNavTiles.scss';
import Pointer from '../../../../assets/svgs-as-components/RightPointer';
import { GlobalContext } from '../../../../context/GlobalContext';
import themeType from '../../../../lib/consts/themeType';

const PageNavTiles = ({ tiles, hasShadow = true }) => {
  const { theme } = useContext(GlobalContext);
  const colourFromTheme = theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471';
  return (
    <>
      {tiles.map((tile) => {
        const isActivePage = useRouteMatch({
          path: tile.href,
        });
        return (
          <div
            key={tile.href}
            className={`page-nav-tile ${isActivePage ? '--active' : ''}`}
          >
            <InternalLink to={tile.href}>
              <Panel border hasShadow={hasShadow}>
                <div className="page-nav-tile__content">
                  <h2> {tile.title}</h2>
                  <h6> {tile.description}</h6>
                </div>
                <div className="page-nav-tile-content__aside">
                  <Pointer color={colourFromTheme} />
                </div>
              </Panel>
            </InternalLink>
          </div>
        );
      })}
    </>
  );
};

PageNavTiles.defaultProps = {
  tiles: [],
  hasShadow: true,
};

PageNavTiles.propTypes = {
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      href: PropTypes.string,
    }),
  ),
  hasShadow: PropTypes.bool,
};
export default PageNavTiles;
