import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import FormError from './FormError';
import Label from '../label/Label';
import { get } from '../../../lib/strings';
import './DatePicker.scss';

const DatePickerField = ({
  name,
  control,
  register,
  label,
  selectedValue,
  errors,
}) => {
  const [id] = useState(_.uniqueId(''));
  let errorMessage;

  if (errors[name]) {
    if (errors[name].type === 'required') {
      errorMessage = 'error';
    } else {
      errorMessage = errors[name].message;
    }
  }

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => {
    return (
      <input className="custom-input-text" required placeholder={get('date-picker-default')} value={value} onClick={onClick} ref={ref} />
    );
  });

  ExampleCustomInput.propTypes = {
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  return (
    <div className="components__form-date-picker-field">
      <Label htmlFor={id} text={label} />

      <Controller
        as={(
          <DatePicker
            autoComplete="off"
            dateFormat="yyyy-MM-dd"
            selected={selectedValue}
            todayButton="Today"
            maxDate={new Date().getTime()}
            dropdownMode="select"
            placeholderText={get('date-picker-default')}
            shouldCloseOnSelect
            customInput={<ExampleCustomInput />}
          />
      )}
        errors={errors}
        control={control}
        register={register({ required: true })}
        name={name}
        onChange={([selected]) => {
          return { value: selected };
        }}
      />

      {errorMessage && <FormError message={errorMessage} />}
    </div>

  );
};

export default DatePickerField;

DatePickerField.defaultProps = {};

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object).isRequired,
};
