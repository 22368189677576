module.exports.activities = {
  MAIN_DASHBOARD: 'main-dashboard',
  FACILITY_PROFILE: 'facility-profile',
  CREATE_ORGANISATION: 'create-organisation',
  CHANGE_ORGANISATION: 'change-organisation',
  VIEW_ORGANISATION_DETAILS: 'view-organisation-details',
  CHANGE_ORGANISATION_DETAILS: 'change-organisation-details',
  ADD_USER_TO_ORGANISATION: 'add-user-to-organisation',
  CHANGE_USER_PERMISSIONS_FOR_ORGANISATION: 'change-user-permissions-for-organisation',
  REMOVE_USER_FROM_ORGANISATION: 'remove-user-from-organisation',
  REVIEWS: 'reviews',
  IMPROVE: 'improve',
  COMMUNICATE: 'communicate',
};

module.exports.doesUserHaveActivities = (
  userActivityList,
  requiredActivities,
) => {
  if (!userActivityList || !Array.isArray(userActivityList)) {
    return false;
  }

  let hasActivity = true;
  requiredActivities.forEach((activity) => {
    if (!userActivityList.includes(activity)) {
      hasActivity = false;
    }
  });

  return hasActivity;
};
