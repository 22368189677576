import React from 'react';
import Card from '../../components/core/card/Card';
import { get } from '../../lib/strings';
import './TermsAndConditions.scss';

const TermsAndConditionsContent = () => {
  return (
    <Card
      className="terms-and-conditions__card"
      hasOverlay
      title={get('termsconditions-title')}
    >
      <br />
      <h6>{get('termsconditions-title')}</h6>
      <p>{get('termsconditions-pre-section1')}{get('termsconditions-pre-section2')}</p>
      <h6>{get('termsconditions-copyright-title')}</h6>
      <p>{get('termsconditions-copyright-para1')}</p>
      <p>{get('termsconditions-copyright-para2')}</p>
      <p>{get('termsconditions-copyright-para3')}</p>
      <p>{get('termsconditions-copyright-para4')}</p>
      <p>{get('termsconditions-copyright-para5')}</p>
      <h6>{get('termsconditions-privacy-title')}</h6>
      <p>{get('termsconditions-privacy-para1')}</p>
      <p>{get('termsconditions-privacy-para2')}</p>
      <p>{get('termsconditions-privacy-para3')}<a href="mailto:privacy@golfenvironment.org?subject=Site Privacy Enquiry">privacy@golfenvironment.org</a> {get('termsconditions-privacy-para4')}</p>
      <p>{get('termsconditions-privacy-para5')}</p>
      <ol>
        <li>{get('termsconditions-privacy-list1')}</li>
        <li>{get('termsconditions-privacy-list2')}</li>
        <ol type="a">
          <li>{get('termsconditions-privacy-list2a')}</li>
          <li>{get('termsconditions-privacy-list2b')}</li>
          <li>{get('termsconditions-privacy-list2c')}</li>
        </ol>
        <li>{get('termsconditions-privacy-list3')}</li>
        <ol type="a">
          <li>{get('termsconditions-privacy-list3a')}</li>
          <li>{get('termsconditions-privacy-list3b')}</li>
          <li>{get('termsconditions-privacy-list3c')}</li>
        </ol>
        <li>{get('termsconditions-privacy-list4')}</li>
        <ol type="a">
          <li>{get('termsconditions-privacy-list4a')}</li>
        </ol>
        <li>{get('termsconditions-privacy-list5')}</li>
        <li>{get('termsconditions-privacy-list6')}</li>
      </ol>
      <h6>{get('termsconditions-privacy-sub-title')}</h6>
      <p>{get('termsconditions-privacy-para6')}</p>
      <p>{get('termsconditions-privacy-para7')}</p>
      <p>{get('termsconditions-privacy-para8')} <a href="https://sustainable.golf/privacy_cookie_policy">{get('termsconditions-privacy-para9')}</a></p>
      <p>{get('termsconditions-privacy-para10')}</p>
      <h6>{get('termsconditions-linking-title')}</h6>
      <p>{get('termsconditions-linking-para1')}</p>
      <p>{get('termsconditions-linking-para2')}</p>
      <h6>{get('termsconditions-cautionary-title')}</h6>
      <p>{get('termsconditions-cautionary-para1')}</p>
      <h6>{get('termsconditions-disclaimer-title')}</h6>
      <p>{get('termsconditions-disclaimer-para1')}</p>
      <p>{get('termsconditions-disclaimer-para2')}</p>
    </Card>
  );
};

export default TermsAndConditionsContent;
