import React from 'react';
import PropTypes from 'prop-types';

import './Label.scss';

const Label = ({ text, htmlFor }) => {
  return (
    <label className="components__label" htmlFor={htmlFor}>
      {text}
    </label>
  );
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
};

export default Label;
