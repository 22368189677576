/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Panel from '../../panel/Panel';
import Button from '../../button/Button';
import './improve-reports-panel.scss';
import contentService from '../../../../services/content/content-service';
import HtmlViewer from '../../html-viewer/HTMLViewer';
import themeType from '../../../../lib/consts/themeType';
import { GlobalContext } from '../../../../context/GlobalContext';

const BasicSection = ({ contentPage, handleDownload }) => {
  return (
    <>
      {contentPage && contentPage.map((_content, i) => (_content && _content.title && (
        <div key={`${_content.sortcode}_${_content.title}`}>
          <div className="oncourse-reports__pannel-container">
            <h5 className="oncourse-reports__panel-title"> {_content.title} </h5>
            <div className="oncourse-reports__panel--content">
              { _content && _content.imageRecord && <img alt="img" src={_content.imgSource} className="oncourse-reports__img" />}
              {_content && _content.content
              && <HtmlViewer html={_content.content} />}
            </div>
          </div>
          {_content && (_content.documentLinks.length > 0 || _content.activeLinks.length > 0)
          && (
          <div className="oncourse-reports__links-container">
            <div className="oncourse-reports__panel--downloads">
              {_content && _content.activeLinks && _content.activeLinks.map((item) => (
                <div key={item.title}>
                  <p><a className="download-resources-anchor" href={item.link} target={(item.openInNewWindow) ? '_blank' : ''} rel="noreferrer"> {item.title} </a></p>
                </div>
              ))}
              {_content.documentLinks.length > 0 && _content.activeLinks.length > 0 && <div className="links-field-divider" />}
              {_content.documentLinks.map((item) => (
                <p key={item.id}>
                  <button
                    type="button"
                    className="download-resources-anchor"
                    key={item.id}
                    onClick={() => handleDownload(item.id)}
                  >{item.name}
                  </button>
                </p>
              ))}
            </div>
          </div>
          )}
          {i !== (contentPage.length - 1) && <hr />}
        </div>
      )
      ))}
    </>
  );
};
BasicSection.propTypes = {
  handleDownload: PropTypes.func.isRequired,
  contentPage: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const ImproveReportsPanel = (
  {
    buttonText,
    handleClickButton,
    content,
  },
) => {
  const handleDownload = (id) => {
    return contentService.getDownloadDocumentByMediaId(id);
  };
  const { theme } = useContext(GlobalContext);

  return (
    <Panel>
      { handleClickButton && (
      <>
        <div className="row ">
          <div className="col-md-12 panel-button">
            {theme !== themeType.TOURNAMENT
            && <Button onClick={handleClickButton}>{buttonText}</Button>}
          </div>
        </div>
        <hr />
      </>
      )}

      <BasicSection contentPage={content} handleDownload={handleDownload} />
    </Panel>
  );
};
ImproveReportsPanel.defaultProps = {
  handleClickButton: undefined,
};
ImproveReportsPanel.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handleClickButton: PropTypes.func,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ImproveReportsPanel;
