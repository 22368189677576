import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import TextInputField from '../../core/form/TextInputField';
import Alert from '../../core/alert/Alert';
import Button from '../../core/button/Button';
import { get } from '../../../lib/strings';

import './LoginForm.scss';

const LoginForm = ({ onComplete, loginError }) => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <Form
      className="components__login_form"
      onSubmit={handleSubmit(onComplete)}
    >
      <TextInputField
        type="email"
        name="email"
        label={get('login-formEmailAddress')}
        placeholder={get('login-formEmailAddressPlaceholder')}
        className="input-field-row"
        data-cy="email-input"
        ref={register({
          required: true,
        })}
        errors={errors}
      />

      <TextInputField
        type="password"
        name="password"
        label={get('login-formPassword')}
        placeholder={get('login-formPasswordPlaceholder')}
        className="input-field-row"
        data-cy="password-input"
        ref={register({
          required: true,
        })}
        errors={errors}
      />

      {loginError && (
        <Alert data-cy="login-error" className="alert" variant="danger">
          {loginError}
        </Alert>
      )}

      <div className="d-flex justify-content-center pt-4">
        <Button
          type="submit"
          className="sign-in-button medium"
          data-cy="sign-in-button"
        >
          {get('login-formButtonLogin')}
        </Button>
      </div>
    </Form>
  );
};

LoginForm.defaultProps = {
  loginError: undefined,
};

LoginForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  loginError: PropTypes.string,
};

export default LoginForm;
