/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import './HTMLViewer.scss';
import { useWindowSize } from '../../../utils/useWindowSize';

const HTMLViewer = ({
  html, className, expandableView, colour,
}) => {
  const cutoffSize = useWindowSize().width > 576 ? 150 : 75;

  const lochtml = DOMPurify.sanitize(html, { ADD_ATTR: ['target'] });

  const notesLines = lochtml.split('<p>').length - 1;
  const isHtmlTooLongLength = lochtml.length > cutoffSize || notesLines > 1;
  const [lessMoreTxt, setLessMoreTxt] = useState(() => (isHtmlTooLongLength ? 'more' : 'less'));

  const toggleLessMoreText = () => {
    setLessMoreTxt((prev) => {
      switch (prev) {
        case 'more':
        default:
          return 'less';
        case 'less':
          return 'more';
      }
    });
  };

  if (!expandableView) {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: lochtml }}
      />
    );
  }

  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <div className="html-viewer-container">
      <div
        className={`${lessMoreTxt === 'more' ? 'more-selected' : 'more-not-selected'}${className}`}
        dangerouslySetInnerHTML={{ __html: lochtml }}
      />
      {isHtmlTooLongLength && (
        <div
          style={{ color: hover ? colour : 'black' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {lessMoreTxt === 'more' && <p />}
          <span aria-hidden className={`more-less-btn ${lessMoreTxt === 'less' && 'more-less-btn--less'}`} onClick={toggleLessMoreText}>{lessMoreTxt}</span>
        </div>
      )}

    </div>
  );
};

HTMLViewer.defaultProps = {
  className: '',
  expandableView: false,
  colour: '#4CA471',
};

HTMLViewer.propTypes = {
  html: PropTypes.string.isRequired,
  className: PropTypes.string,
  expandableView: PropTypes.bool,
  colour: PropTypes.string,
};

export default HTMLViewer;
