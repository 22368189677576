import React, { useEffect, useContext } from 'react';
import './ForgottenPasswordPage.scss';
import { useHistory } from 'react-router-dom';
import Card from '../../../components/core/card/Card';
import ResetPasswordForm from '../../../components/authentication/reset-password-form/ResetPasswordForm';
import InternalLink from '../../../components/core/link/InternalLink';
import Footer from '../../../components/core/footer/Footer';
import BackgroundImage from '../../../components/core/backgroundImage/BackgroundImage';
import authenticationService from '../../../services/authentication/authentication-service';
import { get } from '../../../lib/strings';
import OnCourseLogo from '../../../assets/OnCourse_Logo.svg';
import { GlobalContext } from '../../../context/GlobalContext';
import getCodeFromQueryString from '../../../lib/queryStringGetter';
import THEME_TYPE from '../../../lib/consts/themeType';

const ForgottenPasswordPage = () => {
  const history = useHistory();
  const { getStrings, themeSetup, theme } = useContext(GlobalContext);
  const isTournamentLogin = history.location.pathname.indexOf('tournaments') !== -1;

  const langCode = getCodeFromQueryString();
  let langQueryString = '';
  if (langCode) {
    langQueryString = `?lang=${langCode}`;
  }

  useEffect(() => {
    if (langCode) {
      getStrings(langCode);
    }
  }, []);

  useEffect(() => {
    if (isTournamentLogin && theme !== THEME_TYPE.TOURNAMENT) {
      themeSetup(THEME_TYPE.TOURNAMENT);
    }
  }, []);

  const requestPasswordReset = async ({ email }) => {
    await authenticationService.resetPassword(email, isTournamentLogin);
    return history.push(`${isTournamentLogin ? '/tournaments' : ''}/forgotten-password/link-sent${langQueryString}`);
  };

  return (
    <div className="pages__forgotten-password-page">
      <BackgroundImage />
      <img className="forgotten-password-title" src={OnCourseLogo} alt="OnCourse_logo" />

      <Card
        className="forgotten-password-card"
        title={get('forgottenPassword-formTitle')}
        hasOverlay
      >
        <ResetPasswordForm onComplete={requestPasswordReset} />
        <InternalLink
          className="cancel-password-button"
          to={`${isTournamentLogin ? '/tournaments' : ''}/login`}
          data-cy="return-to-sign-in-link"
        >
          {get('forgottenPassword-formCancelButton')}
        </InternalLink>
      </Card>

      <Footer />
    </div>
  );
};

export default ForgottenPasswordPage;
