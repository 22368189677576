module.exports = {
  // 1xx: General errors applicable throughout the system
  REQUIRED_FIELD_MISSING: 101,
  NOT_UNIQUE: 102,
  INCORRECT_DATE_ORDER: 103,
  BELOW_MIN_VALUE: 104,
  ABOVE_MAX_VALUE: 105,
  DUPLICATED_VALUE: 106,
  NOT_ARRAY_VALUE: 107,
  JSON_PARSE_ERROR: 108,
  UNKNOWN_ENUM_VALUE: 109,
  FORBIDDEN_RESOURCE: 110,
  INVALID_RESOURCE: 111,
  INCORRECT_FORMAT: 112,
  INACTIVE_RESOURCE: 114,
  FILE_TOO_LARGE: 120,
  // 4xx: Authentication and registration specific errors
  INVALID_CREDENTIALS: 401,
  INVALID_TOKEN: 401,
  PASSWORD_DOESNT_MEET_REQUIREMENTS: 402,
  UNPROCESSABLE_ENTITY: 422,
  // 5xx: Internal Server Errors
  INTERNAL_SERVER_ERROR: 500,
};
