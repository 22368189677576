import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import mediaService from '../../../services/media/media-service';
import './panel.scss';

const Panel = ({
  children,
  border,
  hasShadow = true,
  imageId,
}) => {
  const [waterMark, setWaterMark] = useState(null);

  const getWaterMarkImage = async () => {
    if (imageId) {
      const result = await mediaService.getProfilePhoto(imageId);

      if (result) {
        setWaterMark(result);
      }
    }
  };

  useEffect(() => {
    getWaterMarkImage();
  }, []);

  return (
    <div
      className={`panel-container ${border && '--border'}  ${hasShadow ? '' : '--noshadow'}`}
      style={{
        backgroundImage: `url(${waterMark})`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      {children}
    </div>
  );
};

Panel.defaultProps = {
  border: null,
  hasShadow: true,
  imageId: '',
};

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  border: PropTypes.bool,
  hasShadow: PropTypes.bool,
  imageId: PropTypes.string,
};
export default Panel;
