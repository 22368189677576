import HTTPError from '../../lib/errors/http-error';
import api from '../../lib/http';

export default {
  getStringByCountryCode: async (countryCode) => {
    const response = await api(
      'get',
      'string/strings-by-code',
      { code: countryCode },
      false,
      false,
      true,
    );

    if (!response.ok) {
      throw new HTTPError(response.status, response.data);
    }

    return response;
  },
};
