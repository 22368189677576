import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../../lib/strings';
import './badge.scss';
import Label from '../label/Label';

const DeleteAnswerBadge = ({
  title,
  onClickCancelDeleteBadge,
  fieldIndex,
  onClickConfirmRemoveAnswer,
}) => {
  return (
    <div className="badge-container">
      <div className="d-flex pt-4 px-3 m-0">
        <Label text={`${get('data-grid-delete-badge-keep-label')}?`} />
      </div>
      <div className="d-flex px-3 py-4 justify-content-between">
        {title}
        <div className="badge-container-buttons">
          <button
            id="edit"
            type="button"
            onClick={() => onClickCancelDeleteBadge(fieldIndex)}
          >
            {get('data-grid-delete-badge-keep-button')}
          </button>
          <div className="badge-buttons__separator" />

          <button
            id="delete"
            type="button"
            onClick={() => onClickConfirmRemoveAnswer(fieldIndex)}
          >
            {get('data-grid-delete-badge-delete-button')}
          </button>
        </div>
      </div>
    </div>
  );
};

DeleteAnswerBadge.propTypes = {
  title: PropTypes.string.isRequired,
  onClickCancelDeleteBadge: PropTypes.func.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  onClickConfirmRemoveAnswer: PropTypes.func.isRequired,
};

export default DeleteAnswerBadge;
