import HTTPError from '../../lib/errors/http-error';
import api from '../../lib/http';

export default {
  getDocumentByPageAndPosition: async (
    pageName,
    position,
    languageCode,
  ) => {
    const response = await api(
      'get',
      `documents/content/${pageName}/position/${position}/languages/${languageCode}`,
      {},
      false,
      false,
      true,
    );

    if (!response.ok) {
      throw new HTTPError(response.status, response.data);
    }

    return response.data;
  },
  getContentByPageAndLanguageCodeAndSectionId: async (
    pageName,
    languageCode,
    countryCode,
    sectionId,
    orgId,
  ) => {
    const response = await api(
      'get',
      `content/slug/${pageName}/languages/${languageCode}/countries/${countryCode}/section/${sectionId}/organisation/${orgId}`,
      {},
      false,
      false,
      true,
    );

    if (!response.ok) {
      throw new HTTPError(response.status, response.data);
    }

    return response.data;
  },

  getDownloadDocumentByMediaId: async (
    mediaId,
  ) => {
    const result = await api(
      'get',
      `/documents/document/${mediaId}`,
      {},
      false,
      false,
      true,
      { responseType: 'arraybuffer' },
    );
    if (result.ok) {
    // Convert the array buffer into a url
      const fileName = result.headers['content-filename'];
      const url = window.URL.createObjectURL(new Blob([result.data]));
      // generate anchor and download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    return result;
  },

  getDownloadDocumentByDocId: async (
    docId,
  ) => {
    const result = await api(
      'get',
      `/organisation/document/${docId}`,
      {},
      false,
      false,
      true,
      { responseType: 'arraybuffer' },
    );
    if (result.ok) {
    // Convert the array buffer into a url
      const fileName = result.headers['content-filename'];
      const url = window.URL.createObjectURL(new Blob([result.data]));
      // generate anchor and download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    return result;
  },

  getContentSections: async (orgId, languageCode) => {
    const response = await api(
      'get',
      `content/sections/languages/${languageCode}`,
      {
        orgId,
      },
      false,
      false,
      true,
    );

    if (!response.ok) {
      throw new HTTPError(response.status, response.data);
    }

    return response.data;
  },
};
