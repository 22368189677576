import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import LoginForm from '../../../components/authentication/login-form/LoginForm';
import Card from '../../../components/core/card/Card';
import Button from '../../../components/core/button/Button';
import Footer from '../../../components/core/footer/Footer';
import BackgroundImage from '../../../components/core/backgroundImage/BackgroundImage';
import InternalLink from '../../../components/core/link/InternalLink';

import { GlobalContext } from '../../../context/GlobalContext';
import AuthenticationError from '../../../lib/errors/authentication-error';
import PermissionsError from '../../../lib/errors/permissions-error';
import THEME_TYPE from '../../../lib/consts/themeType';

import { get, stringSanitizer } from '../../../lib/strings';
import OnCourseLogo from '../../../assets/OnCourse_Logo.svg';

import './LoginPage.scss';

const LoginPage = () => {
  const [loginError, setLoginError] = useState(undefined);
  const { login, themeSetup, theme } = useContext(GlobalContext);
  const history = useHistory();
  const invalidDetailsString = get('login-formInvalidDetails');
  const loginNoTournamentsString = get('login-noTournamentsError');
  const isTournamentLogin = history.location.pathname.indexOf('tournaments') !== -1;

  useEffect(() => {
    if (isTournamentLogin) themeSetup(THEME_TYPE.TOURNAMENT);
  }, []);

  const performLogin = async ({ email, password }) => {
    setLoginError(undefined);
    try {
      await login(email, password, isTournamentLogin);

      return history.push('/');
    } catch (error) {
      if (error instanceof AuthenticationError) {
        setLoginError(invalidDetailsString);
      } else if (error instanceof PermissionsError) {
        setLoginError(loginNoTournamentsString);
      } else {
        throw error;
      }
    }
  };

  return (
    <div className="pages__login-page">
      <BackgroundImage />
      <img className="login-title" src={OnCourseLogo} alt="OnCourse_logo" />

      <div className="wrapper">
        <Card className={`login-form-card ${isTournamentLogin && 'login-form-card-borders'}`} title={get('login-formTitle')}>
          <LoginForm key={theme} onComplete={performLogin} loginError={loginError} />

          <div className="forgotten-password-wrapper">
            <InternalLink
              to={`${isTournamentLogin ? '/tournaments' : ''}/forgotten-password`}
              className="forgotten-password-text"
            >
              {get('login-formForgottenPassword')}
            </InternalLink>
          </div>
        </Card>
        {!isTournamentLogin ? (
          <Card
            className="register-card"
            title={get('login-registerTitle')}
            titleClassName="register-title"
            hasOverlay
            hasContacts={false}
          >

            <div
              className="register-description"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: stringSanitizer(get('login-registerDescription')),
              }}
            />
            <div className="d-flex justify-content-center">
              <Button
                inverse
                transparent
                className="register-button medium primary"
                data-cy="register-button"
                onClick={() => history.push('/registration-page')}
              >
                {get('login-registerButton')}
              </Button>
            </div>
          </Card>
        ) : null}
      </div>

      <Footer />
    </div>
  );
};

export default LoginPage;
