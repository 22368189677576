import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Tick from '../../../../assets/tick.svg';
import InternalLinkButton from '../../link/InternalLinkButton';
import CircularProgressBar from './CircularProgressBar';
import './CircularProgressStyles.scss';
import { get } from '../../../../lib/strings';
import { GlobalContext } from '../../../../context/GlobalContext';
import TourTick from '../../../../assets/tour-complete-tick.svg';
import themeType from '../../../../lib/consts/themeType';

const CircularProgressCard = ({ percentage, href, text }) => {
  const { theme } = useContext(GlobalContext);
  return (
    <div className="circular-progress-bar">
      <div className="circular-progress-bar__progress">
        <div className="circle">
          {
            (percentage === 100)
              && <img className="circular-progress-bar-complete" src={theme === themeType.TOURNAMENT ? TourTick : Tick} alt="circular tick denoting completion" />
          }
          <CircularProgressBar text={text} percentage={percentage} />
        </div>
      </div>
      {
          href
    && (
    <InternalLinkButton
      to={href}
      size="large"
      colour={theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471'}
      className="btn__basic w-100 primary"
      inverse
    >
      {percentage === 100 ? get('component-CircularPorgressCardComplete') : get('component-CircularPorgressCardIncomplete')}
    </InternalLinkButton>
    )
      }
    </div>
  );
};
CircularProgressCard.defaultProps = {
  href: null,
  percentage: 0,
  text: '',
};

CircularProgressCard.propTypes = {
  percentage: PropTypes.number,
  href: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default CircularProgressCard;
