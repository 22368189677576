const RouteSideBarMapping = {
  PROFILE: {
    LINK: '/user-profile',
    INDEX: -1,
  },
  MAIN_DASHBOARD: {
    LINK: '/',
    INDEX: 0,
  },

  FACILITY_PROFILE: {
    LINK: 'profile/facility',
    INDEX: 1,
  },
  BEST_PRACTICES: {
    LINK: '/review',
    INDEX: 2,
  },
  ONCOURSE_REPORTS: {
    LINK: '/oncourse-reports',
    INDEX: 3,
  },
  TEAM_INTRODUCTION: {
    LINK: '/team-introduction',
    INDEX: 4,
  },
  DOCUMENT_LIBRARY: {
    LINK: '/documents',
    INDEX: 5,
  },
  CREATE_FACILITY: {
    LINK: '/create-facility',
    INDEX: 6,
  },
  ANNUAL_REVIEW: {
    LINK: '/annual-review',
    INDEX: 7,
  },
  IMPROVE: {
    LINK: 'improve',
    INDEX: 8,
  },
};

export default RouteSideBarMapping;
