import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../core/modal/Modal';
import Button from '../core/button/Button';

const WarningUnSaveGuardModal = ({
  open,
  titleText,
  contentText,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal
      show={open}
      // enforceFocus={false}
      onClose={onCancel}
      heading={titleText}
      subtitle={contentText}
      classForUnSaveGuard
      footer={(
        <div className="delete-notes-ctas">
          <Button
            className="primary flex-1"
            form="add-editor-form"
            type="button"
            onClick={onCancel}
            inverse
          >
            {cancelButtonText}
          </Button>
          <Button
            className="flex-3"
            form="add-editor-form"
            type="button"
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
        </div>
          )}
    />
  );
};

WarningUnSaveGuardModal.propTypes = {
  open: PropTypes.bool.isRequired,
  titleText: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
export default WarningUnSaveGuardModal;
