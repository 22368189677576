/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import themeType from '../../lib/consts/themeType';
import { GlobalContext } from '../../context/GlobalContext';

const ReviewLayout = ({
  title, children, hasShadow = true,
}) => {
  const { org, user, theme } = useContext(GlobalContext);
  return (
    <div className={`${theme === themeType.TOURNAMENT ? 'img-tour page' : 'img-facility page'}`}>
      <div className="page-header">
        <h2 className="review-page-section-title mb-4">{title}</h2>
        <div className="row">
          <div className="col-10 mr-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
ReviewLayout.defaultProps = {
  hasShadow: true,
};

ReviewLayout.propTypes = {
  title: PropTypes.string.isRequired,
  hasShadow: PropTypes.bool,
};

export default ReviewLayout;
