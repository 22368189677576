import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextInputField from '../form/TextInputField';
import QuestionErrors from '../../questions-errors/QuestionsErrors';
import DropdownField from '../form/DropdownField';
import { getUnits, getUnitFromPayloadOrFromUtils } from '../../../lib/utils';
import { get } from '../../../lib/strings';
import './CalculatingQuestion.scss';
import Button from '../button/Button';
import { ArrowDown, ArrowUp } from '../../Icons';
import DownArrowImg from '../../../assets/arrows-chevron-down.svg';
import UpArrowImg from '../../../assets/arrows-chevron-up.svg';

const formatTotal = (total, zeroText = '--') => {
  let val = parseFloat(total);
  val = Number.isNaN(val) || val === 0 ? zeroText : val.toFixed(2);
  return val;
};

const renderByUnitType = (
  elem,
  index,
  register,
  answerData,
  setValue,
  errors,
  control,
  answerPreviousYearData,
  returnUnitDropDownOnly = false,
  onChange = () => { },
  rowStyle = {},
  colour,
) => {
  const fieldName = `question[${index}].row[${elem.rowId}].${elem.id}.value`;

  const unitFieldName = `question[${index}].units.value`;
  const fieldNamePreviousData = `previousDataQuestions[${index}].row[${elem.rowId}].${elem.id}`;
  const [fieldError, updateFieldError] = useState({});
  const UNIT_OPTIONS = getUnits(elem.unitType).map(({ unitLabel, unitType }) => ({
    key: unitType,
    value: `${unitType} (${unitLabel})`,
    label: unitLabel,
  }));

  useEffect(() => {
    const answersDataWorkSet = answerData || answerPreviousYearData;

    if (answersDataWorkSet) {
      const keys = Object.keys(answersDataWorkSet).filter((key) => key.split('-').length === 5);
      let nestedValuesWrapper = {};

      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        nestedValuesWrapper = {
          ...nestedValuesWrapper,
          ...answersDataWorkSet[key].nestedValues,
        };
      }

      if (nestedValuesWrapper[elem.id] && nestedValuesWrapper[elem.id].value) {
        setValue(fieldName, nestedValuesWrapper[elem.id].value);
      }
      if (nestedValuesWrapper[elem.id] && nestedValuesWrapper[elem.id].unitMeasurementType) {
        const unitMeasurement = getUnitFromPayloadOrFromUtils(nestedValuesWrapper[elem.id]);

        if (unitMeasurement) {
          const unitLabel = typeof unitMeasurement === 'string' || unitMeasurement instanceof String
            ? unitMeasurement
            : unitMeasurement[elem.unitType];

          setValue(unitFieldName, unitLabel);
        }
      }
    }

    onChange();
  }, []);

  useEffect(() => {
    let errorElm = {};
    if (errors && errors.question) {
      errors.question.map((err) => {
        if (err[elem.id]) {
          errorElm = err[elem.id];
        }
        return err;
      });
    }
    updateFieldError(errorElm.value);
    return () => { };
  }, [errors]);

  if (returnUnitDropDownOnly) {
    return (
      <>
        {elem.unitType ? (
          <>
            <p className="text-left">
              <strong>{get('calculatingQuestion-select-unit')}</strong>
            </p>
            <div className="d-flex row-question-unit-selector row-question__dropdown-container">
              <DropdownField
                colour={colour}
                control={control}
                name={unitFieldName}
                className="row-question__dropdown"
                options={UNIT_OPTIONS}
                errors={errors}
                handleChange={onChange}
              />
            </div>
          </>
        ) : null}
      </>
    );
  }

  return (
    <tr key={elem.id} style={rowStyle} className="row-question-field-row row-question-field-calculating-question">
      <td colSpan={2}>
        <p className="calculating-question-title">
          {elem.title}
        </p>
      </td>
      <td colSpan={2} aria-hidden>
        <TextInputField
          className="d-none"
          name={fieldNamePreviousData}
          ref={register({
            required: false,
          })}
        />
        <TextInputField
          className={`p-2 ${elem.fieldType === 'Number' ? '' : 'input-field-row-text'} ${elem.alertMessage ? 'redText' : ''}`}
          type={elem.fieldType}
          onChange={onChange}
          name={fieldName}
          placeholder={get('calculatingQuestion-input-placeholder')}
          ref={register({
            required: true,
          })}
          label=""
          step={elem.fieldType === 'Number' ? '.01' : undefined}
        />
        <QuestionErrors error={fieldError} />
      </td>
    </tr>
  );
};

const CalculatingQuestion = ({
  title,
  questions,
  register,
  answerData,
  setValue,
  errors,
  control,
  answerPreviousYearData,
  instructions,
  getValues,
  unitType,
  colour,
}) => {
  const previousDataString = get('question-previous-data-message');
  const [data, updateData] = useState(questions);
  const [expandedRows, setExpandedRows] = useState({});
  const [groupTotals, setGroupTotals] = useState({});
  const [overallTotal, setOverallTotal] = useState(0);
  const dynamicStyle = { background: colour };
  const [showPreviouseAnswers, setShowPreviouseAnswers] = useState(false);

  const calculateTotals = () => {
    const totals = {};
    let fullTotal = 0;
    const values = getValues();
    questions.forEach((q) => {
      totals[q.id] = 0;
      q.answers.forEach((sq, index) => {
        const fieldTotal = parseFloat(values.question[index].row[q.id][sq.id].value);
        totals[q.id] += Number.isNaN(fieldTotal) ? 0 : fieldTotal;
      });
    });
    Object.values(totals).forEach((groupTotal) => {
      fullTotal += (groupTotal || 0);
    });
    setGroupTotals(totals);
    setOverallTotal(fullTotal);
  };

  // Expand the table rows with errors in the answer.
  useEffect(() => {
    if (errors.question) {
      const erroredKeys = [];
      const erroredKeysParentQID = [];

      // iterate through each error and get the answer id.
      errors.question.forEach((err) => Object.keys(err).forEach((key) => erroredKeys.push(key)));

      // Find the question id for each errored answer id.
      erroredKeys.forEach((key) => questions.forEach((q) => {
        if (q.answers.find((itm) => itm.id === key)) erroredKeysParentQID.push(q.id);
      }));

      // Expand the row of errored questions.
      const expandedRowsCopy = { ...expandedRows };
      const uniqueErroredKeysParentQID = [...new Set(erroredKeysParentQID)]; // unique array
      uniqueErroredKeysParentQID.forEach((key) => {
        expandedRowsCopy[key] = true;
      });
      setExpandedRows(expandedRowsCopy);
    }
  }, [errors]);

  useEffect(() => {
    setShowPreviouseAnswers(!!(!answerData && answerPreviousYearData));
    updateData(questions);
  }, [answerData]);

  return (
    <>
      {showPreviouseAnswers ? (
        <p className="review-page-alert">{previousDataString}</p>
      ) : null}
      <p className="italic">{instructions}</p>

      {data.map((rowQ, index) => {
        if ((rowQ.answers || []).length < 1) {
          return null;
        }

        return (
          <>
            <table className="calculatingQuestionContainer">
              {
                (index === 0) && (
                  <tr>
                    <td className="headerColumn">
                      {renderByUnitType(
                        {
                          ...rowQ.answers[0],
                          unitType,
                          rowId: rowQ.id,
                          alertMessage: showPreviouseAnswers,
                        },
                        index,
                        register,
                        answerData,
                        setValue,
                        errors,
                        control,
                        answerPreviousYearData,
                        true,
                        calculateTotals,
                        {},
                        colour,
                      )}
                    </td>
                  </tr>
                )
              }
            </table>
            <table className="mt-2">
              <tr key={rowQ.id}>
                <td colSpan={4} aria-hidden>
                  <table className="no-border">
                    <tr>
                      <td colSpan={3} className="d-flex">
                        <div className="table-cell-mobile">
                          <div className="inner-table-cell-mobile">
                            <strong>{rowQ.title}</strong>
                            <div className="inner-table-cell">
                              <strong className="">{get('CalculatingQuestion-total-of-all')}</strong>
                              <span style={dynamicStyle} className="badge badge-success mt-2">{(formatTotal(groupTotals[rowQ.id]))}</span>
                            </div>
                          </div>
                          <Button
                            type="button"
                            colour="transparent"
                            className="button-desktop border-0 bg-white ml-2 pr-0"
                            onClick={() => {
                              const expandedRowsCopy = { ...expandedRows };
                              expandedRowsCopy[rowQ.id] = !expandedRows[rowQ.id];
                              setExpandedRows(expandedRowsCopy);
                            }}
                          >
                            {expandedRows[rowQ.id] ? <img src={DownArrowImg} alt="down arrow" /> : <img className="arrow-left-mobile" src={UpArrowImg} alt="down arrow" />}
                          </Button>
                        </div>

                        <div className="table-cell-desktop">
                          <strong>{rowQ.title}</strong>
                          <div className="inner-table-cell">
                            <strong className="">{get('CalculatingQuestion-total-of-all')}</strong>
                            <span style={dynamicStyle} className="badge badge-success ml-4">{(formatTotal(groupTotals[rowQ.id]))}</span>
                            <Button
                              type="button"
                              colour="transparent"
                              className="button-desktop border-0 bg-white ml-2 pr-0"
                              onClick={() => {
                                const expandedRowsCopy = { ...expandedRows };
                                expandedRowsCopy[rowQ.id] = !expandedRows[rowQ.id];
                                setExpandedRows(expandedRowsCopy);
                              }}
                            >
                              {expandedRows[rowQ.id] ? <ArrowUp fill="#1d1d1d" /> : <ArrowDown fill="#1d1d1d" />}
                            </Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              {rowQ.answers.map((q, answerindex) => {
                return true && (
                  <>
                    {
                      renderByUnitType(
                        {
                          ...q,
                          rowId: rowQ.id,
                          unitType,
                          alertMessage: showPreviouseAnswers,
                        },
                        answerindex,
                        register,
                        answerData,
                        setValue,
                        errors,
                        control,
                        answerPreviousYearData,
                        false,
                        calculateTotals,
                        {
                          display: expandedRows[rowQ.id] ? 'table-row' : 'none',
                        },
                        colour,
                      )
                    }
                  </>
                );
              })}
            </table>
          </>
        );
      })}
      <table className="mt-2">
        <tr>
          <td colSpan={2} className="headerColumn">
            <strong>{get('CalculatingQuestion-your-overall')} {title}</strong>
            <p>{get('CalculatingQuestion-calculated-subtotals-description')}</p>
          </td>
        </tr>
        {data.map((rowQ) => {
          return (
            <tr>
              <td><strong>{get('CalculatingQuestion-total')} {rowQ.title}</strong></td>
              <td className="overall-format-total-row">{formatTotal(groupTotals[rowQ.id], get('calculatingQuestion-not-calculated'))}</td>
            </tr>
          );
        })}
        <tr style={dynamicStyle} className="overall-total-row">
          <td>
            <strong>{get('CalculatingQuestion-overall')} {title}:</strong>
          </td>
          <td className="overall-format-total-row">{formatTotal(overallTotal)}</td>
        </tr>
      </table>
    </>
  );
};

CalculatingQuestion.defaultProps = {
  title: '',
  answerData: null,
  errors: undefined,
  userUnits: {},
  answerPreviousYearData: null,
  instructions: '',
  getValues: () => { },
};

CalculatingQuestion.propTypes = {
  title: PropTypes.string,
  colour: PropTypes.string.isRequired,
  errors: PropTypes.shape({ question: PropTypes.array }),
  register: PropTypes.func.isRequired,
  answerData: PropTypes.shape({}),
  answerPreviousYearData: PropTypes.shape({}),
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  instructions: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  unitType: PropTypes.string.isRequired,
  userUnits: PropTypes.shape({
    Area: PropTypes.string,
    'Area Square': PropTypes.string,
    Currency: PropTypes.string,
    Electricity: PropTypes.string,
    'Flow Rate': PropTypes.string,
    Length: PropTypes.string,
    Mass: PropTypes.string,
    'Mass Per Area': PropTypes.string,
    'Mass Per Volume (Water)': PropTypes.string,
    'Micro Flow Rate': PropTypes.string,
    Volume: PropTypes.string,
    'Volume (Gas)': PropTypes.string,
    'Volume (Water)': PropTypes.string,
  }),
};

export default CalculatingQuestion;
