import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '../../core/panel/Panel';
import Set from './AnnualDataSummaryCard/AnnualDataSummaryCard';
import Modal from '../../core/modal/Modal';
import infoImg from '../../../assets/Info.svg';
import './Summary.scss';

const colourCalc = (type) => {
  switch (type) {
    case 'community':
      return '#D8740D';
    case 'resources':
      return '#2D78C8';
    case 'nature':
      return '#4CA471';
    case 'economics':
      return '#fbbc43';
    default:
  }
};

const Summary = ({
  data, type, slug, questionSetId, answers,
}) => {
  const [transformedData, setTransformedData] = useState([]);
  const [openModal, updateOpenModal] = useState(false);
  const [description, updateDescription] = useState('');

  const transformData = async () => {
    const newData = data.map((_item) => {
      const item = { ..._item };
      if (!item.colour) {
        item.colour = colourCalc(_item.slug.toLowerCase());
      }
      return { ...item };
    });
    setTransformedData(newData.filter((item) => item.active));
  };

  useEffect(() => {
    if (!data) return;
    transformData();
  }, [data]);

  const onOpenModal = (index) => {
    updateDescription(transformedData[index].description);
    updateOpenModal(true);
  };

  return (
    <div className="row">
      {
        transformedData
            && transformedData.map((category, index) => {
              return (
                <div key={category.id} className={`col-12 col-sm-6 col-md-12 ${transformedData.length === 4 ? 'col-lg-3' : 'col-lg-4'} summary-columns summary-section-${category.slug}`}>
                  <Panel imageId={category.iconId}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6 className="--dark summary-title"> {category.title}</h6>
                      {category.description ? (
                        <div className="summary-category-info" onClick={() => onOpenModal(index)} aria-hidden>
                          <img src={infoImg} alt="section-info" />
                        </div>
                      ) : null}
                    </div>
                    <Set
                      data={category.subsections}
                      colour={category.colour}
                      type={type}
                      slug={`${slug}${category.id}`}
                      questionSetId={questionSetId}
                      sectionId={category.id}
                      answers={answers}
                    />
                  </Panel>
                </div>
              );
            })
        }
      <Modal
        show={openModal}
        onClose={() => updateOpenModal(false)}
        heading=""
        bodyText={(
          <p>{description}</p>
        )}
      />
    </div>
  );
};

Summary.defaultProps = {
  data: [],
  questionSetId: null,
  answers: [],
};

Summary.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  questionSetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  answers: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Summary;
