/* eslint-disable react/button-has-type */
import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
import { GlobalContext } from '../../../context/GlobalContext';
import themeType from '../../../lib/consts/themeType';

const Button = ({
  form,
  children,
  className,
  type,
  onClick,
  size,
  colour,
  transparent,
  loading,
  styles,
  inverse,
  disableHoverEffect,
  'data-cy': cypressId,
}) => {
  const [hover, setHover] = useState(false);
  const {
    theme,
  } = useContext(GlobalContext);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  const customStyles = useMemo(() => {
    const locColor = (colour === 'primary' ? `var(--${colour})` : colour) || (theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471');

    if (hover && inverse) {
      return {
        color: transparent ? 'black' : 'white',
        backgroundColor: locColor,
        border: `2px solid ${locColor}`,
      };
    }
    if (hover && !inverse) {
      return {
        color: locColor,
        backgroundColor: transparent ? 'transparent' : 'white',
        border: `2px solid ${locColor}`,
      };
    }
    if (inverse) {
      return {
        color: locColor,
        backgroundColor: transparent ? 'transparent' : 'white',
        border: `2px solid ${locColor}`,
      };
    }
    return {
      color: transparent ? 'black' : 'white',
      backgroundColor: locColor,
      border: `2px solid ${locColor}`,
    };
  }, [hover, inverse, colour]);

  return (
    <button
      disabled={!!loading}
      onClick={onClick}
      type={type}
      form={form}
      className={`btn__basic ${className} ${size} ${(loading) ? '--loading' : ''}`}
      style={{
        ...styles,
        ...customStyles,
      }}
      data-cy={cypressId}
      // condionaly spread props
      {...(!disableHoverEffect && {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })}

    >
      <div className={`${loading ? 'd-flex align-center' : ''}`}>
        {children}

        {
          loading && (
            <div className="button__loading-wrapper">
              <svg className="button__loading-spinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
            </div>
          )
        }
      </div>
    </button>
  );
};

Button.defaultProps = {
  children: undefined,
  className: undefined,
  form: undefined,
  type: 'button',
  onClick: () => { },
  styles: {},
  'data-cy': '',
  size: 'large',
  colour: '',
  transparent: false,
  loading: false,
  inverse: false,
  disableHoverEffect: false,
};

Button.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  form: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  styles: PropTypes.shape({}),
  'data-cy': PropTypes.string,
  size: PropTypes.string,
  colour: PropTypes.string,
  transparent: PropTypes.bool,
  inverse: PropTypes.bool,
  disableHoverEffect: PropTypes.bool,
};

export default Button;
