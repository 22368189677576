/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CheckBoxField from '../form/CheckBoxField';
import TextInputField from '../form/TextInputField';
import Label from '../label/Label';
import DropdownField from '../form/DropdownField';
import answerSetUtils from '../../../lib/answerSetUtils';
import { getUnits, getUnitFromPayloadOrFromUtils } from '../../../lib/utils';
import { get } from '../../../lib/strings';
import './NestedQuestions.scss';
import arrayUtils from '../../../utils/array';

const NestedQuestions = ({
  answers,
  control,
  register,
  getValues,
  setValue,
  userUnits,
  userMeasurementUnit,
  answerData,
  answerPreviousYearData,
  instructions,
  colour,
}) => {
  const previousDataString = get('question-previous-data-message');

  const shouldAllowPreviousYearDataToRender = () => {
    let shouldShow = false;
    let hasCurrentYear = false;

    if (answerData && answerData.complete) return false;

    for (let i = 0; i < answers.length; i += 1) {
      const answerFromData = answerSetUtils.pullOutAnswerSet(answers[i].id, answerData);

      if (answerFromData) {
        shouldShow = false;
        hasCurrentYear = true;
        break;
      }
    }

    if (!hasCurrentYear) {
      for (let i = 0; i < answers.length; i += 1) {
        const answer = answerSetUtils.pullOutAnswerSet(answers[i].id, answerPreviousYearData);
        if (answer) {
          shouldShow = true;
          break;
        }
      }
    }

    return shouldShow;
  };

  const [showPreviousDataMessage,
    setShowPreviousDataMessage] = useState(shouldAllowPreviousYearDataToRender());

  const formatArray = (data) => {
    const answersFiltered = data.filter((obj) => {
      return obj.active;
    });

    const answersSorted = answersFiltered.sort((a, b) => a.sortOrder - (b.sortOrder));

    // Sort answers
    return answersSorted;
  };

  useEffect(() => {
    const shouldShowPreviousData = shouldAllowPreviousYearDataToRender();
    setShowPreviousDataMessage(shouldShowPreviousData);

    // functions for setting the saved values
    const formattedAnswers = formatArray(answers);
    formattedAnswers.map((question, index) => {
      const fieldName = `question[${index}].${question.id}`;
      const fieldNamePreviousData = `previousDataQuestions[${index}].${question.id}`;
      const answerFromData = answerSetUtils.pullOutAnswerSet(question.id, answerData);
      const answerFromPreviousYearData = answerSetUtils.pullOutAnswerSet(
        question.id, answerPreviousYearData,
      );

      answerSetUtils.setAnswersHandler(fieldName, answerFromData, setValue);
      setValue(fieldNamePreviousData, false);

      if (shouldShowPreviousData
        && answerFromPreviousYearData
        && answerFromPreviousYearData.value) {
        setValue(fieldNamePreviousData, true);
        answerSetUtils.setAnswersHandler(fieldName, answerFromPreviousYearData, setValue);
      }

      return question.nestedQuestions
        .map((answer) => {
          return answer.rowQuestions
            .map((rowQuestions, rowQuestionsIndex) => {
              const field = `answersFields[${question.id}][${rowQuestionsIndex}].${rowQuestions.id}.value`;
              const unitFieldName = `answersFields[${question.id}][${rowQuestionsIndex}].${rowQuestions.id}.units`;

              // Get saved answers or fill with the previous year
              let fieldValueFromData = answerSetUtils.pullOutAnswerSet(
                rowQuestions.id, answerFromData, true,
              );
              if (showPreviousDataMessage
                && answerFromPreviousYearData
                && answerFromPreviousYearData.value) {
                fieldValueFromData = answerSetUtils.pullOutAnswerSet(
                  rowQuestions.id, answerFromPreviousYearData, true,
                );
              }

              if (fieldValueFromData) {
                answerSetUtils.setAnswersHandler(field, fieldValueFromData, setValue);
              }

              const unitMeasurement = fieldValueFromData && fieldValueFromData.unitMeasurementType
                ? getUnitFromPayloadOrFromUtils(fieldValueFromData) // directly get unit
                : userUnits;
              const unitLabel = typeof unitMeasurement === 'string' || unitMeasurement instanceof String ? unitMeasurement : unitMeasurement[rowQuestions.unitType];
              setValue(unitFieldName, unitLabel);

              return rowQuestions;
            });
        });
    });
  }, [answerData, showPreviousDataMessage]);

  const handleCheckBoxChange = (name, value, question) => {
    if (value) return null;

    // Reset data if user disabled the checkbox
    question.nestedQuestions.map((nestedQuestion) => {
      // eslint-disable-next-line array-callback-return
      return nestedQuestion.rowQuestions.map((rowQuestion, rowQuestionsIndex) => {
        const field = `answersFields[${question.id}][${rowQuestionsIndex}].${rowQuestion.id}.value`;
        const unitFieldName = `answersFields[${question.id}][${rowQuestionsIndex}].${rowQuestion.id}.units`;
        const answerFromPreviousYearData = answerSetUtils.pullOutAnswerSet(
          question.id, answerPreviousYearData,
        );

        if (answerFromPreviousYearData && answerFromPreviousYearData.nestedValues) {
          answerFromPreviousYearData.nestedValues[rowQuestion.id].value = '';
          // eslint-disable-next-line max-len
          answerFromPreviousYearData.nestedValues[rowQuestion.id].unitMeasurementType = userUnits[rowQuestion.unitType];
        }

        setValue(field, '');
        setValue(unitFieldName, userUnits[rowQuestion.unitType]);
      });
    });
  };

  return (
    <>
      {showPreviousDataMessage ? <p className="review-page-alert">{previousDataString}</p> : null}
      <h7 className="italic pl-3">{instructions}</h7>
      {formatArray(answers).map((question, index) => {
        if (!question.active) return null;
        const fieldName = `question[${index}].${question.id}`;
        const fieldNamePrevious = `previousDataQuestions[${index}].${question.id}`;
        return (

          <div
            key={question.id}
            className="components__nested-question"
          // style={{ border: '1px solid #d8d8d8' }}
          >
            <TextInputField
              className="d-none"
              name={fieldNamePrevious}
              ref={register({
                required: false,
              })}
            />
            <CheckBoxField
              key={question.id}
              name={fieldName}
              label={question.title}
              control={control}
              className="chekbox-field-row"
              theme="normal"
              color={colour}
              isRequiredCert={question.requiredForCertification}
              handleChange={(name, value) => handleCheckBoxChange(name, value, question)}
            />
            <div className="--show components__nested-question-field">
              <NestedQuestionsField
                questionId={question.id}
                nestIndex={index}
                userUnits={userUnits}
                userMeasurementUnit={userMeasurementUnit}
                answerData={answerData}
                nestedQuestions={question.nestedQuestions}
                answerPreviousYearData={answerPreviousYearData}
                placeholder={question.placeholder}
                showPreviousDataMessage={showPreviousDataMessage}
                disabled={!getValues(`question[${index}].${question.id}`)}
                {...{ control, register }}
              />
            </div>
          </div>

        );
      })}
    </>
  );
};

const NestedQuestionsField = ({
  questionId,
  register,
  userUnits,
  nestedQuestions,
  control,
  answerData,
  userMeasurementUnit,
  showPreviousDataMessage,
  disabled,
}) => {
  const fieldName = (rowQuestion, index) => { return `answersFields[${questionId}][${index}].${rowQuestion.id}.value`; };
  const unitFieldName = (rowQuestion, index) => { return `answersFields[${questionId}][${index}].${rowQuestion.id}.units`; };

  const formatData = () => {
    const data = [];
    nestedQuestions.map((answer) => {
      if (!answer.active) return null;
      return answer.rowQuestions
        // eslint-disable-next-line array-callback-return
        .map((rowQuestion) => {
          const answerFromData = answerSetUtils.pullOutAnswerSet(questionId, answerData);

          const fieldValueFromData = answerSetUtils.pullOutAnswerSet(
            rowQuestion.id, answerFromData, true,
          );

          const unitMeasurement = fieldValueFromData && fieldValueFromData.unitMeasurementType
            ? getUnitFromPayloadOrFromUtils(fieldValueFromData)// by user selected
            : userUnits;// default mapping

          data.push({
            ...rowQuestion,
            unitMeasurement: fieldValueFromData && fieldValueFromData.unitMeasurementType
              ? fieldValueFromData.unitMeasurementType
              : userMeasurementUnit,
            unitLabel: rowQuestion.unitType ? unitMeasurement[rowQuestion.unitType] : null,
          });
        });
    });

    return data;
  };

  // eslint-disable-next-line no-unused-vars
  const [data, updateData] = useState(formatData());

  return (
    <>
      {data.map((rowQuestion, index) => {
        return (

          <div className={`d-flex flex-row align-items-center nested-question__parent-container ${disabled && 'disable-inputs'}`}>
            {rowQuestion.unitType && <Label text={rowQuestion.title} />}
            <TextInputField
              className={`${showPreviousDataMessage ? 'redText' : ''}`}
              key={rowQuestion.id}
              type={rowQuestion.fieldType}
              step={(rowQuestion.fieldType === 'Number') ? '.01' : undefined}
              name={fieldName(rowQuestion, index)}
              label={`${!rowQuestion.unitType ? rowQuestion.title : ''}`}
              ref={register({
                required: false,
              })}
            />
            {rowQuestion.unitType ? (
              <div className="nested-question__dropdown-container">
                <DropdownField
                  control={control}
                  name={unitFieldName(rowQuestion, index)}
                  className="row-question__dropdown"
                  options={getUnits(rowQuestion.unitType).map(
                    ({ unitLabel, unitType }) => ({ key: unitType, value: `${arrayUtils.getMeasurementUnitValue(unitType, unitType)} (${unitLabel})`, label: unitLabel }),
                  )}
                />
              </div>
            ) : null}
          </div>

        );
      })}
    </>
  );
};

NestedQuestionsField.defaultProps = {
  userUnits: {},
  answerData: {},
  answerPreviousYearData: null,
  disabled: false,
};

NestedQuestionsField.propTypes = {
  answerData: PropTypes.shape({
    complete: PropTypes.bool,
  }),
  answerPreviousYearData: PropTypes.shape({}),
  questionId: PropTypes.string.isRequired,
  userMeasurementUnit: PropTypes.string.isRequired,
  nestedQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  register: PropTypes.func.isRequired,
  userUnits: PropTypes.shape({
    Area: PropTypes.string,
    'Area Square': PropTypes.string,
    Currency: PropTypes.string,
    Electricity: PropTypes.string,
    'Flow Rate': PropTypes.string,
    Length: PropTypes.string,
    Mass: PropTypes.string,
    'Mass Per Area': PropTypes.string,
    'Mass Per Volume (Water)': PropTypes.string,
    'Micro Flow Rate': PropTypes.string,
    Volume: PropTypes.string,
    'Volume (Gas)': PropTypes.string,
    'Volume (Water)': PropTypes.string,
  }),
  showPreviousDataMessage: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

NestedQuestions.defaultProps = {
  onChange: () => { },
  title: '',
  answerData: {},
  userUnits: {},
  answerPreviousYearData: {},
  instructions: '',
  colour: null,
};

NestedQuestions.propTypes = {
  title: PropTypes.string,
  instructions: PropTypes.string,
  onChange: PropTypes.func,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  userMeasurementUnit: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  answerData: PropTypes.shape({
    complete: PropTypes.bool,
  }),
  answerPreviousYearData: PropTypes.shape({}),
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  isCompleteStatus: PropTypes.bool.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  userUnits: PropTypes.shape({
    Area: PropTypes.string,
    'Area Square': PropTypes.string,
    Currency: PropTypes.string,
    Electricity: PropTypes.string,
    'Flow Rate': PropTypes.string,
    Length: PropTypes.string,
    Mass: PropTypes.string,
    'Mass Per Area': PropTypes.string,
    'Mass Per Volume (Water)': PropTypes.string,
    'Micro Flow Rate': PropTypes.string,
    Volume: PropTypes.string,
    'Volume (Gas)': PropTypes.string,
    'Volume (Water)': PropTypes.string,
  }),
  colour: PropTypes.string,
};
export default NestedQuestions;
