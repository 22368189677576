import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import close from '../../../assets/close.svg';
import closeBlue from '../../../assets/close-blue.svg';
import { GlobalContext } from '../../../context/GlobalContext';
import themeType from '../../../lib/consts/themeType';

import './SidebarModal.scss';

const SideBarModal = ({
  show,
  heading,
  bodyText,
  subtitle,
  footer,
  onClose,
}) => {
  if (show) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'unset';
  }
  const { theme } = useContext(GlobalContext);
  return (
    <div className="components__modal-sidebar">
      <div className={`Modal ${show ? 'Show' : ''}`}>
        <div className="modal-container">
          <div className="modal-header">
            <h5>{heading}</h5>
            <button
              type="button"
              className="modal-close-button"
              onClick={onClose}
            >
              {' '}
              <img
                src={theme === themeType.TOURNAMENT ? closeBlue : close}
                alt="close"
              />{' '}
            </button>
          </div>
          <div className="modal-subtitle">{subtitle}</div>
          <div className="modal-body-area">{bodyText}</div>
          <div className="modal-footer-area">{footer}</div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className={`overlay ${show ? 'Show' : ''}`}
        onClick={onClose}
      />
    </div>
  );
};

SideBarModal.defaultProps = {
  footer: undefined,
  subtitle: undefined,
};

SideBarModal.propTypes = {
  show: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  bodyText: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  subtitle: PropTypes.node,
  footer: PropTypes.node,
};

export default SideBarModal;
