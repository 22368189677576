import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../context/GlobalContext';
import themeType from '../../lib/consts/themeType';

const CloseIconAutoColour = ({ fill, className }) => {
  const { theme } = useContext(GlobalContext);
  const colourFromTheme = theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471';

  return (
    <svg className={className} width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
        <g id="Group" transform="translate(2.000000, 2.000000)" stroke={fill || colourFromTheme} strokeWidth="2">
          <line x1="0.111111111" y1="0.111111111" x2="17.8888889" y2="17.8888889" id="Line" />
          <line x1="0.111111111" y1="0.111111111" x2="17.8888889" y2="17.8888889" id="Line" transform="translate(9.000000, 9.000000) scale(1, -1) translate(-9.000000, -9.000000) " />
        </g>
      </g>
    </svg>
  );
};

export default CloseIconAutoColour;

CloseIconAutoColour.defaultProps = {
  fill: '',
  className: '',
};

CloseIconAutoColour.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};
