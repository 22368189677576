import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextInputField from '../form/TextInputField';
import Label from '../label/Label';
import answerSetUtils from '../../../lib/answerSetUtils';
import QuestionErrors from '../../questions-errors/QuestionsErrors';
import DropdownField from '../form/DropdownField';
import { getUnits, defaultUserUnits } from '../../../lib/utils';
import { get } from '../../../lib/strings';
import './RowQuestions.scss';
import arrayUtils from '../../../utils/array';

const _ = require('lodash');

const getUnitFromPayloadOrFromUtils = (answerFromData) => {
  return defaultUserUnits(answerFromData.unitMeasurementType)
    ? defaultUserUnits(answerFromData.unitMeasurementType)
    : answerFromData.unitMeasurementType;
};
const renderByUnitType = (
  elem,
  index,
  register,
  userUnits,
  answerData,
  setValue,
  errors,
  control,
  answerPreviousYearData,
) => {
  const fieldName = `question[${index}].${elem.id}.value`;
  const unitFieldName = `question[${index}].${elem.id}.units`;
  const fieldNamePreviousData = `previousDataQuestions[${index}].${elem.id}`;
  const [fieldError, updateFieldError] = useState({});
  const UNIT_OPTIONS = getUnits(elem.unitType).map(({ unitLabel, unitType }) => ({
    key: unitType,
    value: `${arrayUtils.getMeasurementUnitValue(unitType, unitType)} (${unitLabel})`,
    label: unitLabel,
  }));

  useEffect(() => {
    const answerFromData = answerSetUtils.pullOutAnswerSet(elem.id, answerData);
    const answerFromPreviousYearData = answerSetUtils.pullOutAnswerSet(
      elem.id,
      answerPreviousYearData,
    );

    // Pre fill inputs with the saved answers or show the previous year answers
    if (answerFromData && answerFromData.value) {
      setValue(fieldNamePreviousData, false);
      answerSetUtils.setAnswersHandler(fieldName, answerFromData, setValue);

      const unitMeasurement = answerFromData && answerFromData.unitMeasurementType
        ? getUnitFromPayloadOrFromUtils(answerFromData)
        : userUnits;
      if (unitMeasurement) {
        const unitLabel = typeof unitMeasurement === 'string' || unitMeasurement instanceof String
          ? unitMeasurement
          : unitMeasurement[elem.unitType];
        setValue(unitFieldName, unitLabel);
      }
    } else {
      if (answerFromPreviousYearData && answerFromPreviousYearData.value) {
        setValue(fieldNamePreviousData, true);
      }
      answerSetUtils.setAnswersHandler(fieldName, answerFromPreviousYearData, setValue);

      // eslint-disable-next-line max-len
      const unitMeasurement = answerFromPreviousYearData && answerFromPreviousYearData.unitMeasurementType
        ? getUnitFromPayloadOrFromUtils(answerFromPreviousYearData)
        : userUnits;
      if (unitMeasurement) {
        const unitLabel = typeof unitMeasurement === 'string' || unitMeasurement instanceof String
          ? unitMeasurement
          : unitMeasurement[elem.unitType];
        setValue(unitFieldName, unitLabel);
      }
    }
  }, []);

  useEffect(() => {
    let errorElm = {};
    if (errors && errors.question) {
      errors.question.map((err) => {
        if (err[elem.id] && !elem.optional) {
          errorElm = err[elem.id];
        }
        return err;
      });
    }
    updateFieldError(errorElm.value);
    return () => {};
  }, [errors]);

  return (
    <div className="row-question-field-row" key={elem.id}>
      <Label text={elem.title} />

      <div className="align-items-end d-flex">
        <TextInputField
          className="d-none"
          name={fieldNamePreviousData}
          ref={register({
            required: false,
          })}
        />
        <TextInputField
          className={`${elem.alertMessage ? 'redText' : ''}`}
          type={elem.fieldType}
          name={fieldName}
          ref={register({
            required: !elem.optional,
          })}
          label=""
          step={elem.fieldType === 'Number' ? '.01' : undefined}
        />
        {elem.unitType ? (
          <div className="row-question-unit-selector row-question__dropdown-container">
            <DropdownField
              control={control}
              name={unitFieldName}
              className="row-question__dropdown"
              options={UNIT_OPTIONS}
              errors={errors}
            />
          </div>
        ) : null}
      </div>

      <QuestionErrors error={fieldError} />
    </div>
  );
};

const RowQuestions = ({
  questions,
  register,
  userUnits,
  answerData,
  setValue,
  errors,
  control,
  userMeasurementUnit,
  answerPreviousYearData,
  instructions,
}) => {
  const previousDataString = get('question-previous-data-message');
  const formatData = () => {
    const data = [];

    // eslint-disable-next-line array-callback-return
    questions.map((question) => {
      const answerFromData = answerSetUtils.pullOutAnswerSet(question.id, answerData);
      const answerFromPreviousYearData = answerSetUtils.pullOutAnswerSet(
        question.id,
        answerPreviousYearData,
      );
      const unitMeasurementType = answerFromData && answerFromData.unitMeasurementType
        ? getUnitFromPayloadOrFromUtils(answerFromData)
        : userUnits;

      data.push({
        ...question,
        unitMeasurement:
          answerFromData && answerFromData.unitMeasurementType
            ? answerFromData.unitMeasurementType
            : userMeasurementUnit,
        unitLabel: question.unitType ? unitMeasurementType[question.unitType] : null,
        unitList: question.unitType ? getUnits(question.unitType) : null,
        alertMessage:
          // eslint-disable-next-line max-len
          _.isEmpty(answerFromData)
          && answerFromPreviousYearData
          && answerFromPreviousYearData.value,
      });
    });

    return data;
  };

  const [data, updateData] = useState(formatData());

  useEffect(() => {
    updateData(formatData());
  }, [answerData]);

  return (
    <>
      {data.filter((item) => item.alertMessage).length > 0 ? (
        <p className="review-page-alert">{previousDataString}</p>
      ) : null}
      <h7 className="italic">{instructions}</h7>
      {data.map((elem, index) => renderByUnitType(
        elem,
        index,
        register,
        userUnits,
        answerData,
        setValue,
        errors,
        control,
        answerPreviousYearData,
      ))}
    </>
  );
};

RowQuestions.defaultProps = {
  answerData: null,
  errors: undefined,
  userUnits: {},
  answerPreviousYearData: null,
  instructions: '',
};

RowQuestions.propTypes = {
  errors: PropTypes.shape({}),
  register: PropTypes.func.isRequired,
  userMeasurementUnit: PropTypes.string.isRequired,
  answerData: PropTypes.shape({}),
  answerPreviousYearData: PropTypes.shape({}),
  setValue: PropTypes.func.isRequired,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  instructions: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  userUnits: PropTypes.shape({
    Area: PropTypes.string,
    'Area Square': PropTypes.string,
    Currency: PropTypes.string,
    Electricity: PropTypes.string,
    'Flow Rate': PropTypes.string,
    Length: PropTypes.string,
    Mass: PropTypes.string,
    'Mass Per Area': PropTypes.string,
    'Mass Per Volume (Water)': PropTypes.string,
    'Micro Flow Rate': PropTypes.string,
    Volume: PropTypes.string,
    'Volume (Gas)': PropTypes.string,
    'Volume (Water)': PropTypes.string,
  }),
};

export default RowQuestions;
