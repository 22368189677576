import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import chevron from '../../../assets/green-arrow.svg';
import chevronblue from '../../../assets/blue-arrow.svg';
import './InternalBackLink.scss';
import { get } from '../../../lib/strings';
import themeType from '../../../lib/consts/themeType';
import { GlobalContext } from '../../../context/GlobalContext';

const InternalBackLink = () => {
  const history = useHistory();
  const { theme } = useContext(GlobalContext);

  const goBackHandler = () => history.goBack();
  return (
    <button
      className="internal-back-link"
      type="button"
      onClick={goBackHandler}
    >
      <img
        className="internal-back-link__chevron"
        src={theme === themeType.TOURNAMENT ? chevronblue : chevron}
        alt="chevron back"
      />{' '}
      {get('general-back')}
    </button>
  );
};

InternalBackLink.propTypes = {};
export default InternalBackLink;
