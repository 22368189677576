import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import PageLoader from '../../../../components/Loaders/PageLoader';
import reviewsService from '../../../../services/reviews/reviews-service';
import { GlobalContext } from '../../../../context/GlobalContext';
import SectionRenderer from '../../../../components/review/SectionRenderer/SectionRenderer';
import InternalBackLink from '../../../../components/core/link/InternalBackLink';
import questionSetUtils from '../../../../lib/questionSets';
import InternalLink from '../../../../components/core/link/InternalLink';
import { get } from '../../../../lib/strings';
import './BestPracticesWrapper.scss';

const BestPracticesWrapper = () => {
  const [loading, updateLoading] = useState(true);
  const [, updateQuestions] = useState({});
  const [setData, updateSetData] = useState({});
  const [answerData, updateAnswers] = useState({});

  const { type, sectionId, reviewType } = useParams();
  const { org, user, reviewLinks } = useContext(GlobalContext);
  const { title, id, colour } = reviewsService.getSectionBySlug('bestPractices', type, reviewLinks);

  useEffect(() => {
    const getData = async () => {
      if (id) {
        updateLoading(true);
        const questionData = await reviewsService.getOrganisationQuestionsById(
          id, org.organisation_id, user.language,
        );

        const { section } = questionSetUtils.getPageDataFromQuestionObjectById(
          questionData, sectionId,
        );

        const answers = await reviewsService.getAnswerSetsByOrg(org.organisation_id, id);
        updateLoading(false);
        updateAnswers(answers[0]);
        updateSetData(section);
        updateQuestions(questionData);
      }
    };
    getData();
  }, [id, sectionId]);

  const onComplete = async (payload) => {
    await reviewsService.patchAnswerSet(org.organisation_id, answerData.id, payload);
    const answers = await reviewsService.getAnswerSetsByOrg(org.organisation_id, id);
    updateAnswers(answers[0]);
  };

  const notesHandlers = {
    deleteNote: async (noteId, questionId) => reviewsService.deleteNotes(
      org.organisation_id, answerData.id, noteId, questionId,
    ),
    updateNotes: async (payload) => reviewsService.postNotes(
      org.organisation_id, answerData.id, payload,
    ),
    editNotes: (questionId, noteId, payload) => reviewsService.editNotes(
      org.organisation_id, answerData.id, noteId, questionId, payload,
    ),
  };

  return (
    <>
      { loading && <PageLoader />}
      <div className="page best-practices">
        <div className="page-header">
          <InternalBackLink />
          <h5 className="review-page-title --capitalize">
            <InternalLink to="/review/best-practice" className="breadcrumb-link light-font"> {get('bestPractices-SummaryTitle')}</InternalLink>
            {' '} -
            <InternalLink to={`/review/${reviewType}/summary#${type}`} className="breadcrumb-link"> {title}</InternalLink>
          </h5>
          <div className="d-flex flex-row justify-content-between align-items-center pr-3">
            <h2 className="review-page-section-title"> {setData.title} </h2>
          </div>
          <SectionRenderer
            data={setData}
            onComplete={onComplete}
            answerData={answerData.answers}
            notesHandlers={notesHandlers}
            colour={colour}
          />
        </div>
      </div>
    </>
  );
};

BestPracticesWrapper.propTypes = {

};
export default BestPracticesWrapper;
