import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../context/GlobalContext';
import Loader from '../../components/Loaders/PageLoader';
import FacilityDetailsForm from '../../components/FacilityProfile/facilityDetails-form/FacilityDetailsForm';
import organisationService from '../../services/organisation/organisation-service';
import tournamentsService from '../../services/tournaments/tournaments-service';

import { get } from '../../lib/strings';

import './FacilityDetails.scss';

const FacilityDetails = ({ organisationId, detailFormDirtyRef }) => {
  const { org, updateOrg } = useContext(GlobalContext);
  const [orgDetails, updateOrgDetails] = useState({});
  const [tournaments, updateTournaments] = useState([]);
  const [groups, updateGroups] = useState([]);
  const [isPageLoading, updateIsPageLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [isNeedReRender, setIsNeedReRender] = useState(false);
  const genericError = get('facilityProfileDetails-genericError');

  useEffect(() => {
    const getOrganisationDetails = async () => {
      const orgDetailsResult = await organisationService.getOrganisationDetails(organisationId);

      if (orgDetailsResult.ok) {
        updateOrgDetails(orgDetailsResult.data);
      }
    };

    const getTournaments = async () => {
      const tournamentsResult = await tournamentsService.getTournaments();

      if (tournamentsResult.ok) {
        updateTournaments(tournamentsResult.data.tournaments);
      }
    };

    const getOrganisationGroups = async () => {
      const groupsResult = await organisationService.getOrganisationGroups();

      if (groupsResult) {
        updateGroups(groupsResult);
      }
    };

    const fetchData = async () => {
      updateIsPageLoading(true);
      await getOrganisationDetails();
      await getTournaments();
      await getOrganisationGroups();
      updateIsPageLoading(false);
    };

    fetchData();
    setIsNeedReRender(false);
  }, [isNeedReRender]);

  const onComplete = async (data) => {
    setError(undefined);
    const result = await organisationService.updateOrganisationCourses(
      organisationId,
      { organisation_id: organisationId, ...data },
    );

    if (!result.ok) {
      setError(genericError);
    }

    // Force org update to show the new oncourse logo
    const updatedOrg = { ...org };
    if (data && typeof data.tournament_organiser !== 'undefined') {
      updatedOrg.trounamentGroup = data.tournament_organiser ? parseInt((data.tournament_organiser || '').split(' -')[0], 10) : null;
    }

    if (data && typeof data.management_group !== 'undefined') {
      updatedOrg.organisationGroup = data.management_group ? parseInt((data.management_group || '').split(' -')[0], 10) : null;
    }

    updateOrg(updatedOrg);

    setIsNeedReRender(true);
  };

  return (
    <>
      <Loader isLoading={isPageLoading}>
        <div className="pt-5">
          <FacilityDetailsForm
            onComplete={onComplete}
            orgDetails={orgDetails}
            tournaments={tournaments}
            groups={groups}
            error={error}
            ref={detailFormDirtyRef}
          />
        </div>
      </Loader>
    </>
  );
};

FacilityDetails.defaultProps = {
  detailFormDirtyRef: PropTypes.shape({ current: undefined }),
};

FacilityDetails.propTypes = {
  organisationId: PropTypes.number.isRequired,
  detailFormDirtyRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default FacilityDetails;
