import React, { useState, useEffect, useContext } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { GlobalContext } from '../../../../context/GlobalContext';
import { get } from '../../../../lib/strings';
import reviewsService from '../../../../services/reviews/reviews-service';
import PageLoader from '../../../../components/Loaders/PageLoader';
import TabSwitch from '../../../../components/core/tabSwitch/TabSwitch';
import SummaryCards from '../../../../components/review/Summary/BestPracticesSummaryCard/BestPracticesSummaryCard';
import themeType from '../../../../lib/consts/themeType';

const susOutActiveTab = (hash, reviewLinks, setTabIndex) => {
  let activeSectionIndex;
  reviewLinks.bestPractices.map((link, index) => {
    if (`#${link.slug}` === hash) {
      activeSectionIndex = index;
    }

    return {};
  });
  if (activeSectionIndex === 0 || activeSectionIndex) {
    setTabIndex(activeSectionIndex);
  }
};

const BestPracticesSummary = () => {
  const [questions, updateQuestions] = useState({});
  const [loading, updateLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [labels, updateLabels] = useState();

  const { hash } = useLocation();
  const history = useHistory();

  const {
    org, user, reviewLinks, theme,
  } = useContext(GlobalContext);
  const questionSetIds = reviewsService.getQuestinSetIds(reviewLinks);

  const renderTabContent = (index) => {
    return reviewLinks.bestPractices
      .map((questionSet, arrayIndex) => {
        if (index === arrayIndex) {
          return (
            <SummaryCards
              data={questions[arrayIndex].sections}
              type={questionSet.slug}
              colour={questionSet.colour}
            />
          );
        }
        return <></>;
      });
  };

  useEffect(() => {
    const getData = async () => {
      if (questionSetIds && questionSetIds.length > 0) {
        const questionData = await Promise.all(questionSetIds.map(async (id) => {
          const questionSet = await reviewsService.getOrganisationQuestionsById(
            id, org.organisation_id, user.language,
          );

          return questionSet;
        }));
        const questonLabels = questionData.map((question) => question.title);
        susOutActiveTab(hash, reviewLinks, setTabIndex);
        updateLabels(questonLabels);
        updateQuestions(questionData);
        updateLoading(false);
      }
    };

    getData();
  }, [reviewLinks]);

  useEffect(() => {
    if (!loading) {
      susOutActiveTab(hash, reviewLinks, setTabIndex);
    }
  }, [hash]);

  const onClickTab = (index) => {
    setTabIndex(index);
    history.push(`#${reviewLinks.bestPractices[index].slug}`);
  };

  const colourCalc = () => {
    if (reviewLinks && reviewLinks.bestPractices) {
      return reviewLinks.bestPractices[tabIndex].colour;
    }
  };

  return (
    <PageLoader isLoading={loading}>
      <div className={`${theme === themeType.TOURNAMENT ? 'img-tour page' : 'img-facility page'}`}>
        <div className="page-header">
          <div className="annual-summary__header-container">
            <div>
              <h5 className="bold --dark review-page-title --capitalize ">{get('bestPractices-SummaryPageTitle')}</h5>
              <h2 className="review-page-section-title">{get('bestPractices-SummaryTitle')}</h2>
            </div>
          </div>
          <TabSwitch onClick={onClickTab} labels={labels} selectedTab={tabIndex} colour={colourCalc()} className="mb-4" hash={hash} />
          {
            (questions && questions.length)
            && renderTabContent(tabIndex)
          }
        </div>
      </div>
    </PageLoader>
  );
};

export default BestPracticesSummary;
