import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BootstrapModal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import validator from 'validator';
import { GlobalContext } from '../../../context/GlobalContext';
import TextInputField from '../../core/form/TextInputField';
import GenericDropdownField from '../../core/form/GenericDropdownField';
import CheckBoxField from '../../core/form/CheckBoxField';
import Alert from '../../core/alert/Alert';
import Button from '../../core/button/Button';
import arrayUtils from '../../../utils/array';
import close from '../../../assets/close.svg';
import closeBlue from '../../../assets/close-blue.svg';
import TermsAndConditions from '../../../pages/termsAndConditions/TermsAndConditions';
import themeType from '../../../lib/consts/themeType';
import { get, stringSanitizer } from '../../../lib/strings';

import './RegistrationForm.scss';

const RegistrationForm = ({ onComplete, registrationError, newUserEmail }) => {
  const { supportedLanguages = [], theme } = useContext(GlobalContext);
  const [openTC, setTC] = useState(false);
  const dropdownStatus = arrayUtils.getRoleOptions();
  const dropdownAboutUs = arrayUtils.getAboutUsOptions();
  const defaultValue = get('registration-formDropdownOptions-default');
  const fromRequiredFieldError = get('registration-fromErrorRequiredField');
  const formAcceptTermsError = get('registration-formErrorAcceptTerms');
  const formInvalidEmailError = get('registration-formInvalidEmailError');

  const keyMap = {
    value: 'label',
    key: 'value',
  };

  const supportedLanguagesOptions = supportedLanguages.map((obj) => {
    return _.mapKeys(obj, (value, key) => {
      return keyMap[key];
    });
  });

  const {
    register, handleSubmit, errors, control, setValue,
  } = useForm({
    defaultValues: {
      dropdownHearAbout: defaultValue,
      dropdownLanguage: defaultValue,
      dropdownStatus: defaultValue,
      checkBoxCorrispondence: false,
      checkBoxTerms: false,
    },
  });

  useEffect(() => {
    setValue('email', newUserEmail);
  }, [newUserEmail]);

  const onSubmit = (data) => {
    const formData = data;

    if (formData.dropdownHearAbout === defaultValue) {
      formData.dropdownHearAbout = null;
    }

    return onComplete(formData);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setTC(true);
  };

  return (
    <div>
      <Form
        className="components__registration_form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="registration-form-name">
          <TextInputField
            themeColor="black"
            type="firstName"
            name="firstName"
            label={get('registration-formFirstName')}
            placeholder={get('registration-formFirstNamePlaceholder')}
            className="input-field-row"
            data-cy="firstName-input"
            ref={register({
              required: true,
            })}
            errors={errors}
          />

          <TextInputField
            themeColor="black"
            type="lastName"
            name="lastName"
            label={get('registration-formLastName')}
            placeholder={get('registration-formLastNamePlaceholder')}
            className="input-field-row"
            data-cy="lastName-input"
            ref={register({
              required: true,
            })}
            errors={errors}
          />
        </div>

        <TextInputField
          themeColor="black"
          type="emailAddress"
          name="email"
          label={get('registration-formEmailAddress')}
          placeholder={get('login-formEmailAddressPlaceholder')}
          className="input-field-row"
          data-cy="email-input"
          ref={register({
            required: true,
            validate: {
              isEmail: (value) => {
                return validator.isEmail(value) || formInvalidEmailError;
              },
            },
          })}
          errors={errors}
        />

        <div className="pt-1" />

        <GenericDropdownField
          control={control}
          name="dropdownLanguage"
          label={get('registration-formLanguageTitle')}
          variant="dark"
          isSearchable
          className="input-field-row"
          options={supportedLanguagesOptions}
          errors={errors}
          validationRules={(value) => {
            if (!value || supportedLanguagesOptions.some((el) => el.value === value)) {
              return true;
            }
            return fromRequiredFieldError;
          }}
        />

        <GenericDropdownField
          control={control}
          name="dropdownStatus"
          label={get('registration-formRoleTitle')}
          variant="dark"
          className="input-field-row"
          options={dropdownStatus}
          errors={errors}
          validationRules={(value) => {
            if (!value || dropdownStatus.some((el) => el.value === value)) {
              return true;
            }
            return fromRequiredFieldError;
          }}
        />

        <GenericDropdownField
          control={control}
          name="dropdownHearAbout"
          label={get('registration-formAboutUsTitle')}
          variant="dark"
          className="input-field-row"
          options={dropdownAboutUs}
          errors={errors}
          validationRules={(value) => {
            if (!value || dropdownAboutUs.some((el) => el.value === value)) {
              return true;
            }
            return fromRequiredFieldError;
          }}
        />

        <div className="pt-1" />

        <CheckBoxField
          control={control}
          name="checkBoxCorrispondence"
          label={get('registration-formCorrespondenceTitle')}
          theme="dark"
          className="input-field-row"
        />

        <CheckBoxField
          control={control}
          name="checkBoxTerms"
          theme="dark"
          className="input-field-row"
          errors={errors}
          validationRules={(value) => {
            if (value) {
              return true;
            }

            return formAcceptTermsError;
          }}
        >
          <div
            className="registration-terms"
            onClick={handleClick}
            aria-hidden
             // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: stringSanitizer(get('registration-formTermsTitle')),
            }}
          />
        </CheckBoxField>

        {registrationError && (
        <Alert data-cy="registration-error" className="alert" variant="danger">
          {registrationError}
        </Alert>
        )}

        <div className="d-flex justify-content-center pt-4">
          <Button
            transparent
            type="submit"
            className="registration-button medium"
            data-cy="register-button"
          >
            {get('registration-formConfirmButton')}
          </Button>
        </div>
      </Form>
      <BootstrapModal
        fullscreen
        show={openTC}
        onHide={() => setTC(false)}
        enforceFocus
        dialogClassName="tc-dialog"
        contentClassName="tc-content"
      >
        <div>
          <button
            type="button"
            className="tc-modal-close-button"
            onClick={() => setTC(false)}
          >
            {' '}
            <img
              src={theme === themeType.TOURNAMENT ? closeBlue : close}
              alt="close"
            />{' '}
          </button>
          <TermsAndConditions hasLogo={false} />
        </div>
      </BootstrapModal>
    </div>

  );
};

RegistrationForm.defaultProps = {
  registrationError: undefined,
  newUserEmail: undefined,
};

RegistrationForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  registrationError: PropTypes.string,
  newUserEmail: PropTypes.string,
};

export default RegistrationForm;
