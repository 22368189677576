import HTTPError from '../../lib/errors/http-error';
import api from '../../lib/http';

export default {
  getSupportedLanguage: async () => {
    const response = await api(
      'get',
      'language/supported-languages',
      false,
      false,
      true,
    );

    if (!response.ok) {
      throw new HTTPError(response.status, response.data);
    }

    return response;
  },

  supportedLanguageFormatted: (languages = []) => {
    const languageFormatted = [];
    // eslint-disable-next-line array-callback-return
    languages.map((item) => {
      languageFormatted.push({
        value: item.language,
        key: item.countryCode,
      });
    });

    return languageFormatted;
  },

  getLanguageCode: (supportedLanguage = [], name) => {
    let languageCode = 'en'; // set default

    const filter = supportedLanguage.filter((item) => {
      return item.value === name;
    });

    if (filter.length > 0) {
      languageCode = filter[0].key;
    }

    return languageCode;
  },

  getCountries: async () => {
    const response = await api(
      'get',
      'language/countires',
      false,
      false,
      true,
    );

    if (!response.ok) {
      throw new HTTPError(response.status, response.data);
    }

    return response;
  },
};
