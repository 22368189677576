import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextInputField from '../../form/TextInputField';
import HiddenField from '../../form/HiddenField';
import './FormModal.scss';
import { get } from '../../../../lib/strings';

const EmployeeModal = ({
  answer,
  rowQuestions,
  nestedQuestion,
  control,
  register,
  setValue,
  getValues,
  errors,
}) => {
  const [totalRowQuestionId, setTotalRowQuestionId] = useState(undefined);
  const [totalIdWithAnswers, setTotalIdWithAnswers] = useState({});
  // total translatable string
  const isTranslatedTotalString = get('question-addDataGrid-total');

  useEffect(() => {
    if (!rowQuestions) return;
    const rowAnswerIds = [];
    let totalId;
    rowQuestions.forEach((rowQuestion) => {
      if (rowQuestion.title === isTranslatedTotalString) {
        totalId = rowQuestion.id;
        setTotalRowQuestionId(rowQuestion.id);
      } else { rowAnswerIds.push(rowQuestion.id); }
    });
    setTotalIdWithAnswers({
      [totalId]: rowAnswerIds,
    });
  }, []);

  const sumValueAndSetToTotal = (e, title) => {
    if (title === isTranslatedTotalString) return;
    const allValues = totalIdWithAnswers[totalRowQuestionId].map((id) => getValues(id));
    const sumValue = allValues.filter((n) => n).reduce((a, b) => a * 1 + b * 1);
    setValue(totalRowQuestionId, sumValue);
  };

  return (
    rowQuestions.map((rowQuestion, idx) => {
      return (
        <div key={rowQuestion.id} className="form-modal-question-container">
          <div className="d-flex form-modal-question-container-child">
            <TextInputField
              key={answer.id}
              type={rowQuestion.fieldType}
              name={rowQuestion.id}
              label={rowQuestion.title}
              className="input-data-grid-field-row"
              errors={errors.textInput && errors.textInput[idx] ? { [`textInput[${idx}]`]: errors.textInput[idx] } : {}}
              ref={register({
                required: true,
              })}
              onChange={(e) => sumValueAndSetToTotal(
                e, rowQuestion.title, isTranslatedTotalString,
              )}
            />
          </div>

          <HiddenField
            name={`nestedQuestionId__${nestedQuestion.id}__${rowQuestion.id}`}
            control={control}
            defaultValue={{
              type: 'TEXTINPUTS',
              fieldType: rowQuestion.fieldType,
              nestedQuestionId: nestedQuestion.id,
              columnName: `${nestedQuestion.title} ${rowQuestion.title}`,
            }}
          />
        </div>
      );
    })

  );
};
EmployeeModal.defaultProps = {
  errors: {},
};

EmployeeModal.propTypes = {
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({}),
  ]),
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  nestedQuestion: PropTypes.shape({}).isRequired,
};
export default EmployeeModal;
