import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Accordion, useAccordionToggle } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import arrowblue from '../../../assets/blue-arrow.svg';
import FlagIcon from '../../../assets/svgs-as-components/flag-icon/FlagIcon';
import { get } from '../../../lib/strings';
import themeType from '../../../lib/consts/themeType';
import { GlobalContext } from '../../../context/GlobalContext';

import ArrowIconAutoColour from './ArrowIconAutoColour';

import './AccordionCard.scss';

const AccordionCard = ({
  headerTitle,
  children,
  eventKey,
  bodyClassName,
  colour,
  secondary,
  StatusIcon,
  isRequiredCert,
  withoutBg,
}) => {
  const { theme } = useContext(GlobalContext);
  const CustomToggle = ({ callback }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
    const currentEventKey = React.useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey && eventKey && currentEventKey === eventKey
      ? true
      : currentEventKey && eventKey
      && currentEventKey.toString().toUpperCase() === eventKey.toString().toUpperCase();

    return (
      <div
        className={`accordion-card-header  ${secondary && '--black'}`}
        onClick={decoratedOnClick}
        aria-hidden="true"
      >
        <div className="d-flex accordion-card-header" style={{ color: colour }}>
          {headerTitle}
          {isRequiredCert ? <FlagIcon hoverText={get('icon-hover-text-cert-required')} /> : null}
        </div>
        {StatusIcon && <div className={secondary ? 'accordion-card-header_statusIcon' : 'accordion-card-header_statusIcon --marginRight'}><StatusIcon fill={colour} /></div>}
        {/* eslint-disable-next-line no-nested-ternary */}
        {secondary
          ? <p className="accordion-card-header-title" style={{ color: colour }}>{`${isCurrentEventKey ? get('general-hide') : get('general-show')}`}</p>
          : (
            theme === themeType.TOURNAMENT
              ? <img className={`accordion-card-header-arrow ${isCurrentEventKey && '--arrow-up'}`} src={arrowblue} alt="Nature" /> : <div className={`accordion-card-header-arrow ${isCurrentEventKey && '--arrow-up'}`}><ArrowIconAutoColour fill={colour} /></div>
          )}
      </div>
    );
  };

  CustomToggle.defaultProps = {
    callback: () => {},
  };

  CustomToggle.propTypes = {
    callback: PropTypes.func,
  };

  return (
    <div className={`components__accordion-card ${withoutBg && '--withoutBg'}`}>
      <div className={`accordion-card-container ${secondary && '--secondary'} ${withoutBg && '--withoutBg'}`}>
        <CustomToggle eventKey={eventKey}>{headerTitle}</CustomToggle>
        <Accordion.Collapse eventKey={eventKey}>
          <div className="accordion-card-body">
            <div className={`accordion-card-divider ${secondary && '--small-divider'} ${withoutBg && '--no-divider'}`} />
            <div className={`accordion-card-body-wrapper ${bodyClassName}`}>
              {children}
            </div>
          </div>
        </Accordion.Collapse>
      </div>
    </div>
  );
};

AccordionCard.defaultProps = {
  eventKey: 0,
  bodyClassName: undefined,
  secondary: false,
  withoutBg: false,
  StatusIcon: undefined,
  isRequiredCert: false,
  colour: '#4ca471',
};

AccordionCard.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  eventKey: PropTypes.number,
  bodyClassName: PropTypes.string,
  colour: PropTypes.string,
  secondary: PropTypes.bool,
  withoutBg: PropTypes.bool,
  StatusIcon: PropTypes.string,
  isRequiredCert: PropTypes.bool,
};

export default AccordionCard;
