import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import TextInputField from '../../core/form/TextInputField';
import Button from '../../core/button/Button';
import { get } from '../../../lib/strings';

import './ResetPasswordForm.scss';

const ResetPasswordForm = ({ onComplete }) => {
  const { register, handleSubmit, errors } = useForm();
  const formInvalidEmailError = get('forgottenPassword-formInvalidEmailError');

  return (
    <div className="components__reset_password_form">
      <p className="forgotten-description">
        {get('forgottenPassword-formDescription')}
      </p>

      <Form onSubmit={handleSubmit(onComplete)}>
        <TextInputField
          type="emailAddress"
          name="email"
          label={get('forgottenPassword-formEmailAddress')}
          placeholder={get('forgottenPassword-formEmailAddressPlaceholder')}
          className="input-field-row"
          data-cy="email-input"
          ref={register({
            required: true,
            validate: {
              isEmail: (value) => {
                return validator.isEmail(value) || formInvalidEmailError;
              },
            },
          })}
          errors={errors}
        />
        <div className="card-buttons-wrapper">
          <Button
            type="submit"
            className="reset-password-button"
            data-cy="reset-password-button"
          >
            {get('forgottenPassword-formResetButton')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
