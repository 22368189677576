import React, {
  useContext, useEffect, useState, useImperativeHandle, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalContext';
import Alert from '../../core/alert/Alert';
import ImageUpload from '../../core/form/ImageUpload';
import TextInputField from '../../core/form/TextInputField';
import Button from '../../core/button/Button';
import mediaService from '../../../services/media/media-service';
import ErrorCodes from '../../../lib/error-codes';
import { get } from '../../../lib/strings';
import profileNoImage from '../../../assets/profile-no-image.svg';
import profileNoImageTour from '../../../assets/profile-no-image-tour.svg';
import Loader from '../../Loaders/PageLoader';
import RouteLeavingGuard from '../../warningUnSaveGuardModal/RouteLeavingGuard';
import themeType from '../../../lib/consts/themeType';

import './ProfileDetailsForm.scss';

const ProfileDetailsForm = forwardRef(({
  onComplete, profileError, className, openPasswordModal,
}, ref) => {
  const [error, setError] = useState(undefined);
  const [logo, updateImage] = useState('');
  const { user, theme, updateUserProfileImage } = useContext(GlobalContext);

  const formInvalidEmailError = get('registration-formInvalidEmailError');

  const formImageTooBigError = get('component-imageTooBigError');
  const formInvalidFileError = get('component-imageInvalidType');
  const [isPageLoading, updateIsPageLoading] = useState(false);

  const {
    register, handleSubmit, errors, control, formState: {
      dirtyFields,
    },
  } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    },
  });
  // check real dirty via hook value
  const isDirtyAlt = !!Object.keys(dirtyFields).length;

  useImperativeHandle(ref, () => ({ getDirtyState() { return isDirtyAlt; } }));
  const history = useHistory();
  const getProfilePhoto = async () => {
    if (user.image_id) {
      const result = await mediaService.getProfilePhoto(user.image_id);

      if (result) {
        updateImage(result);
      }
    }
  };

  useEffect(() => {
    getProfilePhoto();
  }, [user]);

  const onSubmit = (data) => {
    updateIsPageLoading(true);

    const mappedUser = {
      email_address: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
    };

    onComplete(mappedUser);
    setTimeout(() => {
      updateIsPageLoading(false);
    }, 300);
  };

  const onUploadImage = async (image, imageName, imageCropped, callback) => {
    updateIsPageLoading(true);
    const formData = new FormData();

    formData.append('file', image, imageName);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const result = await mediaService.uploadProfilePhoto(user.id, formData, config);

    if (!result.ok) {
      if (result.data.errorCode === ErrorCodes.FILE_TOO_LARGE) {
        setError(formImageTooBigError);
        return null;
      }

      if (result.data.errorCode === ErrorCodes.INCORRECT_FORMAT) {
        setError(formInvalidFileError);
        return null;
      }
    }

    // Update user context with new image id
    updateUserProfileImage(result.data.recordId);
    callback();
    setTimeout(() => {
      updateIsPageLoading(false);
    }, 300);
  };

  return (
    <div className={`components_profile-details-form ${className}`}>
      <p className="profile-details-form-title">{get('profile-tabGeneralInfoCardPersonalDetailsTitle')}</p>
      <div>
        <Form
          id="my-form"
          className="profile-details-form-wrapper"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Loader isLoading={isPageLoading}>
            <ImageUpload
              control={control}
              src={logo}
              name="profileImage"
              label={get('profile-tabGeneralInfoCardPersonalDetailsProfileImage')}
              cropModalTitle={get('profile-tabGeneralInfo-cropModalTitle')}
              cropModalButtonText={get('profile-tabGeneralInfo-cropModalButton')}
              onImageUpload={onUploadImage}
              onCloseModal={() => setError(undefined)}
              imagePlaceholder={theme === themeType.TOURNAMENT
                ? profileNoImageTour
                : profileNoImage}
              error={error}
            />
            <div className="profile-details-form-details-wrapper">
              <div className="profile-details-form-details">
                <TextInputField
                  type="firstName"
                  name="firstName"
                  label={get('profile-tabGeneralInfoCardPersonalDetailsFirstName')}
                  placeholder={get('profile-tabGeneralInfoCardPersonalDetailsFirstNamePlaceholder')}
                  className="input-field-row"
                  data-cy="firstName-input"
                  ref={register({
                    required: true,
                    maxLength: 500,
                  })}
                  errors={errors}
                />

                <TextInputField
                  type="lastName"
                  name="lastName"
                  label={get('profile-tabGeneralInfoCardPersonalDetailsLastName')}
                  placeholder={get('profile-tabGeneralInfoCardPersonalDetailsLastNamePlaceholder')}
                  className="input-field-row"
                  data-cy="LastName-input"
                  ref={register({
                    required: true,
                    maxLength: 500,
                  })}
                  errors={errors}
                />
              </div>
              <div className="profile-details-form-details">
                <TextInputField
                  type="email"
                  name="email"
                  label={get('profile-tabGeneralInfoCardPersonalDetailsEmail')}
                  placeholder={get('profile-tabGeneralInfoCardPersonalDetailsEmailPlaceholder')}
                  className="input-field-row"
                  data-cy="email-input"
                  ref={register({
                    required: true,
                    maxLength: 254,
                    validate: {
                      isEmail: (value) => {
                        return validator.isEmail(value) || formInvalidEmailError;
                      },
                    },
                  })}
                  errors={errors}
                />
                <div className="d-flex justify-content-end align-items-end">
                  <Button className="profile-details-form-password-button" onClick={openPasswordModal}>
                    {get('profile-details-form-changePassword-button')}
                  </Button>
                </div>

              </div>
            </div>

            {profileError && (
            <Alert data-cy="login-error" className="alert" variant="danger">
              {profileError}
            </Alert>
            )}
          </Loader>
        </Form>
      </div>
      <RouteLeavingGuard
            // When should shouldBlockNavigation be invoked,
            // simply passing a boolean
        when={isDirtyAlt}
            // Navigate function
        navigate={(path) => history.push(path)}
            // Use as "message" prop of Prompt of React-Router
        // eslint-disable-next-line no-unused-vars
        shouldBlockNavigation={(location) => {
          // is able to filter specific location
          if (isDirtyAlt) return true;
          return false;
        }}
      />
    </div>
  );
});

ProfileDetailsForm.propTypes = {};

ProfileDetailsForm.defaultProps = {
  profileError: undefined,
  className: undefined,
};

ProfileDetailsForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  openPasswordModal: PropTypes.func.isRequired,
  profileError: PropTypes.string,
  className: PropTypes.string,
};

export default ProfileDetailsForm;
