/* eslint-disable max-len */
import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';
import Permissions from '../lib/permissions';
import BestPracticesSummry from '../pages/review/best-practices/BestPracticesSummary/BestPracticesSummary';
import AnnualDataWrapper from '../pages/review/annual-review/AnnualDataWrapper/AnnualDataWrapper';
import BestPracticesWrapper from '../pages/review/best-practices/BestPracticesWrapper/BestPracticesWrapper';
import AnnualReviewSummary from '../pages/review/annual-review/AnnualReviewSummary/AnnualReviewSummary';
import Review from '../pages/review/Review';

const DynamicRoutes = () => {
  const globalContext = useContext(GlobalContext);
  const { org } = globalContext;

  return (
    <>
      {Permissions.doesUserHaveActivities(org.activities,
        [Permissions.activities.REVIEWS])
              && (
                <Route exact path="/annual-review/:reviewType" component={Review} />
              )}

      {Permissions.doesUserHaveActivities(org.activities,
        [Permissions.activities.REVIEWS])
            && <Route exact path="/annual-review/:reviewType/:year" component={AnnualReviewSummary} />}

      {Permissions.doesUserHaveActivities(org.activities,
        [Permissions.activities.REVIEWS])
              && <Route exact path="/annual-review/:reviewType/:year/:type/:sectionId" component={AnnualDataWrapper} />}

      {Permissions.doesUserHaveActivities(org.activities,
        [Permissions.activities.REVIEWS])
            && <Route exact path="/review/:reviewType" component={Review} />}

      {Permissions.doesUserHaveActivities(org.activities,
        [Permissions.activities.REVIEWS]) && <Route exact path="/review/:reviewType/summary" component={BestPracticesSummry} />}

      {Permissions.doesUserHaveActivities(org.activities,
        [Permissions.activities.REVIEWS]) && <Route exact path="/review/:reviewType/:type/:name/:sectionId" component={BestPracticesWrapper} />}
    </>
  );
};

export default DynamicRoutes;
