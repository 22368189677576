import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import TextInputField from '../../core/form/TextInputField';
import Alert from '../../core/alert/Alert';
import Button from '../../core/button/Button';
import PasswordRulesDescription from '../password-rules-description/PasswordRulesDescrption';
import { get } from '../../../lib/strings';

import './NewPasswordForm.scss';

const NewPasswordForm = ({
  requireConfirmCurrentPassword,
  onComplete,
  submitButtonText,
  formDescriptionText,
  passwordLabel,
  passwordLabelPlaceholder,
  retypePasswordLabel,
  retyPasswordLabelPlaceholder,
}) => {
  const [currentPassword, updateCurrPwInputState] = useState('');
  const [passwordInputState, updatePasswordInputState] = useState('');
  const [error, showError] = useState('');
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const validateMatchHandler = async () => {
    setLoading(true);

    const onCompleteError = await onComplete(
      passwordInputState,
      currentPassword,
    );

    if (onCompleteError) {
      setLoading(false);
      showError(onCompleteError);
    }
  };

  const clearError = () => {
    if (error) showError('');
  };

  return (
    <>
      <Form
        className="components__new_password_form"
        onSubmit={handleSubmit(validateMatchHandler)}
      >
        <PasswordRulesDescription className="components__new_password_form__password-details" descriptionText={formDescriptionText} />

        {requireConfirmCurrentPassword && (
          <TextInputField
            type="password"
            name="currentPassword"
            label="Current Password"
            onChange={(e) => {
              clearError();
              updateCurrPwInputState(e.target.value);
            }}
            placeholder="Enter current password"
            className="input-field-row"
            data-cy="curr-password-input"
            controlled
            ref={register({
              required: true,
            })}
            errors={errors}
          />
        )}

        <TextInputField
          type="password"
          name="password"
          label={passwordLabel}
          onChange={(e) => {
            clearError();
            updatePasswordInputState(e.target.value);
          }}
          placeholder={passwordLabelPlaceholder}
          className="input-field-row"
          data-cy="password-input"
          controlled
          ref={register({
            required: true,
            pattern: {
              // At least 1 letter and at least 1 number and allows symbols
              value: /^(?=.*[0-9])(?=.*[+\-_@#$%&!£^*(){}|"':;?/>.<,~`§±a-zA-Z])/,
              message: get('newPassword-formErrorMinRequirement'),
            },
          })}
          errors={errors}
        />

        <TextInputField
          type="password"
          name="password_match"
          label={retypePasswordLabel}
          onChange={() => {
            clearError();
          }}
          placeholder={retyPasswordLabelPlaceholder}
          className="input-field-row"
          data-cy="password-confirm-input"
          controlled
          ref={register({
            required: true,
            validate: (value) => {
              return (
                value === passwordInputState || 'Your passwords do not match'
              );
            },
          })}
          errors={errors}
        />

        <div className="d-flex justify-content-center pt-4 m-0">
          <Button
            type="submit"
            className="reset-password-button"
            data-cy="reset-password-button"
            size="large"
            colour="primary"
            loading={loading}
          >
            {submitButtonText}
          </Button>
        </div>

        {error && (
          <div className=" input-field-row ">
            <Alert data-cy="new-password-form-error" variant="danger">
              {error}{' '}
            </Alert>
          </div>
        )}
      </Form>
    </>
  );
};

NewPasswordForm.defaultProps = {
  submitButtonText: 'Reset Password',
  requireConfirmCurrentPassword: false,
  formDescriptionText: '',
};

NewPasswordForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  requireConfirmCurrentPassword: PropTypes.bool,
  formDescriptionText: PropTypes.string,
  passwordLabel: PropTypes.string.isRequired,
  passwordLabelPlaceholder: PropTypes.string.isRequired,
  retypePasswordLabel: PropTypes.string.isRequired,
  retyPasswordLabelPlaceholder: PropTypes.string.isRequired,
};

export default NewPasswordForm;
