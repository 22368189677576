import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem, SubMenu,
} from 'react-pro-sidebar';
import { useLocation } from 'react-router-dom';
import Link from '../../link/InternalLink';
import './ImproveLinks.scss';
import PermissionsGated from '../../../context-aware/PermissionsGated/PermissionsGated';
import Permissions from '../../../../lib/permissions';

const ImproveLinks = ({
  styleForSelectedItem,
  handleToggleSidebar,
  contentSections,
}) => {
  const locationObj = useLocation();

  const pathArray = locationObj.pathname.toString().split('/');

  const [itemMenuState, setItemMenuState] = React.useState([false, false]);

  const toggleSubItemMenu = (i, isToggle = true) => {
    const clone = itemMenuState.slice(0);
    // Reset all sub-menus except for the one that clicked
    const newState = clone.map((val, index) => {
      if (index === i) {
        return val;
      }
      return false;
    });
    // if isToggle=false, just open submenu; otherwise reverse preState;
    newState[i] = isToggle ? !newState[i] : true;
    setItemMenuState(newState);
  };

  const isClickedItem = (sectionId, slug) => {
    return sectionId === pathArray[1] * 1 && slug === pathArray[2];
  };

  const initSlug = (section) => {
    let slug = '';
    if (section && section.contentData && section.contentData.length > 0) {
      slug = section.contentData[0].slug;
    }

    return slug;
  };

  return (
    <PermissionsGated activities={[Permissions.activities.IMPROVE]}>
      {contentSections && contentSections.length > 0 && contentSections.map((_section, index) => {
        return (
          <SubMenu
            key={_section.id}
            className="improve-menu"
            title={(
              <Link to={`/section/${_section.slug}/${initSlug(_section)}`}>
                {_section.name}
              </Link>
            )}
            onOpenChange={() => toggleSubItemMenu(index)}
            open={itemMenuState[index]}
          >

            {_section.contentData.map((data) => {
              return (
                <MenuItem
                  className={styleForSelectedItem(`/section/${_section.slug}/${data.slug}`, 'submenu')}
                  onClick={() => handleToggleSidebar()}
                >
                  <Link className={isClickedItem(_section.id, data.slug) ? 'active' : ''} to={`/section/${_section.slug}/${data.slug}`}>{' '}{data.title}
                  </Link>
                </MenuItem>
              );
            })}
          </SubMenu>

        );
      })}
    </PermissionsGated>
  );
};
ImproveLinks.defaultProps = {
  contentSections: undefined,
};
ImproveLinks.propTypes = {
  styleForSelectedItem: PropTypes.func.isRequired,
  handleToggleSidebar: PropTypes.func.isRequired,
  contentSections: PropTypes.arrayOf(PropTypes.object),
};
export default ImproveLinks;
