import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';

import './ExternalLinkButton.scss';

const ExternalLinkButton = ({
  children,
  href,
  'data-cy': cypressId,
  className,
  size,
  colour,
  onNewTab,
}) => {
  return (
    <Button
      className={`components__external-link-button ${className}`}
      size={size}
      colour={colour}
    >
      <a style={{ color: colour }} target={`${onNewTab ? '_blank' : '_self'}`} rel="noopener noreferrer" data-cy={cypressId} href={href}>
        {children}
      </a>
    </Button>
  );
};

ExternalLinkButton.defaultProps = {
  'data-cy': '',
  size: '',
  className: '',
  href: '/',
  colour: 'primary',
  onNewTab: true,
};

ExternalLinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  'data-cy': PropTypes.string,
  size: PropTypes.string,
  colour: PropTypes.string,
  className: PropTypes.string,
  onNewTab: PropTypes.bool,
};

export default ExternalLinkButton;
