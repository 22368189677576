import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Card from '../../../components/core/card/Card';
import Button from '../../../components/core/button/Button';
import Footer from '../../../components/core/footer/Footer';
import BackgroundImage from '../../../components/core/backgroundImage/BackgroundImage';
import registrationService from '../../../services/registration/registration-service';
import { get, stringSanitizer } from '../../../lib/strings';
import OnCourseLogo from '../../../assets/OnCourse_Logo.svg';
import { GlobalContext } from '../../../context/GlobalContext';
import getCodeFromQueryString from '../../../lib/queryStringGetter';

import './RegistrationLinkSentPage.scss';

const RegistrationLinkSentPage = () => {
  const history = useHistory();
  const { getStrings } = useContext(GlobalContext);
  const [resendingEmail, setResendingEmail] = useState(false);
  const [blockInputOnBtn, setBlockInputOnBtn] = useState(false);

  const langCode = getCodeFromQueryString();

  useEffect(() => {
    if (langCode) {
      getStrings(langCode);
    }
  }, []);

  const onClickReSendEmail = async () => {
    await registrationService.reSendEmail(history.location.state.email);
  };

  const CreateDescription = () => {
    return (
      <div
        className="registration-link-sent-description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: stringSanitizer(get('registrationLinkSent-description')),
        }}
      />
    );
  };

  return (
    <div className="pages__registration-link-sent-page">
      <BackgroundImage />
      <img
        className="registration-link-sent-title"
        src={OnCourseLogo}
        alt="OnCourse_logo"
      />

      <Card
        className="registration-link-sent-card"
        title={get('registrationLinkSent-title')}
        titleClassName="registration-link-sent-card-title"
        hasOverlay
      >
        {CreateDescription()}

        <div className="d-flex flex-column align-items-center pt-4">
          <Button
            className="registration-link-sent-resend-button medium"
            data-cy="return-to-sign-in-button"
            loading={blockInputOnBtn}
            onClick={() => {
              setResendingEmail(true);
              setBlockInputOnBtn(true);
              onClickReSendEmail();
              setTimeout(() => {
                setResendingEmail(false);
                setBlockInputOnBtn(false);
              }, 5000);
            }}
          >
            {resendingEmail ? get('registrationLinkSent-ReSendingButton') : get('registrationLinkSent-ReSendButton')}
          </Button>
        </div>
      </Card>

      <Footer />
    </div>
  );
};

export default RegistrationLinkSentPage;
