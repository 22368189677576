import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader';

const PageLoader = ({ isLoading, children, dark }) => {
  return (
    isLoading
      ? (
        <div className={`loader-wrapper ${dark ? '--dark' : ''}`}>
          <Loader />
        </div>
      )
      : children || null
  );
};

PageLoader.defaultProps = {
  isLoading: true,
  dark: false,
};

PageLoader.propTypes = {
  isLoading: PropTypes.bool,
  dark: PropTypes.bool,
};

export default PageLoader;
