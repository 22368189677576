/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import FormError from './FormError';
import { CheckedIcon } from '../../Icons';
import FlagIcon from '../../../assets/svgs-as-components/flag-icon/FlagIcon';
import { GlobalContext } from '../../../context/GlobalContext';
import themeType from '../../../lib/consts/themeType';

import './CheckBoxField.scss';
import { get } from '../../../lib/strings';

const CheckBoxField = ({
  name,
  label,
  isRightLabel,
  isRequiredCert,
  children,
  className,
  theme,
  control,
  errors,
  validationRules,
  rounded,
  defaultValue,
  handleChange,
  color,
  checkIcon,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{
        validate: (value) => validationRules(value),
      }}
      render={(fields) => {
        return (
          <CustomCheckBox
            {...fields}
            theme={theme}
            rounded={rounded}
            label={label}
            isRightLabel={isRightLabel}
            isRequiredCert={isRequiredCert}
            handleChange={handleChange}
            className={className}
            errors={errors}
            color={color}
            checkIcon={checkIcon}
          >
            {children}
          </CustomCheckBox>
        );
      }}
    />
  );
};

CheckBoxField.defaultProps = {
  errors: {},
  validationRules: () => {},
  children: undefined,
  className: undefined,
  label: undefined,
  isRightLabel: true,
  isRequiredCert: false,
  theme: 'light',
  rounded: false,
  defaultValue: '',
  handleChange: () => { },
  color: '',
  checkIcon: '',
};

CheckBoxField.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  isRightLabel: PropTypes.bool,
  isRequiredCert: PropTypes.bool,
  theme: PropTypes.string,
  rounded: PropTypes.bool,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  validationRules: PropTypes.func,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({}),
  ]),
  color: PropTypes.string,
  checkIcon: PropTypes.string,
};

export default CheckBoxField;

const CustomCheckBox = React.forwardRef((props, ref) => {
  const [id] = useState(_.uniqueId(''));

  const {
    name,
    value,
    onChange,
    label,
    isRightLabel,
    isRequiredCert,
    children,
    theme,
    rounded,
    className,
    errors,
    handleChange,
    color,
    checkIcon,
  } = props;

  const { theme: appTheme } = useContext(GlobalContext);

  let errorMessage;

  if (errors[name]) {
    errorMessage = errors[name].message;
  }

  const handle = () => {
    onChange(!value);
    handleChange(name, !value);
  };

  const checkBoxClass = value ? 'checkbox-checked' : 'checkbox-unchecked';
  const themeClass = `components__form-checkbox-${theme}`;
  const errorClasses = errors[name] ? 'error' : '';
  const LabelText = label ? (
    <label className="limitLableWidth" htmlFor={id} title={label}>
      {label}
    </label>
  ) : null;
  const LabelBox = (
    <div className="checkbox-wrapper">
      {rounded ? (
        value ? (
          <div className="checkbox-rounded-checked" onClick={handle}>
            {appTheme === themeType.TOURNAMENT ? <CheckedIcon />
              : <CheckedIcon fill={color} />}
          </div>
        ) : <div className="checkbox-rounded-not-checked" style={{ borderColor: color }} onClick={handle} />
      ) : (
        <>
          <div className={`checkbox-tick ${errorClasses}`} onClick={handle} />
          <div className={`checkbox ${checkBoxClass}`} style={checkBoxClass === 'checkbox-checked' ? { backgroundColor: color } : { }} onClick={handle} />
        </>
      )}
    </div>
  );
  const FlagIconForRequireCert = isRequiredCert ? <FlagIcon hoverText={get('icon-hover-text-cert-required')} /> : null;

  return (
    <div
      ref={ref}
      className={`components__form-checkbox-field ${className} ${themeClass} `}
    >
      <div className={`d-flex ${rounded && '--rounded'}`}>
        {isRightLabel ? (
          <>
            {LabelBox}
            {LabelText}
            {children}
            {FlagIconForRequireCert}
          </>
        ) : (
          <>
            {LabelText}
            {LabelBox}
            {children}
            {FlagIconForRequireCert}
          </>
        )}
      </div>

      {errorMessage && (
        <div className="checkbox-error">
          <FormError message={errorMessage} />
        </div>
      )}
    </div>
  );
});

CustomCheckBox.displayName = 'CustomCheckBox';

CustomCheckBox.defaultProps = {
  label: undefined,
  isRightLabel: true,
  isRequiredCert: false,
  children: undefined,
  className: undefined,
  theme: 'light',
  rounded: false,
  errors: {},
  handleChange: () => { },
  color: '',
  checkIcon: '',
};

CustomCheckBox.propTypes = {
  label: PropTypes.string,
  isRightLabel: PropTypes.bool,
  isRequiredCert: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  theme: PropTypes.string,
  rounded: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({}),
  ]),
  color: PropTypes.string,
  checkIcon: PropTypes.string,
};
