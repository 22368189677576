import React, { useEffect, useState } from 'react';
import OnCourseLogo from '../../assets/OnCourse_Logo.svg';
import Loader from '../../components/Loaders/PageLoader';
import healthCheckService from '../../services/healthCheck/healthCheck-service';
import './Error.scss';

const Error = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function validateServer() {
      // Redirect to home page if server is not down and the user tried to access the error page
      const isServerDown = await healthCheckService.isServerDown();
      if (isServerDown) {
        // Server is down
        setIsLoading(false);
      } else {
        window.location = '/';
      }
      return () => {};
    }

    const root = document.documentElement;

    // We do not have context on this route so force the primary color to be of facility type
    if (root) {
      root.style.setProperty('--primary', '#4CA471');
      root.style.setProperty('--washedOutPrimary', '#e3eeea');
    }

    validateServer();
  }, []);
  return (
    <Loader isLoading={isLoading}>
      <div className="page-error">
        <div className="nav-bar" style={{ backgroundColor: '#4ca471' }}>
          <div className="nav-header-logo" aria-hidden>
            <img src={OnCourseLogo} alt="OnCourse_logo" />
          </div>
        </div>
        <div className="error-container">
          <h3 className="text-center">We&apos;ll be back.</h3>
          <h6 className="text-center">We&apos;re down for scheduled maintenance right now.</h6>
          <h6 className="text-center">Please check back soon.</h6>
        </div>
        <div className="components__footer">
          <div className="footer-wrapper">
            <p className="footer-text">OnCourse has been made possible with generous support from partners and donors.</p>
          </div>
        </div>
      </div>
    </Loader>

  );
};

export default Error;
