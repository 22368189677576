import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import userService from '../../services/user/user-service';
import { GlobalContext } from '../../context/GlobalContext';
import TabSwitch from '../../components/core/tabSwitch/TabSwitch';
import Button from '../../components/core/button/Button';
import Loader from '../../components/Loaders/PageLoader';
import ProfileGeneralInformation from './ProfileGeneralInformation';
import ProfileSettings from './ProfileSettings';
import ProfileFacilities from './ProfileFacilities';
import { get } from '../../lib/strings';
import themeType from '../../lib/consts/themeType';
import './ProfilePage.scss';

const Profile = () => {
  const { user, theme } = useContext(GlobalContext);
  const history = useHistory();
  const { location: { state: { selectedTab = 0 } = {} } = {} } = history || {};
  const [tabIndex, setTabIndex] = useState(selectedTab);
  const [userOrganisations, setUserOrganisations] = useState([]);
  const [isPageLoading, updateIsPageLoading] = useState(false);

  const tabSettingsIndex = userOrganisations.length > 1 ? 2 : 1;

  useEffect(() => {
    const getOrganisation = async () => {
      updateIsPageLoading(true);

      const result = await userService.getUserOrgs(user.id);

      if (result.ok) {
        setUserOrganisations(result.data);
      }

      updateIsPageLoading(false);
    };

    getOrganisation();
  }, []);

  const createTabLabels = () => {
    let tabs = userOrganisations.length > 1
      ? [get('profile-tabGeneralInformationTitle'), get('profile-tabFacilitiesTitle'), get('profile-tabSettingsTitle')]
      : [get('profile-tabGeneralInformationTitle'), get('profile-tabSettingsTitle')];

    if (themeType.TOURNAMENT === theme) {
      tabs = tabs.filter((tab) => tab !== get('profile-tabSettingsTitle'));
    }
    return tabs;
  };
  // refs to grab form/components Dirty status
  const detailFormDirtyRef = useRef();
  const profileGeneralInformationRef = useRef();
  const settingFormRef = useRef();

  const onClickTab = (index) => {
    setTabIndex(index);
  };

  const renderTabContent = (index) => {
    if (index === 0) {
      return (
        <ProfileGeneralInformation
          userOrganisations={userOrganisations}
          setUserOrganisations={setUserOrganisations}
          detailFormDirtyRef={detailFormDirtyRef}
          ref={profileGeneralInformationRef}
        />
      );
    }

    if (userOrganisations.length > 1 && index === 1) {
      return (
        <ProfileFacilities userOrganisations={userOrganisations} />
      );
    }

    if (index === tabSettingsIndex) {
      return <ProfileSettings settingFormRef={settingFormRef} />;
    }

    return null;
  };

  return (
    <Loader isLoading={isPageLoading}>
      <div className="pages__profile-page page">
        <div className="page-header">
          <div className="profile-page-header">
            <p className="review-page-section-title" data-cy="profile-header">{get('profile-title')}</p>
            {(tabIndex === 0 || tabIndex === tabSettingsIndex) && (
            <Button form="my-form" type="submit" className="profile-save-button medium primary" data-cy="profile-save-button">
              {get('profile-saveButton')}
            </Button>
            )}
          </div>
          <TabSwitch
            className="profile-tabswitch"
            labels={createTabLabels()}
            onClick={onClickTab}
            selectedTab={tabIndex}
            detailFormDirtyRef={detailFormDirtyRef}
            generalInformationFormDirtyRef={profileGeneralInformationRef}
            settingFormRef={settingFormRef}
          />
          {renderTabContent(tabIndex)}
        </div>
      </div>
    </Loader>
  );
};

export default Profile;
