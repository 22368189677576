import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import FormError from './FormError';
import { get } from '../../../lib/strings';
import './RichTextEditor.scss';

const RichTextEditor = (props) => {
  const {
    value,
    disabled,
    onChange,
    allowLinks,
    id,
    dataCy,
    className,
    placeholder,
    maxLength,
    error,
  } = props;

  const [length, setLength] = React.useState(0);

  let plugins = 'lists paste';
  let toolbar = 'bold italic underline | bullist numlist | copy paste';

  if (allowLinks) {
    plugins += ' link';
    toolbar += ' | link';
  }

  const handleInit = (evt, editor) => {
    setLength(editor.getContent({ format: 'text' }).length);
  };

  const handleUpdate = (text, editor) => {
    // eslint-disable-next-line no-shadow
    const { length } = editor.getContent({ format: 'text' });
    if (length <= maxLength) {
      onChange(text);
      setLength(length);
    }
  };

  const handleBeforeAddUndo = (evt, editor) => {
    // eslint-disable-next-line no-shadow
    const { length } = editor.getContent({ format: 'text' });
    // note that this is the opposite test as in handleUpdate
    // because we are determining when to deny adding an undo level
    if (length > maxLength) {
      evt.preventDefault();
    }
  };

  return (
    <div className={`rich-text-editor__container ${className}`} data-cy={dataCy}>
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        init={{
          branding: false,
          menubar: false,
          resize: false,
          statusbar: false,
          plugins,
          toolbar,
          link_assume_external_targets: true,
          default_link_target: '_blank',
          target_list: false,
          link_title: false,
          placeholder,
          paste_word_valid_elements: 'p,ul,ol,li,a',
        }}
        value={value}
        onInit={handleInit}
        onEditorChange={handleUpdate}
        onBeforeAddUndo={handleBeforeAddUndo}
        disabled={disabled}
        id={id}
      />
      {error && <FormError message={error} />}
      <p className="pt-1">{get('component-richEditor-max-characteres')} {maxLength - length}</p>
    </div>
  );
};

RichTextEditor.defaultProps = {
  allowLinks: true,
  disabled: false,
  id: '',
  dataCy: '',
  className: '',
  placeholder: '',
  maxLength: 2000,
  error: '',
};

RichTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  allowLinks: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  dataCy: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  error: PropTypes.string,
};

export default RichTextEditor;
