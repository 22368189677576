import React from 'react';
import PropTypes from 'prop-types';
import './PasswordRulesDescription.scss';
import { get } from '../../../lib/strings';

const PasswordRulesDescription = ({ className, descriptionText }) => {
  return (
    <div className={`password-rules-description ${className}`}>
      <div>
        <p className="password-rules-text">{descriptionText}</p>
        <ul>
          <li>{get('newPassword-formRuleLetter')}</li>
          <li>{get('newPassword-formRuleNumber')}</li>
        </ul>
      </div>
    </div>
  );
};

PasswordRulesDescription.defaultProps = {
  className: '',
  descriptionText: '',
};

PasswordRulesDescription.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
};

export default PasswordRulesDescription;
