import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBoxField from '../form/CheckBoxField';
import DropdownField from '../form/DropdownField';
import Modal from '../modal/Modal';
import TextInputField from '../form/TextInputField';
import Button from '../button/Button';
import plusImg from '../../../assets/plus-black.svg';
import removeAnswerImg from '../../../assets/remove_answer.svg';
import { get } from '../../../lib/strings';
import answerSetUtils from '../../../lib/answerSetUtils';
import FormError from '../form/FormError';

import './MultipleChoiceQuestions.scss';

const CheckBoxFieldRender = ({
  answer,
  index,
  control,
  facilityAddedAnswerSelected,
  updateFacilityAddedAnswersSelected,
  removeModaltoggle,
  setValue,
  clickAwnserId,
  preselectedCheckbox,
  color,
  checkIcon,
}) => {
  useEffect(() => {
    if (facilityAddedAnswerSelected.includes(answer.id)) {
      setValue(`answers.[${index}].${answer.id}.name`, true);
    }
  }, [clickAwnserId]);

  return (
    <CheckBoxField
      key={answer.id}
      name={`answers.[${index}].${answer.id}.name`}
      label={answer.title}
      control={control}
      rounded
      defaultValue={preselectedCheckbox}
      isRequiredCert={answer.requiredForCertification}
      className="check-box-input"
      color={color}
      checkIcon={checkIcon}
      handleChange={(name, value) => {
        const removed = facilityAddedAnswerSelected.filter(
          (selectedAnswer) => selectedAnswer !== answer.id,
        );
        if (value) {
          updateFacilityAddedAnswersSelected([
            answer.id, ...removed]);
        } else {
          updateFacilityAddedAnswersSelected(removed);
        }
      }}
    >
      {answer.facilityAddedAnswer
                    && <button type="button" key={answer.id} className="toggle-remove-button" onClick={() => removeModaltoggle(answer.id)}> <img src={removeAnswerImg} alt="close" /> </button>}
    </CheckBoxField>
  );
};
CheckBoxFieldRender.defaultProps = {
  clickAwnserId: null,
  preselectedCheckbox: false,
};
CheckBoxFieldRender.propTypes = {
  answer: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  facilityAddedAnswerSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateFacilityAddedAnswersSelected: PropTypes.func.isRequired,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  removeModaltoggle: PropTypes.func.isRequired,
  clickAwnserId: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  preselectedCheckbox: PropTypes.bool,
  color: PropTypes.string.isRequired,
  checkIcon: PropTypes.string.isRequired,
};

const MultipleChoiceQuestions = ({
  answers = [],
  isDropdown,
  allowAddMore,
  control,
  setValue,
  getValues,
  append,
  remove,
  errors,
  register,
  answerData,
  answerPreviousYearData,
  instructions,
  color,
  checkIcon,
}) => {
  const [addModal, updateAddModal] = useState(false);
  const [removeAddModal, updateRemoveAddModal] = useState(false);
  const [clickAwnserId, updateclickAwnserId] = useState();
  const [render, updateRender] = useState(false);
  const [dropdownOptions, updateDropdownOptions] = useState([]);
  const [errorInAddAnswerModal, updateErrorInAddAnswerModal] = useState();
  const [facilityAddedAnswerSelected, updateFacilityAddedAnswersSelected] = useState([]);
  const previousDataString = get('question-previous-data-message');
  const [showPreviousDataMessage, setShowPreviousDataMessage] = useState(false);

  useEffect(() => {
    if (isDropdown) {
      const options = answers.map((answer) => {
        return {
          key: answer.id,
          value: answer.title,
        };
      });
      updateDropdownOptions(options);
    }

    let facilityAddedAnswers = answerData
      && answerData.facilityAddedAnswers ? answerData.facilityAddedAnswers : [];

    if (!Object.prototype.hasOwnProperty.call(answerData, 'complete') && Object.prototype.hasOwnProperty.call(answerPreviousYearData, 'complete')) {
      facilityAddedAnswers = answerPreviousYearData
        && answerPreviousYearData.facilityAddedAnswers
        ? answerPreviousYearData.facilityAddedAnswers : [];

      if (!showPreviousDataMessage) {
        setShowPreviousDataMessage(true);
      }
    }

    if (facilityAddedAnswers && facilityAddedAnswers.length > 0) {
      append(facilityAddedAnswers.map((t) => ({ ...t, facilityAddedAnswer: true })));
    }
    updateRender(true);
  }, []);

  useEffect(() => {
    if (render === true) {
      (answers).map((answer, index) => {
        const fieldName = `answers.[${index}].${answer.id}.name`;
        let answerFromData = answerSetUtils.pullOutAnswerSet(answer.id, answerData);

        if (!Object.prototype.hasOwnProperty.call(answerData, 'complete') && Object.prototype.hasOwnProperty.call(answerPreviousYearData, 'complete')) {
          answerFromData = answerSetUtils.pullOutAnswerSet(answer.id, answerPreviousYearData);
        }

        answerSetUtils.setAnswersHandler(fieldName, answerFromData, setValue);
        return answer;
      });

      if (isDropdown) {
        // 1 find answer id upon uuid format
        // 2 look up answer obj by answer id
        // 3 get answer value (title)
        let fieldData = answerData;

        if (!Object.prototype.hasOwnProperty.call(answerData, 'complete') && Object.prototype.hasOwnProperty.call(answerPreviousYearData, 'complete')) {
          fieldData = answerPreviousYearData;

          if (!showPreviousDataMessage) {
            setShowPreviousDataMessage(true);
          }
        }

        if (fieldData) {
          const selectedAnswerId = Object.keys(fieldData).find((_item) => _item.split('-').length === 5);
          const answerValue = (answers).find((answer) => answer.id === selectedAnswerId);
          if (answerValue && answerValue.title) {
            setValue('dropdownAnwser', answerValue.id);
          }
        }
      }
      updateRender(false);
    }
  }, [render]);

  useEffect(() => {
    if (showPreviousDataMessage) {
      setShowPreviousDataMessage(false);
    }
  }, [answerData]);

  const onClickAddAwnser = () => {
    const currAddAnswer = getValues('addAnswer');
    // if (!currAddAnswer) { updateErrorInAddAnswerModal('Please complete this field.'); return; }
    if (currAddAnswer.length > 500) { updateErrorInAddAnswerModal('question-AddAnswer-errorMessage-maxlength'); return; }
    append({
      name: '', active: true, title: currAddAnswer, facilityAddedAnswer: true, preselected: true,
    });
    updateErrorInAddAnswerModal();
    updateAddModal(false);
  };

  const removeModaltoggle = (awnserId) => {
    updateclickAwnserId(awnserId);
    updateRemoveAddModal(true);
  };
  const onClickRemoveAwnser = () => {
    const getIndexByAwnserId = answers.findIndex((answer) => answer.id === clickAwnserId);
    remove(getIndexByAwnserId);
    updateRemoveAddModal(false);
    updateRender(true);
    updateclickAwnserId(undefined);
  };

  return (
    <div className="components__multiple-choice-question">
      {showPreviousDataMessage ? <p className="review-page-alert">{previousDataString}</p> : null}
      <h7 className="d-flex pb-2 italic">{instructions}</h7>
      {isDropdown ? (
        <DropdownField
          colour={color}
          control={control}
          name="dropdownAnwser"
          label={get('question-set-dropdownLabel')}
          className="input-field-row"
          options={dropdownOptions}
        />
      ) : (
        <>
          <div className="question-checkbox-wrapper">
            {(answers).map((answer, index) => {
              return (
                <CheckBoxFieldRender
                  key={answer.id}
                  answer={answer}
                  index={index}
                  control={control}
                  facilityAddedAnswerSelected={facilityAddedAnswerSelected}
                  updateFacilityAddedAnswersSelected={updateFacilityAddedAnswersSelected}
                  removeModaltoggle={removeModaltoggle}
                  setValue={setValue}
                  clickAwnserId={clickAwnserId}
                  preselectedCheckbox={answer.preselected || false}
                  color={color}
                  checkIcon={checkIcon}
                />
              );
            })}

          </div>
          {allowAddMore ? (
            <div className="pb-4">
              <button
                type="button"
                className="question-add-button"
                onClick={() => updateAddModal(true)}
              >
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <img className="question-add-button-plus" src={plusImg} alt="plus" />
                  <p className="question-add-button-text">{get('question-set-AddMoreButton')}</p>
                </div>
              </button>
            </div>
          ) : null}
        </>
      )}
      <Modal
        show={addModal}
        closeBtnColour={color}
        onClose={() => { updateErrorInAddAnswerModal(); updateAddModal(false); }}
        heading={get('question-set-modalAddAnwserTitle')}
        bodyText={(
          <div>
            <TextInputField
              as="textarea"
              rows={1}
              maxLength={150}
              type="textarea"
              name="addAnswer"
              label={get('question-set-modalAddAnwserLabel')}
              placeholder={get('question-set-modalAddAnwserPlaceholder')}
              className="input-field-row"
              data-cy="owner-input"
              ref={register({
                required: true,
              })}
              errors={errors}
            />
            {errorInAddAnswerModal && (
            <FormError message={get(errorInAddAnswerModal)} />
            )}
          </div>
      )}
        footer={(
          <Button colour={color} type="button" onClick={onClickAddAwnser}>
            {get('question-set-modalAddAnwserButton')}
          </Button>
      )}
      />
      <Modal
        show={removeAddModal}
        closeBtnColour={color}
        onClose={() => updateRemoveAddModal(false)}
        heading={get('remove-answer-modal-header')}
        bodyText={(
          <p>{get('remove-answer-modal-text')}</p>
        )}
        footer={(
          <div className="answer-modal-footer">
            <Button
              colour={color}
              type="button"
              className="--inverse"
              inverse
              onClick={() => updateRemoveAddModal(false)}
            >
              {get('remove-answer-modal-button-no')}
            </Button>
            <Button
              colour={color}
              type="button"
              className="primary"
              onClick={onClickRemoveAwnser}
            >
              {get('remove-answer-modal-button-yes')}
            </Button>
          </div>
      )}
      />
    </div>
  );
};

MultipleChoiceQuestions.defaultProps = {
  errors: {},
  answerData: {
    facilityAddedAnswers: [],
  },
  answerPreviousYearData: {
    facilityAddedAnswers: [],
  },
  instructions: '',
  color: '#4CA471',
};

MultipleChoiceQuestions.propTypes = {
  answerData: PropTypes.shape({
    facilityAddedAnswers: [],
  }),
  answerPreviousYearData: PropTypes.shape({
    facilityAddedAnswers: [],
  }),
  allowAddMore: PropTypes.bool.isRequired,
  isDropdown: PropTypes.bool.isRequired,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  getValues: PropTypes.func.isRequired,
  append: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({}),
  ]),
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  instructions: PropTypes.string,
  color: PropTypes.string,
  checkIcon: PropTypes.string.isRequired,
};
export default MultipleChoiceQuestions;
