import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import organisationService from '../../services/organisation/organisation-service';
import countryService from '../../services/country/country-service';
import TabSwitch from '../../components/core/tabSwitch/TabSwitch';
import Button from '../../components/core/button/Button';
import Loader from '../../components/Loaders/PageLoader';
import arrayUtils from '../../utils/array';
import FacilityGeneralInformation from './FacilityGeneralInformation';
import FacilityTeam from './FacilityTeam';
import { FacilityTabIndexes } from '../../lib/consts/facilityTabIndexes';
import FacilityDetails from './FacilityDetails';
import themeType from '../../lib/consts/themeType';
import { get } from '../../lib/strings';

import './FacilityProfilePage.scss';

const FacilityProfilePage = () => {
  const { org, theme } = useContext(GlobalContext);
  const orgType = org ? org.organisation_type.name : 'FACILITY';
  const [tabIndex, setTabIndex] = useState(FacilityTabIndexes[orgType].GENERAL);
  const [organisationDetails, setOrganisationDetails] = useState({});
  const [countries, setCountries] = useState([]);
  const [isPageLoading, updateIsPageLoading] = useState(false);

  useEffect(() => {
    const getOrganisation = async () => {
      updateIsPageLoading(true);

      const result = await organisationService.getOrganisation(org.organisation_id);
      const countriesData = await countryService.getCountries();

      if (result) {
        setOrganisationDetails(result);
      }

      if (countriesData) {
        setCountries(arrayUtils.getCountryOptions(countriesData));
      }

      updateIsPageLoading(false);
    };

    if (org !== null) {
      getOrganisation();
    }
  }, []);
  const detailFormDirtyRef = useRef();
  const generalInformationFormDirtyRef = useRef();

  const onClickTab = (index) => {
    setTabIndex(index);
  };

  const renderTabContent = (index) => {
    if (index === FacilityTabIndexes[orgType].GENERAL) {
      return (
        <FacilityGeneralInformation
          organisationDetails={organisationDetails}
          countries={countries}
          updateFacilityInformation={setOrganisationDetails}
          generalInformationFormDirtyRef={generalInformationFormDirtyRef}
        />
      );
    }

    if (index === FacilityTabIndexes[orgType].DETAILS) {
      return (
        <FacilityDetails
          organisationId={org.organisation_id}
          detailFormDirtyRef={detailFormDirtyRef}
        />
      );
    }

    if (index === FacilityTabIndexes[orgType].TEAM) {
      return (
        <FacilityTeam
          organisationId={org.organisation_id}
        />
      );
    }

    return null;
  };

  return (
    <Loader isLoading={isPageLoading}>
      <div className={`${theme === themeType.TOURNAMENT ? 'img-tour' : 'img-facility'} pages__facility-profile-page page`}>
        <div className="page-header">
          <div className="facility-profile-page-header">
            <p className=" review-page-section-title" data-cy="facility-profile-page-header-title">{get('facilityProfile-title')}</p>
            {tabIndex !== FacilityTabIndexes.TEAM && (
            <Button form="my-form" type="submit" className="facility-profile-save-button medium primary" data-cy="facility-profile-save-button">
              {get('profile-saveButton')}
            </Button>
            )}
          </div>
          <TabSwitch
            className="facility-profile-tabswitch"
            labels={org.organisation_type.name === 'FACILITY'
              ? [get('facilityProfile-tabGeneralInformationTitle'), get('facilityProfile-tabFacilityDetailsTitle'), get('facilityProfile-tabFacilityTeamTitle')]
              : [get('facilityProfile-tabGeneralInformationTitle'), get('facilityProfile-tabFacilityTeamTitle')]}
            onClick={onClickTab}
            detailFormDirtyRef={detailFormDirtyRef}
            generalInformationFormDirtyRef={generalInformationFormDirtyRef}
          />
          {renderTabContent(tabIndex)}
        </div>
      </div>
    </Loader>
  );
};

export default FacilityProfilePage;
