/* eslint-disable camelcase */
import React, {
  useState, useEffect, useImperativeHandle, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Alert from '../../core/alert/Alert';
import ImageUpload from '../../core/form/ImageUpload';
import DropdownField from '../../core/form/DropdownField';
import GenericDropdownField from '../../core/form/GenericDropdownField';
import TextInputField from '../../core/form/TextInputField';
import Button from '../../core/button/Button';
import Modal from '../../core/modal/Modal';
import arrayUtils from '../../../utils/array';
import ErrorCodes from '../../../lib/error-codes';
import mediaService from '../../../services/media/media-service';
import { get } from '../../../lib/strings';
import noImage from '../../../assets/no-image.svg';
import Loader from '../../Loaders/PageLoader';
import RouteLeavingGuard from '../../warningUnSaveGuardModal/RouteLeavingGuard';
import './FacilityProfileGeneralInformationForm.scss';

const FacilityProfileGeneralInformationForm = React.forwardRef(
  ({
    profileError,
    organisationDetails,
    countries,
    type,
    onComplete,
    onPreview,
    isPreviewOpen,
    updateError,
  }, ref) => {
    const {
      id,
      address_1,
      address_2,
      city,
      country,
      image_id,
      logo_id,
      phone_number,
      post_zip_code,
      title,
      vat_number,
      email_address,
      measurement_unit,
    } = organisationDetails;
    const [error, setError] = useState(undefined);
    const [profileImage, updateProfileImage] = useState('');
    const [profileLogo, updateProfileLogo] = useState('');
    const [isPageLoading, updateIsPageLoading] = useState(false);
    const [logoImageId, setLogoImageId] = useState(logo_id);
    const [profileImageId, setProfileImageId] = useState(image_id);

    const [profileImageFormData, updateProfileImageFormData] = useState('');
    const [profileLogoFormData, updateProfileLogoFormData] = useState('');

    const countryDetails = countries.filter((item) => item.label === country);

    const defaultValuesOrganisationDetails = {
      facilityName: title,
      addressLine1: address_1,
      addressLine2: address_2,
      city,
      country: countryDetails && countryDetails.length > 0 ? countryDetails[0].value : '',
      postcode: post_zip_code,
      contactNumber: phone_number,
      facilityEmailAddress: email_address,
      vatNumber: vat_number,
      measurementUnit: measurement_unit,
    };

    const {
      register, handleSubmit, errors, control, getValues,
      formState: {
        dirtyFields,
      },
    } = useForm({
      defaultValues: defaultValuesOrganisationDetails,
    });
    const history = useHistory();
    const isDirtyAlt = () => !!Object.keys(dirtyFields).length
                        && !_.isEqual(country, arrayUtils.getOptionLabel(countries, parseInt(getValues('country'), 10)))
                        && !_.isEqual(measurement_unit, getValues('measurementUnit'));// compare unit from context org and current selected org

    useImperativeHandle(ref, () => ({
      getDirtyState() {
        return isDirtyAlt();
      },
    }));

    const fromRequiredFieldError = get('facilityProfile-formErrorRequiredField');
    const formInvalidEmailError = get('registration-formInvalidEmailError');
    const formInvalidNumberError = get('facilityProfile-formInvalidNumberError');
    const formImageTooBigError = get('component-imageTooBigError');
    const formInvalidFileError = get('component-imageInvalidType');

    const getPhoto = async (photoId, callback) => {
      if (photoId) {
        const result = await mediaService.getOrganisationPhoto(photoId);

        if (result && callback && typeof callback === 'function') {
          callback(result);
        }
      }
    };

    useEffect(() => {
      if (!logoImageId && !profileImageId) return;
      getPhoto(profileImageId, (image) => updateProfileImage(image));
      getPhoto(logoImageId, (image) => updateProfileLogo(image));
    }, [logoImageId, profileImageId]);

    const onSubmit = (data) => {
      const countryId = arrayUtils.getOptionKey(countries, data.country)
        ? arrayUtils.getOptionKey(countries, data.country) : data.country;
      const mappedFacility = {
        title: data.facilityName,
        address_1: data.addressLine1,
        address_2: data.addressLine2,
        city: data.city,
        post_zip_code: data.postcode,
        country_id: countryId,
        phone_number: data.contactNumber,
        vat_number: data.vatNumber,
        email_address: data.facilityEmailAddress,
        measurement_unit: data.measurementUnit,
      };

      if (type === 'edit' || (type === 'create' && isPreviewOpen)) {
        onComplete(mappedFacility, profileLogoFormData, profileImageFormData);
      } else if (!isPreviewOpen && type === 'create') {
        onPreview(true);
      }
    };

    const handleErrors = (errorCode) => {
      if (errorCode === ErrorCodes.FILE_TOO_LARGE) {
        setError(formImageTooBigError);
        return null;
      }

      if (errorCode === ErrorCodes.INCORRECT_FORMAT) {
        setError(formInvalidFileError);
        return null;
      }
    };

    const onUploadProfileImage = async (image, imageName, imageCropped, callback) => {
      updateIsPageLoading(true);
      const formData = new FormData();
      formData.append('file', image, imageName);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      if (id) {
        const result = await mediaService.uploadOrganisationProfileImage(id, formData, config);
        if (!result.ok) {
          handleErrors(result.data.errorCode);
          updateIsPageLoading(false);
        } else {
          setProfileImageId(result.data.recordId);
          callback();
          setTimeout(() => {
            updateIsPageLoading(false);
          }, 300);
        }
      } else {
        callback();
        updateProfileImage(imageCropped);
        updateProfileImageFormData(formData);
        setTimeout(() => {
          updateIsPageLoading(false);
        }, 300);
      }
    };

    const onUploadProfileLogo = async (image, imageName, imageCropped, callback) => {
      updateIsPageLoading(true);
      const formData = new FormData();
      formData.append('file', image, imageName);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      if (id) {
        const result = await mediaService.uploadOrganisationProfileLogo(id, formData, config);
        if (!result.ok) {
          handleErrors(result.data.errorCode);
          updateIsPageLoading(false);
        } else {
          setLogoImageId(result.data.recordId);
          callback();
          setTimeout(() => {
            updateIsPageLoading(false);
          }, 300);
        }
      } else {
        callback();
        updateProfileLogo(imageCropped);
        updateProfileLogoFormData(formData);
        setTimeout(() => {
          updateIsPageLoading(false);
        }, 300);
      }
    };

    const inlineText = (inlineTitle, value) => {
      return (
        <div className="facility-profile-details-preview-wrapper">
          <p className="facility-profile-details-preview-title">{inlineTitle}</p>
          <p className="facility-profile-details-preview-value">{value}</p>
        </div>
      );
    };

    const measurementUnitOptions = useMemo(() => {
      return arrayUtils.getMeasurementUnitsOptions();
    }, []);
    return (
      <>
        {/* Using overlay circle instead of Children render circle */}
        {isPageLoading && <Loader />}
        <div className={`components_facility-profile-details-form components_facility-profile-form-${type}`}>
          {(profileError && type === 'edit') ? (
            <Alert data-cy="login-error" className="alert mt-4 mb-0" variant="danger">
              {profileError}
            </Alert>
          ) : null}
          <Form
            id="my-form"
            className="facility-profile-details-form-wrapper"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={`facility-profile-details-form-details-wrapper-${type}`}>
              <div className={`facility-profile-details-form-details-${type}`}>
                <p className="
              facility-profile-form-title"
                >{get('facilityProfile-formGeneralInformationTitle')}
                </p>

                <TextInputField
                  type="facilityName"
                  name="facilityName"
                  label={get('facilityProfile-formGeneralInformation-facilityNameLabel')}
                  placeholder={get('facilityProfile-formGeneralInformation-facilityNamePlaceholder')}
                  className="input-field-row mt-2"
                  data-cy="facilityName-input"
                  ref={register({
                    required: true,
                  })}
                  errors={errors}
                />
                <TextInputField
                  type="addressLine1"
                  name="addressLine1"
                  label={get('facilityProfile-formGeneralInformation-address1Label')}
                  placeholder={get('facilityProfile-formGeneralInformation-address1Placeholder')}
                  className="input-field-row"
                  data-cy="address1-input"
                  ref={register({
                    required: true,
                  })}
                  errors={errors}
                />
                <TextInputField
                  type="addressLine2"
                  name="addressLine2"
                  label={get('facilityProfile-formGeneralInformation-address2Label')}
                  placeholder={get('facilityProfile-formGeneralInformation-address2Placeholder')}
                  className="input-field-row"
                  data-cy="address2-input"
                  ref={register({
                    required: false,
                  })}
                  errors={errors}
                />

                <GenericDropdownField
                  control={control}
                  name="country"
                  label={get('facilityProfile-formGeneralInformation-countryLabel')}
                  variant="white"
                  isSearchable
                  data-cy="country-input"
                  className="input-field-row"
                  options={countries}
                  errors={errors}
                  validationRules={(value) => {
                    if (typeof value !== 'undefined' || countries.some((el) => el.value === value)) {
                      return true;
                    }
                    return fromRequiredFieldError;
                  }}
                />

                <div className="d-flex justify-content-between">
                  <TextInputField
                    type="city"
                    name="city"
                    label={get('facilityProfile-formGeneralInformation-cityLabel')}
                    placeholder={get('facilityProfile-formGeneralInformation-cityPlaceholder')}
                    className="input-field-row-column"
                    data-cy="city-input"
                    ref={register({
                      required: true,
                    })}
                    errors={errors}
                  />
                  <TextInputField
                    type="postcode"
                    name="postcode"
                    label={get('facilityProfile-formGeneralInformation-postcodeLabel')}
                    placeholder={get('facilityProfile-formGeneralInformation-postcodePlaceholder')}
                    className="input-field-row-column"
                    data-cy="postcode-input"
                    ref={register({
                      required: true,
                    })}
                    errors={errors}
                  />
                </div>

                <TextInputField
                  type="conctactNumber"
                  name="contactNumber"
                  label={get('facilityProfile-formGeneralInformation-contactNumberLabel')}
                  placeholder={get('facilityProfile-formGeneralInformation-contactNumberPlaceholder')}
                  className="input-field-row"
                  data-cy="contact-input"
                  ref={register({
                    maxLength: 15,
                    pattern: {
                      value: /^[0-9 +]+$/g,
                      message: formInvalidNumberError,
                    },
                  })}
                  errors={errors}
                />

                <TextInputField
                  type="facilityEmailAddress"
                  name="facilityEmailAddress"
                  label={get('facilityProfile-formGeneralInformation-emailLabel')}
                  placeholder={get('facilityProfile-formGeneralInformation-emailPlaceholder')}
                  className="input-field-row"
                  data-cy="email-input"
                  ref={register({
                    validate: {
                      isEmail: (value) => {
                        if (value) {
                          return validator.isEmail(value) || formInvalidEmailError;
                        }
                        return true;
                      },
                    },
                  })}
                  errors={errors}
                />

                <TextInputField
                  type="vatNumber"
                  name="vatNumber"
                  label={get('facilityProfile-formGeneralInformation-vatLabel')}
                  placeholder={get('facilityProfile-formGeneralInformation-vatPlaceholder')}
                  className="input-field-row"
                  data-cy="vat-input"
                  ref={register({
                    required: false,
                  })}
                  errors={errors}
                />
              </div>

              <div className={`facility-profile-form-image-upload-${type}`}>
                <div className="facility-profile-form-image-container">
                  {type !== 'create' && <p className="facility-profile-form-title">{get('facilityProfile-formGeneralInformationImageUploadTitle')}</p>}

                  <div className="facility-profile-form-image-wrapper ">
                    <ImageUpload
                      control={control}
                      name="logo"
                      label={get('facilityProfile-formGeneralInformation-logoLable')}
                      cropModalTitle={get('profile-tabGeneralInfo-cropModalTitle')}
                      cropModalButtonText={get('profile-tabGeneralInfo-cropModalButton')}
                      imagePlaceholder={noImage}
                      src={profileLogo}
                      onImageUpload={onUploadProfileLogo}
                      onCloseModal={() => setError(undefined)}
                      error={error}
                    />

                    <div className="p-4" />

                    <ImageUpload
                      control={control}
                      name="profileImage"
                      label={get('facilityProfile-formGeneralInformation-profileImageLabel')}
                      cropModalTitle={get('facilityProfile-formGeneralInformation-cropModalTitle')}
                      cropModalButtonText={get('facilityProfile-formGeneralInformation-cropModalButton')}
                      imageWidth={270}
                      imageHeigh={160}
                      aspectRation={4 / 2}
                      imagePlaceholder={noImage}
                      src={profileImage}
                      onImageUpload={onUploadProfileImage}
                      onCloseModal={() => setError(undefined)}
                      error={error}
                      className="facility-profile-form-image"
                    />
                  </div>
                </div>

                <div className="facility-profile-form-image-unit">
                  {type !== 'create' && <p className="facility-profile-form-title">{get('facilityProfile-formGeneralInformationMeasureUnitTitle')}</p>}

                  <div className="facility-profile-form-image-wrapper">
                    <DropdownField
                      control={control}
                      name="measurementUnit"
                      data-cy="measurementUnit-input"
                      className="input-field-row"
                      options={measurementUnitOptions}
                      errors={errors}
                      validationRules={(value) => {
                        if (typeof value !== 'undefined' || measurementUnitOptions.some((el) => el.key === value)) {
                          return true;
                        }
                        return fromRequiredFieldError;
                      }}
                    />

                  </div>
                </div>
              </div>

            </div>
          </Form>

          <RouteLeavingGuard
            // When should shouldBlockNavigation be invoked,
            // simply passing a boolean
            // (same as "when" prop of Prompt of React-Router)
            when={isDirtyAlt}
            // Navigate function
            navigate={(path) => history.push(path)}
            // Use as "message" prop of Prompt of React-Router
            // eslint-disable-next-line no-unused-vars
            shouldBlockNavigation={(location) => {
              // is able to filter specific location
              if (isDirtyAlt()) return true;
              return false;
            }}
          />
          <Modal
            show={isPreviewOpen}
            onClose={() => {
              onPreview(false);
              updateError(undefined);
            }}
            heading={get('createFacility-modalPreview-title')}
            bodyText={(
              <div className="facility-profile-details-preview-modal-body">
                <p>{get('createFacility-modalPreview-description')}</p>
                {inlineText(get('createFacility-modalPreview-name'), getValues('facilityName'))}
                {inlineText(get('createFacility-modalPreview-address'), `${getValues('addressLine1')} ${getValues('addressLine2')}`)}
                {inlineText(get('createFacility-modalPreview-city'), getValues('city'))}
                {inlineText(get('createFacility-modalPreview-country'), arrayUtils.getOptionLabel(countries, parseInt(getValues('country'), 10)))}
                {inlineText(get('createFacility-modalPreview-postcode'), getValues('postcode'))}
                {inlineText(get('createFacility-modalPreview-emailAddress'), getValues('facilityEmailAddress'))}
                {inlineText(get('createFacility-modalPreview-vat'), getValues('vatNumber'))}
                <div className="facility-profile-details-preview-modal-image-wrapper">
                  <div className="d-flex flex-column">
                    <img className="facility-profile-details-preview-logo" src={profileLogo} alt="facility-logo" />
                    <p className="facility-profile-details-preview-title">{get('createFacility-modalPreview-profileLogo')}</p>
                  </div>
                  <div className="d-flex flex-column">
                    <img className="facility-profile-details-preview-image" src={profileImage} alt="facility-profile" />
                    <p className="facility-profile-details-preview-title">{get('createFacility-modalPreview-profileImage')}</p>
                  </div>
                </div>
              </div>
          )}
            footer={(
              <div className="container">
                {profileError ? (
                  <Alert data-cy="login-error" className="alert mt-4 mb-0" variant="danger">
                    {profileError}
                  </Alert>
                ) : null}
                <div className="container d-flex justify-content-end">
                  <Button className="profile-facility-team-modal-button" form="my-form" type="submit">
                    {get('createFacility-modalPreview-button')}
                  </Button>
                </div>
              </div>
      )}
          />
        </div>
      </>
    );
  },
);

FacilityProfileGeneralInformationForm.defaultProps = {
  profileError: undefined,
  organisationDetails: {},
  customerFacilityDetails: {},
  countries: [],
  onComplete: () => {},
  type: 'edit',
  isPreviewOpen: false,
  onPreview: () => {},
  updateError: () => {},
};

FacilityProfileGeneralInformationForm.propTypes = {
  profileError: PropTypes.string,
  type: PropTypes.string,
  isPreviewOpen: PropTypes.bool,
  onComplete: PropTypes.func,
  onPreview: PropTypes.func,
  updateError: PropTypes.func,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  organisationDetails: PropTypes.shape({
    address_1: PropTypes.string,
    address_2: PropTypes.string,
    city: PropTypes.string,
    image_id: PropTypes.string,
    logo_id: PropTypes.string,
    id: PropTypes.string,
    phone_number: PropTypes.string,
    post_zip_code: PropTypes.string,
    title: PropTypes.string,
    country: PropTypes.string,
    vat_number: PropTypes.string,
    email_address: PropTypes.string,
    facility_type: PropTypes.string,
    measurement_unit: PropTypes.string,
  }),
  customerFacilityDetails: PropTypes.shape({
    facilityName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    _city: PropTypes.string,
    country: PropTypes.string,
    postcode: PropTypes.string,
    contactNumber: PropTypes.string,
    facilityEmailAddress: PropTypes.string,
    vatNumber: PropTypes.string,
  }),
};

export default FacilityProfileGeneralInformationForm;
