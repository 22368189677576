import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '../../../core/panel/Panel';
import Question from '../Question/Question';
import Modal from '../../../core/modal/Modal';
import infoImg from '../../../../assets/Info.svg';
import { GlobalContext } from '../../../../context/GlobalContext';

import './BestPracticesSummaryCard.scss';
import reviewsService from '../../../../services/reviews/reviews-service';
import PageLoader from '../../../Loaders/PageLoader';
import answerSetUtils from '../../../../lib/answerSetUtils';

const QuestionInMap = ({ field }) => (
  <Question
    title={field.title}
    href={field.href}
    colour={field.colour}
    questionBlockToOpen={field.questionBlockToOpen}
    sectionId={field.sectionId}
    sectionTitle={field.sectionTitle}
    completed={field.completed}
  />
);

QuestionInMap.propTypes = {
  field: PropTypes.shape({
    title: PropTypes.string.isRequired,
    completed: PropTypes.bool,
    colour: PropTypes.string,
    href: PropTypes.string.isRequired,
    sectionId: PropTypes.string,
    questionBlockToOpen: PropTypes.string,
    sectionTitle: PropTypes.string,
  }).isRequired,
};

const BestPracticesSummaryCard = ({
  data, type, colour,
}) => {
  const { org, reviewLinks } = useContext(GlobalContext);
  const [openModal, updateOpenModal] = useState(false);
  const [description, updateDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState([]);

  const onOpenModal = (index) => {
    updateDescription(data[index].description);
    updateOpenModal(true);
  };

  const checker = (arr) => {
    return arr.every((e) => e === 'isComplete');
  };

  useEffect(() => {
    const getData = async () => {
      const activeQuestionList = reviewsService.activeQuestions(data);
      const activeQuestions = activeQuestionList.length;
      if (activeQuestions === 0) {
        setLoading(false);
        return;
      }

      const id = reviewsService.getIdBySlug('bestPractices', type, reviewLinks);

      const answerData = await reviewsService.getAnswerSetsByOrg(
        org.organisation_id,
        id,
      );

      setAnswers(answerData[0]);
    };

    getData();
    setLoading(false);
  }, [reviewLinks]);

  return (
    <>
      { loading && <PageLoader />}
      <div className="row">
        {
        data
          .map((section, idx) => {
            return (
              <div className={`col-12 col-sm-6 col-md-12 ${data.length === 4 ? 'col-lg-3' : 'col-lg-4'} best-practices-summary-columns`} key={section.id}>
                <Panel>
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="--dark summary-title"> {section.title}</h5>
                    {section.description ? (
                      <div className="summary-category-info" onClick={() => onOpenModal(idx)} aria-hidden>
                        <img src={infoImg} alt="section-info" />
                      </div>
                    ) : null}
                  </div>
                  {
                    (section.subsections)
                      ? section.subsections
                        .map((subsection, index) => {
                          const showTitle = !subsection.title;
                          const href = `${type}${(type !== 'annual') ? `/${section.slug}/${section.id}` : ''}`;
                          const questionBlockToOpen = (type !== 'annual') ? subsection.id : section.title;
                          const sectionId = section.id;
                          const sectionTitle = section.title;
                          const fields = {
                            colour, type, href, questionBlockToOpen, sectionId, sectionTitle,
                          };
                          const allQuestionIds = subsection.fieldSets.flatMap((fieldSet) => {
                            return fieldSet.questions.flatMap((question) => question.id);
                          });
                          const statusArray = answers.answers && allQuestionIds.map(
                            (questionId) => {
                              const answerFromPayload = answerSetUtils.pullOutAnswerById(
                                questionId,
                                answers.answers,
                              );
                              if (answerFromPayload && answerFromPayload.complete) {
                                return 'isComplete';
                              }
                              return null;
                            },
                          );
                          const complete = statusArray && checker(statusArray);
                          if (showTitle) {
                            return subsection.fieldSets
                              .map((field) => (
                                <QuestionInMap
                                  key={field.id}
                                  field={{
                                    ...field,
                                    ...fields,
                                    questionBlockToOpen: field.id,
                                    completed: complete,
                                  }}

                                />
                              ));
                          }
                          return (
                            <>
                              <h6 className="--dark"> {subsection.title} </h6>
                              {
                              subsection.fieldSets
                                .map((field) => (
                                  <QuestionInMap
                                    key={field.id}
                                    field={{
                                      ...field,
                                      ...fields,
                                      questionBlockToOpen: field.id,
                                      completed: complete,
                                    }}
                                  />
                                ))
                            }
                              {index !== (section.subsections.length - 1) && <div className="best-practices-section-separator" />}
                            </>
                          );
                        }) : ''
                }
                </Panel>
              </div>
            );
          })
       }
        <Modal
          show={openModal}
          onClose={() => updateOpenModal(false)}
          heading=""
          bodyText={(
            <p>{description}</p>
          )}
        />
      </div>
    </>
  );
};

BestPracticesSummaryCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired,
};
export default BestPracticesSummaryCard;
