import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';
import PanelLinkLayout from '../../components/layout/PanelLinkLayout';
import Panel from '../../components/core/panel/Panel';
import Button from '../../components/core/button/Button';
import countryService from '../../services/country/country-service';
import organisationService from '../../services/organisation/organisation-service';
import arrayUtils from '../../utils/array';
import FacilityProfileGeneralInformationForm from '../../components/FacilityProfile/facilityProfileGeneralInformation-form/FacilityProfileGeneralInformationForm';
import HTTPError from '../../lib/errors/http-error';
import mediaService from '../../services/media/media-service';
import themeType from '../../lib/consts/themeType';

import { get } from '../../lib/strings';

import './JoinFacility.scss';

const CreateFacility = () => {
  const { user, updateOrg, theme } = useContext(GlobalContext);
  const [countries, setCountries] = useState([]);
  const [addUserModal, updateAddUserModal] = useState(false);
  const [error, setError] = useState(undefined);
  const history = useHistory();

  const errorNotUniq = get('createFacility-form-Name-not-unique');

  useEffect(() => {
    const getCountries = async () => {
      const countriesData = await countryService.getCountries();

      if (countriesData) {
        setCountries(arrayUtils.getCountryOptions(countriesData));
      }
    };

    getCountries();
  }, []);

  const onClickSaveSettings = async (newFacility, logoImageFormData, profileImageFormData) => {
    setError(undefined);
    const result = await organisationService.createOrganisation(user.id, newFacility);
    // upload image grab organ id

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    if (!result.ok) {
      if (result.data && result.data.field === 'title') {
        setError(errorNotUniq);
      } else {
        throw new HTTPError(result.status, result.data);
      }
    }

    if (result.ok && result.data) {
      const orgId = result.data[0].organisation_id;
      if (logoImageFormData) {
        await mediaService.uploadOrganisationProfileLogo(orgId, logoImageFormData, config);
      }
      if (profileImageFormData) {
        await mediaService.uploadOrganisationProfileImage(orgId, profileImageFormData, config);
      }
      updateOrg(result.data.length > 0 ? result.data[0] : null);
      return history.push('/');
    }
  };
  return (
    <div className={`${theme === themeType.TOURNAMENT ? 'img-tour page' : 'img-facility page'}`}>
      <PanelLinkLayout
        title={get('createFacility-title')}
        tiles={
                [
                  {
                    title: get('createFacility-cardTitle'),
                    description: get('createFacility-cardDescription'),
                    href: '/create-facility',
                  },
                  {
                    title: get('joinFacility-cardTitle'),
                    description: get('joinFacility-cardDescription'),
                    href: '/join-facility',
                  },
                ]
              }
        hasShadow={false}
      >
        <Panel>
          <div className="container no-gutters create-facility-wrapper">
            <div className="row panel-header">
              <h5>{get('createFacility-panelTitle')}</h5>
              <p>{get('createFacility-panelDescription')}</p>
              <FacilityProfileGeneralInformationForm
                type="create"
                countries={countries}
                onComplete={onClickSaveSettings}
                profileError={error}
                updateError={setError}
                onPreview={updateAddUserModal}
                isPreviewOpen={addUserModal}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button className="create-facility-button" form="my-form" type="submit">
                {get('createFacility-createButton')}
              </Button>
            </div>
          </div>
        </Panel>
      </PanelLinkLayout>
    </div>
  );
};
export default CreateFacility;
