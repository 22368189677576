import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import HttpStatus from 'http-status-codes';
import NewPasswordForm from '../../../components/authentication/new-password-form/NewPasswordForm';
import authenticationService from '../../../services/authentication/authentication-service';
import Card from '../../../components/core/card/Card';
import Footer from '../../../components/core/footer/Footer';
import BackgroundImage from '../../../components/core/backgroundImage/BackgroundImage';
import ErrorCodes from '../../../lib/error-codes';
import { GlobalContext } from '../../../context/GlobalContext';
import getCodeFromQueryString from '../../../lib/queryStringGetter';
import THEME_TYPE from '../../../lib/consts/themeType';

import { get } from '../../../lib/strings';
import OnCourseLogo from '../../../assets/OnCourse_Logo.svg';
import './NewPasswordPage.scss';

const NewPasswordPage = () => {
  const [token, setToken] = useState('false');
  const history = useHistory();
  const { getStrings, themeSetup, theme } = useContext(GlobalContext);
  const isTournamentLogin = history.location.pathname.indexOf('tournaments') !== -1;

  const langCode = getCodeFromQueryString();
  let langQueryString = '';
  let langAdditionalString = '';
  if (langCode) {
    langQueryString = `?lang=${langCode}`;
    langAdditionalString = `&lang=${langCode}`;
  }

  useEffect(() => {
    if (isTournamentLogin && theme !== THEME_TYPE.TOURNAMENT) {
      themeSetup(THEME_TYPE.TOURNAMENT);
    }
  }, []);

  useEffect(() => {
    if (langCode) {
      getStrings(langCode);
    }
  }, []);
  useEffect(() => {
    async function validateAndSetToken() {
      // Redirect to invalid token page if no token in query params.
      const qsToken = qs.parse(history.location.search, { ignoreQueryPrefix: true });

      if (!qsToken.token) {
        history.push(`${isTournamentLogin ? '/tournaments' : ''}/invalid-password-reset-token${langQueryString}`);
        // we return a clean up function to stop further state changes
        return () => {};
      }

      // Redirect to invalid token page if the token is not valid
      const result = await authenticationService.validatePasswordResetToken(qsToken.token);

      if (!result.data.valid) {
        history.push(`${isTournamentLogin ? '/tournaments' : ''}/invalid-password-reset-token${langQueryString}`);
        return () => {};
      }

      // All appears OK - Set the token!
      setToken(qsToken.token);
    }

    validateAndSetToken();
  }, [setToken, history.location.search]);

  const displayValidationError = (validationError) => {
    if (
      validationError.data.field === 'password'
      && validationError.data.code === ErrorCodes.PASSWORD_DOESNT_MEET_REQUIREMENTS
    ) {
      // If the error is one we are expecting, display the message accordingly.
      return 'The password does not meet the required complexity requirements.';
    }
    // If the error is not one we are expecting, then bubble the error up the stack
    throw validationError;
  };

  const newPasswordSet = async (password) => {
    // Redirect to invalid token page if the token is not valid
    const result = await authenticationService.validatePasswordResetToken(token);

    if (!result.data.valid) {
      history.push(`${isTournamentLogin ? '/tournaments' : ''}/invalid-password-reset-token${langQueryString}`);
      return () => {};
    }

    try {
      const newPasswordResponse = await authenticationService.newPassword(
        token,
        password,
        isTournamentLogin,
      );

      return history.push(
        `${isTournamentLogin ? '/tournaments' : ''}/password-reset-success?signInLink=${newPasswordResponse.signInLink}${langAdditionalString}`,
      );
    } catch (registrationError) {
      if (registrationError.statusCode === HttpStatus.UNPROCESSABLE_ENTITY) {
        // If the error is a validation error, the display a message accordingly
        return displayValidationError(registrationError);
      }
      // If the error is not a validation error, then bubble the error up the stack
      throw registrationError;
    }
  };

  return (
    <div className="pages__new-password-page">
      <BackgroundImage />
      <img className="new-password-page-title" src={OnCourseLogo} alt="OnCourse_logo" />

      <Card
        className="new-passowrd-card"
        title={get('newPassword-formTitle')}
        hasOverlay
      >
        <NewPasswordForm
          onComplete={newPasswordSet}
          submitButtonText={get('newPassword-formButton')}
          formDescriptionText={get('newPassword-formRulesTitle')}
          passwordLabel={get('newPassword-formNewPassword')}
          passwordLabelPlaceholder={get('newPassword-formNewPasswordPlaceholder')}
          retypePasswordLabel={get('newPassword-formRetypePassword')}
          retyPasswordLabelPlaceholder={get('newPassword-formRetypePasswordPlaceholder')}
        />
      </Card>

      <Footer />
    </div>
  );
};

export default NewPasswordPage;
