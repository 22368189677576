import { get } from '../lib/strings';
import themeType from '../lib/consts/themeType';

function getAboutUsOptions() {
  const options = [{
    value: 'pleseChoose',
    label: get('registration-formDropdownOptions-default'),
  }, {
    value: 'webSearch',
    label: get('registration-formAboutUsOptions-webSearch'),
  }, {
    value: 'blog',
    label: get('registration-formAboutUsOptions-blog'),
  }, {
    value: 'socialMedia',
    label: get('registration-formAboutUsOptions-socialMedia'),
  }, {
    value: 'magazine',
    label: get('registration-formAboutUsOptions-magazine'),
  }, {
    value: 'association',
    label: get('registration-formAboutUsOptions-association'),
  }, {
    value: 'conference',
    label: get('registration-formAboutUsOptions-conference'),
  }, {
    value: 'invite',
    label: get('registration-formAboutUsOptions-invite'),
  }];

  return options;
}

function getRoleOptions(type) {
  const options = [{
    type: themeType.FACILITY,
    key: 'management',
    value: 'management',
    label: get('registration-formRoleOptions-management'),
  }, {
    type: themeType.FACILITY,
    key: 'facility Management',
    value: 'facility Management',
    label: get('registration-formRoleOptions-facility-management'),
  }, {
    type: themeType.FACILITY,
    key: 'coaching',
    value: 'coaching',
    label: get('registration-formRoleOptions-coaching'),
  }, {
    type: themeType.FACILITY,
    key: 'commitee',
    value: 'commitee',
    label: get('registration-formRoleOptions-commitee'),
  }, {
    type: themeType.FACILITY,
    key: 'owner',
    value: 'owner',
    label: get('registration-formRoleOptions-owner'),
  }, {
    type: themeType.TOURNAMENT,
    key: 'tournament director',
    value: 'tournament director',
    label: get('registration-formRoleOptions-tournament-director'),
  }, {
    type: themeType.TOURNAMENT,
    key: 'tournament manager',
    value: 'tournament manager',
    label: get('registration-formRoleOptions-tournament-manager'),
  }, {
    type: themeType.TOURNAMENT,
    key: 'tournament assistant',
    value: 'tournament assistant',
    label: get('registration-formRoleOptions-tournament-assistant'),
  }, {
    type: themeType.TOURNAMENT,
    key: 'tournament consultant',
    value: 'tournament consultant',
    label: get('registration-formRoleOptions-tournament-consultant'),
  }, {
    type: themeType.TOURNAMENT,
    key: 'sustainability manager',
    value: 'sustainability manager',
    label: get('registration-formRoleOptions-sustainability-manager'),
  }, {
    type: themeType.TOURNAMENT,
    key: 'communications manager',
    value: 'communications manager',
    label: get('registration-formRoleOptions-communications-manager'),
  }, {
    type: themeType.TOURNAMENT,
    key: 'commercial manager',
    value: 'commercial manager',
    label: get('registration-formRoleOptions-commercial-manager'),
  }];

  if (themeType.FACILITY === type) {
    return options.filter((item) => item.type === themeType.FACILITY);
  }

  if (themeType.TOURNAMENT === type) {
    return options.filter((item) => item.type === themeType.TOURNAMENT);
  }

  return options;
}

function getMeasurementUnitsOptions() {
  const options = [{
    key: 'metric',
    value: get('profile-measurementUnitOptions-metric'),
  }, {
    key: 'british imperial',
    value: get('profile-measurementUnitOptions-british-imperial'),
  }, {
    key: 'united states customary',
    value: get('profile-measurementUnitOptions-united-states-customary'),
  }];

  return options;
}

function getMeasurementUnitValue(key, defaultVal) {
  const systemUnitMap = {};
  const metricUnitOptions = getMeasurementUnitsOptions();
  metricUnitOptions.forEach((item) => {
    systemUnitMap[item.key] = item.value;
  });
  return systemUnitMap[key] || defaultVal;
}

function getRoleTypeOptions() {
  const options = [{
    key: 'ADMIN_USER',
    value: get('facilityProfileTeam-usertype-admin'),
  }];

  return options;
}

function getGreenFeeOptions() {
  const options = [{
    key: 'BELOW_30',
    value: get('facility-greenFeeOptions-below-30'),
  }, {
    key: 'BET_30_100',
    value: get('facility-greenFeeOptions-between-30-100'),
  }, {
    key: 'ABOVE_100',
    value: get('facility-greenFeeOptions-above-100'),
  }, {
    key: 'NA',
    value: get('facility-greenFeeOptions-na'),
  }];

  return options;
}

function getAnnualFeeOptions() {
  const options = [{
    key: 'BELOW_1000',
    value: get('facility-annualFeeOptions-below-1000'),
  }, {
    key: 'BET_1K_5K',
    value: get('facility-annualFeeOptions-below-1000-5000'),
  }, {
    key: 'ABOVE_5K',
    value: get('facility-annualFeeOptions-above-5000'),
  }, {
    key: 'NA',
    value: get('facility-greenFeeOptions-na'),
  }];

  return options;
}

function getMaintenanceBudgetOptions() {
  const options = [{
    key: 'BELOW_500000',
    value: get('facility-maintenanceBudgetOptions-Below-500000'),
  }, {
    key: 'BET_500K_1.5M',
    value: get('facility-maintenanceBudgetOptions-between-500k-1.5M'),
  }, {
    key: 'ABOVE_1.5M',
    value: get('facility-maintenanceBudgetOptions-above-1.5M'),
  }];

  return options;
}

function getHistoricLandUseOptions() {
  const options = [{
    key: 'NATURAL',
    value: get('facility-historicLandUseOptions-natural'),
  }, {
    key: 'CROPLAND',
    value: get('facility-historicLandUseOptions-cropland'),
  }, {
    key: 'DEGRADED',
    value: get('facility-historicLandUseOptions-degraded'),
  }, {
    key: 'URBAN',
    value: get('facility-historicLandUseOptions-urban'),
  }, {
    key: 'RECREATION',
    value: get('facility-historicLandUseOptions-recreation'),
  }];

  return options;
}

function getSurroundingsOptions() {
  const options = [{
    key: 'URBAN',
    value: get('facility-surroundingsOptions-urban'),
  }, {
    key: 'SUBURBAN',
    value: get('facility-surroundingsOptions-suburban'),
  }, {
    key: 'COASTAL',
    value: get('facility-surroundingsOptions-coastal'),
  }, {
    key: 'NATLOWDEV',
    value: get('facility-surroundingsOptions-natlowdev'),
  }, {
    key: 'NATLITDEV',
    value: get('facility-surroundingsOptions-natlitdev'),
  }];

  return options;
}

function getFacilityTypeOptions() {
  const options = [{
    key: 'PRIVATE_MEMBERS',
    value: get('facility-typesOptions-privateMembers'),
  }, {
    key: 'DAILY_FEE',
    value: get('facility-typesOptions-dailyFee'),
  }, {
    key: 'RESORT',
    value: get('facility-typesOptions-resort'),
  }, {
    key: 'MUNICIPAL',
    value: get('facility-typesOptions-municipal'),
  }, {
    key: 'OTHER',
    value: get('facility-typesOptions-other'),
  }];

  return options;
}

function getContactReasonsOptions() {
  const options = [{
    key: 'HELP_ONCOURSE',
    value: get('helpdesk-reason-option-help-with-OnCourse'),
  }, {
    key: 'CALL',
    value: get('helpdesk-reason-option-request-a-consultation-call'),
  }];

  return options;
}

function getFacilityOptions(facilities) {
  const formattedFacilities = [];

  for (let i = 0; i < facilities.length; i += 1) {
    formattedFacilities.push({
      value: facilities[i].organisation_id,
      label: facilities[i].organisation_name,
      type: facilities[i].organisation_type
        && facilities[i].organisation_type.name === themeType.FACILITY.toUpperCase()
        ? themeType.FACILITY : themeType.TOURNAMENT,
    });
  }

  return formattedFacilities;
}

function getCountryOptions(countries) {
  const formattedCountries = [];

  for (let i = 0; i < countries.length; i += 1) {
    formattedCountries.push({
      key: countries[i].id,
      value: countries[i].id,
      label: countries[i].title,
    });
  }

  return formattedCountries;
}

function getTournamentHostseOptions(tournaments) {
  const formattedTournaments = [{
    value: '',
    label: get('registration-formDropdownOptions-default'),
  }];

  for (let i = 0; i < tournaments.length; i += 1) {
    formattedTournaments.push({
      value: `${tournaments[i].id} - ${tournaments[i].title}`,
      label: tournaments[i].title,
    });
  }

  return formattedTournaments;
}

function getOrganisationGroupsOptions(groups) {
  const formattedGroups = [{
    value: '',
    label: get('registration-formDropdownOptions-default'),
  }];

  for (let i = 0; i < groups.length; i += 1) {
    formattedGroups.push({
      value: `${groups[i].id} - ${groups[i].title}`,
      label: groups[i].title,
    });
  }

  return formattedGroups;
}

function createOwnerSwtichOptions(users = [], currentUserId) {
  return users.filter((user) => {
    if (user.id === currentUserId) return false;
    if (user.inviteStatus === 'invited') return false;
    return true;
  }).map((user) => {
    return { value: user.email_address, key: user.id };
  });
}

function getOptionLabel(data, key) {
  // eslint-disable-next-line eqeqeq
  const field = data.find((item) => item.key == key);

  if (field) {
    return field.label ? field.label : field.value;
  }

  return key;
}

function getOptionKey(data, value) {
  const field = data.find((item) => item.value === value);

  if (field) {
    return field.key;
  }

  return '';
}

function arraySort(data) {
  // Remove inactive data
  const dataFiltered = data.filter((obj) => {
    return obj.active;
  });

  const dataSorted = dataFiltered.sort((a, b) => a.sortOrder - (b.sortOrder));

  // Sort awnsers
  return dataSorted;
}

export default {
  getAboutUsOptions,
  getRoleOptions,
  getMeasurementUnitsOptions,
  getMeasurementUnitValue,
  getOptionLabel,
  getOptionKey,
  getFacilityOptions,
  getCountryOptions,
  getRoleTypeOptions,
  getGreenFeeOptions,
  getAnnualFeeOptions,
  getMaintenanceBudgetOptions,
  getHistoricLandUseOptions,
  getSurroundingsOptions,
  getFacilityTypeOptions,
  arraySort,
  getContactReasonsOptions,
  getTournamentHostseOptions,
  getOrganisationGroupsOptions,
  createOwnerSwtichOptions,
};
