/* eslint-disable react/no-danger */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PanelLinkLayout from '../../../components/layout/PanelLinkLayout';
import './SupportPlanning.scss';
import { get } from '../../../lib/strings';
import { GlobalContext } from '../../../context/GlobalContext';
import countryService from '../../../services/country/country-service';
import mediaService from '../../../services/media/media-service';
import contentService from '../../../services/content/content-service';
import ImproveReportsPanel from '../../../components/core/tiles/improve-reports-panel/ImproveReportsPanel';

const SupportPlanning = () => {
  const [content, updateContent] = useState([]);
  const { org, user } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const sectionId = pathname.split('/')[1];

  const getPageContent = async () => {
    const country = await countryService.getIdByLookUpId(org.country_lookup_id);
    if (!country) return;
    const slug = pathname.split('/').pop();
    const pageContent = await
    contentService.getContentByPageAndLanguageCodeAndSectionId(
      slug, user.language, country.id, sectionId, org.organisation_id,
    );
    const page = await Promise.all(pageContent.map(async (item) => {
      const itemClone = { ...item };
      if (item.documents) {
        const documentLinks = itemClone.documents.map((_document) => {
          const document = { ..._document };
          document.link = `${process.env.REACT_APP_API_URL}/api/documents/document/${_document.id}`;
          document.text = `${_document.name}.${_document.fileType}`;
          return document;
        });
        itemClone.documentLinks = documentLinks;
      }
      itemClone.imgSource = itemClone.imageRecord
        ? await mediaService.getPartnerLogo(itemClone.imageRecord.id)
        : null;
      return itemClone;
    }));
    page.sort((a, b) => a.sortcode - b.sortcode);
    updateContent(page);
  };
  useEffect(() => {
    getPageContent();
  }, [pathname]);
  return (
    <>
      <PanelLinkLayout
        title={get('improve-pageTitle')}
        tiles={
                [
                  {
                    title: get('improve-onCourseReportsTileTitle'),
                    description: get('improve-onCourseReportsTileDescription'),
                    href: `/${sectionId}/oncourse-reports`,
                  },
                  {
                    title: get('improve-RecognitionTileTitle'),
                    description: get('improve-onCourseRecognitionTileDescription'),
                    href: `/${sectionId}/recognition`,
                  },
                  {
                    title: get('improve-supportPlanningTileTitle'),
                    description: get('improve-onCourseSupportPlanningTileDescription'),
                    href: `/${sectionId}/support-planning`,
                  },
                ]
              }
        hasShadow={false}
      >
        <ImproveReportsPanel
          content={content}
        />
      </PanelLinkLayout>
    </>
  );
};

export default SupportPlanning;
