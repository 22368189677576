import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../../../components/core/card/Card';
import Footer from '../../../components/core/footer/Footer';
import BackgroundImage from '../../../components/core/backgroundImage/BackgroundImage';
import { get, stringSanitizer } from '../../../lib/strings';
import OnCourseLogo from '../../../assets/OnCourse_Logo.svg';
import { GlobalContext } from '../../../context/GlobalContext';
import THEME_TYPE from '../../../lib/consts/themeType';

import './ForgottenPasswordLinkSentPage.scss';

const ForgottenPasswordLinkSentPage = () => {
  const history = useHistory();
  const { themeSetup, theme } = useContext(GlobalContext);
  const isTournamentLogin = history.location.pathname.indexOf('tournaments') !== -1;

  useEffect(() => {
    if (isTournamentLogin && theme !== THEME_TYPE.TOURNAMENT) {
      themeSetup(THEME_TYPE.TOURNAMENT);
    }
  }, []);

  const CreateDescription = () => {
    return (
      <div
        className="forgotten-password-description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: stringSanitizer(get('forgottenPasswordLinkSent-description')),
        }}
      />
    );
  };

  return (
    <div className="pages__forgotten-password-link-sent-page">
      <BackgroundImage />
      <img className="forgotten-password-title" src={OnCourseLogo} alt="OnCourse_logo" />

      <Card
        className="forgotten-password-link-sent-card"
        title={get('forgottenPasswordLinkSent-title')}
        hasOverlay
      >
        {CreateDescription()}
      </Card>

      <Footer />
    </div>
  );
};

export default ForgottenPasswordLinkSentPage;
