import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInputField from '../form/TextInputField';
import { get } from '../../../lib/strings';
import FormError from '../form/FormError';
import Label from '../label/Label';
import './badge.scss';

const WhatElseBadge = ({
  errorInAddAnswerModal,
  onClickConfirmAddAnswer,
  onClickCancelWhatElseBadge,
  fieldIndex,
  name: parentName,
  clickedAnswer,
  register,
  setValue,
}) => {
  const textInput = React.createRef();
  useEffect(() => {
    if (!clickedAnswer) return;
    setValue(`${parentName}.[${fieldIndex}]`, clickedAnswer.title);
  }, [clickedAnswer]);
  return (
    <div className="badge-container">
      <div className="d-flex pt-4 px-3 m-0">
        <Label htmlFor="data-grid-what-else-badge-label" text={`${get('data-grid-what-else-badge-label')}?`} /><p>{`(${get('data-grid-what-else-badge-label-validation')})`}</p>
      </div>
      <div className="d-flex px-3 py-4 justify-content-between">
        <TextInputField
          autoComplete="none"
          name={`${parentName}.[${fieldIndex}]`}
          label=""
          placeholder={get('question-set-modalAddAnwserPlaceholder')}
          className="input-field-row"
          data-cy="owner-input"
          ref={register({
            required: true,
          })}
        />
        <div className="badge-container-buttons">

          <button
            id="edit"
            type="button"
            onClick={() => onClickConfirmAddAnswer(textInput, fieldIndex)}
          >
            {clickedAnswer ? get('data-grid-what-else-badge-confirm-button') : get('data-grid-what-else-badge-add-button')}
          </button>
          <div className="badge-buttons__separator" />

          <button
            id="delete"
            type="button"
            onClick={() => onClickCancelWhatElseBadge(fieldIndex)}
          >
            {get('data-grid-what-else-badge-cancel-button')}
          </button>
        </div>

      </div>
      {errorInAddAnswerModal && (
      <div className="badge-error_message">
        <FormError message={get(errorInAddAnswerModal)} />
      </div>
      )}

    </div>
  );
};

WhatElseBadge.defaultProps = {
  errorInAddAnswerModal: undefined,
  clickedAnswer: undefined,
};
WhatElseBadge.propTypes = {
  errorInAddAnswerModal: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClickConfirmAddAnswer: PropTypes.func.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  onClickCancelWhatElseBadge: PropTypes.func.isRequired,
  clickedAnswer: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
export default WhatElseBadge;
