import api from '../../lib/http';

export default {
  updateUser: async (
    userId,
    userUpdate,
  ) => {
    const response = await api(
      'patch',
      `app-user/${userId}`,
      userUpdate,
      true,
      false,
      true,
    );

    return response;
  },

  getUserOrgs: async (
    userId,
    query = {},
  ) => {
    let qs = '';
    if (query.type) {
      qs = `?type=${query.type}`;
    }
    const response = await api(
      'get',
      `app-user/${userId}/organisation${qs}`,
      {},
      false,
      false,
      true,
    );

    return response;
  },

  changePassword: async (
    userId,
    password,
  ) => {
    const result = await api(
      'post',
      `/app-user/change-password/${userId}`,
      { password },
      false,
      false,
      true,
    );

    return result;
  },

  contactHelpdesk: async (
    userId,
    data,
  ) => {
    const result = await api(
      'post',
      `/app-user/contact-helpdesk/${userId}`,
      data,
      false,
      false,
      true,
    );

    return result;
  },
};
