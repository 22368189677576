import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  ProSidebar, Menu, MenuItem, SidebarContent,
} from 'react-pro-sidebar';
import {
  useRouteMatch, useLocation,
} from 'react-router-dom';
import Link from '../link/InternalLink';
import { get } from '../../../lib/strings';
import ReviewLinks from './review-links/ReviewLinks';
import ImproveLinks from './improve-links/ImproveLinks';
import './Sidebar.scss';
import PermissionsGated from '../../context-aware/PermissionsGated/PermissionsGated';
import OranisationGated from '../../context-aware/OrganisationGated/OrganisationGated';
import Permissions from '../../../lib/permissions';
import sidebarBg from '../../../assets/navbar-ring.svg';
import sidebarBgTour from '../../../assets/navbar-ring-tour.svg';
import geoLogo from '../../../assets/GEO.svg';
import RouteSideBarMapping from '../../../lib/consts/routeSideBarMapping';
import { GlobalContext } from '../../../context/GlobalContext';
import themeType from '../../../lib/consts/themeType';

const Sidebar = ({ toggled, handleToggleSidebar, contentSections }) => {
  const { pathname } = useLocation();
  const [subMenuState, setSubMenuState] = React.useState([false, false, false]);// false:close
  const { theme } = useContext(GlobalContext);

  const toggleSubMenu = (i, isToggle = true) => {
    const clone = subMenuState.slice(0);
    // Reset all sub-menus except for the one that clicked
    const newState = clone.map((val, index) => {
      if (index === i) {
        return val;
      }
      return false;
    });
    // if isToggle=false, just open submenu; otherwise reverse preState;
    newState[i] = isToggle ? !newState[i] : true;
    setSubMenuState(newState);
  };

  useEffect(() => {
    if (!pathname) return;
    if (pathname === RouteSideBarMapping.BEST_PRACTICES.LINK) {
      toggleSubMenu(RouteSideBarMapping.BEST_PRACTICES.INDEX, false);
    }

    if (pathname === RouteSideBarMapping.ONCOURSE_REPORTS.LINK) {
      toggleSubMenu(RouteSideBarMapping.ONCOURSE_REPORTS.INDEX, false);
    }

    if (pathname === RouteSideBarMapping.TEAM_INTRODUCTION.LINK) {
      toggleSubMenu(RouteSideBarMapping.TEAM_INTRODUCTION.INDEX, false);
    }

    if (pathname.includes(RouteSideBarMapping.ANNUAL_REVIEW.LINK)) {
      toggleSubMenu(RouteSideBarMapping.BEST_PRACTICES.INDEX, false);
    }
  }, [pathname]);

  const styleForTopMenu = (index) => { // just add under-line for top menu item when in close stage;
    return subMenuState[index] ? '' : 'sidebar-nav--item';
  };
  const styleForSelectedItem = (_href, menuLevel) => {
    const isActivePage = useRouteMatch(_href);
    switch (menuLevel) {
      case 'menu':
        return isActivePage && isActivePage.isExact ? 'sidebar-nav--selectedItem' : 'sidebar-nav--item';// selected : normal
      case 'submenu':
        return isActivePage && isActivePage.isExact ? 'sidebar-nav--selectedItem' : 'sidebar-submenu--item';
      case 'item':
        return isActivePage && isActivePage.isExact ? 'sidebar-nav--selectedItem' : 'sidebar-nav--item';
      default:
        return isActivePage && isActivePage.isExact ? 'sidebar-nav--selectedItem' : 'sidebar-nav--item';
    }
  };

  return (
    <>
      <div className="sidebar-parent">
        <div style={{ position: 'fixed', zIndex: 1 }}>
          <ProSidebar
            breakPoint="md"
            onToggle={handleToggleSidebar}
            toggled={toggled}
          >
            <SidebarContent>
              <Menu>
                <OranisationGated
                  subMenuState={subMenuState}
                  styleForSelectedItem={styleForSelectedItem}
                  RouteSideBarMapping={RouteSideBarMapping}
                  styleForTopMenu={styleForTopMenu}
                  toggleSubMenu={toggleSubMenu}
                  handleToggleSidebar={handleToggleSidebar}
                >

                  <PermissionsGated activities={[Permissions.activities.MAIN_DASHBOARD]}>
                    <MenuItem
                      className={styleForSelectedItem('/', 'menu')}
                      onClick={
                          () => {
                            handleToggleSidebar();
                            toggleSubMenu(RouteSideBarMapping.MAIN_DASHBOARD.INDEX);
                          }
                        }
                    ><Link to="/">{get('sidebar-MainDashboard')}</Link>
                    </MenuItem>
                  </PermissionsGated>

                  <PermissionsGated activities={[Permissions.activities.FACILITY_PROFILE]}>
                    <MenuItem
                      className={styleForSelectedItem(`/profile/${theme === themeType.TOURNAMENT ? 'tournament' : 'facility'}`, 'menu')}
                      onClick={() => {
                        handleToggleSidebar();
                        toggleSubMenu(RouteSideBarMapping.FACILITY_PROFILE.INDEX);
                      }}
                    ><Link to={`/profile/${theme === themeType.TOURNAMENT ? 'tournament' : 'facility'}`}>{get('sidebar-FacilityProfile')}</Link>
                    </MenuItem>
                  </PermissionsGated>

                  <ReviewLinks
                    styleForSelectedItem={styleForSelectedItem}
                    toggleSubMenu={toggleSubMenu}
                    subMenuState={subMenuState}
                    handleToggleSidebar={handleToggleSidebar}
                  />
                  <ImproveLinks
                    styleForSelectedItem={styleForSelectedItem}
                    toggleSubMenu={toggleSubMenu}
                    subMenuState={subMenuState}
                    handleToggleSidebar={handleToggleSidebar}
                    contentSections={contentSections}
                  />
                  <MenuItem
                    className="sidebar-nav--item sidebar-nav--lastitem"
                    onClick={() => {
                      handleToggleSidebar();
                      toggleSubMenu(RouteSideBarMapping.DOCUMENT_LIBRARY.INDEX);
                    }}
                  >
                    <Link to={`/documents/${get('sidebar-DocumentLibrary')}`}>
                      {get('sidebar-DocumentLibrary')}
                    </Link>
                  </MenuItem>

                </OranisationGated>
                <img src={theme === themeType.TOURNAMENT ? sidebarBgTour : sidebarBg} className="sidebar-image" alt="ring" />
                <div className="sidebar-footer-container">
                  <MenuItem className="sidebar-logout">
                    <Link to="/logout">{get('sidebar-LogOut')}</Link>
                  </MenuItem>
                  <div className="sidebar-footer-logo">
                    <img className="partner-logo" src={geoLogo} alt="sustianble golf geo" />
                  </div>
                </div>
              </Menu>
            </SidebarContent>
          </ProSidebar>
        </div>
      </div>
      <div className="behind-the-scenes" />
    </>
  );
};

Sidebar.defaultProps = {
  handleToggleSidebar: () => {},
  contentSections: undefined,
};

Sidebar.propTypes = {
  toggled: PropTypes.bool.isRequired,
  handleToggleSidebar: PropTypes.func,
  contentSections: PropTypes.arrayOf(PropTypes.shape({})),

};

export default Sidebar;
