import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../core/card/Card';
import Footer from '../../core/footer/Footer';
import BackgroundImage from '../../core/backgroundImage/BackgroundImage';
import ExternalLinkButton from '../../core/link/ExternalLinkButton';
import { get, stringSanitizer } from '../../../lib/strings';
import OnCourseLogo from '../../../assets/OnCourse_Logo.svg';

import './RegistrationResponseWidget.scss';

const RegistrationResponseWidget = ({
  href,
  heading,
  bodyText,
  theme,
}) => {
  const themeClass = theme === 'white' ? 'regstration-response-widget__page-white' : undefined;
  return (
    <div className={`regstration-response-widget__page ${themeClass}`}>
      <BackgroundImage />
      <img className="login-title" src={OnCourseLogo} alt="OnCourse_logo" />

      <Card
        className="regstration-response-widget-card"
        title={heading}
        titleClassName="regstration-response-widget-card-title"
        hasOverlay
      >

        <div
          data-cy="body-text"
          className="regstration-response-widget-body-text"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: stringSanitizer(bodyText),
          }}
        />

        <div className="d-flex justify-content-center pt-4">
          <ExternalLinkButton
            className="regstration-response-widget-return-to-home medium"
            data-cy="return-to-sign-in-button"
            href={href}
            onNewTab={false}
          >
            {get('registrationComplete-returnToLoginButton')}
          </ExternalLinkButton>
        </div>
      </Card>
      <Footer />
    </div>
  );
};

RegistrationResponseWidget.defaultProps = {
  theme: 'black',
};

RegistrationResponseWidget.propTypes = {
  href: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

export default RegistrationResponseWidget;
