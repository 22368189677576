import HTTPError from '../../lib/errors/http-error';
import api from '../../lib/http';

export default {
  getTilesByOrgId: async (orgId, languageCode) => {
    const response = await api(
      'get',
      `tiles/languages/${languageCode}`,
      {
        orgId,
      },
      false,
      false,
      true,
    );

    if (!response.ok) {
      throw new HTTPError(response.status, response.data);
    }

    return response.data;
  },
};
