import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const InternalLink = ({
  children,
  to,
  'data-cy': cypressId,
  className,
  style,
}) => {
  return (
    <Link to={to} className={className} data-cy={cypressId} style={style}>
      {children}
    </Link>
  );
};

InternalLink.defaultProps = {
  'data-cy': '',
  children: undefined,
  className: '',
  style: {},
};

InternalLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  'data-cy': PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

export default InternalLink;
