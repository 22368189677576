import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import './Toggle.scss';

const Toggle = ({
  name,
  label,
  className,
  control,
  errors,
  validationRules,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => validationRules(value),
      }}
      render={(fields) => {
        return (
          <ToggleComponent
            {...fields}
            label={label}
            className={className}
            errors={errors}
          />
        );
      }}
    />
  );
};

Toggle.defaultProps = {
  errors: {},
  validationRules: () => {},
  className: undefined,
  label: undefined,
};

Toggle.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
  validationRules: PropTypes.func,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({}),
  ]),
};

export default Toggle;

const ToggleComponent = ({ label, value, onChange }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const onPress = () => {
    setSelected(!selected);
    onChange(!selected);
  };

  return (
    <div className="d-flex align-items-center component__toggle">
      <p className="toggle-title">{label}</p>
      <button type="button" aria-label="toggle" onClick={() => onPress()} className={`btn-toggle ${selected ? 'active' : ''}`} data-toggle="button" aria-pressed={value} autoComplete="off">
        <div className="handle" />
      </button>
    </div>
  );
};

ToggleComponent.defaultProps = {
  label: '',
  value: false,
  onChange: () => {},
};

ToggleComponent.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};
