import React, {
  useState, useContext, useEffect,
} from 'react';

import './BestPractices.scss';
import { useParams } from 'react-router';
import _ from 'lodash';

import Panel from '../../../components/core/panel/Panel';
import { get, stringSanitizer } from '../../../lib/strings';
import LinearProgressCard from '../../../components/core/progress/liner/LinearProgressCard';
import PageLoader from '../../../components/Loaders/PageLoader';
import reviewsService from '../../../services/reviews/reviews-service';
import { GlobalContext } from '../../../context/GlobalContext';
import { useWindowSize } from '../../../utils/useWindowSize';
import PageNavTiles from '../../../components/core/tiles/page-nav-tiles/PageNavTiles';

const BestPractices = () => {
  const { type } = useParams();
  const globalContext = useContext(GlobalContext);
  const { reviewLinks } = globalContext;
  const bestPracticePanel = reviewsService.getBestPracticePanels(reviewLinks);
  const [loading, setLoading] = useState(true);
  const [panelData, setPanelData] = useState([]);

  const { org, user } = useContext(GlobalContext);
  const size = useWindowSize();

  const classNameMap = {
    nature: 'primary', resources: 'secondary', community: 'tertiary', approach: 'approach', baseline: 'baseline',
  };
  const locColorClass = classNameMap[type || 'nature'];

  useEffect(() => {
    const getData = async () => {
      await Promise.all(
        bestPracticePanel.map(async (panel) => {
          const questionSections = await reviewsService.getOrganisationQuestionsById(
            panel.id,
            org.organisation_id,
            user.language,
          );

          const activeQuestionList = reviewsService.activeQuestions(questionSections.sections);
          const activeQuestions = activeQuestionList.length;
          if (activeQuestions === 0) return 0;

          const answers = await reviewsService.getAnswerSetsByOrg(
            org.organisation_id,
            panel.id,
          );

          // TODO: Duplicate IDs question and answers are generated here - Needs to be analysed
          const activeQuestionListUnique = _.uniqWith(
            activeQuestionList,
            (arrayVal, otherVal) => arrayVal.id === otherVal.id,
          );

          const completeAnswerCount = reviewsService.getMarkedCompleteCount(
            answers[0].answers,
            activeQuestionListUnique,
          );
          const percentage = (completeAnswerCount / activeQuestionListUnique.length) * 100;

          // eslint-disable-next-line no-param-reassign
          panel.percentage = parseInt(percentage.toFixed(), 10);
        }),
      );
      setPanelData(bestPracticePanel);
      setLoading(false);
    };

    getData();
  }, [reviewLinks]);

  return (
    <PageLoader isLoading={loading}>
      <>
        <Panel>
          <h5 className="best-practices__panel-title"> {get('review-BestPracticesWidgetTitle')}</h5>
          <p className="best-practices__panel-description">{get('review-BestPracticesTileDescription')}</p>
          <div className="best-pracatices__progress-container">
            {
              panelData.map((panel) => {
                return (
                  <LinearProgressCard
                    key={panel.title}
                    title={panel.title}
                    icon={panel.icon}
                    percentage={panel.percentage}
                    colour={panel.colour}
                    href={panel.href}
                    colourClass={locColorClass}
                  />
                );
              })
            }
          </div>
          <hr />
          <div className="best-practices__benefits-blurb">
            <h5 className="best-practices__panel-title"> {get('review-BestPracticesWidgetBlurbTitle')}</h5>
            <div
              className="best-practices__panel-description"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: stringSanitizer(get('review-BestPracticesWidgetBlurb')),
              }}
            />
          </div>
        </Panel>
        <div className="mt-3" />
        {(size.width !== undefined && size.width <= 768) && (
          <div className="ml-3 mr-3">
            <PageNavTiles
              tiles={[

                {
                  title: get('review-AnnualDataTileTitle'),
                  description: get('review-AnnualDataTileDescription'),
                  href: '/annual-review/annual-data',
                },
              ]}
              hasShadow={false}
            />
          </div>
        )}
      </>
    </PageLoader>
  );
};

export default BestPractices;
