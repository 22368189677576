import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../context/GlobalContext';
import FacilityProfileGeneralInformationForm from '../../components/FacilityProfile/facilityProfileGeneralInformation-form/FacilityProfileGeneralInformationForm';
import OrganisationService from '../../services/organisation/organisation-service';
import errorCodes from '../../lib/error-codes';
import { get } from '../../lib/strings';
import Loader from '../../components/Loaders/PageLoader';

const FacilityGeneralInformation = ({
  organisationDetails,
  countries,
  updateFacilityInformation,
  generalInformationFormDirtyRef,
}) => {
  const { org, updateOrg } = useContext(GlobalContext);
  const [profileError, setProfileError] = useState(undefined);
  const genericErrorMessage = get('facility-profile-page-formGenericError');
  const facilityNameNotUniqueErrorMessage = get('facility-profile-page-formNameNotUnique');
  const [isPageLoading, updateIsPageLoading] = useState(false);

  const onClickSaveSettings = async (newFacility) => {
    setProfileError(undefined);
    updateIsPageLoading(true);
    const result = await OrganisationService.updateOrganisation(
      organisationDetails.id,
      newFacility,
    );

    if (result.ok) {
      updateFacilityInformation(result.data);

      // Update context org
      const updatedOrg = {
        ...org,
        country_lookup_id: result.data.lookup_id,
        measurement_unit: result.data.measurement_unit,
      };
      await updateOrg(updatedOrg);
    } else if (result.data.field === 'title' && result.data.code === errorCodes.NOT_UNIQUE) {
      setProfileError(facilityNameNotUniqueErrorMessage);
    } else {
      setProfileError(genericErrorMessage);
    }
    updateIsPageLoading(false);
  };

  return (
    <>
      <div className="component__profile-facility-generalInformation">
        <FacilityProfileGeneralInformationForm
          className="profile-page-profile-settings-form"
          onComplete={onClickSaveSettings}
          organisationDetails={organisationDetails}
          countries={countries}
          profileError={profileError}
          ref={generalInformationFormDirtyRef}
        />
      </div>
      { isPageLoading && <Loader />}
    </>
  );
};

FacilityGeneralInformation.defaultProps = {
  organisationDetails: {},
  countries: [],
  updateFacilityInformation: () => {},
  generalInformationFormDirtyRef: PropTypes.shape({ current: undefined }),
};

FacilityGeneralInformation.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  organisationDetails: PropTypes.shape({
    id: PropTypes.number.isRequired,
    address_1: PropTypes.string,
    address_2: PropTypes.string,
    city: PropTypes.string,
    image_id: PropTypes.string,
    phone_number: PropTypes.string,
    post_zip_code: PropTypes.string,
    title: PropTypes.string,
    country: PropTypes.string,
    vat_number: PropTypes.string,
    email_address: PropTypes.string,
    facility_type: PropTypes.string,
  }),
  updateFacilityInformation: PropTypes.func,
  generalInformationFormDirtyRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default FacilityGeneralInformation;
