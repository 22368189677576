import React, {
  useContext, useImperativeHandle, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import DropdownField from '../../core/form/DropdownField';
import Toggle from '../../core/form/Toggle';
import { get } from '../../../lib/strings';
import arrayUtils from '../../../utils/array';
import HiddenField from '../../core/form/HiddenField';
import Alert from '../../core/alert/Alert';
import { GlobalContext } from '../../../context/GlobalContext';
import RouteLeavingGuard from '../../warningUnSaveGuardModal/RouteLeavingGuard';
import './ProfileSettingsForm.scss';

const ProfileSettingsForm = forwardRef(({
  onComplete, className, profileError,
}, ref) => {
  const { user, supportedLanguages } = useContext(GlobalContext);
  const unitsOptions = arrayUtils.getMeasurementUnitsOptions();

  // GOLF-629 - Temporary hide the Zulu(zu) language selection
  const supportedLanguagesFiltered = supportedLanguages.filter((language) => language.key !== 'zu');

  const {
    handleSubmit, errors, control, formState, getValues,
  } = useForm({
    defaultValues: {
      dropdownLanguage: arrayUtils.getOptionLabel(supportedLanguages, user.language),
      dropdownUnits: arrayUtils.getOptionLabel(unitsOptions, user.measurement_unit),
      toggleNotification: user.email_notifications,
      toggleEmail: user.allow_correspondence,
      toggleTour: user.introductory_tour,
      emailAddress: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    },
  });
  const fieldsEdited = formState.dirtyFields;
  // compare original and current language, falsy: none block;
  const isDirtyAlt = () => {
    if (!getValues) return false; // none block
    return !_.isEqual(arrayUtils.getOptionLabel(supportedLanguages, getValues().dropdownLanguage),
      arrayUtils.getOptionLabel(supportedLanguages, user.language));
  };

  const history = useHistory();
  useImperativeHandle(ref, () => ({ getDirtyState() { return isDirtyAlt(); } }));

  const onSubmit = (data) => {
    const mappedUser = {
      language: typeof fieldsEdited.dropdownLanguage !== 'undefined' ? data.dropdownLanguage : arrayUtils.getOptionKey(supportedLanguages, data.dropdownLanguage),
      email_notifications: data.toggleEmail,
      measurement_unit: typeof fieldsEdited.dropdownUnits !== 'undefined' ? data.dropdownUnits : arrayUtils.getOptionKey(unitsOptions, data.dropdownUnits),
      introductory_tour: data.toggleTour,
      allow_correspondence: data.toggleNotification,
      // mandatory fields the api expects but shoudn't be displayed in this form
      email_address: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
    };

    onComplete(mappedUser);
  };

  return (
    <div className={`components_profile-settings-form ${className}`}>
      <div>
        {profileError && (
        <Alert data-cy="login-error" className="alert" variant="danger">
          {profileError}
        </Alert>
        )}
        <Form
          id="my-form"
          className="profile-settings-form-wrapper"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="profile-settings-wrapper">
            <div className="profile-settings-card">
              <p className="profile-settings-card-title">{get('profile-tabSettingsCardLanguageTitle')}</p>
              <p className="profile-settings-card-description">{get('profile-tabSettingsCardLanguageDescription')}</p>
              <DropdownField
                control={control}
                name="dropdownLanguage"
                className="input-field-row"
                options={supportedLanguagesFiltered}
                errors={errors}
              />
            </div>
          </div>
          {/* This section is disabled because it will not be connected to the
            API for the first live release */}
          <div className="profile-settings-wrapper --disabled">
            <div className="profile-settings-card">
              <p className="profile-settings-card-title">{get('profile-tabSettingsCardNotificationTitle')}</p>
              <div className="profile-settings-card-notification-wrapper">
                <Toggle
                  control={control}
                  name="toggleNotification"
                  label={get('profile-tabSettingsCardNotificationDescription')}

                />
              </div>
              <div className="profile-settings-card-separator" />
              <div className="profile-settings-card-notification-wrapper">
                <Toggle
                  control={control}
                  name="toggleEmail"
                  label={get('profile-tabSettingsCardNotificationEmailDescription')}
                />
              </div>
            </div>

            <div className="profile-settings-card">
              <p className="profile-settings-card-title">{get('profile-tabSettingsCardTourTitle')}</p>
              <p className="profile-settings-card-description">{get('profile-tabSettingsCardTourDescription')}</p>
              <div className="profile-settings-card-tour-wrapper">
                <Toggle
                  control={control}
                  name="toggleTour"
                  label={get('profile-tabSettingsCardTourToggleDescription')}
                />
              </div>
            </div>
          </div>
          <HiddenField name="emailAddress" control={control} />
          <HiddenField name="firstName" control={control} />
          <HiddenField name="lastName" control={control} />
        </Form>
      </div>
      <RouteLeavingGuard
        when={isDirtyAlt()}
        navigate={(path) => history.push(path)}
        // eslint-disable-next-line no-unused-vars
        shouldBlockNavigation={(location) => {
          if (isDirtyAlt()) return true;
          return false;
        }}
      />
    </div>
  );
});

ProfileSettingsForm.defaultProps = {
  className: undefined,
  profileError: undefined,
};

ProfileSettingsForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  className: PropTypes.string,
  profileError: PropTypes.string,
};

export default ProfileSettingsForm;
