import React from 'react';

import { get } from '../../lib/strings';
import ReviewLayout from '../../components/layout/ReviewLayout';
import AnnualReview from './annual-review/AnnualReview';
import BestPractices from './best-practices/BestPractices';

const Review = () => {
  return (
    <ReviewLayout
      title={get('review-AnnualDataPageTitle')}
      hasShadow={false}
    >
      <BestPractices panelOnly />
      <AnnualReview panelOnly />
    </ReviewLayout>
  );
};

export default Review;
