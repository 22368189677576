/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../../../context/GlobalContext';
import { get } from '../../../../lib/strings';
import DropdownField from '../../../../components/core/form/DropdownField';
import './AnnualReviewSummary.scss';
import Summary from '../../../../components/review/Summary/Summary';
import reviewsService from '../../../../services/reviews/reviews-service';
import PageLoader from '../../../../components/Loaders/PageLoader';
import questionSetLib from '../../../../lib/questionSets';
import themeType from '../../../../lib/consts/themeType';

const AnnualReviewSummary = () => {
  const { year } = useParams();
  const [questions, updateQuestions] = useState({});
  const [loading, updateLoading] = useState(true);
  const [options, updateYearOptions] = useState([]);
  const [answerData, updateAnswers] = useState({});
  const [answerPreviousYearData, updateAnswersPreviousYear] = useState({});
  const sideBarMenuAnnualDataTitle = get('sidebar-AnnualData');

  const {
    org, user, reviewLinks, theme,
  } = useContext(GlobalContext);
  const id = reviewsService.pullOutAnnualDataId(reviewLinks);

  const {
    control,
  } = useForm({
    defaultValues: {
      companyReviewYears: year,
    },
  });

  useEffect(() => {
    if (id) {
      const getData = async () => {
        updateLoading(true);
        const annualReviewData = await reviewsService.getOrganisationQuestionsById(
          id, org.organisation_id, user.language,
        );

        const answers = await reviewsService.getAnswerSetsByOrg(org.organisation_id, id);
        const yearOptions = await questionSetLib.getYearsFromAnswers(answers, {
          isTournament: theme === themeType.TOURNAMENT,
        });

        let sectionOrder = annualReviewData.sections;
        if (annualReviewData && annualReviewData.sections) {
          sectionOrder = annualReviewData.sections.sort((a, b) => b.sortOrder - a.sortorder);
        }
        annualReviewData.sections = sectionOrder;

        const answerYearData = await questionSetLib.pullOutAnswersByYear(answers, year);
        updateAnswers(answerYearData);

        updateYearOptions(yearOptions);
        updateQuestions(annualReviewData, 'annual-data');

        const yearIndex = yearOptions.findIndex((item) => item.value === year);
        if (yearIndex !== -1 && yearIndex > 0) {
          const answersPreviousYearData = await questionSetLib.pullOutAnswersByYear(
            answers, yearOptions[yearIndex - 1],
          );
          updateAnswersPreviousYear(answersPreviousYearData);
        }

        updateLoading(false);
      };

      getData();
    }
  }, [id]);

  const handleChange = (val) => {
    if (val !== year) {
      window.location = `/annual-review/${sideBarMenuAnnualDataTitle}/${val}`;
    }
  };

  return (
    <PageLoader isLoading={loading}>
      <div className={`${theme === themeType.TOURNAMENT ? 'img-tour page' : 'img-facility page'}`}>
        <div className="page-header">
          <div className="annual-summary__header-container">
            <div className="annual-summary__titles">
              <h6 className="review-page-title">{get('annualData-SummaryTitle')}</h6>
              <h4 className="review-page-section-title"> {year}</h4>
            </div>
            <div className="annual-summary__dropdown-container">
              <Form>
                <DropdownField
                  label={get('annualData-DateDropdownLabel')}
                  control={control}
                  name="companyReviewYears"
                  options={options}
                  className="annual-summary__dropdown "
                  handleChange={(e) => handleChange(e)}
                />
              </Form>
            </div>
          </div>
          <Summary data={questions.sections} type="annual" slug={`${year}/annual/`} answers={answerData.answers} answerPreviousYearData={answerPreviousYearData.answers} />
        </div>
      </div>
    </PageLoader>
  );
};

export default AnnualReviewSummary;
