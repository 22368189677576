import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './DocumentLibrarySectionTile.scss';
import { useHistory } from 'react-router-dom';
import { get } from '../../../../lib/strings';

import Button from '../../button/Button';
import Pointer from '../../../../assets/svgs-as-components/RightPointer';
import mediaService from '../../../../services/media/media-service';
import reviewImg from '../../../../assets/Review.svg';

const DocumentLibrarySectionTile = ({
  iconSource, href, title, fileCount, color, titleColor,
}) => {
  const [fetchedIcon, updateIcon] = useState('');
  useEffect(() => {
    const getIcon = async () => {
      const logo = await mediaService.getReviewIcon(iconSource);
      updateIcon(logo);
    };

    if (typeof iconSource === 'number') {
      getIcon();
    } else if (typeof iconSource === 'string') {
      updateIcon(iconSource);
    } else {
      updateIcon(reviewImg);
    }
  }, []);

  const history = useHistory();
  const styles = 'document-library-card-button';
  return (
    <div className="document-library-card">
      <Button
        colour={titleColor || color}
        inverse
        className={styles}
        disableHoverEffect
        styles={{
          borderColor: titleColor || color,
          color,
        }}
        onClick={() => history.push(href)}
      >
        <div className="document-library-card-button-content">
          <div className="document-library-card-content">
            <img src={fetchedIcon} alt="" />
            <span
              className="document-library-card-content-title"
              style={{ color: titleColor }}
            >
              {title}
            </span>
            <p style={{ color: titleColor }} className="document-library-card-content-count">{fileCount.toString(10)} {(fileCount === 0 || fileCount > 1) ? get('documentLibrary-filesCountPlural') : get('documentLibrary-filesCountSingular')}</p>
          </div>
          <div className="document-library-card-pointer">
            <Pointer color={titleColor || color} />
          </div>
        </div>
      </Button>
    </div>

  );
};

DocumentLibrarySectionTile.defaultProps = {
  titleColor: '',
};

DocumentLibrarySectionTile.propTypes = {
  href: PropTypes.string.isRequired,
  iconSource: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fileCount: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
};

export default DocumentLibrarySectionTile;
