import React, { useContext } from 'react';
import PanelLinkLayout from '../../components/layout/PanelLinkLayout';
import Panel from '../../components/core/panel/Panel';
import Button from '../../components/core/button/Button';
import { get } from '../../lib/strings';
import { GlobalContext } from '../../context/GlobalContext';
import themeType from '../../lib/consts/themeType';

import './JoinFacility.scss';

const JoinFacility = () => {
  const { user, theme } = useContext(GlobalContext);
  return (
    <div className={`${theme === themeType.TOURNAMENT ? 'img-tour page' : 'img-facility page'}`}>
      <PanelLinkLayout
        title={get('createFacility-title')}
        tiles={
                [
                  {
                    title: get('createFacility-cardTitle'),
                    description: get('createFacility-cardDescription'),
                    href: '/create-facility',
                  },
                  {
                    title: get('joinFacility-cardTitle'),
                    description: get('joinFacility-cardDescription'),
                    href: '/join-facility',
                  },
                ]
              }
        hasShadow={false}
      >
        <Panel>
          <div className="container no-gutters">
            <div className="d-flex justify-content-end">
              <a
                href={`mailto:hello@sustainable.golf?subject=${get('joinFacility-mail-subject')} ${user.email}&body=${get('joinFacility-mail-greeting')},%0D${get('joinFacility-mail-text')}:`}
                target="_blank"
                rel="noreferrer"
              >
                <Button className="create-facility-button">
                  {get('joinFacility-requestButton')}
                </Button>
              </a>
            </div>
            <div className="create-facility-divider" />
            <div className="row panel-header padding-left ">
              <h5>{get('joinFacility-panelTitle')}</h5>
              <p>{get('joinFacility-panelDescription')}</p>
            </div>
          </div>
        </Panel>
      </PanelLinkLayout>
    </div>
  );
};
export default JoinFacility;
