/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import FormError from './FormError';
import Label from '../label/Label';
import { get } from '../../../lib/strings';

import './TextInputField.scss';

const TextInputField = React.forwardRef((props, ref) => {
  const {
    name,
    label,
    errors,
    className,
    placeholder,
    onChange,
    value,
    type,
    as,
    rows,
    units,
    step,
    maxLength,
    // eslint-disable-next-line react/prop-types
    defaultValue,
    'data-cy': cypressId,
  } = props;

  const showString = get('login-formPasswordShow');
  const hideString = get('login-formPasswordHide');

  const [id] = useState(_.uniqueId(''));
  const inputClasses = errors[name] ? 'error' : '';
  const [typeState, setTypeState] = useState({
    type,
    name: showString,
  });

  let errorMessage;

  if (errors[name]) {
    if (errors[name].type === 'required') {
      errorMessage = get('login-formRequiredField');
    } else if (errors[name].type === 'maxLength') {
      errorMessage = get('login-formMaxCharsExceeded');
    } else {
      errorMessage = errors[name].message;
    }
  }

  // Login screen - on first render the show/hide strings are empty.
  // Force a new render to update the UI when we have a value
  useEffect(() => {
    const string = () => showString;
    setTypeState({
      type,
      name: string(),
    });
  }, [showString]);

  const changeTypeState = () => {
    setTypeState({
      type: typeState.type === 'password' ? 'text' : 'password',
      name: typeState.name === showString ? hideString : showString,
    });
  };

  const otherProps = {};
  if (typeof as !== 'undefined') {
    otherProps.as = as;
  }

  const isTypeNumber = typeState.type.toLowerCase() === 'number';

  const onKeyDown = (event) => {
    if (event.which === 38 || event.which === 40 || event.which === 189) {
      event.preventDefault();
    }
  };

  const onWheel = (event) => {
    event.target.blur();
  };

  const keyProps = isTypeNumber && {
    onKeyDown,
    onWheel,
  };

  return (
    <div className={`components__form-text-input-field ${className}`}>
      {label && <Label htmlFor={id} text={label} />}

      <div
        className={`form-inner-wrapper ${inputClasses} ${
          (as === 'textarea' || isTypeNumber) && '--no-border'
        } ${isTypeNumber && '--number'}`}
      >
        <div className="form-control-container">
          <Form.Control
            className={`${isTypeNumber && '--number'}`}
            id={id}
            type={typeState.type}
            name={name}
            rows={rows}
            value={value}
            ref={ref}
            placeholder={placeholder}
            data-cy={cypressId}
            onChange={onChange}
            step={step}
            maxLength={maxLength}
            defaultValue={defaultValue}
            {...keyProps}
            {...otherProps}
          />
          {isTypeNumber && <div className="form-text-input-field-number" />}
        </div>

        {units ? <p className="form-text-input-units">{units}</p> : null}

        {type === 'password' && (
          <div className="show-password" onClick={changeTypeState}>
            {typeState.name}
          </div>
        )}
      </div>
      {errorMessage && <FormError message={errorMessage} />}
    </div>
  );
});

TextInputField.displayName = 'TextInputField';

TextInputField.defaultProps = {
  type: 'text',
  className: '',
  placeholder: '',
  'data-cy': undefined,
  onChange: () => {},
  value: undefined,
  errors: {},
  as: undefined,
  units: undefined,
  rows: 0,
  defaultValue: undefined,
  step: undefined,
  maxLength: undefined,
};

TextInputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  units: PropTypes.string,
  as: PropTypes.string,
  step: PropTypes.string,
  rows: PropTypes.number,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({}),
  ]),
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  'data-cy': PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
};

export default TextInputField;
