module.exports.FacilityTabIndexes = {
  FACILITY: {
    GENERAL: 0,
    DETAILS: 1,
    TEAM: 2,
  },
  TOURNAMENT: {
    GENERAL: 0,
    TEAM: 1,
  },

};
