/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

const Field = ({ name, value }) => {
  return (
    <input name={name} value={value} type="hidden" />
  );
};

Field.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const HiddenField = ({ control, name, defaultValue }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(fields) => {
        return (
          <Field {...fields} />
        );
      }}
    />
  );
};

HiddenField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  control: PropTypes.shape({
    getValues: PropTypes.func,
  }).isRequired,
};

export default HiddenField;
