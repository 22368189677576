import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from '../../../lib/strings';

import './MaintenanceBanner.scss';

const MaintenanceBanner = ({ notificationBanner }) => {
  const [isClosed, setIsClosed] = useState(null);

  useEffect(() => {
    setIsClosed(false);
  }, [notificationBanner.createdAt]);

  const onClickClose = () => {
    setIsClosed(true);
  };

  if (isClosed) {
    return null;
  }

  const {
    maintenanceDuration,
    maintenanceStartDate,
    maintenanceStartTime,
    durationUnits,
  } = notificationBanner;

  const mapObj = {
    '{{maintenanceDuration}}': maintenanceDuration,
    '{{maintenanceStartDate}}': maintenanceStartDate,
    '{{maintenanceStartTime}}': maintenanceStartTime,
    '{{durationUnits}}': durationUnits,
  };

  let string = get('maintenance-description');
  string = string.replace(/{{maintenanceStartDate}}|{{maintenanceDuration}}|{{durationUnits}}|{{maintenanceStartDate}}|{{maintenanceStartTime}}/gi, (matched) => {
    return mapObj[matched];
  });

  return (
    <div className="notification-bar">
      <div>
        <strong>{get('maintenance-title')} </strong>{`- ${string}`}
      </div>
      <div className="close" onClick={onClickClose} aria-hidden="true">×</div>
    </div>
  );
};

MaintenanceBanner.defaultProps = {
  notificationBanner: {},
};

MaintenanceBanner.propTypes = {
  notificationBanner: PropTypes.object,
};

export default MaintenanceBanner;
