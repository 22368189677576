import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../../lib/strings';
import { SGbgCircle } from '../../Icons';
import './Card.scss';

const Card = ({
  title,
  children,
  className,
  titleClassName,
  hasOverlay,
  hasContacts,
}) => {
  return (
    <div className={`card__wrapper ${className}`}>
      <div className="card-title-wrapper">
        <p className={`card-title ${titleClassName}`}>{title}</p>
      </div>

      {children}

      {hasContacts && (
        <div className="card-contact-wrapper">
          <p>
            {`${get('login-formHelp')} `}
            <a href="mailto: hello@sustainable.golf">hello@sustainable.golf</a>
          </p>
        </div>
      )}

      {hasOverlay && (
        <div className="overlay">
          <SGbgCircle />
        </div>
      )}
    </div>
  );
};

Card.defaultProps = {
  children: undefined,
  className: undefined,
  hasOverlay: false,
  titleClassName: undefined,
  hasContacts: true,
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  hasOverlay: PropTypes.bool,
  hasContacts: PropTypes.bool,
};

export default Card;
