import React, {
  useEffect, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { FaBars } from 'react-icons/fa';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Profile } from '../../Icons';
import OnCourseLogo from '../../../assets/OnCourse_Logo.svg';
import QuestionMarkActiveImg from '../../../assets/question-mark.svg';
import QuestionMarkInactiveImg from '../../../assets/question-mark-inactive.svg';
import QuestionMarkActiveImgTour from '../../../assets/question-mark-active-tournament.svg';
import QuestionMarkInactiveImgTour from '../../../assets/question-mark-inactive-tournament.svg';
import { GlobalContext } from '../../../context/GlobalContext';
import mediaService from '../../../services/media/media-service';
import organisationService from '../../../services/organisation/organisation-service';
import countryService from '../../../services/country/country-service';
import MaintenanceBanner from '../maintenanceBanner/MaintenanceBanner';
import './Nav.scss';
import themeType from '../../../lib/consts/themeType';

const Nav = ({
  notification, handleToggleSidebar, toggled, notificationBanner,
}) => {
  let notificationTextClass = {};
  let notificationCount = notification;
  const history = useHistory();
  const [helpdeskIcon, setHelpdeskIcon] = useState(QuestionMarkInactiveImg);
  const { user, theme, org } = useContext(GlobalContext);
  const [avatar, setAvatar] = useState(null);
  const [onCourseLogo, setOnCourseLogo] = useState(OnCourseLogo);
  const { pathname } = useLocation();

  if (notification > 99) {
    notificationTextClass = 'nav-bar-notification-badge-text-bigger';
    notificationCount = '99+';
  }

  const onClickHelpdesk = () => {
    history.push('/helpdesk');
  };

  const onClickLogo = () => {
    history.push('/');
  };

  const getProfilePhoto = async () => {
    if (user.image_id) {
      const result = await mediaService.getProfilePhoto(user.image_id);

      if (result) {
        setAvatar(result);
      }
    }
  };

  useEffect(() => {
    getProfilePhoto();
  }, [user]);

  const getOncourseLogo = async () => {
    if (org && org.organisation_id) {
      const countryData = await countryService.getIdByLookUpId(org.country_lookup_id);
      const logoId = await organisationService.getOncourseLogoId(
        org.organisation_id, countryData.id,
      );

      // Get oncourse imageStream by country code
      const result = await mediaService.getOncourseLogo(logoId);
      setOnCourseLogo(result || OnCourseLogo);
    }
  };

  useEffect(() => {
    getOncourseLogo();
  }, [org]);

  useEffect(() => {
    if (pathname === '/helpdesk') {
      setHelpdeskIcon(
        theme === themeType.TOURNAMENT
          ? QuestionMarkActiveImgTour
          : QuestionMarkActiveImg,
      );
    } else {
      setHelpdeskIcon(
        theme === themeType.TOURNAMENT
          ? QuestionMarkInactiveImgTour
          : QuestionMarkInactiveImg,
      );
    }
  }, [pathname, theme]);

  return (
    <div className="nav-bar">
      <div className="btn-toggle" onClick={() => handleToggleSidebar(!toggled)} aria-hidden="true">
        <FaBars />
      </div>
      <div className="nav-header-logo" onClick={onClickLogo} aria-hidden>
        <img src={onCourseLogo} alt="OnCourse_logo" />
      </div>
      <div className="nav-bar-icons-wrapper">
        {org && org.organisation_name ? <h6 className="nav-header-org-name">{org.organisation_name}</h6> : null}
        <div className="nav-bar-notification">
          <div onClick={onClickHelpdesk} aria-hidden="true">
            <img src={helpdeskIcon} alt="help_desk" />
          </div>
          {notification > 0 ? (
            <div className={`nav-bar-notification-badge ${notificationTextClass}`}>
              <p className="nav-bar-notification-badge-text">{notificationCount}</p>
            </div>
          ) : null}
        </div>
        <div className="nav-bar-profile">
          <Link to="/user-profile">
            {avatar ? (
              <img src={avatar} alt={user.firstName + user.lastName} />
            ) : (
              <Profile />
            )}
          </Link>
        </div>
      </div>
      {notificationBanner && notificationBanner.createdAt ? (
        <MaintenanceBanner
          notificationBanner={notificationBanner}
        />
      ) : null}
    </div>
  );
};

Nav.defaultProps = {
  notification: 0,
  handleToggleSidebar: () => {},
};

Nav.propTypes = {
  notification: PropTypes.number,
  handleToggleSidebar: PropTypes.func,
  toggled: PropTypes.bool.isRequired,
  notificationBanner: PropTypes.object.isRequired,
};

export default Nav;
