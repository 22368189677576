import React from 'react';
import PropTypes from 'prop-types';
import FormError from '../core/form/FormError';
import { get } from '../../lib/strings';

const QuestionsErrors = ({ error }) => {
  if (error && error.type === 'required') {
    return (
      <FormError message={get('form-validation-please-complete')} />
    );
  }

  return (
    <>
    </>

  );
};

QuestionsErrors.defaultProps = {
  error: undefined,
};

QuestionsErrors.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string,
  }),
};
export default QuestionsErrors;
