/* eslint-disable max-len */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import QuestionsWrapper from '../../questions-wrapper/QuestionsWrapper';
import AccordionCard from '../../core/accordionCard/AccordionCard';
import arrayUtils from '../../../utils/array';
import './SectionRenderer.scss';
import answerSetUtils from '../../../lib/answerSetUtils';
import { QuestionMarkCompleteImg, QuestionMarkReviewImg } from '../../Icons';

const checker = (arr) => {
  if (arr.includes('isSaveInProcess')) return 'isSaveInProcess';
  if (arr.every((e) => e === 'isComplete')) return 'isComplete';
};
const SectionRenderAccord = ({
  hash,
  subsection,
  onComplete,
  answerData,
  notesHandlers,
  getStatusIconForFieldSets,
  subQuestionHash,
  answerPreviousYearData,
  colour,
}) => {
  // control scrolling to active area
  // sets the sroll area on load to match the empty div below
  const ref = useRef();

  useEffect(() => {
    if (!hash) return;
    if (subsection.id.replace(/-/g, '').toUpperCase() === hash.substring(1).toUpperCase()) {
      if (ref && ref.current) {
        ref.current.scrollIntoView({ inline: 'start', behaviour: 'smooth' });
      }
    }
  }, []);

  return (
    <div className="section-renderer__annual-data-accordion">
      <div
        className="section-renderer__annual-data-hidden-field"
        ref={ref}
        id={subsection.id.replace(/-/g, '').toUpperCase()}
      />
      <AccordionCard
        colour={colour}
        key={subsection.id}
        headerTitle={subsection.title}
        eventKey={subsection.id.replace(/-/g, '').toUpperCase()}
        bodyClassName="p-0"
        StatusIcon={getStatusIconForFieldSets(subsection.fieldSets)}
      >
        {subsection.fieldSets
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((fieldSet) => {
            if (fieldSet && fieldSet.questions && fieldSet.questions.length === 0) return null;
            return (
              <Accordion
                defaultActiveKey={
                  subQuestionHash ? subQuestionHash.replace(/-/g, '').toUpperCase() : null
                }
              >
                <div key={fieldSet.id}>
                  <AccordionCard
                    colour={colour}
                    withoutBg
                    key={fieldSet.id}
                    eventKey={fieldSet.id.replace(/-/g, '').toUpperCase()}
                    headerTitle={
                      fieldSet.title ? (
                        <span className="questions-wrapper-subsection-title">{fieldSet.title}</span>
                      ) : null
                    }
                    bodyClassName="p-0"
                  >
                    {arrayUtils
                      .arraySort(fieldSet.questions)
                      .sort((a, b) => a.sortOrder - b.sortOrder)
                      .map((question) => {
                        return (
                          // status icon
                          <div key={question.id} className="questions-wrapper">
                            <QuestionsWrapper
                              colour={colour}
                              question={question}
                              onComplete={onComplete}
                              answerData={answerData}
                              answerPreviousYearData={answerPreviousYearData}
                              notesHandlers={notesHandlers}
                            />
                            <div className="separator" />
                          </div>
                        );
                      })}
                  </AccordionCard>
                </div>
              </Accordion>
            );
          })}
      </AccordionCard>
    </div>
  );
};
SectionRenderAccord.propTypes = {
  hash: PropTypes.string.isRequired,
  subQuestionHash: PropTypes.string.isRequired,
  subsection: PropTypes.shape().isRequired,
  onComplete: PropTypes.func.isRequired,
  answerData: PropTypes.shape({}).isRequired,
  answerPreviousYearData: PropTypes.shape({}).isRequired,
  notesHandlers: PropTypes.func.isRequired,
  getStatusIconForFieldSets: PropTypes.func.isRequired,
  colour: PropTypes.string.isRequired,
};

const SectionRenderer = ({
  data,
  colour,
  isAnnual,
  onComplete,
  answerData,
  notesHandlers,
  answerPreviousYearData,
}) => {
  const { hash, subQuestionHash } = useLocation();
  const getStatusIconForFieldSetsByQuestionIds = (allQuestionIds) => {
    // 2 get 'complete' status for all questions
    const statusArray = allQuestionIds.map((questionId) => {
      const answerFromPayload = answerSetUtils.pullOutAnswerById(questionId, answerData);
      if (answerFromPayload && answerFromPayload.savelater) {
        return 'isSaveInProcess';
      }
      if (answerFromPayload && answerFromPayload.complete) {
        return 'isComplete';
      }
      return null;
    });
    // 3get fieldsets complete status
    const statusValue = checker(statusArray);
    // 4 get image
    switch (statusValue) {
      case 'isSaveInProcess':
        return QuestionMarkReviewImg;
      case 'isComplete':
        return QuestionMarkCompleteImg;
      default:
        break;
    }
  };
  const getStatusIconForFieldSets = (fieldSets) => {
    // 1get all question id
    const allQuestionIds = fieldSets.flatMap((fieldSet) => {
      return fieldSet.questions.flatMap((question) => question.id);
    });
    return getStatusIconForFieldSetsByQuestionIds(allQuestionIds);
  };
  const getStatusIconForQuestions = (questions) => {
    const questionIds = questions.flatMap((question) => question.id);
    return getStatusIconForFieldSetsByQuestionIds(questionIds);
  };

  if (isAnnual) {
    return (
      <Accordion defaultActiveKey={hash.substring(1).toUpperCase()}>
        {data && data.subsections
          ? data.subsections
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((subsection) => {
              return (
                <SectionRenderAccord
                  hash={hash}
                  type={data.slug || 'nature'}
                  colour={data.colour || colour}
                  subQuestionHash={subQuestionHash}
                  subsection={subsection}
                  onComplete={onComplete}
                  answerData={answerData}
                  answerPreviousYearData={answerPreviousYearData}
                  notesHandlers={notesHandlers}
                  getStatusIconForFieldSets={getStatusIconForFieldSets}
                />
              );
            })
          : ''}
      </Accordion>
    );
  }

  return (
    <>
      {data && data.subsections
        ? data.subsections
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .filter((subsections) => subsections.fieldSets && subsections.fieldSets.length)
          .map((subsection) => {
            return (
              <div key={subsection.id}>
                {subsection.title && (
                <h5 className="section-renderer__heading"> {subsection.title} </h5>
                )}
                {subsection.fieldSets
                    && subsection.fieldSets.length
                    && subsection.fieldSets
                      .sort((a, b) => {
                        return a.sortOrder - b.sortOrder;
                      })
                      .map((fieldSet) => {
                        // control scrolling to active area
                        // sets the sroll area on load to match the empty div below
                        let ref = null;

                        if (hash && hash.substring(1).toUpperCase()) {
                          ref = useRef();
                          useEffect(() => {
                            if (
                              fieldSet.id.replace(/-/g, '').toUpperCase()
                              === hash.substring(1).toUpperCase()
                            ) {
                              if (ref && ref.current) {
                                ref.current.scrollIntoView();
                              }
                            }
                          }, []);
                        }

                        return (
                          <>
                            <div ref={ref} id={fieldSet.id.replace(/-/g, '').toUpperCase()} />
                            <Accordion
                              key={fieldSet.id}
                              defaultActiveKey={hash.substring(1).toUpperCase()}
                            >
                              <AccordionCard
                                colour={colour}
                                headerTitle={fieldSet.title}
                                eventKey={fieldSet.id.replace(/-/g, '').toUpperCase()}
                                bodyClassName="p-0"
                                StatusIcon={getStatusIconForQuestions(fieldSet.questions)}
                              >
                                <div>
                                  {arrayUtils
                                    .arraySort(fieldSet.questions)
                                    .sort((a, b) => a.sortOrder - b.sortOrder)
                                    .map((question) => {
                                      return (
                                        <div key={question.id} className="questions-wrapper">
                                          <QuestionsWrapper
                                            colour={colour}
                                            question={question}
                                            onComplete={onComplete}
                                            answerData={answerData}
                                            answerPreviousYearData={answerPreviousYearData}
                                            notesHandlers={notesHandlers}
                                          />
                                          <div className="separator" />
                                        </div>
                                      );
                                    })}
                                </div>
                              </AccordionCard>
                            </Accordion>
                          </>
                        );
                      })}
              </div>
            );
          })
        : ''}
    </>
  );
};
SectionRenderer.defaultProps = {
  data: undefined,
};

SectionRenderer.propTypes = {
  data: PropTypes.shape(),
  onComplete: PropTypes.func.isRequired,
  answerData: PropTypes.shape({}).isRequired,
  answerPreviousYearData: PropTypes.shape({}).isRequired,
  notesHandlers: PropTypes.func.isRequired,
  isAnnual: PropTypes.bool.isRequired,
  colour: PropTypes.string.isRequired,
};
export default SectionRenderer;
