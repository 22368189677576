import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Question from '../Question/Question';
import './AnnualDataSummaryCard.scss';
import answerSetUtils from '../../../../lib/answerSetUtils';

const Set = ({
  data, colour, slug, sectionId, type, answers,
}) => {
  const checker = (arr) => {
    return arr.every((e) => e === 'isComplete');
  };

  return (
    <div>
      {
        data
          && data.map((set, index) => {
            return (
              <div key={set.title}>
                {set.title && <h6 className="--dark set-title"> {set.title} </h6>}
                {
                  set.fieldSets.map((fieldSet) => {
                    const allQuestionIds = fieldSet.questions.flatMap((question) => question.id);
                    const statusArray = answers && allQuestionIds.map(
                      (questionId) => {
                        const answerFromPayload = answerSetUtils.pullOutAnswerById(
                          questionId,
                          answers,
                        );
                        if (answerFromPayload && answerFromPayload.complete) {
                          return 'isComplete';
                        }
                        return null;
                      },
                    );
                    const complete = statusArray && checker(statusArray);
                    const hasEmptyQuestions = _.isEmpty(fieldSet.questions);
                    return (
                      !hasEmptyQuestions && (
                      <Question
                        key={fieldSet.id}
                        title={fieldSet.title}
                        completed={complete}
                        colour={colour}
                        href={slug}
                        sectionId={sectionId}
                        questionBlockToOpen={set.id}
                        subquestionToOpen={fieldSet.id}
                        type={type}
                      />
                      )
                    );
                  })
                }
                {index !== (data.length - 1) && <div className="annual-data-section-separator" />}
              </div>
            );
          })
      }
    </div>
  );
};

Set.defaultProps = {
  data: [],
  colour: null,
  sectionId: null,
  type: null,
  answers: [],
};

Set.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  colour: PropTypes.string,
  type: PropTypes.string,
  slug: PropTypes.string.isRequired,
  sectionId: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.shape({})),
};
export default Set;
