/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import { GlobalContext } from '../../context/GlobalContext';
import Button from '../../components/core/button/Button';
import Modal from '../../components/core/modal/Modal';
import Label from '../../components/core/label/Label';
import DropdownField from '../../components/core/form/DropdownField';
import RichTextEditor from '../../components/core/form/RichTextEditor';
import arrayUtils from '../../utils/array';
import userService from '../../services/user/user-service';
import { get } from '../../lib/strings';
import './HelpdeskPage.scss';

const HelpdeskPage = () => {
  const { user } = useContext(GlobalContext);
  const [contactUsModal, setContactUsModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const fromRequiredFieldError = get('helpdesk-formReasonRequired');
  const fromRequiredMessageError = get('helpdesk-formQueryRequired');
  const REASON_OPTIONS = arrayUtils.getContactReasonsOptions();

  const {
    errors, control, handleSubmit,
  } = useForm({});

  const onClickContactus = () => {
    setContactUsModal(true);
  };

  const onSubmitMessage = async (data) => {
    await userService.contactHelpdesk(user.id, {
      reason: arrayUtils.getOptionLabel(REASON_OPTIONS, data.reason),
      message: data.message,
    });

    setContactUsModal(false);
    setConfirmationModal(true);
  };

  return (
    <div className="pages__helkpdesk-page page">
      <div className="page-header helkpdesk-page-header">
        <p className="helkpdesk-page-title">{get('helpdesk-title')}</p>
        <Button className="facility-profile-save-button medium primary" onClick={onClickContactus}>
          {get('helpdesk-title-button-contactUs')}
        </Button>
      </div>

      <div className="helpdesk-page-card">
        <p className="helkpdesk-page-card-title">{get('helpdesk-card-title')}</p>
        <p className="helkpdesk-page-card-description">{get('helpdesk-card-subText1')}</p>
        <p className="helkpdesk-page-card-email">{get('helpdesk-card-email')}
          <a
            href="#"
            aria-hidden
            onClick={() => window.open('mailto:hello@sustainable.golf', '_blank')}
          >
            {' hello@sustainable.golf'}
          </a>
        </p>
        <p className="helkpdesk-page-card-description">{get('helpdesk-card-subText2')}</p>
      </div>

      <Modal
        show={contactUsModal}
        enforceFocus={false}
        onClose={() => setContactUsModal(false)}
        heading={get('helpdesk-card-message-modal-title')}
        subtitle=""
        bodyText={(
          <div>
            <Form
              id="send-message-form"
              className="facility-details-form-wrapper"
              onSubmit={handleSubmit(onSubmitMessage)}
            >

              <DropdownField
                control={control}
                name="reason"
                label={get('helpdesk-card-message-modal-reasonLabel')}
                data-cy="country-input"
                className="input-field-row"
                options={REASON_OPTIONS}
                errors={errors}
                validationRules={(value) => {
                  if (typeof value !== 'undefined' || REASON_OPTIONS.some((el) => el.key === value)) {
                    return true;
                  }

                  return fromRequiredFieldError;
                }}
              />
              <Label htmlFor="message" text={get('helpdesk-card-message-modal-queryLabel')} />
              <Controller
                name="message"
                control={control}
                defaultValue=""
                rules={{
                  validate: (value) => {
                    if (value) {
                      return true;
                    }

                    return fromRequiredMessageError;
                  },
                }}
                render={({ onChange, value }) => (
                  <RichTextEditor
                    maxLength={2000}
                    onChange={onChange}
                    value={value}
                    error={errors.message ? errors.message.message : ''}
                  />
                )}
              />
            </Form>
          </div>
                  )}
        footer={(
          <>
            <Button
              form="send-message-form"
              type="submit"
            >
              {get('helpdesk-card-message-modal-button')}
            </Button>
          </>
      )}
      />

      <Modal
        show={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        heading={get('helpdesk-card-confirmation-modal-title')}
        bodyText={(
          <>
            <p>{get('helpdesk-card-confirmation-modal-description1')}</p>
            <p>{get('helpdesk-card-confirmation-modal-description2')}</p>
          </>
      )}
      />
    </div>
  );
};

export default HelpdeskPage;
