import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import HttpStatus from 'http-status-codes';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { useLocation } from 'react-router-dom';
import { get } from '../../../lib/strings';
import DocumentLibrarySectionTile from '../../../components/core/tiles/document-library-section-tile/DocumentLibrarySectionTile';
import Loader from '../../../components/Loaders/PageLoader';
import TextInputField from '../../../components/core/form/TextInputField';
import DropdownField from '../../../components/core/form/DropdownField';
import Alert from '../../../components/core/alert/Alert';
import Button from '../../../components/core/button/Button';
import Modal from '../../../components/core/modal/Modal';
import './DocumentLibraryYourDocuments.scss';
import organisationService from '../../../services/organisation/organisation-service';
import { GlobalContext } from '../../../context/GlobalContext';
import reviewsService from '../../../services/reviews/reviews-service';
import documentsService from '../../../services/documents/documents-service';
import contentService from '../../../services/content/content-service';
import {
  DocumentLibrarySubsectionMapping,
  DocumentLibraryUploadLocations,
} from '../../../lib/consts/documentLibraryLocations';
import themeType from '../../../lib/consts/themeType';
import IconEditImg from '../../../assets/icon-edit.svg';
import IconTrashImg from '../../../assets/icon-trash.svg';

const DocumentLibraryYourDocuments = React.forwardRef(({ organisationId }, ref) => {
  const { pathname } = useLocation();
  const globalContext = useContext(GlobalContext);
  const {
    org,
    user,
    reviewLinks,
    theme,
  } = globalContext;

  const [uploadDocumentModal, updateUploadDocumentModal] = useState(false);
  const [modalError, setModalError] = useState(undefined);
  const [isPageLoading, updateIsPageLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [librarySections, setLibrarySections] = useState(null);
  const [questionSetData, setQuestionSetData] = useState(null);
  const [fileModal, setFileModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(false);
  const [documentDetails, setDocumentDetails] = useState({});

  const {
    register, handleSubmit, errors, control, setValue,
  } = useForm();
  const uploadRef = useRef(null);

  const fromRequiredFieldError = get('documentLibrary-formErrorRequiredField');
  const uploadDocumentError = get('documentLibrary-uploadDocumentError');

  const getDocuments = async () => {
    const result = await organisationService.getDocuments(organisationId);

    if (result.ok) {
      setDocuments(result.data);
    }
  };
  // bestPractices effect render, put this value into effect dependent array
  const { bestPractices } = reviewLinks;
  const getSections = async () => {
    const sections = [];
    const questionSets = {};
    const isTournament = theme === themeType.TOURNAMENT;

    if (isTournament) {
      const tournamentSubsections = DocumentLibrarySubsectionMapping.TOURNAMENTS;
      for (let i = 0; i < tournamentSubsections.length; i += 1) {
        sections.push({
          ...tournamentSubsections[i],
        });
      }
    } else {
      if (!bestPractices) {
        return;
      }
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < bestPractices.length; i += 1) {
        const reviewLink = bestPractices[i];
        const questionSet = await reviewsService.getOrganisationQuestionsById(
          reviewLink.id,
          org.organisation_id,
          user.language,
        );
        questionSets[questionSet.id] = questionSet;

        sections.push({
          key: questionSet.id,
          labelString: reviewLink.title,
          slug: `your/${reviewLink.slug}`,
          color: reviewLink.colour,
          filterKeys: [questionSet.id, ...questionSet.sections.map((item) => item.id)],
          icon: reviewLink.icon,
        });
      }
    }

    sections.push({
      ...DocumentLibrarySubsectionMapping.OTHER,
    });

    setQuestionSetData(questionSets);
    setLibrarySections(sections);
  };

  useEffect(() => {
    // falsy return
    if (!bestPractices) return;
    getSections();
    if (documents !== null) {
      getDocuments();
    }
  }, [bestPractices]);

  const deleteDocument = async (documentId) => {
    const result = await organisationService.deleteDocument(organisationId, documentId);

    if (result.ok) {
      getDocuments();
    }
    setFileToDelete(false);
  };

  const downloadDocument = async (documentId) => {
    return contentService.getDownloadDocumentByDocId(documentId);
  };

  const onClickUpload = () => {
    setModalError(undefined);
    updateUploadDocumentModal(true);
  };

  useImperativeHandle(ref, () => ({
    onClickUpload: () => {
      onClickUpload();
    },
  }));

  const onClickModalUploadDocument = async (data) => {
    updateUploadDocumentModal(false);
    updateIsPageLoading(true);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('name', data.name);
    formData.append('section', data.section);

    const result = await organisationService.uploadDocument(organisationId, formData, config);

    if (result.ok) {
      getDocuments();
    } else if (result.status === HttpStatus.UNAUTHORIZED) {
      updateUploadDocumentModal(true);// if got response error, will re-launch modal
      setModalError(uploadDocumentError);
    } else {
      throw modalError;
    }
    updateIsPageLoading(false);
  };

  const onClickSelectFile = () => {
    uploadRef.current.click();
  };
  const openMoveFileModal = (document, currentLocation) => {
    setModalError(undefined);
    setFileModal(true);
    const updateDocument = {
      ...document,
      currentLocation,
    };
    setDocumentDetails(updateDocument);
  };
  useEffect(() => {
    if (fileModal) {
      setValue('filename', documentDetails.blob_filename);
    }
  }, [fileModal]);

  const onClickModalUpdateDocument = async (data) => {
    setFileModal(false);
    updateIsPageLoading(true);

    const docData = {
      documents: data,
    };
    const result = await organisationService.updateDocument(
      organisationId, documentDetails.id, docData,
    );

    if (result.ok) {
      getDocuments();
    } else if (result.status === HttpStatus.UNAUTHORIZED) {
      fileModal(true);// if got response error, will re-launch modal
      setModalError(uploadDocumentError);
    } else {
      throw modalError;
    }
    updateIsPageLoading(false);
  };
  return (
    <div>
      <div className="document-library-your-buttons">
        {
          librarySections && librarySections.map((tileData) => {
            const count = documents.filter((doc) => {
              return tileData.filterKeys.includes(doc.location);
            }).length;
            return (
              <DocumentLibrarySectionTile
                iconSource={tileData.icon}
                href={`${pathname}/${tileData.slug}`}
                title={typeof tileData.labelString === 'function' ? tileData.labelString() : tileData.labelString}
                titleColor={tileData.titleColor}
                fileCount={count}
                color={tileData.color}
                key={tileData.key}
              />
            );
          })
        }
      </div>
      <Loader isLoading={isPageLoading}>
        <div className="component__document-library">
          <div className="document-library-your-documents-latest-card">
            <div className="document-library-your-documents-latest-header">
              <p className="document-library-your-documents-latest-header-title">{get('documentLibrary-latestDocuments-title')}</p>
            </div>
            <p className="document-library-your-documents-latest-description">{get('documentLibrary-latestDocuments-description')}</p>
            <table>
              <thead>
                <tr>
                  <th scope="col">{get('documentLibrary-documentTable-filename')}</th>
                  <th scope="col">{get('documentLibrary-documentTable-location')}</th>
                  <th scope="col">{get('documentLibrary-documentTable-description')}</th>
                  <th scope="col">{get('documentLibrary-documentTable-date')}</th>
                  <th scope="col" aria-label="cta" />
                </tr>
              </thead>
              <tbody>
                {
                  documents && documents.map((document) => {
                    const currentLocation = documentsService.locationStringForId(
                      document.location,
                      questionSetData,
                      librarySections,
                    );
                    return (
                      <tr key={document.id}>
                        <td
                          className="document-library-your-documents-latest-download-button"
                          data-cy="document-library-delete-document-button"
                          data-label={get('documentLibrary-documentTable-filename')}
                          onClick={() => downloadDocument(document.id)}
                          aria-hidden="true"
                        >
                          {document.blob_filename}
                        </td>
                        <td data-label={get('documentLibrary-documentTable-location')}>{
                          currentLocation
                        }
                        </td>
                        <td data-label={get('documentLibrary-documentTable-description')}>{document.blob_filename !== document.title ? document.title : ''}</td>
                        <td data-label={get('documentLibrary-documentTable-date')}>{format(new Date(document.created_at), 'dd MMMM yyyy')}</td>
                        <td>{
                          document.oncourse_document ? null : (
                            <div className="document-library-process">
                              <button
                                id="edit"
                                type="button"
                                onClick={() => openMoveFileModal(
                                  document, currentLocation,
                                )}
                              >
                                <img src={IconEditImg} alt="edit answer" />
                              </button>
                              <button
                                id="delete"
                                type="button"
                                data-cy="document-library-delete-document-button"
                                onClick={() => setFileToDelete(document.id)}
                              >
                                <img src={IconTrashImg} alt="trash answer" />
                              </button>
                            </div>
                          )
                        }
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </Loader>
      <Modal
        show={uploadDocumentModal}
        onClose={() => updateUploadDocumentModal(false)}
        heading={get('documentLibrary-uploadDocumentModalTitle')}
        bodyText={(
          <div>
            <Form>
              <div className="document-library-upload-modal">
                <input
                  type="file"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                  }}
                  ref={uploadRef}
                  hidden
                />
                <Button className="upload-button document-library-upload-button" onClick={onClickSelectFile} aria-hidden="true">
                  <p className="upload-button-text">{get('documentLibrary-selectFile')}</p>
                </Button>
                {selectedFile ? (
                  <div className="document-library-your-documents-latest-selected-file">
                    <p className="col-9">{selectedFile.name}</p>
                    <Button
                      className="col-3 document-library-your-documents-latest-delete-button --red"
                      onClick={
                        () => {
                          uploadRef.current.value = null;
                          setSelectedFile(null);
                        }
                      }
                    >
                      {get('documentLibrary-unselectFile')}
                    </Button>
                  </div>
                ) : null}
                <TextInputField
                  type="text"
                  name="name"
                  label={get('documentLibrary-uploadDocumentModalFormTitleLabel')}
                  placeholder={get('documentLibrary-uploadDocumentModalFormTitlePlaceholder')}
                  className="input-field-row"
                  data-cy="upload-document-title"
                  controlled
                  ref={register({
                    required: true,
                  })}
                  errors={errors}
                />
                <DropdownField
                  label={get('documentLibrary-uploadDocumentModalFormSectionDropdownLabel')}
                  control={control}
                  name="section"
                  options={
                    [DocumentLibraryUploadLocations.otherLocations]
                      .concat((librarySections && librarySections.flatMap((section) => {
                        const options = section.filterKeys.map((key) => {
                          return {
                            key,
                            value: section.key === 'OTHER'
                              ? get(documentsService.locationStringForId(key, questionSetData))
                              : documentsService.locationStringForId(key, questionSetData),
                          };
                        });
                        return options;
                      })
                      ))
                  }
                  className="input-field-row"
                  validationRules={(value) => {
                    if (typeof value !== 'undefined' && librarySections.find((section) => {
                      return section.filterKeys.includes(value);
                    })) {
                      return true;
                    }
                    return fromRequiredFieldError;
                  }}
                  errors={errors}
                />
              </div>
            </Form>
          </div>
        )}
        footer={(
          <div className="document-library-upload-modal-footer">
            {modalError && (
              <Alert data-cy="registration-error" className="document-library-upload-modal-alert" variant="danger">
                {modalError}
              </Alert>
            )}
            <Button className="document-library-upload-modal-button" onClick={handleSubmit(onClickModalUploadDocument)}>
              {get('documentLibrary-uploadDocumentModalFormButton')}
            </Button>
          </div>

        )}
      />

      <Modal
        show={fileModal}
        enforceFocus={false}
        onClose={() => setFileModal(false)}
        heading={get('documentLibrary-moveDocumentModalFormHead')}
        subtitle={`${get('documentLibrary-moveDocumentModalFormSubTitle')} ${documentDetails.currentLocation}`}
        bodyText={(
          <div>
            <Form>
              <div className="document-library-upload-modal">
                <DropdownField
                  label={get('documentLibrary-uploadDocumentModalFormSectionDropdownLabel')}
                  control={control}
                  name="location"
                  options={
                    [DocumentLibraryUploadLocations.otherLocations]
                      .concat(
                        (librarySections && librarySections.flatMap((section) => {
                          const options = section.filterKeys.map((key) => {
                            return {
                              key,
                              value: documentsService.locationStringForId(
                                key, questionSetData, librarySections,
                              ),
                            };
                          });
                          return options;
                        })
                        ),
                      ).filter((item) => !Array.isArray(item)).filter((n) => n)
                  }
                  className="input-field-row"
                  validationRules={(value) => {
                    if (typeof value !== 'undefined' && librarySections.find((section) => {
                      return section.filterKeys.includes(value);
                    })) {
                      return true;
                    }
                    return fromRequiredFieldError;
                  }}
                  errors={errors}
                />
                <TextInputField
                  type="text"
                  name="filename"
                  label={get('documentLibrary-documentTable-filename')}
                  className="input-field-row"
                  data-cy="upload-document-title"
                  controlled
                  ref={register({
                    required: true,
                  })}
                  errors={errors}
                />

              </div>
            </Form>
          </div>
        )}
        footer={(
          <div className="delete-notes-ctas">
            <Button
              className="--inverse flex-1"
              form="add-editor-form"
              type="button"
              inverse
              onClick={() => setFileModal(false)}
            >
              {get('question-deleteNotesCancelButton')}
            </Button>
            <Button
              className="primary flex-1"
              form="add-editor-form"
              type="button"
              onClick={handleSubmit(onClickModalUpdateDocument)}
            >
              {get('question-deleteNotesConfirmButton')}
            </Button>
          </div>
        )}
      />

      <Modal
        show={fileToDelete}
        enforceFocus={false}
        onClose={() => setFileToDelete(false)}
        heading={get('documentLibrary-deleteDocumentModalFormTitle')}
        subtitle={get('documentLibrary-deleteDocumentModalFormSubTitle')}
        footer={(
          <div className="delete-notes-ctas">
            <Button
              className="--inverse flex-1"
              form="add-editor-form"
              type="button"
              inverse
              onClick={() => setFileToDelete(false)}
            >
              {get('question-deleteNotesCancelButton')}
            </Button>
            <Button
              className="primary flex-1"
              form="add-editor-form"
              type="button"
              onClick={() => deleteDocument(fileToDelete)}
            >
              {get('question-deleteNotesConfirmButton')}
            </Button>
          </div>
        )}
      />
    </div>
  );
});

DocumentLibraryYourDocuments.propTypes = {
  organisationId: PropTypes.number.isRequired,
};

export default DocumentLibraryYourDocuments;
