/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import qs from 'qs';
import { GlobalContext } from '../../../context/GlobalContext';
import ExternalLinkButton from '../../../components/core/link/ExternalLinkButton';
import Card from '../../../components/core/card/Card';
import Footer from '../../../components/core/footer/Footer';
import BackgroundImage from '../../../components/core/backgroundImage/BackgroundImage';
import { get, stringSanitizer } from '../../../lib/strings';
import OnCourseLogo from '../../../assets/OnCourse_Logo.svg';
import THEME_TYPE from '../../../lib/consts/themeType';

import './PasswordSuccessfullyResetPage.scss';

const PasswordSuccessfullyResetPage = () => {
  const history = useHistory();
  const [signInLink, updateSignInLink] = useState('/login');
  const { themeSetup, theme } = useContext(GlobalContext);
  const isTournamentLogin = history.location.pathname.indexOf('tournaments') !== -1;

  useEffect(() => {
    const qsToken = qs.parse(history.location.search, { ignoreQueryPrefix: true });

    if (qsToken.signInLink) {
      updateSignInLink(qsToken.signInLink);
    }

    if (qsToken.lang && qsToken.signInLink) {
      updateSignInLink(`${qsToken.signInLink}?lang=${qsToken.lang}`);
    }
  }, [updateSignInLink, history.location.search]);

  useEffect(() => {
    if (isTournamentLogin && theme !== THEME_TYPE.TOURNAMENT) {
      themeSetup(THEME_TYPE.TOURNAMENT);
    }
  }, []);

  const CreateDescription = () => {
    return (
      <div
        className="password-reset-success-description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: stringSanitizer(get('passwordResetSuccess-description')),
        }}
      />
    );
  };

  return (
    <div className="pages__password-reset-success-page">
      <BackgroundImage />
      <img className="password-reset-success-page-title" src={OnCourseLogo} alt="OnCourse_logo" />

      <Card
        className="password-reset-success-card"
        title={get('passwordResetSuccess-title')}
        hasOverlay
      >
        {CreateDescription()}

        <div className="d-flex justify-content-center pt-4">
          <ExternalLinkButton
            size="large"
            className="password-reset-success-button medium"
            data-cy="return-to-sign-in-button"
            href={signInLink}
            onNewTab={false}
          >
            {get('passwordResetSuccess-button')}
          </ExternalLinkButton>
        </div>
      </Card>

      <Footer />
    </div>
  );
};

export default PasswordSuccessfullyResetPage;
