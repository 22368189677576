import React from 'react';
import PropTypes from 'prop-types';

const QuestionMarkReviewImg = ({ fill, className }) => {
  return (
    <svg id="icon-section-incomplete" className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Path_35810" data-name="Path 35810" fill="none">
        <path d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" stroke="none" />
        <path d="M 12 1 C 9.061790466308594 1 6.299449920654297 2.144199371337891 4.221820831298828 4.221820831298828 C 2.144199371337891 6.299449920654297 1 9.061790466308594 1 12 C 1 14.93820953369141 2.144199371337891 17.7005500793457 4.221820831298828 19.77817916870117 C 6.299449920654297 21.85580062866211 9.061790466308594 23 12 23 C 14.93820953369141 23 17.7005500793457 21.85580062866211 19.77817916870117 19.77817916870117 C 21.85580062866211 17.7005500793457 23 14.93820953369141 23 12 C 23 9.061790466308594 21.85580062866211 6.299449920654297 19.77817916870117 4.221820831298828 C 17.7005500793457 2.144199371337891 14.93820953369141 1 12 1 M 12 0 C 18.62742042541504 0 24 5.372579574584961 24 12 C 24 18.62742042541504 18.62742042541504 24 12 24 C 5.372579574584961 24 0 18.62742042541504 0 12 C 0 5.372579574584961 5.372579574584961 0 12 0 Z" stroke="none" fill={fill} />
      </g>
      <path id="Path_35809" data-name="Path 35809" d="M11.192-.125a1.048,1.048,0,0,0,.923-.536l4.9-7.647q.091-.171.177-.353a.853.853,0,0,0,.085-.365.745.745,0,0,0-.29-.61,1,1,0,0,0-.644-.234.952.952,0,0,0-.809.524l-4.374,7L9.108-4.991a1.2,1.2,0,0,0-.393-.336.964.964,0,0,0-.427-.1.853.853,0,0,0-.644.268.9.9,0,0,0-.256.644,1.153,1.153,0,0,0,.273.707L10.235-.661a1.469,1.469,0,0,0,.444.41A1.045,1.045,0,0,0,11.192-.125Z" transform="translate(-0.331 16.996)" fill={fill} />
    </svg>

  );
};

export default QuestionMarkReviewImg;

QuestionMarkReviewImg.defaultProps = {
  fill: '#877972',
  className: '',
};

QuestionMarkReviewImg.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};
