import * as React from 'react';
import PropTypes from 'prop-types';

const TickSmall = (props) => {
  const color = props.color || '#009150';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      viewBox="0 0 375 375"
      height={20}
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path d="M102.844 107.352h192.68v174.75h-192.68Zm0 0" />
        </clipPath>
      </defs>
      <path
        fill={color}
        d="M187.5 0C83.945 0 0 83.945 0 187.5S83.945 375 187.5 375 375 291.055 375 187.5 291.055 0 187.5 0"
      />
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M111.688 199.898c-1.965-2.03-5.25-2.066-7.286-.101a5.137 5.137 0 0 0-.101 7.27l39.308 75.12 150.133-165.792c2.156-1.84 2.395-5.102.531-7.25-1.863-2.133-5.132-2.375-7.27-.512L143.642 232.8l-31.953-32.903"
        />
      </g>
    </svg>
  );
};

TickSmall.defaultProps = {
  color: '#009150',
};

TickSmall.propTypes = {
  color: PropTypes.string,
};

export default TickSmall;
