import React from 'react';
import './Loader.scss';

const Loader = () => {
  return (
    <div className="loader" />
  );
};

Loader.propTypes = {

};
export default Loader;
