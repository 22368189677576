import React, { useContext } from 'react';
import { get } from '../../lib/strings';
import themeType from '../../lib/consts/themeType';
import { GlobalContext } from '../../context/GlobalContext';

const GettingStartPage = () => {
  const { theme } = useContext(GlobalContext);
  return (
    <div className={`${theme === themeType.TOURNAMENT ? 'img-tour page' : 'img-facility page'}`}>
      <div className="page-header">
        <h4 className="--dark"> {get('startedPage-GreetingTitle')} </h4>
      </div>
    </div>
  );
};
export default GettingStartPage;
