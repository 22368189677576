import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import answerSetUtils from '../../../../lib/answerSetUtils';
import { defaultUserUnits as checkUserUnits } from '../../../../lib/utils';
import { get } from '../../../../lib/strings';

import './RowRender.scss';

const RowRenderer = ({
  row, columns, answers, customerAnswerFields, showPreviousDataMessage, onclick,
}) => {
  const checkCustomerAnswerTitle = (id) => {
    if (!id) return undefined;
    const customerAnswer = customerAnswerFields
      .filter((answer) => answer.id === id);
    if (customerAnswer[0]) return customerAnswer[0].title;
  };
  return columns.map((column, index) => {
    let values;
    // Used to check if user has entered any data for the current row.
    // If yes we should not show the example row text
    const hasAnswerForTheRow = !_.isEmpty(row || {});
    if (column.uiType === 'DROPDOWNLIST' || column.uiType === 'MULTIPLECHOICE_ADDMORE' || column.uiType === 'SINGLECHOICE') {
      const selection = row[column.nestedQuestionId] || {};
      const selectionIds = Object.keys(selection);
      values = selectionIds.map((id) => {
        return answerSetUtils.pullDatagridAnswerFromQuestion(answers, id)
          ? answerSetUtils.pullDatagridAnswerFromQuestion(answers, id)
          : checkCustomerAnswerTitle(id) || (
          <span className="placeholder-answer-text">
            {hasAnswerForTheRow ? '' : `${get('question-set-ExampleAnswer')}`}
          </span>
          );
      });
    } else if (
      row[column.nestedQuestionId]
      && row[column.nestedQuestionId][column.rowQuestionId]
      && row[column.nestedQuestionId][column.rowQuestionId].value) {
      const {
        unitMeasurementType,
        value: fieldValue,
      } = row[column.nestedQuestionId][column.rowQuestionId];
      const { unitType } = column;
      values = fieldValue;
      if (values && unitType && unitMeasurementType) {
        const defaultUnits = checkUserUnits(unitMeasurementType);
        values = defaultUnits ? `${values} ${defaultUnits[unitType]}` : values;
      }
    } else values = '';
    const key = `${column.answerId}-${index}`;

    let answerString;
    if (Array.isArray(values)) {
      answerString = values.map((item) => (
        <ul>
          <li>{item}</li>
        </ul>
      ));
    } else {
      answerString = values ? <p className="m-0 p-0">{values}</p> : undefined;
    }
    return answerString && (answerString.length > 0 || (typeof answerString === 'object' && Object.keys(answerString).length > 0)) ? (
      <td key={key} className={`${showPreviousDataMessage ? 'components__data-row redText' : 'components__data-row'}`} onClick={onclick} aria-hidden="true" data-label={column.title}>
        {answerString}
      </td>
    ) : (
      <td key={key} className="temp-example-td" onClick={onclick} aria-hidden="true" data-label={column.title}>
        {hasAnswerForTheRow ? '' : `${get('question-set-ExampleAnswer')}`}
      </td>
    );
  });
};

RowRenderer.propTypes = {
  row: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onclick: PropTypes.func.isRequired,
};

export default RowRenderer;
