import React, { useContext } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { get } from '../../../lib/strings';
import THEME_TYPE from '../../../lib/consts/themeType';
import './Footer.scss';

const Footer = () => {
  const { theme } = useContext(GlobalContext);
  return (
    <div className="components__footer">
      <div className="footer-wrapper">
        <p className="footer-text">{`${THEME_TYPE.TOURNAMENT === theme ? get('login-footer-tournament') : get('login-footer')}`}</p>
      </div>
    </div>
  );
};

export default Footer;
