import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import HttpStatus from 'http-status-codes';
import NewPasswordForm from '../../../components/authentication/new-password-form/NewPasswordForm';
import Card from '../../../components/core/card/Card';
import BackgroundImage from '../../../components/core/backgroundImage/BackgroundImage';
import Footer from '../../../components/core/footer/Footer';
import registrationService from '../../../services/registration/registration-service';
import ErrorCodes from '../../../lib/error-codes';
import { get, stringSanitizer } from '../../../lib/strings';
import OnCourseLogo from '../../../assets/OnCourse_Logo.svg';
import { GlobalContext } from '../../../context/GlobalContext';
import getCodeFromQueryString from '../../../lib/queryStringGetter';

import './CompleteRegistrationPage.scss';

const CompleteRegistrationPage = () => {
  const [token, setToken] = useState('false');
  const history = useHistory();
  const { getStrings } = useContext(GlobalContext);

  const langCode = getCodeFromQueryString();
  let langQueryString = '';
  if (langCode) {
    langQueryString = `?lang=${langCode}`;
  }

  useEffect(() => {
    if (langCode) {
      getStrings(langCode);
    }
  }, []);

  useEffect(() => {
    async function validateAndSetToken() {
      // Redirect to invalid token page if no token in query params.
      const qsToken = qs.parse(history.location.search, { ignoreQueryPrefix: true });

      if (!qsToken.token) {
        window.location = (`/invalid-registration-token${langQueryString}`);
        // we return a clean up function to stop further state changes
        return () => {};
      }

      // Redirect to invalid token page if the token is not valid
      const result = await registrationService.validateToken(qsToken.token);

      if (!result.data.valid) {
        window.location = (`/invalid-registration-token${langQueryString}`);
        return () => {};
      }

      // All appears OK - Set the token!
      setToken(qsToken.token);
    }

    validateAndSetToken();
  }, [setToken, history.location.search]);

  const displayValidationError = (validationError) => {
    if (
      validationError.data.field === 'password'
      && validationError.data.code === ErrorCodes.PASSWORD_DOESNT_MEET_REQUIREMENTS
    ) {
      // If the error is one we are expecting, display the message accordingly.
      return 'The password does not meet the required complexity requirements.';
    }
    // If the error is not one we are expecting, then bubble the error up the stack
    throw validationError;
  };

  const setPasswordHandler = async (passwordInputState) => {
    let regResponse;

    try {
      regResponse = await registrationService.completeRegistration(
        token,
        passwordInputState,
      );
    } catch (registrationError) {
      if (registrationError.statusCode === HttpStatus.UNPROCESSABLE_ENTITY) {
        // If the error is a validation error, the display a message accordingly
        return displayValidationError(registrationError);
      }
      // If the error is not a validation error, then bubble the error up the stack
      throw registrationError;
    }

    // Password setting successful, redirect to registration completed.
    return history.push(
      `/registration-completed?signInLink=${regResponse.signInLink}`,
    );
  };

  const createDescription = () => {
    return (
      <div
        className="registration-link-sent-description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: stringSanitizer(get('completeRegistration-description')),
        }}
      />
    );
  };

  return (
    <div className="pages__registration-complete-page">
      <BackgroundImage />
      <img className="registration-complete-page-title" src={OnCourseLogo} alt="OnCourse_logo" />

      <Card
        className="registration-complete-page-card"
        title={get('completeRegistration-title')}
        titleClassName="registration-complete-page-card-title"
        hasOverlay
      >
        <NewPasswordForm
          type="create"
          onComplete={setPasswordHandler}
          formDescriptionText={createDescription()}
          submitButtonText={get('completeRegistration-formCofirmButton')}
          passwordLabel={get('completeRegistration-formPasswordLabel')}
          passwordLabelPlaceholder={get('completeRegistration-formPasswordPlaceholder')}
          retypePasswordLabel={get('completeRegistration-formRetypePasswordLabel')}
          retyPasswordLabelPlaceholder={get('completeRegistration-formRetypePasswordPlaceholder')}
        />
      </Card>

      <Footer />
    </div>
  );
};

export default CompleteRegistrationPage;
