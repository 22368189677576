import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './DocumentLibraryTile.scss';
import { useHistory } from 'react-router-dom';
import { get } from '../../../../lib/strings';

import Button from '../../button/Button';
import themeType from '../../../../lib/consts/themeType';
import { GlobalContext } from '../../../../context/GlobalContext';

const DocumentLibraryTile = ({
  iconSource, href,
}) => {
  const history = useHistory();
  const {
    theme,
  } = useContext(GlobalContext);
  return (
    <div className="dashboard-document-card">
      <img src={iconSource} alt="" className="dashboard-document-card-img" />
      <Button
        inverse
        colour={theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471'}
        className="dashboard-document-card-button"
        onClick={() => history.push(href)}
      >
        {get('document-cardButtonText')}
      </Button>
    </div>

  );
};

DocumentLibraryTile.propTypes = {
  href: PropTypes.string.isRequired,
  iconSource: PropTypes.string.isRequired,
};

export default DocumentLibraryTile;
