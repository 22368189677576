import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WarningUnSaveGuardModal from '../../warningUnSaveGuardModal/WarningUnSaveGuardModal';
import './TabSwitch.scss';
import { get } from '../../../lib/strings';

const TabSwitch = ({
  labels,
  onClick,
  className,
  selectedTab,
  colour,
  detailFormDirtyRef,
  generalInformationFormDirtyRef,
  settingFormRef,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(selectedTab);
  const [showUnSaveGuard, setShowUnSaveGuard] = useState(false);
  const [indexInAdvanced, setIndexInAdvanced] = useState('');
  const labelsLength = labels.length;

  const onClickTab = (index) => {
    const isDirtyDetailForm = detailFormDirtyRef.current
      ? detailFormDirtyRef.current.getDirtyState() : false;

    const isDirtyGeneralInformationForm = generalInformationFormDirtyRef.current
      ? generalInformationFormDirtyRef.current.getDirtyState() : false;

    const isDirtySettingForm = settingFormRef.current
      ? settingFormRef.current.getDirtyState() : false;

    if (isDirtyDetailForm || isDirtyGeneralInformationForm || isDirtySettingForm) {
      // if modified, store selected index in state in advance, and pop up guard dialog.
      // then if user kick off yes, be able to go ahead to this index tab page.
      setIndexInAdvanced(index);
      setShowUnSaveGuard(true);
    }

    if (!isDirtyDetailForm && !isDirtyGeneralInformationForm && !isDirtySettingForm) {
      setSelectedIndex(index);
      onClick(index);
    }
  };
  const onCancel = () => {
    setIndexInAdvanced(undefined);
    setShowUnSaveGuard(false);
  };
  const onConfirm = () => {
    setSelectedIndex(indexInAdvanced);
    onClick(indexInAdvanced);
    setShowUnSaveGuard(false);
    setIndexInAdvanced(undefined);
  };

  useEffect(() => {
    setSelectedIndex(selectedTab);
  }, [selectedTab]);

  return (
    <div className={`components__tabswitch ${className}`}>
      {labels.map((item, index) => {
        const isSelected = selectedIndex === index ? 'tabswitch-item-text-selected' : {};
        let colourStyle = '';
        if (selectedIndex === index) {
          colourStyle = colour;
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="tabswitch-item-wrapper" onClick={() => onClickTab(index)} aria-hidden="true">
            <p className={`tabswitch-item-text ${isSelected}`} style={{ borderBottom: colourStyle ? `4px ${colourStyle} solid` : '' }}>{item}</p>
            {index < labelsLength - 1 && <div className="tabswitch-separator" />}
          </div>
        );
      })}

      <WarningUnSaveGuardModal
        open={showUnSaveGuard}
        titleText={get('warning-unsave-modal-title')}
        contentText={get('warning-unsave-modal-content')}
        cancelButtonText={get('warning-unsave-modal-cancel-button')}
        confirmButtonText={get('warning-unsave-modal-confirm-button')}
        onCancel={onCancel}
        onClose={onCancel}
        onConfirm={onConfirm}
      />
    </div>
  );
};

TabSwitch.defaultProps = {
  onClick: () => {},
  labels: [],
  className: undefined,
  selectedTab: 0,
  colour: '',
  detailFormDirtyRef: PropTypes.shape({ current: undefined }),
  generalInformationFormDirtyRef: PropTypes.shape({ current: undefined }),
  settingFormRef: PropTypes.shape({ current: undefined }),

};

TabSwitch.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  colour: PropTypes.string,
  detailFormDirtyRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  generalInformationFormDirtyRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  settingFormRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default TabSwitch;
