import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { get } from '../../../lib/strings';
import Loader from '../../../components/Loaders/PageLoader';
import './DocumentLibraryYourDocuments.scss';
import organisationService from '../../../services/organisation/organisation-service';
import documentsService from '../../../services/documents/documents-service';
import reviewsService from '../../../services/reviews/reviews-service';
import contentService from '../../../services/content/content-service';
import { GlobalContext } from '../../../context/GlobalContext';
import themeType from '../../../lib/consts/themeType';
import {
  DocumentLibrarySubsectionMapping,
} from '../../../lib/consts/documentLibraryLocations';

const DocumentLibraryOnCourseDocuments = (props) => {
  const globalContext = useContext(GlobalContext);
  const {
    org,
    user,
    reviewLinks,
    theme,
  } = globalContext;
  const { organisationId } = props;
  const [isPageLoading, updateIsPageLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [questionSetData, setQuestionSetData] = useState(null);
  const [librarySections, setLibrarySections] = useState(null);

  const getDocuments = async () => {
    updateIsPageLoading(true);

    const result = await organisationService.getDocuments(organisationId);

    if (result.ok) {
      setDocuments(result.data.filter((entry) => {
        return entry.oncourse_document === true;
      }));
    }

    updateIsPageLoading(false);
  };

  const { bestPractices } = reviewLinks;
  const getSections = async () => {
    const sections = [];
    const questionSets = {};
    const isTournament = theme === themeType.TOURNAMENT;
    if (isTournament) {
      const tournamentSubsections = DocumentLibrarySubsectionMapping.TOURNAMENTS;
      for (let i = 0; i < tournamentSubsections.length; i += 1) {
        sections.push({
          ...tournamentSubsections[i],
        });
      }
    } else {
      if (!bestPractices) {
        return;
      }
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < bestPractices.length; i += 1) {
        const reviewLink = bestPractices[i];
        const questionSet = await reviewsService.getOrganisationQuestionsById(
          reviewLink.id,
          org.organisation_id,
          user.language,
        );

        questionSets[questionSet.id] = questionSet;

        sections.push({
          key: questionSet.id,
          labelString: reviewLink.title,
          slug: `your/${reviewLink.slug}`,
          color: reviewLink.colour,
          filterKeys: [questionSet.id, ...questionSet.sections.map((item) => item.id)],
          icon: reviewLink.icon,
        });
      }
    }

    sections.push({
      ...DocumentLibrarySubsectionMapping.OTHER,
    });

    setQuestionSetData(questionSets);
    setLibrarySections(sections);
  };

  useEffect(() => {
    getSections();
    if (documents !== null) {
      getDocuments();
    }
  }, []);

  const downloadDocument = async (documentId) => {
    return contentService.getDownloadDocumentByDocId(documentId);
  };

  return (
    <div>
      <Loader isLoading={isPageLoading}>
        <div className="component__document-library">
          <div className="document-library-your-documents-latest-card">
            <div className="document-library-your-documents-latest-header">
              <p className="document-library-your-documents-latest-header-title">{get('documentLibrary-oncourseDocuments-title')}</p>
            </div>
            <p className="document-library-your-documents-latest-description">{get('documentLibrary-oncourseDocuments-description')}</p>
            <table>
              <thead>
                <tr>
                  <th scope="col">{get('documentLibrary-documentTable-filename')}</th>
                  <th scope="col">{get('documentLibrary-documentTable-location')}</th>
                  <th scope="col">{get('documentLibrary-documentTable-description')}</th>
                  <th scope="col">{get('documentLibrary-documentTable-date')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  documents.map((document) => (
                    <tr key={document.id}>
                      <td
                        className="document-library-your-documents-latest-download-button --blue"
                        data-label={get('documentLibrary-documentTable-filename')}
                        onClick={() => downloadDocument(document.id)}
                        aria-hidden="true"
                      >
                        {document.blob_filename}
                      </td>
                      <td data-label={get('documentLibrary-documentTable-location')}>
                        {documentsService.locationStringForId(
                          document.location,
                          questionSetData,
                          librarySections,
                        )}
                      </td>
                      <td data-label={get('documentLibrary-documentTable-description')}>{document.blob_filename !== document.title ? document.title : ''}</td>
                      <td data-label={get('documentLibrary-documentTable-date')}>{format(new Date(document.created_at), 'dd MMMM yyyy')}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </Loader>
    </div>
  );
};

DocumentLibraryOnCourseDocuments.propTypes = {
  organisationId: PropTypes.number.isRequired,
};

export default DocumentLibraryOnCourseDocuments;
